<template>
    <nav class="navbar navbar-light fixed-top">
        <div class="container-fluid d-flex justify-content-center align-items-center px-0">
            <div class="row justify-content-center row__width">
                <div class="col-11 d-flex justify-content-center align-items-center px-0">
                    <div class="row justify-content-center justify-content-lg-between align-items-center row__width">
                        <div class="col-12 col-lg-3 d-flex justify-content-between justify-content-lg-start align-items-center px-0">
                            <div class="d-flex justify-content-start align-items-center">
                                <button class="navbar-toggler me-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar5" aria-controls="offcanvasNavbar5">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <a class="navbar-brand ms-lg-3 d-flex justify-content-center align-items-center" href="#">Masterclass <p>PLUS</p> </a>
                            </div>
                            <button class="btn_none d-none"><img src="../../img/menu-icons/lupa-blanca.svg" alt=""></button>
                        </div>
                        <div class="col-lg-9 d-flex justify-content-center align-items-center row__lg__nav px-0 d-none d-lg-flex">
                            <div class="row justify-content-end align-items-center row__width">
                                <div class="col d-flex justify-content-end align-items-center">
                                    <button class="btn_none" @click="funcionBuscador"><img src="../../img/menu-icons/lupa-blanca.svg" alt="" :class="botonBuscador == true ? 'animate__animated  animate__fadeIn' : 'animate__animated animate__fadeOut'" v-if="botonBuscador"></button>
                                    <div class="d-flex justify-content-between align-items-center buscador" :class="buscadorDiv == true ? 'animate__animated  animate__fadeIn' : 'animate__animated animate__fadeOut'" v-if="buscadorDiv">
                                        <input type="text" placeholder="Buscar">
                                        <button class="btn_none" @click="funcionBuscador"><img src="../../img/menu-icons/lupa-blanca.svg" alt=""></button>
                                    </div>
                                    <ul class="ul_precios pb-0 px-0 mb-0">
                                        <li class="nav-item dropdown" >
                                            <a class="nav-link dropdown-toggle justify-content-center"  href="#" id="offcanvasNavbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src="../../img/menu-icons/mundo-blanco.svg" alt="" class="me-2">
                                                    MX
                                                <img src="../../img/menu-icons/arrow-abajo-blanco.svg" alt="" class=" ms-2 me-0">
                                            </a>
                                            <ul class="dropdown-menu paises" aria-labelledby="offcanvasNavbarDropdown">
                                                <li class="d-flex justify-content-between align-items-center">
                                                    {{ $t('message.currency') }} 
                                                    <div class="d-flex justify-content-center align-items-center">
                                                        <p>MXN</p>
                                                        <img src="../../img/nav-icons/arrow-rigth-negro.svg" alt="">
                                                    </div>
                                                </li>
                                                <li class="d-flex justify-content-between align-items-center">
                                                    {{ $t('message.language') }} 
                                                    <div class="d-flex justify-content-center align-items-center">
                                                        <p>{{ $t('message.spanish') }}</p>
                                                        <img src="../../img/nav-icons/arrow-rigth-negro.svg" alt="">
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <button class="btn_none col-1 d-flex p-0" style="max-width: 40px;" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasCarrito" aria-controls="offcanvasCarrito">
                                        <img src="../../img/nav-icons/carrito-blanco.svg" alt="">
                                    </button>
                                    <button class="btn_none col-1 d-flex" style="max-width: 40px;"><img src="./../../img/nav-icons/notificacion-blanco.svg" alt=""></button>
                                    <ModalFoto/>
                                </div>
                            </div>
                        </div>
                        <MenuHome/>
                        <Menu/>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script setup>
    import MenuHome from "./MenuHome.vue";
    import Menu from "../Carrito/Menu.vue";
    import { ref } from "vue";
import ModalFoto from "./ModalFoto.vue";

    const buscadorDiv = ref(false)

    const botonBuscador = ref(true)

    const funcionBuscador = () => {
        if(buscadorDiv == false){
            buscadorDiv = true
            botonBuscador = false
        }else{
            buscadorDiv = false
            botonBuscador = true
        }
    }
</script>

<style lang="scss" scoped>
.navbar{
    background-color: var(--colorSecondary);
    box-shadow: 0px 3px 6px #00000029;
    height: 68px;
    .container-fluid{
        height: 100%;
    }
    .navbar-toggler{
        border: 0;
        padding: 0;
        outline: 0;
        .navbar-toggler-icon{
            background-image: url('../../img/menu-icons/menu-icon-blanco.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            height: 25px;
            width: 29px;
            filter: invert(1);
        }
        &:focus{
            outline: 0;
            box-shadow: none;
        }
    }
    .nav-link{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--enlacesNav);
        font-size: 15px;
        font-weight: 500;
        text-align: left;
        margin: 10px 0px;
        position: relative;
        img{
            margin-right: 20px;
        }
        .position{
            position: absolute;
            right: 0;
        }
        i{
            margin-right: 15px;
            min-height: 23px;
            min-width: 23px;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
        }
        .cursos{
            background-image: url('../../img/menu-icons/cursos.svg');
        }
        .categorias{
            background-image: url('../../img/menu-icons/categorias.svg');
        }
        .escuela{
            background-image: url('../../img/menu-icons/escuela.svg');
        }
        .concursos{
            background-image: url('../../img/menu-icons/concursos.svg');
        }
        .nosotros{
            background-image: url('../../img/menu-icons/nosotros.svg');
        }
        .contacto{
            background-image: url('../../img/menu-icons/contacto.svg');
        }
        .premium{
            background-image: url('../../img/menu-icons/premium.svg');
        }
        &:focus, &:hover{
            color: var(--colorPrimary);
            font-weight: 700;
            &::before{
                content: '';
                background-color: var(--colorPrimary);
                height: 100%;
                position: absolute;
                width: 5px;
                border-radius: 10px;
                left: -8.5%;
                top: 0;
            }
            .cursos{
                background-image: url('../../img/menu-icons/cursos_active.svg');
            }
            .categorias{
                background-image: url('../../img/menu-icons/categorias_active.svg');
            }
            .escuela{
                background-image: url('../../img/menu-icons/escuela_active.svg');
            }
            .concursos{
                background-image: url('../../img/menu-icons/concursos_actives.svg');
            }
            .nosotros{
                background-image: url('../../img/menu-icons/nosotros_active.svg');
            }
            .contacto{
                background-image: url('../../img/menu-icons/contacto_active.svg');
            }
            .premium{
                background-image: url('../../img/menu-icons/premium-active.svg');
            }
        }
    }
    .navbar-brand{
        font-weight: 700;
        font-size: 22px;
        color: #FAFAFA;
        margin: 0;
        p{
            font-weight: inherit;
            font-size: inherit;
            color: #F6BE14;
            padding-left: 5px;
        }
    }
    .dropdown-toggle::after{
        border: 0;
    }
    ul{
        h4{
            color: var(--grisPrimario);
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            padding: 0 0 10px 0;
        }
        li{
            padding: 0;
        }
    }
    .btns{
        margin: 10px 0px;
    }
    .cuenta_a{
            background-color: transparent;
            border: 0;
            outline: 0;
            max-width: 60px !important;
            height: 100%;
            position: relative;
            .foto-perfil{
                height: 100%;
                width: 100%;
                border-radius: 50%;
                object-fit: cover;
            }
            .cuenta__a_ul{
                position: absolute;
                border-radius: 20px;
                top: 100%;
                background-color: var(--bgPrincipal);
                box-shadow: 0px 3px 6px #0000002a;
                right: 10%;
                padding: 20px;
                width: 250px;
                display: none;
                li{
                    width: 100%;
                    padding: 10px 0px 10px 0px;
                    img{
                        margin-left: 10px;
                    }
                }
                .borde{
                    border-top: 1px solid #c6bacc7c;
                    padding: 15px 0px;
                    img{
                        margin-right: 10px;
                        margin-left: 0;
                    }
                }
            }
            &:hover,&:focus{
                color: inherit;
                &::before{
                    display: none;
                }
                ul{
                    display: block;
                }
            }
        }
    .ul_precios{
        padding-left: 0;
        list-style: none;
        padding-bottom: 40px;
        width: 100px;
        li{
            a{
                color: var(--bgPrincipal);
                font-size: 16px;
                font-weight: 500;
                text-align: left;
                &:focus, &:hover{
                    color: var(--bgPrincipal) !important;
                }
            }
        }
        .nav-link{
            &:focus, &:hover{
                color: var(--colorSecondary);
                font-weight: 500;
                &::before{
                    display: none;
                }
            }
        }
        .paises{
            border-radius: 20px;
            background-color: var(--bgPrincipal);
            right: 10%;
            padding: 10px 20px;
            width: 250px;
            li{
                width: 100%;
                padding: 10px 0px 10px 0px;
                img{
                    margin-left: 10px;
                }
                .nav-link{
                    &:focus, &:hover{
                        color: var(--enlacesNav);
                        &::before{
                            display: none;
                        }
                    }
                }
            }
            .borde{
                border-top: 1px solid #c6bacc7c;
                padding: 15px 0px;
                img{
                    margin-right: 10px;
                    margin-left: 0;
                }
            }
        }
    }
    .boder-top{
        border-top: 1px solid rgba(184, 193, 219, 0.5);
        h6{
            color: var(--grisSecundario);
            font-size: 16px;
            font-weight: 500;
            text-align: left;
        }
        .form-check-input{
            border-color: var(--colorPrimary);
            position: relative;
            display: flex;
            align-items: center;
        }
        .form-switch .form-check-input {
            height: 25px;
            width: 48px;
            background-image: url('../../img/menu-icons/switch_normal.svg');
            background-size: 42% 100%;
            background-position-x: 2px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                right: 5px;
            }
            &::after{
                display: none;
            }
        }
        .form-switch .form-check-input:checked{
            background-image: url('../../img/menu-icons/switch_active.svg');
            background-position-x: 25px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                filter: brightness(10);
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                left: 5px;
            }
        }
        .form-switch .form-check-input:focus{
            box-shadow: none;
        }

    }
    .navbar-nav .dropdown-menu{
        position: absolute;
    }
    .row__lg__nav{
        .btn_none{
            border: 0;
            background-color: transparent;
            &:focus{
                outline: none;
            }
        }
        .buscador{
            border: 2px solid #fff;
            border-radius: 23px;
            min-height: 45px;
            min-width: 70%;
            padding: 0 7px 0 15px;
            margin-right: 5px;
            transition: all ease 3s;
            input{
                border: 0;
                outline: 0;
                font-weight: 400;
                width: 100%;
                color: #fff;
                font-size: 16px;
                background-color: transparent;
                &::placeholder{
                    color: #fff;
                }
            }
        }
    }
    .offcanvas-carrito{
        min-width: 436px;
        padding: 20px;
        .offcanvas-title{
            font-size: 20px;
            font-weight: 700;
            color: var(--colorSecondary);
        }
        .div{
            min-height: 80%;
            padding: 20px;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: #FCFCFC;
            h2{
                font-size: 20px;
                font-weight: 700;
                color: var(--colorSecondary);
            }
            img{
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 50%;
                margin-bottom: 20px;
            }
            h3{
                font-size: 16px;
                font-weight: 400;
                color: #697891;
                text-align: center;
                padding: 10px 0px;
            }
        }
        .offcanvas .btn-close{
            background-image: url('../../img/carrito-icons/cerrar-modal.svg');
            opacity: 1;
        }
        .borde{
            border: 1px solid rgba(184, 193, 219, 0.5);
        }
        .modal-carrito{
            .offcanvas-header{
                margin-bottom: 10px !important;
            }
            h4{
                font-weight: 700;
                font-size: 22px;
                color: var(--colorSecondary);
            }
            p{
                font-weight: 500;
                font-size: 16px;
                color: #697891;
                padding: 10px 0px;
            }
            .btn{
                max-width: 90%;
            }
        }
    }
    .offcanvas-backdrop{
        backdrop-filter: blur(3px);
        @media (min-width: 992px) {
            top: 95px !important;
            backdrop-filter: blur(10px);
            background-color: var(--colorSecondary);
        }
    }
    @media (min-width: 992px) {
        height: 95px;
    }
    .foto-perfil{
        max-height: 40px;
    }
}
.offcanvas{
    padding: 10px 10px;
    max-width: 85%;
    border-radius: 0px 0px 15px 0px;
    .offcanvas-header{
        align-items: flex-start;
        padding: 10px 25px;
    }
    .btn-close{
        background-image: url('../../img/menu-icons/cerrar.svg');
    }
    .offcanvas-body{
        padding: 10px 25px;
    }
}
.offcanvas-backdrop.show{
    backdrop-filter: blur(3px);
}
.navbar-nav .dropdown-menu{
    position: absolute;
}
</style>