<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start px-lg-0">
        <NavbarCertificados :seccion="$t('message.users')"/>
        <NavLateralPanelAdmin :users="true"/>
        <div class="div-grey right" id="div-grey">
            <button class="boton-toggler" id="toggleSidebar" type="button" @click="toggleSidebar">
                <img src="../../../img/home-icons/arrow-left-blanco.svg" alt="">
            </button>
        </div>
        <div class="col d-flex flex-column justify-content-center align-items-center align-items-lg-start p-lg-3 classSpecial" :style="isSidebarOpen ? 'padding-left: 300px !important;'  : 'padding-left: 24px !important;'">
            <!-- CONTENIDO -->
            <Home v-show="page_id == 1"/>
            <Usuario v-show="page_id == 2" :isSidebarOpen="isSidebarOpen"/>
        </div>
        <FooterCertificados :activeCertificados="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../components/Navs/NavbarCertificados.vue";
import FooterCertificados from "../../../components/Footer/FooterCertificados.vue";
import NavLateralPanelAdmin from "../../../components/Navs/NavLateralPanelAdmin.vue";
import TituloPanelAdmin from "../../../components/Titulo/TituloPanelAdmin.vue";
import Home from "./Home.vue"
import Usuario from "./Usuario.vue"
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../stores/user"
import { onBeforeMount, ref, provide } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

const router = useRouter()

const users = useUsersStore();
const { access, headers } = storeToRefs(users);
const { validate, url } = users;

const algo = onBeforeMount( () => {
    access.value = [1]
    //console.log(access.value) 
    // validate();
}) 

const urlReadUsers = '/api/admin/users'

const page_id = ref(1)
provide('page_id', page_id)

const user_id = ref(0)
provide('user_id', user_id)

const state = ref('c')
provide('state', state)

const users_data = ref()
provide('users_data', users_data)

const isSidebarOpen = ref(true)
provide('isSidebarOpen', isSidebarOpen)

console.log(isSidebarOpen.value)

const toggleSidebar = () => {
    isSidebarOpen.value = !isSidebarOpen.value
    if(document.getElementById('sidebar').classList.contains('d-none')){
        document.getElementById('sidebar').classList.remove('d-none')
    } else{
        document.getElementById('sidebar').classList.add('d-none')
    }
    if(document.getElementById('div-grey').classList.contains('right')){
        document.getElementById('div-grey').classList.remove('right')
        document.getElementById('div-grey').classList.add('left')
    } else{
        document.getElementById('div-grey').classList.remove('left')
        document.getElementById('div-grey').classList.add('right')
    }
}

const fetchUsers = async() => {
    let page = '1'
    const response = await axios.get(url+urlReadUsers+`?page=${page}`, { headers: headers.value })
    users_data.value = response.data.data
    console.log(response)
}
fetchUsers()

provide('fetchUsers', fetchUsers)

</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #fff;
    min-height: 92vh;
    @media (min-width: 992px) {
        margin-top: 77px;
    }
}
.classSpecial{
   @media (min-width: 992px) {
        width: 70vw !important;
   }
   padding-right: 0px !important;
}

</style>