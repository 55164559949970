<template>
    <div class="container-fluid usuario-sesion">
        <Navbar class="d-lg-none"/>
        <NavbarBuscador class="d-none d-lg-flex"/>
        <div class="row justify-content-center align-items-center mt-lg-5">
            <div class="col-11 mt-2 mt-lg-5 pt-5 d-none d-lg-flex">
                <Volver_Inicio/>
            </div>
            <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                <div class="row justify-content-center row__width titulo">
                    <div class="col-12 d-flex justify-content-center align-items-center my-4">
                        <img src="../../img/plus-icons/pago-aprobado.svg" alt="">
                    </div>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                        <h2>¡Tuvimos un problema! Su pedido fue:</h2><span>Rechazado</span>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center max-width">
                <div class="row justify-content-center row__width detalles d-none">
                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                        <p>01/07/2022 | #{{ route.params.payment_id }} | email@example.com</p>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pb-4">
                        <h3>{{ $t('message.cuorse') }} # 01</h3>
                        <h3>MX $4000</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pb-3">
                        <h5>{{$t('message.subtotal')}}:</h5>
                        <h3>MX $4000</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pb-4">
                        <h5>{{ $t('message.disccounts') }}:</h5>
                        <h3>- MX $200</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pt-3">
                        <h6>{{ $t('message.total') }}</h6>
                        <h6>MX $10.000</h6>
                    </div>
                    <div class="col-12 d-flex justify-content-end align-items-center px-0">
                        <p class="metodo">{{ $t('message.paypalText') }}</p>
                    </div>
                </div>
                <div class="row justify-content-center row__width pt-lg-4">
                    <div class="col-12  d-flex justify-content-center align-items-center">
                        <p class="recuerda">Por favor comuníquese con nosotros para resolver este problema</p>
                    </div>
                </div>
                <VolverPagina titulo="Volver al inicio" class="d-lg-none"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import Navbar from '../../components/Navs/Navbar.vue';
import Volver_Inicio from '../../components/Volver/Volver_Inicio.vue';
import BTNPrimario from "../../components/Botones/Primario.vue";
import VolverPagina from "../../components/Volver/VolverPagina.vue";
import NavbarBuscador from "../../components/Navs/NavbarBuscador.vue";
import { useRoute } from 'vue-router';

const route = useRoute()
</script>

<style lang="scss" scoped>
.max__width__login__Desk{
    @media (min-width: 992px) {
        max-width: 30%;
    }
    @media (min-width: 1200px) {
        max-width: 51%;
    }
}
.margin-top{
    margin-top: 5vh;
}
.titulo{
    h2{
        font-weight: 700;
        font-size: 32px;
        color: var(--colorSecondary);
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 767px) {
            font-size: 22px;
        }
    }
    span{
        font-size: 32px;
        font-weight: 700;
        color: #FF576E;
        padding-left: 5px;
        @media (max-width: 767px) {
            font-size: 22px;
        }
    }
}
.detalles{
    p{
        font-size: 14px;
        font-weight: 400;
        color: #7D93B2;
        padding: 3vh 0;
        @media (min-width: 400px) {
            font-size: 16px;
        }
    }
    h3{
        font-weight: 500;
        font-size: 16px;
        color: var(--colorSecondary);
    }
    h5{
        font-weight: 700;
        font-size: 16px;
        color: var(--colorSecondary);
    }
    h6{
        font-size: 22px;
        font-weight: 700;
        color: var(--colorSecondary);
    }
    .metodo{
        font-weight: 400;
        font-size: 16px;
        color: #7D93B2;
        padding: 0;
    }
}
.recuerda{
    font-weight: 500;
    font-size: 14px;
    color: var(--colorSecondary);
    padding: 20px 0;
}
.max-width{
    @media (max-width: 768px) {
        width: 389px !important;
    }
    @media (min-width: 768px) {
        width: 515px !important;
    }
}
</style>