<template>
    <nav class="navbar-menu">
        <div class="container-fluid d-flex justify-content-center align-items-center">
            <div class="row justify-content-center row__width">
                <div class="col-12 d-flex justify-content-center align-items-center px-0">
                    <div class="row justify-content-center justify-content-lg-between align-items-center row__width max-height">
                        <div class="col-12 col-lg-3 d-flex justify-content-between justify-content-lg-start align-items-center px-0">
                            <div class="d-flex justify-content-start align-items-center"> 
                                <button class="btn_none d-lg-none" @click="$router.back(-1)"><img src="../../img/menu-icons/arrow-left-negro.svg" alt="" class="arreglo__img"></button>
                                <router-link to="/" class="navbar-brand m-0 ms-2 d-flex justify-content-center align-items-center" href="#"><img src="../../img/Logo.png" alt=""></router-link>
                            </div>
                            <div class="d-flex justify-content-end align-items-center d-lg-none">
                                <!-- <button class="version">
                                    Version <i></i>
                                </button> -->
                                <!--- BOTÓN ---->
                                <button class="navbar-toggler d-none d-lg-block" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebar" aria-controls="sidebarLabel">
                                    <span class="navbar-toggler-icon"></span>
                                </button>

                                <button class="navbar-toggler d-lg-none" type="button" @click="toggleMenu">
                                   <img src="../../img/nav-icons/menu.svg" alt="">
                                </button>
                            </div>
                        </div>
                        <div class="col-lg-6 d-flex justify-content-center align-items-center row__lg__nav px-0 d-none d-lg-flex certificados">
                            <div class="row justify-content-center align-items-center row__width pe-4">
                                <div class="col d-flex justify-content-end align-items-center px-0 me-4">
                                    <p>{{ $t('message.seeing') }}</p> <h5>{{ seccion }}</h5>
                                </div>
                                <button class="ul_certificados nombre_div_padre p-0 col-4 mb-0 d-flex justify-content-center align-items-center">
                                    <li class="nav-item d-flex flex-column w-100 row">
                                        <div class="nav-link div_borde w-100 d-flex justify-content-start align-items-center">
                                            <!-- FOTO --->
                                            <img :src="userData.avatar" alt="avatar"  class="col-2 d-flex foto-perfil">
                                            <!-- <img src="../../img/nav-icons/perfil-foto.svg" alt="avatar" class="col-2 d-flex foto-perfil" v-else> -->
                                            <div class="col-8 d-flex flex-column justify-content-center align-items-start">
                                                <div class="d-flex justify-content-between align-items-center w-100">
                                                    <!-- NOMBRE --->
                                                    <div class="d-flex justify-content-start align-items-center" style="max-width:100%; min-width: 100%;">
                                                        <h4 v-if="userData && userData.personal_data && userData.personal_data.first_name" class="nombre_div"> {{ userData.personal_data.first_name }}</h4>
                                                        <h4 v-else-if="idUser.team == 1">{{ $t('message.admin') }}</h4>
                                                        <h4 v-else-if="idUser.team == 3">{{ $t('message.professor') }}</h4>
                                                        <h4 v-else-if="idUser !== null && idUser.team >= 4" >{{ $t('message.student') }}</h4>
                                                    </div>
                                                    <!-- FIN NOMBRE --->
                                                    <img src="../../img/menu-icons/arrow-abajo-negro.svg" alt="" class="img_icon ms-2 me-0">
                                                </div>
                                                <h3 v-if="idUser.team == 1">{{ $t('message.modeAdminUppercase') }}</h3>
                                                <h3 v-else-if="idUser !== null && idUser.team >= 4">{{ $t('message.studentUppercase') }}</h3>
                                                <h3 v-else-if="idUser.team == 3">{{ $t('message.professorUppercase') }}</h3>
                                            </div>
                                        </div>
                                        <MenuModal/>
                                    </li>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <div class="offcanvas-menu" :class="isMenuOpen == false ? 'closed' : 'open' ">
        <div class="col-12 d-flex justify-content-end align-items-center py-3">
            <button type="button" class="btn-close d-lg-none" @click="toggleMenu()"></button>
            <button class="boton-toggler d-none d-lg-flex" id="toggleSidebar" type="button" @click="toggleMenu()">
                <span>
                    <img src="../../img/home-icons/arrow-left-blanco.svg" alt="">
                </span>
            </button>
        </div>
        <div class="offcanvas-header col-12 d-flex justify-content-between align-items-center">
            <h5>{{ $t('message.modeAdminUppercase') }}</h5>
            
        </div>
        <div class="offcanvas-body col-12 d-flex flex-column justify-content-start align-items-center position-relative scroll">
            <ul class="navbar-nav justify-content-end row row__width">
                <!-- <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/dashboard" :class="props.dashboard == true ? 'active' : ''">
                        <i class="dashboard"></i>
                        {{ $t('message.dashboard') }}
                    </router-link>
                </li> -->
                <!-- <li class="nav-item">
                    <button class="nav-link btn-upload" role="button" id="professionalUpload" data-bs-toggle="dropdown" aria-expanded="false" :class="props.stats == true ? 'active' : ''" >
                        <i class="stats"></i>
                        {{ $t('message.stats') }}
                        <i class="arrow"></i>
                    </button>
                    <ul class="ul_updload dropdown-menu" aria-labelledby="professionalUpload" style="inset: 0 -20px !important;">
                        <li class="nav-item">
                            <router-link class="nav-link" aria-current="page" :to="'/estadisticas-academicas/'+idUser.id" :class="props.academic == true ? 'active' : ''">
                            <i class="mas"></i> Académicas
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" aria-current="page" :to="'/estadisticas-financieras/'+idUser.id" :class="props.financial == true ? 'active' : ''">
                            <i class="mas"></i> Financieras
                            </router-link>
                        </li>
                    </ul>
                </li> -->
                <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/productos" :class="props.products == true ? 'active' : ''">
                        <i class="products"></i>
                        Productos
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link btn-upload" to="/subir-producto" :class="props.upload == true ? 'active' : ''" >
                        <i class="upload-products"></i>
                        {{ $t('message.uploadProducts') }}
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/usuarios" :class="props.users == true ? 'active' : ''"  >
                        <i class="users"></i>
                        {{ $t('message.users') }}
                    </router-link>
                </li>
                <!-- <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/concursos" :class="props.contests == true ? 'active' : ''"  >
                        <i class="contests"></i>
                        {{ $t('message.contests') }}
                    </router-link>
                </li> -->
                <!-- <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/referidos" :class="props.referrals == true ? 'active' : ''">
                        <i class="referrals"></i>
                        {{ $t('message.referrals') }}
                    </router-link>
                </li> -->
                <div class="px-0">
                    <h5 class="py-3">{{ $t('message.other') }}</h5>
                    <li class="nav-item">
                        <router-link class="nav-link" aria-current="page" to="/ajustes-admin" :class="submenu == true ? 'active' : ''" @click="submenu = true">
                            <i class="ajustes"></i>
                            {{ $t('message.settings') }}
                        </router-link>
                        <ul class="ul_updload ps-4" aria-labelledby="professionalUpload" style="inset: 0 -20px !important;"  v-show="submenu">
                            <li class="nav-link" @click="changePage(1); professionPage = false" :class="actual_page == 1 ? 'active' : ''">
                                {{ $t('message.FAQ') }}
                            </li>
                            <li class="nav-link" @click="changePage(2); professionPage = false" :class="actual_page == 2 ? 'active' : ''">
                                {{ $t('message.benefits') }}
                            </li>
                            <li class="nav-link" @click="changePage(3); professionPage = false" :class="actual_page == 3 ? 'active' : ''">
                                {{ $t('message.values') }}
                            </li>
                            <li class="nav-link" @click="changePage(4); professionPage = false" :class="actual_page == 4 ? 'active' : ''">
                                {{ $t('message.academicSetup') }}
                            </li>
                            <li class="nav-link" @click="changePage(5); professionPage = false" :class="actual_page == 5 ? 'active' : ''">
                                {{ $t('message.categories') }}
                            </li>
                            <li class="nav-link" @click="changePage(6); professionPage = false" :class="actual_page == 6 ? 'active' : ''">
                                {{ $t('message.professions') }}
                            </li>
                        </ul>
                    </li>
                </div>
            </ul>
            <!-- <div class="row justify-content-center row__width position padding">
                <div class="col-12 d-flex justify-content-between align-items-center boder-top">
                    <h6> {{$t('message.mode') }} <strong>{{ $t('message.light') }}</strong></h6>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                    </div>
                </div>
            </div> -->
        </div>
        <router-link to="/" class="nav-link boder-box" @click="logout">
            <i class="log-out"></i>
            {{ $t('message.logOut') }}
        </router-link>
    </div>
    
    <div class="backdrop-menu" @click="toggleMenu" v-if="isMenuOpen"></div>
</template>

<script setup>
import { useGeneralStore } from "../../stores/general"
import { useUsersStore } from "../../stores/user"    
import { storeToRefs } from "pinia";
import { ref, onMounted, onUnmounted, watch, inject } from "vue";
import axios from 'axios';
import { useRouter } from 'vue-router';
import MenuModal from "./Utilidades/MenuModal.vue";
import NavLateralPanel from "./NavLateralPanel.vue";
const router = useRouter()
    
const general = useGeneralStore();
const {} = storeToRefs(general);
const {} = general;

const user = useUsersStore();
const {userData, headers, idUser, userInformation} = storeToRefs(user);
const {} = user;

const props = defineProps({
  seccion: ''
})

const menuAdminMobile = ref(false)

const isMenuOpen = ref(false);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const actual_page = inject('actual_page')
const changePage = inject('changePage')
const submenu = inject('submenu')
const professionPage = inject('professionPage')


const showBanner = () => {
    const sizeWidth = ref(window.innerWidth)
    console.log(sizeWidth.value)
    if(sizeWidth.value < 992){

    }else{
        isMenuOpen.value = true
    }
}
showBanner()
onMounted(() => {
    window.addEventListener("resize", showBanner)
})

onUnmounted(() => {
    window.removeEventListener("resize", showBanner)
})
</script>

<style lang="scss" scoped>
$transition-speed: 0.3s;
.navbar-menu{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    a{
        img{
            max-height: 50px;
            @media (min-width: 992px) {
                max-height: 50px;
            }
        }
    }
    .max-height{
        max-height: 100%;
    }
    .container-fluid{
        height: 100%;
    }
    .navbar-toggler{
        border: 0;
        padding: 0;
        outline: 0;
        .navbar-toggler-icon{
            background-image: url('../../img/menu-icons/menu-icon.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            height: 25px;
            width: 29px;
        }
        &:focus{
            outline: 0;
            box-shadow: none;
        }
    }
    .nav-link{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--enlacesNav);
        font-size: 15px;
        font-weight: 500;
        text-align: left;
        margin: 10px 0px;
        position: relative;
        .position{
            position: absolute;
            right: 0;
        }
        i{
            margin-right: 15px;
            min-height: 23px;
            min-width: 23px;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
        }
        .cursos{
            background-image: url('../../img/menu-icons/cursos.svg');
        }
        .categorias{
            background-image: url('../../img/menu-icons/categorias.svg');
        }
        .escuela{
            background-image: url('../../img/menu-icons/escuela.svg');
        }
        .concursos{
            background-image: url('../../img/menu-icons/concursos.svg');
        }
        .nosotros{
            background-image: url('../../img/menu-icons/nosotros.svg');
        }
        .contacto{
            background-image: url('../../img/menu-icons/contacto.svg');
        }
        .premium{
            background-image: url('../../img/menu-icons/premium.svg');
        }
        &:focus, &:hover{
            color: var(--colorPrimary);
            font-weight: 700;
            &::before{
                content: '';
                background-color: var(--colorPrimary);
                height: 100%;
                position: absolute;
                width: 5px;
                border-radius: 10px;
                left: -8.5%;
                top: 0;
            }
            .cursos{
                background-image: url('../../img/menu-icons/cursos_active.svg');
            }
            .categorias{
                background-image: url('../../img/menu-icons/categorias_active.svg');
            }
            .escuela{
                background-image: url('../../img/menu-icons/escuela_active.svg');
            }
            .concursos{
                background-image: url('../../img/menu-icons/concursos_actives.svg');
            }
            .nosotros{
                background-image: url('../../img/menu-icons/nosotros_active.svg');
            }
            .contacto{
                background-image: url('../../img/menu-icons/contacto_active.svg');
            }
            .premium{
                background-image: url('../../img/menu-icons/premium-active.svg');
            }
        }
    }
    .navbar-brand{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary);
        @media (min-width: 992px) {
            font-size: 30px;
        }
    }
    .dropdown-toggle::after{
        border: 0;
    }
    ul{
        h4{
            color: var(--grisPrimario);
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            padding: 0 0 10px 0;
        }
        li{
            padding: 0;
            width: 100% ;
        }
    }
    .btns{
        margin: 10px 0px;
    }
    .ul_precios{
        padding-left: 0;
        list-style: none;
        padding-bottom: 40px;
        li{
            a{
                color: var(--colorSecondary);
                font-size: 16px;
                font-weight: 500;
                text-align: left;
            }
        }
    }
    .ul_certificados {
        li {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .nav-link {
                &::before {
                    display: none;
                }
            }
            .cuenta__a_ul {
                display: none;
            }
        }
        .dropdown-menu {
            right: 1%;
        }
        &:focus {
            .cuenta__a_ul {
                display: flex;
            }
        }
    }
    .boder-top{
        border-top: 1px solid rgba(184, 193, 219, 0.5);
        h6{
            color: var(--grisSecundario);
            font-size: 16px;
            font-weight: 500;
            text-align: left;
        }
        .form-check-input{
            border-color: var(--colorPrimary);
            position: relative;
            display: flex;
            align-items: center;
        }
        .form-switch .form-check-input {
            height: 25px;
            width: 48px;
            background-image: url('../../img/menu-icons/switch_normal.svg');
            background-size: 42% 100%;
            background-position-x: 2px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                right: 5px;
            }
            &::after{
                display: none;
            }
        }
        .form-switch .form-check-input:checked{
            background-image: url('../../img/menu-icons/switch_active.svg');
            background-position-x: 25px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                filter: brightness(10);
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                left: 5px;
            }
        }
        .form-switch .form-check-input:focus{
            box-shadow: none;
        }

    }
    .navbar-nav .dropdown-menu{
        position: absolute;
    }
    .row__lg__nav{
        .btn_none{
            border: 0;
            background-color: transparent;
            &:focus{
                outline: none;
            }
        }
    }
    .offcanvas-carrito{
        min-width: 436px;
        padding: 20px;
        .offcanvas-title{
            font-size: 20px;
            font-weight: 700;
            color: var(--colorSecondary);
        }
        .div{
            min-height: 80%;
            padding: 20px;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: #FCFCFC;
            h2{
                font-size: 20px;
                font-weight: 700;
                color: var(--colorSecondary);
            }
            img{
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 50%;
                margin-bottom: 20px;
            }
            h3{
                font-size: 16px;
                font-weight: 400;
                color: #697891;
                text-align: center;
                padding: 10px 0px;
            }
        }
        .offcanvas .btn-close{
            background-image: url('../../img/carrito-icons/cerrar-modal.svg');
            opacity: 1;
        }
        .borde{
            border: 1px solid rgba(184, 193, 219, 0.5);
        }
        .modal-carrito{
            .offcanvas-header{
                margin-bottom: 10px !important;
            }
            h4{
                font-weight: 700;
                font-size: 22px;
                color: var(--colorSecondary);
            }
            p{
                font-weight: 500;
                font-size: 16px;
                color: #697891;
                padding: 10px 0px;
            }
            .btn{
                max-width: 90%;
            }
        }
    }
    .offcanvas-backdrop{
        backdrop-filter: blur(3px);
        @media (min-width: 992px) {
            top: 95px !important;
            backdrop-filter: blur(10px);
            background-color: var(--colorSecondary);
        }
    }
    @media (min-width: 992px) {
        height: 77px;
        z-index: 9999;
    }
    .foto-perfil{
        max-height: 40px;
        border-radius: 50%;
        margin-left: 10px;
        object-fit: cover;
    }
    .version{
        border: 2px solid #000000;
        background-color: #fff;
        color: var(--colorSecondary);
        font-size: 14px;
        font-weight: 500;
        outline: 0;
        min-width: 5vw;
        border-radius: 25px;
        padding: 7px 10px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        i{
            display: block;
            background-repeat: no-repeat;
            background-size: cover;
            min-height: 20px;
            min-width: 20px;
            background-image: url('../../img/certificados-icons/version.svg');
            margin-left: 5px;
        }
        &:focus, &:hover{
            border-color: var(--colorPrimary);
            color: var(--colorPrimary);
            i{
                background-image: url('../../img/certificados-icons/version-active.svg');
            }
        }
    }
    @media (max-width: 991px) {
        .arreglo__img{
            height: 2.5vh;
            min-height: 18px;
        }
    }
    .certificados{
        p, h5{
            font-weight: 300;
            font-size: 20px;
            color: var(--colorSecondary);
        }
        h5{
            font-weight: 700;
            padding-left: 5px;
        }
        h4{
            font-weight: 500;
            font-size: 16px;
            color: var(--colorSecondary);
            padding-bottom: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
            min-width: 100%;
           
        }
        h3{
            color: #B8C1DB;
            font-size: 14px;
            font-weight: 400;
            padding-top: 3px;
        }
        .foto-perfil{
            max-height: none;
            min-height: 43px;
            min-width: 43px;
            margin-right: 7px;
            border-radius: 50%;
        }
        button{
            border: 0;
            background-color: transparent;
            outline: 0;
            &:focus, &:hover{
                &:before{
                    display: none;
                }
            }
        }
        .div_borde{
            border-left: 1px solid #0000001a;
        }
    }
    @media (max-width: 992px) {
        position: fixed;
    }
}

.navbar-menu {
    position: fixed;
    top: 0;
    height:70px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
    a{
        img{
            max-height: 50px;
            @media (min-width: 992px) {
                max-height: 50px;
            }
        }
    }
    .navbar-brand {
        a {
        color: #000;
        font-size: 1.5rem;
        text-decoration: none;
        }

        .menu-toggle {
            background: none;
            border: none;
            cursor: pointer;
        
        }
    }
}

.offcanvas-menu {
    position: fixed;
    top: 0;
    left: -100%;
    width: 300px;
    height: 100%;
    background-color: #fff;
    box-shadow: -3px 0 5px rgba(0, 0, 0, 0.2);
    transition: left $transition-speed ease;
    padding: 20px;
    z-index: 1000;
    &.open {
        left: 0;
    }
    .offcanvas-body{
        animation: none;
        padding: 0;
    }
    h5{
        color: #B8C1DB;
        font-weight: 700;
        font-size: 16px;
        padding-top: 10px;
        text-transform: uppercase;
        text-align: left;
    }
    .nav-link{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--enlacesNav);
        font-size: 15px;
        font-weight: 500;
        text-align: left;
        margin: 5px 0px;
        position: relative;
        .position{
            position: absolute;
            right: 0;
        }
        i{
            margin-right: 15px;
            min-height: 25px;
            min-width: 27px;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
        }
        .dashboard{
            background-image: url('../../img/menu-icons/dashboard.svg');
        }
        .stats{
            background-image: url('../../img/menu-icons/stats.svg');
        }
        .users{
            background-image: url('../../img/menu-icons/users.svg');
        }
        .contests{
            background-image: url('../../img/menu-icons/contest.svg');
        }
        .referrals{
            background-image: url('../../img/menu-icons/refrerrals.svg');
        }
        .arrow{
            background-image: url('../../img/menu-icons/arrow.svg');
            transform: rotate(-180deg);
            transition: all ease 0.2s;
            margin-left: 20px;
        }
        .ajustes{
            background-image: url('../../img/certificados-icons/ajustes.svg');
        }
        .log-out{
            background-image: url('../../img/certificados-icons/log-out.svg');
        }
        .upload-products{
            background-image: url('../../img/menu-icons/upload-products.svg');
        }
        .products{
            background-image: url('../../img/certificados-icons/productos.svg');
        }
        &:focus, &:hover{
            color: var(--colorPrimary);
            font-weight: 700;
            &::before{
                content: '';
                background-color: var(--colorPrimary);
                height: 100%;
                position: absolute;
                width: 5px;
                border-radius: 10px;
                left: -12%;
                top: 0;
            }
            .dashboard{
                background-image: url('../../img/menu-icons/dashboard-active.svg');
            }
            .stats{
                background-image: url('../../img/menu-icons/stats-active.svg');
            }
            .users{
                background-image: url('../../img/menu-icons/users-active.svg');
            }
            .contests{
                background-image: url('../../img/menu-icons/contest-active.svg');
            }
            .referrals{
                background-image: url('../../img/menu-icons/refrerrals-active.svg');
            }
            .arrow{
                background-image: url('../../img/menu-icons/arrow-azul.svg');
                transform: rotate(0deg);
                transition: all ease 0.2s;
                margin-left: 20px;
            }
            .ajustes{
                background-image: url('../../img/certificados-icons/ajustes-active.svg');
            }
            .log-out{
                background-image: url('../../img/certificados-icons/log-out-active.svg');
            }
            .upload-products{
                background-image: url('../../img/menu-icons/upload-products-active.svg');
            }
            .products{
                background-image: url('../../img/certificados-icons/productos-active.svg');
            }
        }
        
    }
    .active{
        color: var(--colorPrimary) !important;
        font-weight: 700;
        &::before{
            content: '';
            background-color: var(--colorPrimary);
            height: 100%;
            position: absolute;
            width: 5px;
            border-radius: 10px;
            left: -12%;
            top: 0;
        }
        .dashboard{
            background-image: url('../../img/menu-icons/dashboard-active.svg');
        }
        .stats{
            background-image: url('../../img/menu-icons/stats-active.svg');
        }
        .users{
            background-image: url('../../img/menu-icons/users-active.svg');
        }
        .contests{
            background-image: url('../../img/menu-icons/contest-active.svg');
        }
        .referrals{
            background-image: url('../../img/menu-icons/refrerrals-active.svg');
        }
        .arrow{
            background-image: url('../../img/menu-icons/arrow-azul.svg');
            transform: rotate(0deg);
            transition: all ease 0.2s;
            margin-left: 20px;
        }
        .ajustes{
            background-image: url('../../img/certificados-icons/ajustes-active.svg');
        }
        .log-out{
            background-image: url('../../img/certificados-icons/log-out-active.svg');
        }
        .upload-products{
            background-image: url('../../img/menu-icons/upload-products-active.svg');
        }
        .products{
            background-image: url('../../img/certificados-icons/productos-active.svg');
        }
    }
    .navbar-brand{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary);
        @media (min-width: 992px) {
            font-size: 30px;
        }
    }
    .dropdown-toggle::after{
        border: 0;
    }
    ul{
        h4{
            color: var(--grisPrimario);
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            padding: 0 0 10px 0;
        }
        li, .nav-item{
            padding: 0;
            // ul{
            //     display: none;
            // }
            // &:hover, &:focus{
            //     ul{
            //         display: block;
            //     }
            // }
        }
    }
    .btn-upload{
        background-color: transparent;
        border: 0;
    }
    .ul_updload{
        list-style: none;
        position: relative !important;
        padding: 0;
        border: 0;
        outline: none;
        transform: none !important;
        inset: 0 -20px !important;
        .nav-item{
            padding-left: 15px;
            color: var(--enlacesNav);
            font-size: 15px;
            font-weight: 500;
            &:hover, &:focus{
                background-color: #F8F8F9;
                border-radius: 10px;
            }
            a, .nav-link{
                &:hover, &:focus{
                    &::before{
                        display: none !important;
                    }
                    
                }
            }
            .nav-link.active{
                &::before{
                    display: none !important;
                }
                .mas{
                    background-image: url('../../img/menu-icons/mas-active.svg');
                }
            }
            i{
                margin-right: 15px;
                min-height: 25px;
                min-width: 27px;
                background-repeat: no-repeat;
                background-position: center;
                display: block;
            }
            .mas{
                background-image: url('../../img/menu-icons/mas.svg');
            }
        }
    }
    .ul_updload.show{
        inset: 0 -20px !important;
    }
    .btns{
        margin: 10px 0px;
    }
    .boder-top{
        padding-top: 35%;
        h6{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            strong{
                font-size: inherit;
            }
        }
        .form-check-input{
            border-color: var(--colorPrimary);
            position: relative;
            display: flex;
            align-items: center;
        }
        .form-switch .form-check-input {
            height: 25px;
            width: 48px;
            background-image: url('../../img/menu-icons/switch_normal.svg');
            background-size: 42% 100%;
            background-position-x: 2px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                right: 5px;
            }
            &::after{
                display: none;
            }
        }
        .form-switch .form-check-input:checked{
            background-image: url('../../img/menu-icons/switch_active.svg');
            background-position-x: 25px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                filter: brightness(10);
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                left: 5px;
            }
        }
        .form-switch .form-check-input:focus{
            box-shadow: none;
        }
    }
    .navbar-nav .dropdown-menu{
        position: absolute;
    }
    .boder-box{
        box-shadow: 0px 3px 10px #00000033;
        background-color: #fff;
        padding: 10px 10px 15px 30px;
        position: absolute;
        z-index: 1449;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 60px;
        .nav-link{
            &::before{
                display: none !important;
            }
        }
    }
    .scroll{
        overflow-y: scroll;
        padding-bottom: 90px;
        max-height: 95vh;
        &::-webkit-scrollbar{
            width: 5px;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-track{
            background-color: transparent;
            width: 5px;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #02020262;
            width: 5px;
            border-radius: 5px;
        }
        @media (min-width: 992px) {
            max-height: 85vh;
        }
    }

    @media (min-width: 992px) {
        z-index: 0;
        background-color: #fff;
        height: 100vh;
        position: relative;
        padding-top: 100px;
        &.closed {
            width: 0 !important; 
            left: -20%;
            .boton-toggler{
                position: fixed;
                left: 5px;
            }
        }
        &.open {
            width: 300px; 
            
            
        }
    }
}

.boton-toggler{
    position: absolute;
    transition: left $transition-speed ease;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000018;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    right: -10px;
    padding: 0;
    span{
        background-color: var(--colorPrimary);
        width: 75%;
        height: 75%;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
}

// Backdrop styles
.backdrop-menu {
  @media (max-width: 992px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
}

</style>