<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start ps-lg-0" ref="contentRef">
        <NavbarViendoCursos/>
        <!-- SIDEBAR -->
        <NavModulos/>
        <!-- CONTENIDO -->
        <ModuleZero v-if="modules == 0"/>
        <Video v-else />
        <FooterCertificados :activeCertificados="true" class="d-none"/>
        <div class="espacio2"></div>
        <div class="container-loader" v-if="loader">
            <div class="custom-loader"></div>
        </div>
    </div>
</template>

<script setup>
import FooterCertificados from "../../components/Footer/FooterCertificados.vue";
import NavModulos from "../../components/Navs/NavModulos.vue";
import NavbarViendoCursos from "../../components/Navs/NavbarViendoCursos.vue";
import Video from "../ViendoCurso/Video.vue";
import ModuleZero from "../ViendoCurso/ModuleZero.vue";
import { ref, provide, watch, onMounted, onUnmounted } from 'vue';
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../stores/user";
import { useVideoStore } from "../../stores/video";
import { onBeforeMount } from "vue";
import axios from 'axios';
import { useRoute } from 'vue-router';

const route = useRoute()

const users = useUsersStore();
const {access, idUser} = storeToRefs(users);
const {validate} = users;

const video = useVideoStore();
const {videosDurations, totalTimeVideo} = storeToRefs(video);
const {verifiedPlataform, getTotalDuration} = video;

const url = process.env.VUE_APP_BASE_URL

const urlFetchCourse = '/api/course/' // lang, slug, pId, userId
const getQuiz = '/api/quiz/get/random/question/' // lang - lessonId - userId - repeat 0-1
const urlAllResources = '/api/product/course/modules/resources/' // pId
const urlStart = '/api/student/set/course/start'
const urlSetLesson = '/api/student/set/course/lesson/start'
const urlGetFPStatus = '/api/student/get/course/fp/' //fpId - userId

const algo = onBeforeMount( () => {
    access.value = [1, 2, 3, 4, 5]
    //console.log(access.value) 
    validate();
}) 

const response = ref()
const content = ref([])
let quizz = ref({})
let quizzList = ref()
const modZero = ref()
const modules = ref(0)
const lessonIds = ref([])
const nextPageName = ref()
const currentLessonInfo = ref()
const modComplete = ref(0)
const loader = ref(false)
let remainingSeconds = ref(0)
let allResources = ref()
let success = ref(false)
let evaluating = ref(0)
let finalProjectContent = ref()
let actualLessonId = ref()
let courseProgress = ref()
let nextBlock = ref(false)
const offcanvasRef = ref(null);
const contentRef = ref(null);

const backdrop = ref(true)

provide('response', response)
provide('content', content)
provide('quizz', quizz)
provide('quizzList', quizzList)
provide('modZero', modZero)
provide('modules', modules)
provide('lessonIds', lessonIds)
provide('nextPageName', nextPageName)
provide('currentLessonInfo', currentLessonInfo)
provide('modComplete', modComplete)
provide('loader', loader)
provide('remainingSeconds', remainingSeconds)
provide('allResources', allResources)
provide('success', success)
provide('evaluating', evaluating)
provide('finalProjectContent', finalProjectContent)
provide('backdrop', backdrop)
provide('actualLessonId', actualLessonId)
provide('courseProgress', courseProgress)
provide('nextBlock', nextBlock)
provide('offcanvasRef', offcanvasRef)
provide('contentRef', contentRef)

const setStart = async() => {
    let uId = idUser.value.id
    let pId = route.params.slug.split('-').pop()
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    try {
        await axios.post(url+urlStart, { userId: uId, courseId: pId }, { headers: headers.value }).then((result) => {
            // console.log(result)
            if(!result.data.lesson_id){
                actualLessonId.value = 0
            }
            else{
                actualLessonId.value = result.data.lesson_id
            }
            if(result.data.lesson_type == 2){
                fetchQuiz(result.data.lesson_id)
            }
        })
    } catch (error) {
        console.log(error)
    }
}
setStart()

const setLesson = async(lId = 0) => {
    let uId = idUser.value.id
    let pId = route.params.slug.split('-').pop()
    let lessonId = lId == -1 ? actualLessonId.value : lId
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    try {
        await axios.post(url+urlSetLesson, { userId: uId, courseId: pId, lessonId: lessonId }, { headers: headers.value }).then((result) => {
            // console.log(result)
        })
    } catch (error) {
        console.log(error)
    }
}
provide('setLesson', setLesson)

const fetchCourse = async() => {
    loader.value = true
    const lang = JSON.parse(localStorage.getItem('lang'))
    let slug = route.params.slug || null
    let id = route.params.id || 0
    // console.log(idUser.value.id)

    const regex = /(\d+)(?!.*\d)/;
    const match = slug.match(regex);
    try {
        await axios.get(url+urlFetchCourse+lang+'/'+slug+'/'+id+'/'+idUser.value.id).then((result) => {
            //console.log(url+urlFetchCourse+lang+'/'+slug)
            console.log(result)
            response.value = result.data
            modZero.value = result.data.intro
            content.value = result.data.units
            finalProjectContent.value = result.data.certificates.final_proj
            if(result.data.certificates && result.data.certificates.final_proj && !result.data.certificates.final_proj.length == 0){
                getFPStatus()
            }
            //console.log(content.value[0])
            // console.log(finalProjectContent.value)
        })
        modZero.value.information = await verifiedPlataform(modZero.value.video);
        await fetchLessonIds()
        getTotalDuration(videosDurations.value)
        if(response.value.intro.video.includes('vimeo')){
            const segments = response.value.intro.video.split('/');
            const lastSegment = segments[segments.length - 1];
            response.value.intro.video = `https://player.vimeo.com/video/${lastSegment}`;
            console.log(response.value.intro.video)
        }
        setTimeout(() => {
            loader.value = false
        }, 500)
    } catch (error) {
        console.log(error)
        setTimeout(() => {
            loader.value = false
        }, 500)
    }
}
fetchCourse()

const fetchAllResources = async() => {
    let pId = route.params.slug.split('-').pop()
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    try {
        await axios.get(url+urlAllResources+`${pId}`, { headers: headers.value }).then(result => {
            // console.log(result)
            allResources.value = result.data
        })
    } catch (error) {
        console.log(error)
    }
}
fetchAllResources()

// Consultar por cada lección 
const fetchLessonIds = async() => {
    const ids = []
    let isBlocked = false
    ids.push({id: 0, title: 'Introducción', isBlocked: false})
    for (let i = 0; i < content.value.length; i++) {
            const unit = content.value[i]
            
            let unitTotalSeconds = 0; // Variable para almacenar el tiempo total en segundos de cada unidad
            let hours = undefined
            let minutes = undefined
            let seconds = undefined
            unit.complete = 0
            // console.log(unit.complete)
            // console.log(unit.length)
            // console.log(content.value)
        if(unit[0].lessons){
            for (let j = 0; j < unit[0].lessons.length; j++) {
                const lesson = unit[0].lessons[j]
                if(lesson.percentage == 100){
                    unit.complete++
                }
                const lessonInfo = await verifiedPlataform(lesson.video)
                lesson.information = lessonInfo
                // Agregar la duración de la lección al total de la unidad (en segundos)
                if(lesson.information){
                    //console.log(lesson.information.time, lesson.information.time.hours, lesson.information.time.minutes)
                    unitTotalSeconds += lesson.information.time.hours * 3600 + lesson.information.time.minutes * 60 + lesson.information.time.seconds
                    //console.log(unitTotalSeconds)
                }
                // Establecer la variable isBlocked en la lección
                lesson.isBlocked = isBlocked
                // Comprobar si la lección es de tipo 2 y tiene config mandatory y viewed
                if(lesson.lessonType == 2 && lesson.config.mandatory && !lesson.result.viewed){
                    isBlocked = true
                }
                ids.push({id: lesson.lessonId, title: lesson.title, isBlocked: lesson.isBlocked})
            }
        }
        let arr = unit[0].lessons.filter(item => item.lessonType == 2).length
        if(unit.complete === (unit[0].lessons.length - arr)){
            modComplete.value++
        }
        // Calcular el tiempo total de la unidad en la propiedad totalTime
        hours = Math.floor(unitTotalSeconds / 3600)
        minutes =  Math.floor((unitTotalSeconds - (hours * 3600)) / 60)
        seconds = unitTotalSeconds - (hours * 3600) - (minutes * 60)
        // Guardar el tiempo total de la unidad en la propiedad totalTime
        //console.log(hours, minutes, seconds)
        unit.totalTime = {
            hours, minutes, seconds
        };
        unit[0].totalTime = {
            hours, minutes, seconds
        };
        // console.log(unit)
        // console.log(modComplete.value)
    }
    content.value.push([{title: 'Finalizar curso', description: 'Termina el curso!', type: 'final', lessons: []}])
    lessonIds.value = ids
    //console.log(content.value);
    
}
fetchLessonIds()
provide('fetchLessonIds', fetchLessonIds)

const fetchQuiz = async(lessonId, x = 0) => {
    loader.value = true
    let uId = idUser.value.id
    let lang = 'es'
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    try {
        await axios.get(url+getQuiz+`${lang}/${lessonId}/${uId}/${x}`, { headers: headers.value }).then(result => {
            console.log(result)
            quizz.value.quiz = result.data.result
            quizz.value.settings = result.data.settings
            quizz.value.lessonId = lessonId
            quizz.value.result = result.data.status
            remainingSeconds.value = result.data.settings.limit_time * 60
            setTimeout(() => {
                loader.value = false
            }, 500); 
        })
    } catch (error) {
        console.log(error)
        setTimeout(() => {
                loader.value = false
            }, 500); 
    }
}

provide('fetchQuiz', fetchQuiz)

const getFPStatus = async() => {
    let userId = idUser.value.id
    console.log(response.value)
    let fpId = response.value.certificates.final_proj.id
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    try {
        await axios.get(url+urlGetFPStatus+fpId+'/'+userId, { headers: headers.value }).then((result) => {
            console.log(result)
            if(Object.keys(result.data).length === 0){
                console.log('Estoy vacio')
            }
            else {
                response.value.certificates.final_proj.status = result.data
            }
        })
    } catch (error) {
        console.log(error)
    }
}

provide('getFPStatus', getFPStatus)

const currentPage = async(x = null) =>{
    let lang = '/es'
    let userId = `/${idUser.value.id}`
    loader.value = true
    let isBlocked = false
    //console.log(current)
    if(actualLessonId.value == 0){
        modules.value = 0
        //console.log(modules.value)
    }else {
        modules.value = actualLessonId.value
        //console.log(modules.value)
        try {
            await axios.get(url+'/api/product/course/modules/lesson/'+actualLessonId.value+lang+userId).then((result) => {
                console.log(result)
                if(x){
                    quizz.value.result = result.data.lesson.result
                    currentLessonInfo.value = result.data.lesson
                    for (let i = 0; i < content.value.length; i++) {
                        const modules = content.value[i];
                        if (modules[0].lessons.some(lesson => lesson.lessonId == actualLessonId.value)) {
                            const lessonIndex = modules[0].lessons.findIndex(lesson => lesson.lessonId == actualLessonId.value)
                            if (lessonIndex !== -1) {
                                modules[0].lessons[lessonIndex].result = result.data.lesson.result
                            }
                            break;
                        }
                        if(modules[0].lessons){
                            for (let j = 0; j < modules[0].lessons.length; j++) {
                                const lesson = modules[0].lessons[j]
                                // Establecer la variable isBlocked en la lección
                                lesson.isBlocked = isBlocked
                                // Comprobar si la lección es de tipo 2 y tiene config mandatory y viewed
                                if(lesson.lessonType == 2 && lesson.config.mandatory && !lesson.result.viewed){
                                    isBlocked = true
                                }
                            }
                        }
                    }
                }
                else if(actualLessonId.value == -1){
                }
                else{
                    currentLessonInfo.value = result.data.lesson
                    currentLessonInfo.value.percentage = result.data.lesson.lessonPercentage
                    console.log(currentLessonInfo.value)
                    if (currentLessonInfo.value.lessonType_id == 1) {
                        currentLessonInfo.value.resources.forEach(resource => {
                            if (resource.type === 4) {
                                getTaskStatus(resource.resourceId, resource)
                            }
                        })
                    }
                    setLesson(result.data.lesson.lessonId)
                    // console.log(unit)
                    // console.log(modComplete.value)
                }
                setTimeout(() => {
                    loader.value = false
                }, 500);
            })
            if(!currentLessonInfo.value.information){
                console.log(currentLessonInfo.value.information)
                currentLessonInfo.value.information = await verifiedPlataform(currentLessonInfo.value.mainVideo)
                if(currentLessonInfo.value.mainVideo.includes('vimeo')){
                    const segments = currentLessonInfo.value.mainVideo.split('/');
                    const lastSegment = segments[segments.length - 1];
                    currentLessonInfo.value.mainVideo = `https://player.vimeo.com/video/${lastSegment}`;
                    console.log(currentLessonInfo.value.mainVideo)
                }
            }
            //fetchLessonResources()
        } catch (error) {
            console.error(error)
            setTimeout(() => {
                loader.value = false
            }, 500);
        }
    }
}

provide('currentPage', currentPage)

const getTaskStatus = async(resourceId, resource) => {
    let userId = idUser.value.id
    let rId = `/${resourceId}`
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    try {
        await axios.get(url+'/api/student/get/course/task/'+userId+rId, { headers: headers.value }).then((result) => {
            console.log(result)
            if(Object.keys(result.data).length === 0){
                console.log('Estoy vacio')
                console.log(resource)
            }
            else {
                resource.taskId = result.data.taskId
                resource.status = result.data.status
                console.log(resource)
            }
        })
        setTimeout(() => {
            loader.value = false
        }, 500)
    } catch (error) {
        console.log(error)
        setTimeout(() => {
            loader.value = false
        }, 500)
    }
}

const showBanner = () => {
    const sizeWidth = ref(window.innerWidth)
    //console.log(sizeWidth.value)
    if(sizeWidth.value < 992){
        backdrop.value = true
        // console.log(backdrop.value)
    }else{
        backdrop.value = false
        // console.log(backdrop.value)
    }
}
showBanner()

onMounted(() => {
    window.addEventListener("resize", showBanner)
})

onUnmounted(() => {
    window.removeEventListener("resize", showBanner)
})

</script>

<style lang="scss" scoped>
#app, .container-fluid{
    @media (min-width: 992px) {
        margin-top: 77px;
    }
}
.caja{
    transition: width 0.3s ease-in-out !important;
    @media (max-width: 991px) {
        padding: 0;
    }
    @media (min-width: 992px) {
        position: relative;
        padding: 20px 30px;
    }
}
.padding{
    padding: 30px 0 15px 0;
}
.show + .caja{
    @media (min-width: 992px) {
        transition: width 0.3s ease-in-out !important;
    }
}
.botones{
    span{
        background-color: var(--bgPrincipal);
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #F5F5F5;
        border-radius: 21px;
        width: 42px;
        height: 42px;
        margin: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    button{
        border: 0;
        background-color: transparent;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        color: #697891;
        padding: 10px 20% 10px 0;
        border-bottom: 1px solid #E4E4E4;
    }
    .siguiente{
        font-weight: 600;
        padding: 10px 0 10px 20%; 
        border-left: 1px solid #E4E4E4;
    }
}
.border-bottom-viendo{
    border-bottom: 1px solid #E4E4E4;
    height: 80px;
}
.video{
    video{
        width: 100%;
    }
}
.titulo{
    h2{
        font-weight: 700;
        font-size: 25px;
        color: var(--colorSecondary);
        text-align: left;
    }
    input{
        display: none;
    }
    input + label{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 30px;
        font-weight: 500;
        font-size: 14px;
        color: var(--colorSecondary);
        text-align: left;
        @media (min-width: 1600px) {
            font-size: 16px;
        }
        &::before{
            content: '';
            position: absolute;
            border: 2px solid #E4E4E4;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    input:checked + label::before{
        background-image: url('../../img/viendo-icons/unidad-finalizada.svg');
        background-size: cover;
    }
}
.tablas{
    h6{
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
        text-align: left;
        padding: 2vh 0 6vh 0;
    }
    .tab-pane{
        padding: 0;
    }
    .nav-link{
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        color: #B8C1DB;
        position: relative;
        padding: 10px 0;
        border: 0;
    }
    .nav-link.active{
        font-weight: 700;
        border: 0;
        color: var(--colorSecondary);
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 5px;
            bottom: 0;
            left: 0;
            background-color: var(--colorPrimary);
            
        }
        @media (min-width: 992px) {
            &::before{
                height: 4px;
            }   
        }
    }
    .scroll{
        overflow-x: scroll;
        max-width: 100%;
        padding: 0 15px;
        ul{
            min-width: max-content;
            li{
                min-width: 150px;
                padding: 0;
            }
        }
        @media (min-width: 992px) {
            overflow: hidden;
            max-width: none;
            width: 100%;
            ul{
                max-width: 100%;
                min-width: 1vw;
                width: 100%;
                li{
                    min-width: max-content;
                }
            }
        }
    }
    .texto_mas{
        p{
            font-weight: 400;
            font-size: 14px;
            color: var(--colorSecondary);
            text-align: left;
        }
    }
    .profesor{ 
        padding: 20px 5px 20px 15px;
        margin: 20px 0px;
        h2{
            font-weight: 700;
            font-size: 16px;
            color: var(--colorSecondary);
            text-align: left;
            @media (min-width: 425px) {
                font-size: 18px;
            }
        }
        h3{
            font-weight: 400;
            font-size: 16px;
            color: var(--colorSecondary);
            padding: 0;
            text-align: left;
        }
        h4{
            font-weight: 700;
            font-size: 16px;
            color: var(--colorSecondary);
            padding: 0;
            text-align: left;
            padding-left: 5px;
            @media (min-width: 425px) {
                font-size: 18px;
            }
        }
        .perfil{
            height: 80px;
            border-radius: 50%;
            @media (min-width: 992px) {
                height: 72px;
            }
        }
        .max-width{
            max-width: 80px;
        }
        .contenedor{
            position: relative;
            .verificado{
                position: absolute;
                bottom: 0;
                right: 0;
                height: 27px;
            }
        }
    }
    .apuntes{
        span{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: var(--colorPrimary);
            color: var(--bgPrincipal);
            font-weight: 600;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .texto{
            max-width: 100%;
            overflow: hidden;
        }
        h3{
            font-weight: 700;
            font-size: 18px;
            color: var(--colorSecondary);
            text-align: left;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            white-space: pre;
            width: 100%;
        }
        .cortado{
            font-weight: 400;
            font-size: 18px;
            color: var(--colorSecondary);
            text-align: left;
            padding-left: 5px;
        }
        .button1{
            border: 0;
            background-color: transparent;
            outline: 0;
            &:focus{
                outline: 0;
            }
        }
        .contenedor{
            border: 2px solid #E4E4E4;
            border-radius: 10px;
            background-color: var(--bgPrincipal);
            min-height: 50px;
            margin-bottom: 10px;
            padding: 0 10px;
            input{
                border: 0;
                outline: 0;
                background-color: transparent;
                font-size: 16px;
                font-weight: 400;
                color: #697891;
                height: 100%;
                width: 95%;
                &:focus{
                    outline: 0;
                }
            }
            .button{
                border: 0;
                outline: 0;
                font-size: 20px;
                font-weight: 600;
                color: #697891;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                &:focus{
                    outline: 0;
                }
            }
            a{
                font-weight: 600;
                font-size: 16px;
                color: var(--colorSecondary);
            }
        }
        .ejemplo{
            border-radius: 10px;
            background-color: #E4E4E4;
            height: 50px;
            font-size: 16px;
            font-weight: 400;
            color: #697891;
            border: 0;
            width: 100%;
            margin-bottom: 10px;
            padding-left: 15px;
            &:focus{
                outline: 0;
            }
            @media (min-width: 992px) {
                background-color: #F5F5F5;
            }
        }
    }
    .colaboradores{
        box-shadow: 0px 7px 6px #00000029;
        background-color: #FFFFFF;
        height: 106px;
        border-bottom: 1px solid #00000029;
        &:first-child{
            margin-top: 20px;
        }
        .perfil{
            height: 56px;
            width: 56px;
            border-radius: 50%;
            object-fit: cover;
        }
        h3{
            font-weight: 600;
            font-size: 16px;
            color: #374557;
            text-align: left;
        }
        h4{
            font-weight: 400;
            font-size: 15px;
            color: #B8C1DB;
            text-align: left;
            padding: 5px 0;
        }
        .comentar{
            font-weight: 400;
            font-size: 15px;
            color: #B8C1DB;
            text-align: left;
        }
        i{
            width: 25px;
            height: 20px;
            background-repeat: no-repeat;
            display: block;
        }
        .like{
            background-image: url('../../img/viendo-icons/like.svg');
        }
        .dislike{
            background-image: url('../../img/viendo-icons/dislike.svg');
        }
        .btn-none{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .max-widthButtons{
            max-width: 40px;
            &:focus, &:hover{
                .like{
                    background-image: url('../../img/viendo-icons/like-a.svg');
                }
                .dislike{
                    background-image: url('../../img/viendo-icons/dislike-a.svg');
                }
            }
        }
        .activeButton{
            .like{
                background-image: url('../../img/viendo-icons/like-a.svg');
            }
            .dislike{
                background-image: url('../../img/viendo-icons/dislike-a.svg');
            }
        }
    }
    .descargas{
        height: 50px;
        &:first-child{
            margin-top: 20px;
        }
        .perfil{
            max-width: 40px;
            padding: 0;
            img{
                max-width: 100%;
                max-height: 25px;
            }
        }
        h3{
            font-weight: 700;
            font-size: 14px;
            color: #0E1133;
            text-align: left;
        }
        h4{
            font-weight: 400;
            font-size: 14px;
            color: #B8C1DB;
            text-align: left;
            padding: 5px 0;
        }
        .comentar{
            font-weight: 400;
            font-size: 15px;
            color: #B8C1DB;
            text-align: left;
        }
        i{
            width: 25px;
            height: 20px;
            background-repeat: no-repeat;
            display: block;
        }
        .btn-none{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .recursos{
        h6{
            font-weight: 700;
            font-size: 22px;
            padding:10px 0 20px 0;
            color: var(--colorSecondary);
        }
    }
    .modulosTab{
        h5{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 25px;
            padding-bottom: 10px;
        }
        h4{
            font-weight: 400;
            font-size: 16px;
            color: #697891;
            text-align: left;
        }
        .progreso{
            .gris{
                border-radius: 50%;
                width: 56px;
                height: 56px;
                position: relative;
                background-color: #E3E3E3;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                .blanco{
                    background-color: #fff;
                    height: 48px;
                    width: 48px;
                    border-radius: 50%;
                    display: flex;       
                    justify-content: center;
                    align-items: center; 
                    z-index: 1;
                    h6{
                        font-weight: 600;
                        font-size: 18px;
                        color: var(--colorPrimary);
                        padding: 0;
                    }
                }
                .azul{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-color: var(--colorPrimary);
                    width: 20%;
                    height: 110%;
                    max-height: 103%;
                    border-radius: 35%;
                    transform: rotate(0);
                    transition: all 1s ease-out;;
                }
            }
        }
    }
}
.quizSeccion{
    padding: 20px 0;
    .titulo{
        h2{
            font-weight: 700;
            font-size: 25px;
            color: var(--colorSecondary);
            text-align: left;
        }
        h3{
            font-weight: 600;
            font-size: 18px;
            color: var(--colorSecondary);
            text-align: left;
            padding-top: 5px;
        }
        h4{
            font-weight: 600;
            font-size: 22px;
            color: var(--colorSecondary);
            text-align: left;
            padding: 15px 0;
        }
        h5{
            font-weight: 400;
            font-size: 14px;
            color: var(--colorSecondary);
            text-align: left;
            line-height: 22px;
        }
        
        input{
            display: none;
        }
        input + label{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-left: 30px;
            font-weight: 500;
            font-size: 14px;
            color: var(--colorSecondary);
            text-align: left;
            @media (min-width: 1600px) {
                font-size: 16px;
            }
            &::before{
                content: '';
                position: absolute;
                border: 2px solid #E4E4E4;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                left: 24%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        input:checked + label::before{
            background-image: url('../../img/viendo-icons/unidad-finalizada.svg');
            background-size: cover;
        }
        
    }
    .border_bottom{
            border-bottom: 1px solid #E4E4E4;
        }
    .checks1{
        input{
            display: none;
        }
        input + label{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border: 2px solid #E9E3E3;
            border-radius: 5px;
            padding: 10px;
            margin: 10px 0 15px 0;
            height: 50px;
            .span{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                border: 2px solid #E4E4E4;
                width: 21px;
                height: 21px;
                left: 0;
                &::after{
                    content: '';
                    position: absolute;
                    border-radius: 50%;
                    background-color: var(--colorPrimary);
                    width: 11px;
                    height: 11px;
                    display: none;
                }
            }
            h5{
                color: #697891;
                font-size: 16px;
                font-weight: 400;
                text-align: left;
                padding-left: 15px;
            }
        }
        input:checked + label .span{
            border-color: var(--colorPrimary);
            &::after{
                display: block;
            }
        }
    }
    .checks2{
        border: 2px solid #E9E3E3;
        border-radius: 5px;
        background-color: transparent;
        height: auto;
        input{
            display: none;
        }
        input + label{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px 0;
            h5{
                color: #697891;
                font-size: 16px;
                font-weight: 400;
                text-align: left;
                padding-left: 35px;
            }
            &:before{
                width: 21px;
                height: 21px;
                border: 2px solid #E4E4E4;
                border-radius: 5px;
                content: '';
                position: absolute;
                border-radius: 8px;
                left: 0;
            }
        }
        input:checked + label::before{
            background-image: url('../../img/viendo-icons/tick-azul.svg');
            background-color: var(--colorPrimary);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
    .checks3{
        border: 2px solid #E9E3E3;
        border-radius: 5px;
        background-color: transparent;
        height: 200px;
        .scroll{
            max-width: 100%;
            overflow: scroll;
            overflow-y: hidden;
        }
        .margin{
            margin-right: 15px;
            max-width: max-content;
        }
        input{
            display: none;
        }
        input + label{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            height: 150px;
            width: 240px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 5px;
            }
            &::after{
                content: '';
                position: absolute;
                background-color: #0D0505;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                opacity: 0.16;
            }
            &::before{
                width: 21px;
                height: 21px;
                border-radius: 50%;
                content: '';
                position: absolute;
                right: 15px;
                bottom: 15px;
                z-index: 1;
            }
        }
        input:checked + label::before{
            background-image: url('../../img/viendo-icons/tick-azul.svg');
            background-color: var(--colorPrimary);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
        input:checked + label{
            border: 2px solid var(--colorPrimary);
            border-radius: 5px;
            box-shadow: 0px 3px 6px #00000029;
        }
    }
    h6{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
        line-height: 24px;
    }
    .validar{
        input{
            display: none;
        }
        .checkNombre{
            input + label{
                width: 100%;
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 0;
                h5{
                    color: #697891;
                    font-size: 15px;
                    font-weight: 400;
                    text-align: left;
                    padding-left: 30px;
                }
                &:before{
                    width: 21px;
                    height: 21px;
                    border: 2px solid #E4E4E4;
                    border-radius: 5px;
                    content: '';
                    position: absolute;
                    border-radius: 8px;
                    left: 0;
                }
            }
            input:checked + label::before{
                background-image: url('../../img/viendo-icons/tick-azul.svg');
                background-color: var(--colorPrimary);
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            } 
        }
        
    }
    .checkUnidad{
        padding: 40px 0;
        border-top: 1px solid #E4E4E4;
        border-bottom: 1px solid #E4E4E4;
        input{
            display: none;
        }
        input + label{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 0;
            h5{
                color: var(--colorSecondary);
                font-size: 16px;
                font-weight: 600;
                text-align: left;
                padding-left: 30px;
            }
            &:before{
                width: 30px;
                height: 30px;
                border: 2px solid #E4E4E4;
                border-radius: 50%;
                content: '';
                position: absolute;
                left:20%;
            }
        }
        input:checked + label::before{
            background-image: url('../../img/viendo-icons/unidad-finalizada.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }  
    }
}

.container-loader{
    background-color: var(--bgPrincipal);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1045;
}
.custom-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#1679FC 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#1679FC);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation:s3 1s infinite linear;
  z-index: 1046;
}
@keyframes s3{ 
  100%{transform: rotate(1turn)}
}
</style>