<template>
    <div class="row unete">
        <div class="col-12 d-flex justify-content-between align-items-center">
            <h5>{{$t('message.joinMasterclass')}}</h5>
            <BTNPrimario :textoBTNPrimario="$t('message.bePremium')" :sinBack=true />
        </div>
    </div>
</template>

<script setup>
    import BTNPrimario from "../Botones/Primario.vue";
    import { useGeneralStore } from "../../stores/general"
    import { storeToRefs } from "pinia";

    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;
</script>

<style lang="scss" scoped>
.unete{
    width: 100%;
    background-color: #F5F5F5;
    box-shadow: 0px 3px 6px #00000029;
    min-height: 50px;
    position: fixed;
    bottom: 95px;
    padding: 10px;
    z-index: 99;
    h5{
        font-weight: 700;
        font-size: 18px;
        color: var(--colorSecondary);
        min-width: 60%;
    }
}
</style>