<template>
    <div class="row row justify-content-center row__width condiciones">
        <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0 px-lg-auto">
            <h3 class="d-lg-none">{{ $t('message.completeBuyAcceptTerms') }}<a href="">{{ $t('message.termsOfUse') }}</a></h3>
            <h3>{{ $t('message.personalInfoPurchase') }}</h3>
        </div>
    </div>
</template>

<script setup>
  
</script>

<style lang="scss" scoped>
.condiciones{
  padding: 40px 0px 0px 0;
  h3{
    font-weight: 400;
    font-size: 16px;
    color: #7D93B2;
    padding: 0 0 10px 0;
    letter-spacing: 0.5px;
    text-align: left;
    a{
      font-size: inherit;
      color: var(--colorPrimary);
      padding-left: 5px;
    }
  }
}
</style>