<template>
    <div class="col-12 d-flex justify-content-center align-items-center px-0 content" v-if="actualActivity != null && viewEditPrice == true">
        <div class="row justify-content-center row__width">
            <div class="col-12 d-flex justify-content-center align-items-center px-0 mt-lg-4">
                <div class="row justify-content-start row__width">
                    <div class="col-12 d-flex justify-content-center align-items-start px-0">
                        <div class="row row__width justify-content-center">
                            <Nombre/>
                            <div class="col-12 d-flex justify-content-start align-items-center mt-4 pt-2 px-0">
                                <h3>{{ $t('message.settingCount') }}</h3>
                            </div>
                            <Precio/>
                            <Porcentaje/>
                        </div>
                    </div>
                    <!--- AFILIADOS OFERTA --->
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center collapses px-0 mt-4" >
                        <div class="accordion d-flex flex-column justify-content-center align-items-center" id="accordionExample">
                            <div class="accordion-item row row__width justify-content-center">
                                <h2 class="accordion-header px-0" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <span>
                                            <img src="../../../img/subir-productos-icons/cursos-icons/porcentaje.svg" alt="">
                                        </span>
                                        <div class=" d-flex flex-column justify-content-center align-items-start ps-3">
                                            <h3>Formas de pago</h3>
                                            <h6>Seleccione las formas de pago que acepta para su producto</h6>
                                        </div>
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="col-12 accordion-collapse collapse px-0" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div class="row  justify-content-center">
                                            <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0 mt-4">
                                                <h3>Formas de pago</h3>
                                                <div class="row justify-content-center row__width">
                                                    <div class="col-12 d-flex justify-content-center align-items-center mb-3 switch">
                                                        <div class="row justify-content-between row__width">
                                                            <div class="col-9 d-flex justify-content-start align-items-center">
                                                                <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                                                                    <input class="form-check-input" type="checkbox" role="switch" id="medio1" :value="1" :checked="actualActivity.paymentType == 1" @change="handleCheckboxChange(1, $event)">
                                                                    <label class="form-check-label" for="medio1"></label>
                                                                </div>
                                                                <h5>Pago único</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-12 d-flex justify-content-center align-items-center mb-3 switch">
                                                        <div class="row justify-content-between row__width">
                                                            <div class="col-9 d-flex justify-content-start align-items-center">
                                                                <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                                                                    <input class="form-check-input" type="checkbox" role="switch" id="medio2" v-model="actualActivity.payment.paypal">
                                                                    <label class="form-check-label" for="medio2"></label>
                                                                </div>
                                                                <h5>Cuotas</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 d-flex justify-content-center align-items-center mb-3 switch">
                                                        <div class="row justify-content-between row__width">
                                                            <div class="col-9 d-flex justify-content-start align-items-center">
                                                                <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                                                                    <input class="form-check-input" type="checkbox" role="switch" id="medio3" v-model="actualActivity.payment.stripe">
                                                                    <label class="form-check-label" for="medio3"></label>
                                                                </div>
                                                                <h5>Reservas</h5>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- PRODUCTO ADICIONAL -->
                            <!-- <div class="accordion-item row row__width justify-content-center">
                                <h2 class="accordion-header px-0" id="heading">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse" aria-expanded="false" aria-controls="collapse">
                                        <span>
                                            <img src="../../../img/subir-productos-icons/cursos-icons/flame-gris.svg" alt="">
                                        </span>
                                        <div class=" d-flex flex-column justify-content-center align-items-start ps-3">
                                            <h3>{{ $t('message.productInformation') }}</h3>
                                            <h6>{{ $t('message.selectCounter') }}</h6>
                                        </div>
                                    </button>
                                </h2>
                                <div id="collapse" class="col-12 accordion-collapse collapse px-0" aria-labelledby="heading" data-bs-parent="#accordionExample">
                                    <div class="accordion-body row justify-content-center mt-3">
                                        <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                            <h3>{{ $t('message.counterTime') }}</h3>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center align-items-center mt-3 px-0 checkboxes">
                                            <div class="row row__width justify-content-start">
                                                <FinContador/>
                                                <NombreCuenta/>
                                                <div class="col-12 col-xl-6 d-flex justify-content-center align-items-center px-0 ps-lg-0 pe-lg-3">
                                                    <div class="row row__width justify-content-between bg-azul">
                                                        <InicioContadorCalendario/>
                                                        <DesdeContadorCalendario/>
                                                        <div class="col-4 col-xxl-3 d-flex justify-content-center align-items-center time px-0">
                                                            <div class="row row__width justify-content-between justify-content-lg-center margin">
                                                                <div class="col col-perso2 d-flex justify-content-center align-items-center px-0 mx-lg-1">
                                                                    <input type="radio" id="am" name="time" value="am" v-model="actualActivity.discount.start.noon">
                                                                    <label for="am">
                                                                        <div class="span">
                                                                            AM
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div class="col col-perso2 d-flex justify-content-center align-items-center px-0 mx-lg-1">
                                                                    <input type="radio" id="pm" name="time" value="pm" v-model="actualActivity.discount.start.noon">
                                                                    <label for="pm">
                                                                        <div class="span">
                                                                            PM
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-xl-6 d-flex justify-content-center align-items-center px-0 pe-lg-0">
                                                    <div class="row row__width justify-content-between bg-azul">
                                                        <FinContadorCalendario/>
                                                        <HastaContadorCalendario/>
                                                        <div class="col-4 col-xxl-3 d-flex justify-content-center align-items-center time px-0">
                                                            <div class="row row__width justify-content-between justify-content-lg-center margin">
                                                                <div class="col col-perso2 d-flex justify-content-center align-items-center px-0 mx-lg-1">
                                                                    <input type="radio" id="am1" name="time1" value="am" v-model="actualActivity.discount.end.noon">
                                                                    <label for="am1">
                                                                        <div class="span">
                                                                            AM
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div class="col col-perso2 d-flex justify-content-center align-items-center px-0 mx-lg-1">
                                                                    <input type="radio" id="pm1" name="time1" value="pm" v-model="actualActivity.discount.end.noon">
                                                                    <label for="pm1">
                                                                        <div class="span">
                                                                            PM
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-lg-3 d-flex justify-content-center align-items-center mt-4">
                                                    <div class="row row__width justify-content-start">
                                                        <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                                            <h5>Color del contador</h5>
                                                        </div>
                                                        <div class="col d-flex justify-content-start align-items-center px-0">
                                                            <input type="radio" id="color1" name="color" :value="1" v-model="actualActivity.discount.color">
                                                            <label for="color1">
                                                                <div class="span rojo"></div>
                                                            </label>
                                                        </div>
                                                        <div class="col d-flex justify-content-start align-items-center px-0">
                                                            <input type="radio" id="color2" name="color" :value="2" v-model="actualActivity.discount.color">
                                                            <label for="color2">
                                                                <div class="span verde"></div>
                                                            </label>
                                                        </div>
                                                        <div class="col d-flex justify-content-start align-items-center px-0">
                                                            <input type="radio" id="color3" name="color" :value="3" v-model="actualActivity.discount.color">
                                                            <label for="color3">
                                                                <div class="span azul"></div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-lg-3 d-flex justify-content-center align-items-center mt-4">
                                                    <div class="row row__width justify-content-start">
                                                        <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                                            <h5>Icono del contador</h5>
                                                        </div>
                                                        <div class="col d-flex justify-content-start align-items-center px-0">
                                                            <input type="radio" id="icon1" name="icon" :value="1" v-model="actualActivity.discount.icon">
                                                            <label for="icon1">
                                                                <i class="star"></i>
                                                            </label>
                                                        </div>
                                                        <div class="col d-flex justify-content-start align-items-center px-0">
                                                            <input type="radio" id="icon2" name="icon" :value="2" v-model="actualActivity.discount.icon">
                                                            <label for="icon2">
                                                                <i class="flame"></i>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <Plazo/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Nombre from '../../../components/Inputs/AgregarPrecioSubirCurso/Nombre.vue';
import Precio from '../../../components/Inputs/AgregarPrecioSubirCurso/Precio.vue';
import Porcentaje from '../../../components/Inputs/AgregarPrecioSubirCurso/Porcentaje.vue';
import FinContador from '../../../components/Inputs/AgregarPrecioSubirCurso/FinContador.vue';
import InicioContadorCalendario from '../../../components/Inputs/AgregarPrecioSubirCurso/InicioContadorCalendario.vue';
import DesdeContadorCalendario from '../../../components/Inputs/AgregarPrecioSubirCurso/DesdeContadorCalendario.vue';
import NombreCuenta from '../../../components/Inputs/AgregarPrecioSubirCurso/NombreCuenta.vue';
import FinContadorCalendario from '../../../components/Inputs/AgregarPrecioSubirCurso/FinContadorCalendario.vue';
import HastaContadorCalendario from '../../../components/Inputs/AgregarPrecioSubirCurso/HastaContadorCalendario.vue';
import Plazo from '../../../components/Inputs/AgregarPrecioSubirCurso/Plazo.vue';

import { inject } from 'vue';
import { useI18n } from "vue-i18n"
import { useLanguageStore } from "../../../stores/langs"

const {locale} = useI18n({ useScope: 'global' })
const langs = useLanguageStore()
const {} = langs

let actualActivity = inject('actualActivity')
let viewEditPrice = inject('viewEditPrice')

const handleCheckboxChange = (value, $event) => {
    if (event.target.checked) {
        actualActivity.value.paymentType = value;
    } else {
        actualActivity.value.paymentType = null; // o cualquier valor por defecto que desees
    }
}

</script>

<style lang="scss" scoped>
h2{
    font-weight: 600;
    color: var(--colorSecondary);
    font-size: 22px;
    text-align: left;
    margin-bottom: 10px !important;
    width: 100%; 
}
h3{
    font-weight: 600;
    color: var(--colorSecondary);
    font-size: 18px;
    text-align: left;
    margin-bottom: 10px !important;
    width: 100%;
}
h4{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
p{
    color: var(--colorSecondary);
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    padding: 2px 0px;
}
h5{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
h6{
    color:#B8BCC8;
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    padding: 5px 0px;
}
.col-perso{
    @media (min-width: 992px) {
        min-width: 55% !important;
        max-width: 55%;
    }
}
.agregar-cursos-btn{
    box-shadow: 0 3px 6px #00000016;
    border-radius: 43px;
    height: 60px;
    margin: 20px 0;
    border: 0;
    background-color: var(--bgPrincipal);
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    position: relative;
    p{
        padding-left: 5px;
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
    }
    span{
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 15px;
    }
}
.buttons{
    .btn__primario{
        height: 45px;
    }
}
.collapses{
    h3{
        margin-bottom: 0px !important;
    }
    h6{
        color:#B8BCC8;
        font-size: 15px;
        font-weight: 400;
        text-align: left;
        padding: 5px 0px;
    }
    .accordion{
        width: 100%;
        .accordion-item{
            margin: 15px 0px;
            border: 0;
            border-radius: 10px;
        }
        .accordion-item:last-of-type .accordion-button.collapsed{
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .accordion-header{
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: #F9F9F9;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            .accordion-button{
                border-radius: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                background-color: #F9F9F9;
                &:focus{
                    border-color: inherit;
                    box-shadow: none;
                }
                span{
                    border: 2px solid #E9E3E3;
                    min-height: 40px;
                    min-width: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h3{
                        text-align: center;
                    }
                }
                @media (max-width: 991px) {
                    justify-content: flex-start;
                    align-items: flex-start;
                }
            }
            .accordion-button:not(.collapsed){
                background-color: inherit;
                box-shadow: none;
            }
            .accordion-button::after{
                position: absolute;
                right: 5%;
                background-image: url('../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                background-size: 100%;
                background-position: center;
            }
            .accordion-body{
                input,select{
                    width: 100%;
                    border: 2px solid #E9E3E3;
                    border-radius: 10px;
                    background-color: #FCFCFC;
                    height: 50px;
                    padding-left: 15px;
                    outline: 0;
                    color: #697891;
                    font-size: 15px;
                    font-weight: 400;
                    -webkit-appearance: none;
                    &:focus, &:hover{
                        border: 2px solid #1679FC;
                    }
                    &::placeholder{
                        color: #697891;
                    }
                }
                span{
                    border: 2px solid #E9E3E3;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h3{
                        text-align: center;
                    }
                }
                button{
                    background-color: transparent;
                    border: 2px solid #E4E4E4;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    margin-left: 10px;
                }
                @media (max-width: 991px) {
                    margin-top: 5px;
                }
            }
        }
    }
    .switch{
        background-color: #F9F9F9;
        box-shadow: 0px 3px 6px #00000016;
        height: 77px;
        border-radius: 15px;
        margin:10px 0 20px 0;
        .form-switch .form-check-input{
            width: 48px;
            height: 23px;
            background-color: #E4E4E4;
            background-image: url('../../../img/panel-icons/check-icon.svg');
            background-size: 40%;
            border: 0;
            background-position-x: 2px;
        }
        .form-switch .form-check-input:focus{
            outline: 0;
            box-shadow: none;
            border: 0;
            background-image: url('../../../img/panel-icons/check-icon.svg');
            background-size: 40%;
        }
        .form-switch .form-check-input:checked{
            background-color: var(--colorPrimary);
            background-position-x: 27px;
        }
        p{
            padding-left: 10px;
            font-weight: 500;
        }
        button{
            background-color: transparent;
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            min-height: 40px;
            min-width: 40px;
            margin-left: 10px;
        }
        h5{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            padding-left: 10px;
        }
    }
}
.ofertas{
    border: 1px solid #E4E4E4;
    background-color: var(--bgPrincipal);
    border-radius: 15px;
    height: 110px;
    h4{
        color: var(--colorSecondary);
        font-weight: 600;
        font-size: 16px;
    }
    h5{
        color: #2E4765;
        font-weight: 300;
        font-size: 14px;
    }
    .eliminar{
        border-left: 2px solid #E4E4E4;
        button{
            background-color: transparent;
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            margin-left: 10px;
        }
    }
    .ejemplo{
        border-radius: 5px;
        height: 83px;
        img{
            max-height: 100%;
            max-width: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    
}
.checkboxes{
    input[type=radio]{
        display: none;
    }
    label{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .span{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 2px solid #E4E4E4;
            min-width: 28px;
            min-height: 28px;
            left: 0;
        }
        .rojo{
            background-color: #EF4836;
        }
        .verde{
            background-color: #37BFA3;
        }
        .azul{
            background-color: #1679FC;
        }
        i{
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            display: block;
        }
        .star{
            background-image: url('../../../img/subir-productos-icons/cursos-icons/star.svg');
        }
        .flame{
            background-image: url('../../../img/subir-productos-icons/cursos-icons/flame.svg');
        }
    }
    input:checked + label .span{
        border-color: #000;
        &::after{
            display: block;
        }
    }
    input:checked + label .star{
        background-image: url('../../../img/subir-productos-icons/cursos-icons/star-active.svg');
    }
    input:checked + label .flame{
        background-image: url('../../../img/subir-productos-icons/cursos-icons/flame-active.svg');
    }
    .col{
        max-width: 32px;
    }
    .col-perso2{
        max-width: 45px;
    }
    .bg-azul{
        background-color: rgba(22, 122, 252, 0.17);
        padding: 15px;
        border-radius: 5px;
        margin: 30px 0;
        h5{
            font-weight: 500;
            color: #242134;
            font-size: 15px;
            padding: 5px 0;
        }
    }
    .time{
        .span{
            border: 2px solid var(--colorPrimary);
            height: 44px;
            width: 44px;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 12px;
            color: var(--colorPrimary);
            padding: 10px;
        }
        input:checked + label .span{
            background-color: var(--colorPrimary);
            border-color: #E4E4E4;
            color: var(--bgPrincipal);
            &::after{
                display: block;
            }
        }
        .margin{
            margin-top: 30px;
        }
    }
}
</style>