<template>
    <button class="btn__secundario" :class="alert && step == 2 || alert && step == 3 ? 'alert-nextstep2' : ''" type="button" @click="stepPlus">
            {{ $t('message.continueV2') }}
        <i class="d-none d-lg-block"></i>
    </button>
</template>

<script setup>
import { inject, ref } from 'vue';
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../stores/user";
import axios from 'axios';

let type = inject('type')
let modality = inject('modality')
let product = inject('product')
let modules = inject('modules')
let information = inject('information')
let multimedia = inject('multimedia')


let step = inject('step')
let subStep = inject('subStep')
let certificate = inject('certificate')
let finalProject = inject('finalProject')

let alert = inject('alert')
let modulesRef = inject('modulesRef')

const users = useUsersStore();
const { } = storeToRefs(users);
const { url } = users;

//Guarda la informacion de la pestaña 1 - Informacion Basica
const urlSaveInfo = '/api/product/creating/add/info'
//Guarda la informacion de la pestaña 2 - Multimedia
const urlSaveMulti = '/api/product/creating/add/multimedia'

const urlCreateProduct = '/api/product/create'
const urlConfigCertif = '/api/certificate/create/'
const urlFetchCertif = '/api/certificate/get/'
const urlCreateFP = '/api/final/create'
const urlUploadGuide = '/api/final/upload/'
const urlAddPFinal = '/api/certificate/add/pf/' // cId - fpId

let owner_id = inject('owner_id')
let product_id = inject('product_id')

//Crea el producto luego de elegir tipo de producto y modalidad
const createProduct = async () => {
    const idData = JSON.parse(localStorage.getItem('idData'))
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    try {
        await axios.post(url + urlCreateProduct, { owner_id: idData.id, class_id: type.value, type_id: modality.value }, { headers: headers.value }).then((result) => {
            console.log(result)
            product.value.id = result.data.id
            product.value.ownerId = idData.id
            owner_id.value = idData.id
            product_id.value = result.data.id
            console.log(owner_id.value)
            console.log(product_id.value)
        })
    } catch (error) {
        console.error(error)
    }
}

const createFP = async() => {
    if(certificate.value.hasFinalProject){
        const token = JSON.parse(localStorage.getItem('token'))
        const headers = ref({'Authorization': `Bearer ${token}`})
        try {
            await axios.post(url+urlCreateFP, finalProject.value, {headers: headers.value}).then((result) => {
                console.log(result)
                console.log(1)
                finalProject.value.fProjId = result.data.id
                finalProject.value.title = result.data.title
                finalProject.value.description = result.data.description
                if(finalProject.value.description == null){
                    finalProject.value.description = ''
                }
                addPfinal(certificate.value.certId, certificate.value.fProjId)
            })
        } catch (error) {
            console.log(error)

        }
    }
}

//Crea el proyecto final y lo linkea
const addPfinal = async (cId, fpId) => {
    const idData = JSON.parse(localStorage.getItem('idData'))
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    let id = product.value.id
    certificate.value.uId = idData.id
    try {
        await axios.post(url + urlAddPFinal + cId + '/' + fpId, certificate.value, { headers: headers.value }).then((result) => {
            console.log(result)
            certificate.value.certId = result.data.certId
            certificate.value.fProjId = result.data.fProjId
        })
    } catch (error) {
        console.error(error)
    }
}

//Crea el producto luego de elegir tipo de producto y modalidad
const configCertif = async () => {
    const idData = JSON.parse(localStorage.getItem('idData'))
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    let id = product.value.id
    certificate.value.uId = idData.id
    try {
        await axios.post(url + urlConfigCertif + id, certificate.value, { headers: headers.value }).then((result) => {
            console.log(result)
            certificate.value.certId = result.data.certId
            certificate.value.fProjId = result.data.id
            if(!result.data.id){
                addPfinal(certificate.value.certId, certificate.value.fProjId)
            }
            
        })
    } catch (error) {
        console.error(error)
    }
}

const stepPlus = () => {
    console.log(step.value)
    if (step.value == 1) {
        //Valida si ya se creo el producto guardado en cache
        
        if (modality.value) {
            if (product.value.id == null) {
                createProduct()
                console.log('me ejecuto')
            }
            let message = document.getElementById('modality-empty')
            message.classList.add('d-none')
            step.value = step.value + 1
            console.log('me ejecuto 2')
        }
        else {
            let message = document.getElementById('modality-empty')
            message.classList.remove('d-none')
            console.log(step.value)
            step.value = step.value + 1
            console.log('no creé nada')
        }
    }
    else if (step.value == 2) {
        let infoError = false
        let multiError = false
        //Valida errores en informacion
        if (information.value.lang && information.value.title && information.value.producer) {
            let lang = document.getElementById('info-tab')
            lang.classList.remove('error-color')
            infoError = true
        }
        else {
            let lang = document.getElementById('info-tab')
            lang.classList.add('error-color')
            infoError = false
        }
        if (information.value.lang) {
            let lang = document.getElementById('lang-empty')
            lang.classList.add('d-none')
        }
        else {
            let lang = document.getElementById('lang-empty')
            lang.classList.remove('d-none')
        }
        if (information.value.title) {
            let name = document.getElementById('name-empty')
            name.classList.add('d-none')
        }
        else {
            let name = document.getElementById('name-empty')
            name.classList.remove('d-none')
        }
        if (information.value.producer) {
            let producer = document.getElementById('producer-empty')
            producer.classList.add('d-none')
        }
        else {
            let producer = document.getElementById('producer-empty')
            producer.classList.remove('d-none')
        }

        //Valida errores en multimedia
        if (multimedia.value.image) {
            let urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
            if (urlRegex.test(multimedia.value.image)) {
                let images = document.getElementsByClassName('input-m-empty')
                for (let i = 0; i < images.length; i++) {
                    images[i].classList.remove('error_input');
                }
                let message = document.getElementById('multimedia-empty')
                message.classList.add('d-none')
                let multi = document.getElementById('multimedia-tab')
                multi.classList.remove('error-color')
                multiError = true
            }
            else {
                let images = document.getElementsByClassName('input-m-empty')
                for (let i = 0; i < images.length; i++) {
                    images[i].classList.add('error_input');
                }
                let message = document.getElementById('multimedia-empty')
                message.classList.remove('d-none')
                let multi = document.getElementById('multimedia-tab')
                multi.classList.add('error-color')
                multiError = false
            }
        }
        else {
            let images = document.getElementsByClassName('input-m-empty')
            for (let i = 0; i < images.length; i++) {
                images[i].classList.add('error_input');
            }
            let message = document.getElementById('multimedia-empty')
            message.classList.remove('d-none')
            let multi = document.getElementById('multimedia-tab')
            multi.classList.add('error-color')
            multiError = false
        }

        //Guarda la pestaña INFORMACION BASICA
        if (subStep.value == 1) {
            const saveInfo = async (id, trans_id, detail_id) => {
                const token = JSON.parse(localStorage.getItem('token'))
                const headers = ref({ 'Authorization': `Bearer ${token}` })
                information.value.id = id
                if(!information.value.trans_id){
                    information.value.trans_id = trans_id
                }
                if(!information.value.detail_id){
                    information.value.detail_id = detail_id
                }
                information.value.owner_id = owner_id.value
                information.value.product_id = product_id.value
                console.log(owner_id)
                try {
                    await axios.post(url + urlSaveInfo, information.value, { headers: headers.value }).then((result) => {
                        console.log(result)
                        product.value.information.trans_id = result.data.trans_id
                        product.value.information.detail_id = result.data.detail_id
                    })
                } catch (error) {
                    console.error(error)
                }
            }
            if (information.value.lang && information.value.title && information.value.producer && information.value.detail_id) {
                saveInfo(product.value.id, product.value.information.trans_id, product.value.information.detail_id)
            }
        }
        //Guarda la pestaña MULTIMEDIA
        else if (subStep.value == 2) {
            const saveInfo = async (id, image_id, video_id) => {
                const token = JSON.parse(localStorage.getItem('token'))
                const headers = ref({ 'Authorization': `Bearer ${token}` })
                console.log(multimedia.value)
                multimedia.value.id = id
                multimedia.value.image_id = image_id
                multimedia.value.video_id = video_id
                multimedia.value.owner_id = owner_id.value
                multimedia.value.product_id = product_id.value
                console.log(multimedia.value)
                try {
                    await axios.post(url + urlSaveMulti, multimedia.value, { headers: headers.value }).then((result) => {
                        console.log(result)
                        product.value.multimedia.image_id = result.data.image_id
                        product.value.multimedia.video_id = result.data.video_id
                    })
                } catch (error) {
                    console.error(error)
                }
            }
            if (multimedia.value.image) {
                let urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
                if (urlRegex.test(multimedia.value.image)) {
                    saveInfo(product.value.id, product.value.multimedia.image_id, product.value.multimedia.video_id)
                    console.log(product.value.id, product.value.multimedia.image_id, product.value.multimedia.video_id)
                }
            }
        }
        if (infoError && multiError) {
            step.value = step.value + 1
            alert.value = false
        }
        else {
            alert.value = true
        }
    }
    else if (step.value == 3){
        let titleError = false
        let videoError = false
        let modulesError = false
        if (product.value.titleIntro) {
            let title = document.getElementById('intro-title-empty')
            title.classList.add('d-none')
            titleError = true
        }
        else {
            let title = document.getElementById('intro-title-empty')
            title.classList.remove('d-none')
            titleError = false
        }
        if (product.value.videoIntro) {
            let urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
            if (urlRegex.test(product.value.videoIntro)) {
                let videos = document.getElementsByClassName('input-v-empty')
                for (let i = 0; i < videos.length; i++) {
                    videos[i].classList.remove('error_input');
                }
                let video = document.getElementById('intro-video-empty')
                video.classList.add('d-none')
                videoError = true
            }
            else {
                let videos = document.getElementsByClassName('input-v-empty')
                for (let i = 0; i < videos.length; i++) {
                    videos[i].classList.add('error_input');
                }
                let video = document.getElementById('intro-video-empty')
                video.classList.remove('d-none')
                videoError = false
            }
        }
        else {
            let videos = document.getElementsByClassName('input-v-empty')
            for (let i = 0; i < videos.length; i++) {
                videos[i].classList.add('error_input');
            }
            let video = document.getElementById('intro-video-empty')
            video.classList.remove('d-none')
            videoError = false
        }
        if (modules.value.content <= 0){
            modulesRef.value.classList.remove('d-none')
            modulesError = false
        }
        else {
            modulesError = true
        }
        //Validar contenido de modulo y leccion
        if (titleError && videoError && modulesError) {
            step.value = step.value + 1
        }
        else {
            alert.value = true
        }
    }
    //Guarda la configuracion de certificados y crea el producto
    else if (step.value == 4) {
        //Valida si ya se creo el producto guardado en cache
        if (product.value.id) {
            configCertif()
            if (certificate.value.certId){
                //fetchCertif()
            }
            step.value = step.value + 1
            alert.value = false
        }
    }
}
</script>

<style lang="scss" scoped>
i {
    min-height: 25px;
    min-width: 27px;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    background-image: url('../../img/subir-productos-icons/arrow-blanco.svg');
    margin-left: 10px;
}

button {
    border: 0;
    font-size: 16px;
    font-weight: 600;

    @media (min-width: 992px) {
        padding-left: 25px;

        &:focus,
        &:hover {
            i {
                background-image: url('../../img/subir-productos-icons/arrow-azul.svg');
                transform: rotate(180deg);
            }
        }
    }
}
.alert-nextstep2 {
    background-color: #FF6A6A;
    &:hover, &:focus {
        background-color: #FFF;
        color: #FF6A6A;
        border: 2px solid #FF6A6A;
        i {
            background-image: url('../../img/subir-productos-icons/arrow-rojo.svg');
            transform: rotate(180deg);
        }
    }

}
</style>