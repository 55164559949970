<template>
    <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start mt-3 px-0 ps-lg-0 pe-lg-3">
        <h3>{{ $t('message.selectProfession') }}</h3>
        <select name="" id="professions" v-model="information.profession" @change="setSpecs(information.profession)">
            <option :value="[prof.id]" v-for="(prof, index) in listProfs">{{ prof.name }}</option>
        </select>
        <!-- :placeholder="userData.full_name !== undefined ? userData.full_name : $t('message.user')" -->
    </div>
</template>

<script setup>
import { useGeneralStore } from "../../../stores/general"
import { computed, inject, onMounted } from 'vue';
import { useUsersStore } from "../../../stores/user"    
import { storeToRefs } from "pinia";
import axios from 'axios';

const users = useUsersStore();
const { access } = storeToRefs(users);
const { url, validate } = users;

const urlFetchSpec = '/api/categories/profession/'

const listProfs = inject('listProfs')
const listSpecs = inject('listSpecs')
const information = inject('information')

//Actualiza las especialidades
const setSpecs = async(id) => {
    try {
        await axios.get(url+urlFetchSpec+'es'+'/'+id).then((result) => {
            console.log(result)
            listSpecs.value = []
            if (result.status == 200){
                for(let i = 0; i < result.data.length; i++){
                    listSpecs.value.push( result.data[i] )
                }
            }
        })
    } catch (error) {
        console.error(error)
    }
}
</script>

<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
input,select{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: #FCFCFC;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    color: #697891;
    font-size: 15px;
    font-weight: 400;
    -webkit-appearance: none;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
.valido{
    background-image: url('../../../img/panel-icons/input-valido.svg');
    background-repeat: no-repeat;
    background-position: 98% center;
}
</style>