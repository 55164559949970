<template>
    <div class="row justify-content-center detalle row__width">
        <div class="col-12 d-flex flex-column justify-content-center align-items-start">
            <h2>{{$t('message.total')}}</h2>
            <h3>$ {{ arrayCheckout.precioTotal }}</h3>
        </div>
        <div class="col-12 d-flex justify-content-between align-items-center">
            <h5 v-if="arrayCheckout.promo">$ {{ arrayCheckout.total }}</h5>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue';
    import { storeToRefs } from "pinia"
    import { useCartStore } from "../../stores/cart"

    const carts = useCartStore();
    const { arrayCheckout } = storeToRefs(carts);
    const { setCheckout } = carts

    setCheckout()
    
</script>



<style lang="scss" scoped>
.detalle{
    h2{
        font-weight: 600;
        font-size: 28px;
        color: var(--colorSecondary);
        padding-top: 15px;
        padding-bottom: 10px;
    }
    h3{
        font-weight: 700;
        font-size: 32px;
        color: var(--colorSecondary);
        padding-bottom: 10px;
    }
    h5{
        color: #B8C1DB;
        font-weight: 500;
        font-size: 20px;
        text-decoration: line-through;
    }
}
</style>