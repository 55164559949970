<template>
    <div class="row justify-content-center row__width inputs__carrito">
        <div class="col-12 d-flex justify-content-start align-items-center ">
            <h6>Detalles para facturación</h6>
        </div>
        <div class="col-12 col-lg-12 d-flex flex-column justify-content-center align-items-start cont_input">
            <h5>{{$t('message.fullname')}}</h5>
            <input type="text" v-model="params.name">
        </div>
        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start cont_input">
            <h5>{{$t('message.email')}}</h5>
            <input type="text" v-model="params.email">
        </div>
        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center cont_input position-relative checkoutTelefono">
            <h5>{{$t('message.telephone')}}</h5>
            <MazPhoneNumberInput
                v-model="params.phone"
                show-code-on-list
                color="info"
                :preferred-countries="['FR', 'BE', 'DE', 'US', 'GB']"
                :ignored-countries="['AC']"
                @update="results = $event"
                :success="results?.isValid"
                :translations="{
                    countrySelector: {
                        placeholder: '',
                        error: '',
                    },
                    phoneInput: {
                        placeholder: '',
                        example: '',
                        label: ''
                    },
                }"
                no-example
                fetch-country
                no-use-browser-locale
            />
        </div>
        <RecordarCheckout/>
        <IngresoRapidoCheckout class="mb-lg-4"/>
        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center d-none">
            <ContinuarComoBTN :titulo="$t('message.loginFacebook')"/>
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
            <Google :textoBTNGoogle="$t('message.loginGoogle')"/>
        </div>
    </div>
</template>

<script setup>
    import Google from "../Botones/Google.vue";
    import ContinuarComoBTN from "../Botones/ContinuarComo.vue";
    import IngresoRapidoCheckout from "../Titulo/IngresoRapidoCheckout.vue";
    import RecordarCheckout from "./RecordarCheckout.vue";
    import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
    import { useGeneralStore } from "../../stores/general"    
    import { storeToRefs } from "pinia";
    import { ref, provide, inject } from 'vue';
    import axios from 'axios';
    
    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;

    const params = inject('params', {name: '', email: '', phone: ''})
    const registerCheckout = inject('registerCheckout', () => 'registerCheckout')

</script>

<style lang="scss" scoped>
h6{
    font-weight: 600;
    font-size: 18px;
    color: var(--colorSecondary);
    padding: 30px 0 10px 0;
}
h5{
    font-weight: 400;
    font-size: 16px;
    color: var(--colorSecondary);
    padding: 10px 0px;
    width: 100%;
    text-align: left;
}
.cont_input{
    margin-bottom: 10px;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: transparent;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
</style>