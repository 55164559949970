<template>
    <div class="container-fluid">
        <NavbarBuscadorBack/>
        <HeaderTitulo class="d-none d-lg-flex" :tituloHeader="$t('message.HomePresets')"/>
        <div class="row justify-content-center position-relative">
            <div class="col-4 d-none d-lg-flex justify-content-center align-items-start ps-0" v-if="mapaDesplegado"></div>
            <div class="col-3 justify-content-center align-items-start ps-0 mt-lg-5" v-if="filtrosDesplegados">
                <BodyFiltros/>
            </div>
            <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0 px-lg-auto mt-lg-5 position-relative" 
                :class="filtrosDesplegados == true ? 'col-lg-8 justify-content-lg-start' : 'col-lg-11' && mapaDesplegado == true ? 'col-lg-7 justify-content-lg-start' : 'col-lg-11' ">
                <Filtros class=""/>
                <TitulosSecciones :titulo="$t('message.coursesPhotography')" :haySubtitulo="false" class="d-lg-none"/>
                <!-- <OrdenarPorSelect class="d-lg-none"/> -->
                <TituloYOpcionesResultadosCursos class="d-none d-lg-flex" :titulo="$t('message.coursesPhotography')" idNextV2="nextSliderCursosboton" idBackV2="prevSliderCursosboton" pagination="pagCursos"/>
                <SliderMobileSeccionesHomeV2/>
                <BotonesSlidersHome class="d-lg-none" idNext="nextSliderCursosboton" idBack="prevSliderCursosboton"/>
                <TitulosSecciones :titulo="$t('message.coursesDiscount')" :haySubtitulo="false" class="d-lg-none"/>
                <!-- <OrdenarPorSelect class="d-lg-none"/> -->
                <!-- <TituloYOpcionesResultadosCursos class="d-none d-lg-flex mt-lg-4" :titulo="$t('message.coursesDiscount')" :lista="2" :categoria="2" idNextV2="nextSliderCursosV2boton" idBackV2="prevSliderCursosV2boton"/>
                <SliderMobileSeccionesResultadosCursosV2/>
                <BotonesSlidersHome class="d-lg-none" idNext="nextSliderCursosV2boton" idBack="prevSliderCursosV2boton"/> -->
                <!-- <panelSeccion class="d-lg-none"/> -->
                <!-- <PanelSeccionV3 :class="filtrosDesplegados == true ? 'd-none' : 'd-flex' || mapaDesplegado == true ? 'd-none' : 'd-flex'"/> -->
                <!-- <TitulosSeccionesV2 :titulo="$t('message.exploreCategories')" :class=" filtrosDesplegados == true ? 'd-none' : 'd-flex' || mapaDesplegado == true ? 'd-none' : 'd-flex' "/> -->
                <!-- <CategoriasListado :class=" filtrosDesplegados == true ? 'd-none' : 'd-flex' || mapaDesplegado == true ? 'd-none' : 'd-flex' "/> -->
            </div>
            
            <div class="col-4 d-none d-lg-flex justify-content-center align-items-start ps-0" v-if="mapaDesplegado"></div>
            <div class="col d-none d-lg-flex justify-content-center align-items-start ps-0 mt-lg-5 position" v-if="mapaDesplegado">
                <MapaFiltros/>
            </div>
            <div class="filtros-absolute" v-if="filtrosMapa" @click="cerrarTodo">
                
            </div>
            <div class="filtros row d-flex justify-content-center align-items-start" v-if="filtrosMapa">
                <div class="col-11 d-flex justify-content-center align-items-center px-0">
                    <FiltrosV2 :funcionMostrarFiltros="funcionMostrarFiltros" :funcionMapa="funcionMapa2"/>
                </div>
                <div class="col-3 d-flex justify-content-center align-items-start ps-0">
                    <BodyFiltros/>
                </div>
                <div class="col-8 d-flex flex-column justify-content-center align-items-center px-0 position-relative">
                    <TituloYOpcionesResultadosCursos :titulo="$t('message.coursesPhotography')" idNextV2="nextSliderCursosboton" idBackV2="prevSliderCursosboton"/>
                    <SliderMobileSeccionesHomeV3 class=""/>
                    <!-- <TituloYOpcionesResultadosCursos class="mt-lg-4" :titulo="$t('message.coursesDiscount')" :lista="2" :categoria="2" idNextV2="nextSliderCursosV2boton" idBackV2="prevSliderCursosV2boton"/>
                    <SliderMobileSeccionesResultadosCursosV3 class=""/> -->
                </div>
            </div>
        </div>
        <!-- <div class="row justify-content-center mt-lg-5" :class=" filtrosDesplegados == true ? 'd-flex' : 'd-none' || mapaDesplegado == true ? 'd-flex' : 'd-none' ">
            <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0 px-lg-auto " >
                <PanelSeccionV3/>
                <TitulosSeccionesV2 :titulo="$t('message.exploreCategories')"/>
                <CategoriasListado/>
            </div>
        </div> -->
        <div class="espacio2"></div>
        <FooterHome />
        <Footer :activeHome="true" class="d-lg-none"/>
        <div class="espacio2"></div>

    </div>
</template>

<script setup>
    import Filtros from "../../components/Filtros/Filtros.vue";
    import FiltrosV2 from "../../components/Filtros/FiltrosV2.vue";
    import Footer from "../../components/Footer/Footer.vue";
    import TitulosSecciones from "../../components/Titulo/TitulosSecciones.vue";
    import OrdenarPorSelect from "../../components/Ordenar/PorSelect.vue";
    import panelSeccion from "../../components/PanelSeccion/PanelSeccion.vue";
    import FooterHome from "../../components/Footer/FooterHome.vue";
    import NavbarBuscadorBack from "../../components/Navs/NavbarBuscadorBack.vue";
    import TitulosSeccionesV2 from "../../components/Titulo/TitulosSeccionesV2.vue";
    import CategoriasListado from "../../components/Categorias/CategoriasListado.vue";
    import BotonesSlidersHome from "../../components/Botones/SlidersHome.vue";
    import TituloYOpcionesResultadosCursos from "../../components/Titulo/TituloYOpciones.vue";
    import HeaderTitulo from "../../components/Titulo/HeaderTitulo.vue";
    import PanelSeccionV3 from "../../components/PanelSeccion/PanelSeccionV3.vue";
    import BodyFiltros from "../../components/Filtros/Body.vue";
    import SliderMobileSeccionesHomeV2 from "../../components/Sliders/SeccionesHomeV2.vue";
    import SliderMobileSeccionesResultadosCursosV2 from "../../components/Sliders/SeccionesResultadosCursosV2.vue";
    import MapaFiltros from "../../components/Filtros/Mapa.vue";
    import SliderMobileSeccionesHomeV3 from "../../components/Sliders/SeccionesHomeV3.vue";
    import SliderMobileSeccionesResultadosCursosV3 from "../../components/Sliders/SeccionesResultadosCursosV3.vue";
    import { ref } from "vue";
    import { useGeneralStore } from "../../stores/general"    
    import { storeToRefs } from "pinia";

    const general = useGeneralStore();
    const {filtrosDesplegados, filtrosMapa, mapaDesplegado} = storeToRefs(general);
    const {} = general;

    const funcionMostrarFiltros = () => {
    if(mapaDesplegado.value == true ){
        if(filtrosMapa.value == false){
            filtrosMapa.value = true 
        }else{
            filtrosMapa.value = false
        }
        //console.log(this.filtrosMapa)
    }else{
        if(filtrosDesplegados.value == false){
            filtrosDesplegados.value = true 
        }else{
            filtrosDesplegados.value = false
        }
    }
}

const funcionMapa = () => {
    if(filtrosDesplegados.value == true ){
        filtrosDesplegados.value = false
        //console.log('hola3')
    }
    else if(mapaDesplegado.value == false){
        mapaDesplegado.value = true 
        //console.log('hola4')
    }else{
        mapaDesplegado.value = false
        //console.log(mapaDesplegado.value)
        //console.log('hola5')
    }
}

const funcionMapa2 = () => {
    if(mapaDesplegado.value == true){
        //console.log(this.mapaDesplegado, 'hola1')
        mapaDesplegado.value = false 
        filtrosMapa.value = false
    }else{
        mapaDesplegado.value = false
        //console.log(this.mapaDesplegado, 'hola1')
    }
}

const cerrarTodo = () => {
    mapaDesplegado = false
    filtrosMapa.value = false
}

</script>

<style lang="scss" scoped>
body{
    max-width: 100% !important;
}
.position{
    position: absolute;
    right: 0;
    max-width: 34%;
    height: 100%;
    top: 0;
}
.filtros-absolute{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.35);
    width: 100vw;
    min-height:  102.5%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    z-index: 5;
    
}
.filtros{
    position: absolute;
    background-color: #FFFFFF;
    max-width: 95vw;
    min-height:  102.5%;
    z-index: 10;
    left: 0;
    top: 0;
    
}
</style>