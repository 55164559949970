<template>
    <div class="row justify-content-center row__width inputs">
        <div class="col-12 d-flex flex-column justify-content-center align-items-start">
            <h3>{{$t('message.password')}}</h3>
            <div class="position">
                <input type="password" :placeholder="$t('message.password')" id="passwordTerminar" name="password" :class="estados.password" v-model.trim="usuariosRegistro.password">
                <button class="btn_none d-flex justify-content-center align-items-center" @click="cambiar()" type="button"><i class="ojo"></i></button>
            </div>
        </div>
    </div>
</template>
<script setup>
    // Importo mi método
    // Llamo a mi tienda de Pinia en forma de Setup 
    import { useUsersStore } from "../../stores/user";
    import { useGeneralStore } from "../../stores/general"    
    import { storeToRefs } from "pinia";
    import { inject } from 'vue';
    
    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;
    const estados = inject('estados')
    // Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
    const users = useUsersStore();
    // Mis estados los paso a objetos porque en la tienda están en reactivos //
    const {usuariosRegistro} = storeToRefs(users);
    const {} = users;

    const cambiar = () => {
        if(document.getElementById('passwordTerminar').getAttribute('type') == 'password'){
            document.getElementById('passwordTerminar').setAttribute('type', 'text')
        }else{
            document.getElementById('passwordTerminar').setAttribute('type','password')
        }
    }  
</script>

<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        padding: 0 0px 10px 0;
    }
    input{
        width: 100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: transparent;
        height: 50px;
        padding-left: 15px;
        outline: 0;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }
    .position{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        button{
            position: absolute;
            right: 15px;
            padding: 0;
        }
        .ojo{
            background-image: url('../../img/roles-icons/ojo.svg');
            background-repeat: no-repeat;
            display: block;
            min-height: 20px !important;
            min-width: 20px !important;
        }
    }
    
}
</style>