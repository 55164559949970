<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start ps-lg-0">
        <NavbarCertificados :seccion="$t('message.profilePanel')"/>
        <NavLateralPanel :profile="true" />
        <div class="div-grey right" id="div-grey">
            <button class="boton-toggler" id="toggleSidebar" type="button" @click="toggleSidebar">
                <img src="../../../img/home-icons/arrow-left-blanco.svg" alt="">
            </button>
        </div>
        <div class="col-12 col-lg ms-lg-3 mt-3 d-flex justify-content-center align-items-start p-lg-3" :style="isSidebarOpen ? 'padding-left: 300px !important;'  : 'padding-left: 50px !important;'">
            <div class="row justify-content-center justify-content-lg-start row__width">
                <div class="col-11 col-lg d-flex flex-column justify-content-start align-items-center px-0 contenedor">
                    <div class="row justify-content-center row__width border-bottom2">
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center">
                            <TituloAjustes :titulo="$t('message.account')" :subtitulo="$t('message.accountInfoSubtitle')" v-if="actual_page == 1"/>
                            <TituloAjustes :titulo="$t('message.personal')" :subtitulo="$t('message.personalInfoSubtitle')" v-if="actual_page == 2"/>
                            <TituloAjustes :titulo="$t('message.professional')" :subtitulo="$t('message.professionalInfoSubtitle')" v-if="actual_page == 3"/>
                            <TituloAjustes :titulo="$t('message.myGalleries')" :subtitulo="$t('message.myGalleriesSubtitle')" v-if="actual_page == 4"/>
                            <TituloAjustes :titulo="$t('message.reviews')" :subtitulo="$t('message.myProfileSubtitle')" v-if="actual_page == 5"/>
                            <TituloAjustes :titulo="$t('message.ranking')" :subtitulo="$t('message.myProfileSubtitle')" v-if="actual_page == 6"/>
                            <TituloAjustes :titulo="$t('message.settings')" :subtitulo="$t('message.myProfileSubtitle')" v-if="actual_page == 7"/>
                        </div>
                    </div>
                    <div class="row justify-content-center row__width">
                        <div class="col-12 d-flex justify-content-center align-items-start px-0">
                            <PerfilPanel/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterCertificados :account="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../components/Navs/NavbarCertificados.vue"
import FooterCertificados from "../../../components/Footer/FooterCertificados.vue"
import NavLateralPanel from "../../../components/Navs/NavLateralPanel.vue"
import atrasBTNCertificados from "../../../components/Botones/Atras.vue"
import TituloAjustes from "../../../components/Titulo/TituloAjustes.vue"
import PerfilPanel from "../../../components/Panel/Perfil/PerfilPanel.vue"
// Importo mi método
import { storeToRefs } from "pinia"
// Llamo a mi tienda de Pinia en forma de Setup 
import { useUsersStore } from "../../../stores/user"
import { onBeforeMount, provide, ref, onMounted } from 'vue';
import axios from 'axios';
// Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
const users = useUsersStore()
// Mis estados los paso a objetos porque en la tienda están en reactivos //
const { access, idUser } = storeToRefs(users)
const { usuarioData, userProfessionalData } = users
const { url, validate } = users

const algo = onBeforeMount( () => {
    access.value = [1, 2, 3, 4, 5]
    console.log(access.value) 
    validate()
})
const isSidebarOpen = ref(true)
const toggleSidebar = () => {
    isSidebarOpen.value = !isSidebarOpen.value
    if(document.getElementById('sidebar').classList.contains('d-none')){
        document.getElementById('sidebar').classList.remove('d-none')
    } else{
        document.getElementById('sidebar').classList.add('d-none')
    }
    if(document.getElementById('div-grey').classList.contains('right')){
        document.getElementById('div-grey').classList.remove('right')
        document.getElementById('div-grey').classList.add('left')
    } else{
        document.getElementById('div-grey').classList.remove('left')
        document.getElementById('div-grey').classList.add('right')
    }
}

provide('isSidebarOpen', isSidebarOpen)


const actual_page = ref(1)

const changePage = (page) => {
    actual_page.value = page
}

const new_user = ref({
    id: 0,
    personal_data: {
        first_name: '',
        last_name: '',
        country_code: '',
        phone_number: '',
        birthdate: '',
        country: {
            id: ''
        },
        nickname: ''
    },
    email: '',
    plan: {
        id: ''
    },
    team: {
        id: ''
    },
    avatar: null,
    password: '',
    status: false,
    professional: {
        id: '',
        active: '',
        professional_data: {
            id: '',
            email: '',
            banner: '',
            country: {
                id: ''
            },
            state: {
                id: ''
            },
            city: {
                id: ''
            },
            address: '',
            postal_code: '',
            country_code: '',
            phone_number: '',
            social_networks: [],
            about: '',
            slug: '',
            temporary: ''
        },
        professions: [
            {
                specializations: []
            }
        ],
        portfolio: null,
        reviews: null
    }
})

provide('actual_page', actual_page)
provide('changePage', changePage)
provide('new_user', new_user)

</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #fff;
    min-height: 100vh;
    @media (min-width: 992px) {
        margin-top: 77px;
    }
}
.contenedor{
   @media (min-width: 992px) {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding: 30px 0;
        min-height: 80vh;
   }
}
.border-bottom2{
    @media (min-width:992px) {
        border-bottom: 1px solid #E4E4E4;
        padding-bottom: 20px;
    }
}
</style>