<template>
    <div class="row justify-content-center detalle">
        <div class="col-12 d-flex justify-content-between align-items-center pb-3">
            <h6>{{$t('message.subtotal')}}:</h6> <p>USD ${{ arrayCheckout.total }}</p>
        </div>
        <div class="col-12 d-flex justify-content-between align-items-center pb-3" v-if="arrayCheckout.promoTotal">
            <h6>{{ $t('message.disccounts') }}:</h6> <p>USD - ${{arrayCheckout.promoTotal}}</p>
        </div>
        <div class="col-12 d-flex flex-column justify-content-center align-items-start">
            <h2>Total</h2>
            <h3>USD ${{ arrayCheckout.precioTotal }}</h3>
        </div>
        <div class="col-12 d-flex justify-content-between align-items-center">
            <h5 v-if="arrayCheckout.promo">{{coinSelected}} ${{ arrayCheckout.total }}</h5>
        </div>
    </div>
</template>

<script setup>
import { storeToRefs } from "pinia"
import { useCartStore } from "../../stores/cart"
import { useConfigStore } from "../../stores/config";
import { ref, inject, onMounted, provide } from 'vue';

const carts = useCartStore();
const { cart, totalPrice, totalDiscount, arrayCheckout } = storeToRefs(carts);
const {  } = carts

const config = useConfigStore();
const { coins, coinSelected} = storeToRefs(config);
</script>

<style lang="scss" scoped>
.detalle{
    padding: 20px 0px 0px 0px;
    h6{
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
    }
    p{
        font-weight: 500;
        font-size: 16px;
        color: var(--colorSecondary);
        padding: 0;
    }
    h2{
        font-weight: 600;
        font-size: 28px;
        color: var(--colorSecondary);
        padding-top: 15px;
        padding-bottom: 10px;
    }
    h3{
        font-weight: 700;
        font-size: 32px;
        color: var(--colorSecondary);
        padding-bottom: 10px;
    }
    h5{
        color: #B8C1DB;
        font-weight: 500;
        font-size: 20px;
        text-decoration: line-through;
    }
}
</style>