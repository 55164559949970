<template>
    <div class="row justify-content-center row__width item">
        <div class="col-2 col-xxl d-flex justify-content-center align-items-center px-0 ejemplo">
            <img :src="image" alt="" class="">
        </div>
        <div class="col d-flex flex-column justify-content-start align-items-start position-relative">
            <h2>{{titulo}}</h2>
            <div class="d-flex flex-column justify-content-center align-items-start pt-2">
                <h3>$ {{prize}}</h3><h5 v-if="discount">$ {{discount}}</h5>
            </div>
            <button>
                <img src="../../img/carrito-icons/delete.svg" alt="" @click="deleteCart(id)">
            </button>
        </div>
    </div>
</template>

<script setup>
    import { inject } from 'vue';
    import { storeToRefs } from "pinia"
    import { useCartStore } from "../../stores/cart"

    const carts = useCartStore();
    const { cart, totalPrice, totalDiscount } = storeToRefs(carts);
    const { deleteCart } = carts

    const props = defineProps({
        titulo: '',
        prize: '',
        discount: '',
        image: '',
        id: ''
    })

</script>

<style lang="scss" scoped>
.item{
    padding: 25px 0px;
    border-bottom: 1px solid #6978911c;
    .ejemplo{
        position: relative;
        img{
            height: 100%;
            width: 100%;
            max-height: 93px;
            border-radius: 10px;
            object-fit: cover;
        }
        &::before{
            content: '';
            position: absolute;
            background: transparent linear-gradient(180deg, #252E4800 0%, #081026 100%) 0% 0% no-repeat padding-box;
            border-radius: 0px 0px 15px 15px;
            z-index: 1;
            width: 100%;
            height: 50%;
            bottom: 0;
        }
        @media (min-width: 992px) {
            max-width: 150px;
        }
    }
    h2{
        font-weight: 600;
        font-size: 16px;
        text-align: start;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 460px;
        color: var(--colorSecondary);
    }
    h3{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary);
    }
    h5{
        font-weight: 600;
        font-size: 18px;
        color: #B8C1DB;
        text-decoration: line-through;
    }
    button{
        border: 0;
        background-color: transparent;
        outline: 0;
        position: absolute;
        top: 0;
        right: 0;
    }
}
</style>