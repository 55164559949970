<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center texto_mas">
        <div class="row__width" :class="product.benefits.length > 4 ? 'before' : ''" id="filtro32">
            <div class="row justify-content-center">
                <div class="col-12 d-flex justify-content-start align-items-center seccion" v-for="(item, index) in product.benefits.slice(0, 3)">
                    <img :src="item.icon" class="icon-resize" alt="">
                    <p>{{ item[lang] }}</p>
                </div>
            </div>
            <div id="desaparecer32" class="d-none row justify-content-center">
                <div class="col-12 d-flex justify-content-start align-items-center seccion" v-for="(item, index) in startFrom(product.benefits, 3)">
                    <img :src="item.icon" class="icon-resize" alt="">
                    <p>{{ item[lang] }}</p>
                </div>
            </div>
        </div>
        <BTNMostrarMas :funcionTexto="mostrarTexto32" :img="iconoImg" v-if="product.benefits.length > 4"/>
    </div>
</template>

<script setup>
    import BTNMostrarMas from "../Botones/MostrarMas.vue";
    import { ref, inject, onMounted } from 'vue';

    let lang = 'es'

    let product = inject('product')

    const iconoImg = ref(false)

    const startFrom = (array, index) => {
      return array.slice(index)
    }

    const mostrarTexto32 = () => {
        document.getElementById('desaparecer32').classList.toggle('d-none')
        document.getElementById('filtro32').classList.toggle('before')
        if(iconoImg.value == false){
            iconoImg.value =true
        }else{
            iconoImg.value =false
        }
    }
</script>

<style lang="scss" scoped>
.texto_mas{
    p, li{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
    }
    ul{
        padding: 0;
        max-width: 100%;
        position: relative;
        li{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding-left: 30px;
            min-height: 40px;
            width: 100%;
            margin-bottom: 10px;
            &::before{
                content: url('../../img/home-icons/cursos-icons/tick.png');
                position: absolute;
                left: 0;
                width: 20px;
                height: 20px;
            }
        }
    }
    .seccion{
        padding: 10px 10px;
        img{
            padding-right: 10px;
        }
    }
}
.icon-resize {
    height: 18.5px;
    width: 29.5px;
}
</style>