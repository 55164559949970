<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center texto_mas" v-if="product && product.values">
        <div class="algo" :class="product.values.length > 4 ? 'before' : ''" id="filtro2">
            <ul>
                <li v-for="(item, index) in product.values.slice(0, 3)">
                    {{ item[lang] }}
                </li>
                <div id="desaparecer2" class="d-none">
                    <li v-for="(item, index) in startFrom(product.values, 3)">
                        {{ item[lang] }}
                    </li>
                </div>
            </ul>
        </div>
        <BTNMostrarMas :funcionTexto="mostrarTexto" :img="iconoImg" v-if="product.values.length > 4"/>
    </div>
</template>

<script setup>
    import BTNMostrarMas from "../Botones/MostrarMas.vue";
    import { ref, inject } from 'vue';

    let lang = 'es'

    let product = inject('product')

    const startFrom = (array, index) => {
      return array.slice(index)
    }

    const iconoImg = ref(false)

    const mostrarTexto = () => {
        document.getElementById("desaparecer2").classList.toggle("d-none");
        document.getElementById("filtro2").classList.toggle("before");
        if(iconoImg.value == false){
            iconoImg.value =true
        }else{
            iconoImg.value =false
        }
    }
</script>

<style lang="scss" scoped>
.texto_mas{
    p, li{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
    }
    ul{
        padding: 0;
        max-width: 100%;
        position: relative;
        width: 100%;
        li{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding-left: 30px;
            min-height: 40px;
            width: 100%;
            margin-bottom: 10px;
            &::before{
                content: url('../../img/home-icons/cursos-icons/tick.png');
                position: absolute;
                left: 0;
                width: 20px;
                height: 20px;
            }
        }
    }
    .seccion{
        padding: 10px 10px;
        img{
            padding-right: 10px;
        }
    }
}
.algo{
    width: 100% !important;
}
</style>