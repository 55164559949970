<template>
    <div class="row justify-content-center my-3 row__width">
        
        <swiper-container 
            :navigation="{
                nextEl: '#nextSliderPresetsboton',
                prevEl: '#prevSliderPresetsboton'
            }"
            :pagination="{
                el: '#pagPresets',
                type: 'fraction',
                currentClass: 'paginationCurrentStyle',
                totalClass: 'paginationTotalStyle'
            }"
            :spaceBetween="20"
            :breakpoints="{
                375: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3,
                },
                1920: {
                    slidesPerView: 4,
                },
            }"
            class="mySwiper">
            <swiper-slide v-for="(slide, id) in products" class="d-flex justify-content-center align-items-center">
                <div class="col-11 d-flex flex-column justify-content-start align-items-center slide px-0">
                    <div class="row justify-content-center row__width caption">
                        <div class="col-12 d-flex justify-content-center align-items-center filtro  px-0">
                            <img :src="slide.image" alt="" class="ejemplo">
                            <div class="row justify-content-center row__width position">
                                <div class="col-12 d-flex justify-content-center align-items-center">
                                    <div class="row justify-content-center row__width row__slide">
                                        <div class="col-12 d-flex justify-content-center align-items-center abajo px-0">
                                            <div class="row justify-content-center row__width">
                                                <div class="col d-flex justify-content-end align-items-center">
                                                    <h6><img src="../../img/home-icons/cursos-icons/rate.svg" alt="">{{$t('message.rating')}}</h6><p>{{$t('message.opinions')}}</p>
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center descripcion row__width">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                            <h2>{{ slide.name }}</h2>
                            <h5>{{ slide.description }}</h5>
                            <span class="border-bottom mt-4 w-100"></span>
                        </div>
                    </div>
                    <div class="row justify-content-center comprar row__width">
                        <div class="col-5 d-flex flex-column justify-content-center align-items-start padding">
                            <h2>{{ slide.pricePromo }}</h2>
                            <h5>{{ slide.price }}</h5>
                        </div>
                        <div class="col-7 d-flex justify-content-center align-items-center ps-0">
                            <BTNPrimario url="/preset/" :slug="slide.slug[lang]" :textoBTNPrimario="$t('message.buy')"/>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper-container>
    </div>
</template>

<script setup>
    import BTNPrimario from "../Botones/PrimarioRutas.vue";
    import { ref, inject } from 'vue';

    const lang = ref(localStorage.getItem('lang'))
    const products = inject('products')
    
</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 20px 0px;
    margin: 0px 0;
}
.slide{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    opacity: 1;
    min-height: 500px;
    max-height: 70vh;
    .ejemplo{
        width: 100%;
    }
    .caption{
        position: relative;
        margin: 10px 0px;
        img{
            max-width: 100%;
        }
        .position{
            position: absolute;
            top: 0;
            min-height: 100%;
            z-index: 3;
            h6{
                font-family: 'Proxima Nova';
                font-weight: 500;
                font-size: 17px;
                color: #FEB230;
            }
            p{  font-family: 'Proxima Nova';
                font-weight: 500;
                font-size: 17px;
                color: var(--bgPrincipal);
                padding-left: 3px;
            }
            .row__slide{
                position: relative;
                min-height: 100% !important;
                min-height: 250px;
                .arriba{
                    position: absolute;
                    top: 10%;
                }
                .abajo{
                    position: absolute;
                    bottom: 10%;
                }
            }
            
        }
        .filtro{
            &::before{
                content: '';
                position: absolute;
                bottom: 10px;
                width: 96%;
                height: 40%;
                border-radius: 0px 0px 15px 15px;
                background: linear-gradient(to top,  #081026 , #252E4800);
                filter: opacity(1);
                z-index: 2;
            }
        }
    }
    .descripcion{
        h2{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            text-align: left;
        }
        h3{
            color: #B8C1DB;
            font-weight: 500;
            font-size: 15px;
        }
        h4{
            color: var(--colorSecondary);
            font-weight: 500;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                margin-left: 5px;
            }
        }
        h5{
            font-weight: 400;
            font-size: 15px;
            color: #B8C1DB;
            padding-top: 10px;
        }
        p{
            color: var(--bgPrincipal);
            font-weight: 500;
            font-size: 15px;
        }
        .creador{
            border-right: 1px solid rgba(184, 193, 219, 0.3);
            padding-bottom: 10px;
        }
        .border-bottom{
            width: 90%;
            height: 1px;
            background-color: rgba(184, 193, 219, 0.3);
        }
    }
    .comprar{
        margin: 15px 0px;
        h2{
            font-weight: 700;
            font-size: 23px;
            color: var(--colorSecondary);
            text-align: left;
        }
        h5{
            font-size: 14px;
            font-weight: 400;
            color: #B8C1DB;
            text-decoration: line-through;
            text-align: left;
        }
    }
}
</style>