<template>
	<div class="row justify-content-center row__width inputs">
		<div class="col-12 d-flex flex-column justify-content-center align-items-start">
			<h3>{{ $t('message.regionAndTelephone') }}</h3>
			<div class="row__width">
				<div class="col-12 d-flex flex-column justify-content-center align-items-start" :class="results?.isValid ? 'success-style' : ''">
					<MazPhoneNumberInput
						:class="estados.phone_number"
						name="phone_number"
						show-code-on-list
						color="info"
						:preferred-countries="['AR']"
						:ignored-countries="[]"
						@update="results = $event; updatePhone(results)"
						:success="results?.isValid"
						:translations="{
							countrySelector: {
								placeholder: '',
								error: '',
							},
							phoneInput: {
								placeholder: 'Teléfono',
								example: '',
								label: ''
							},
						}"
						no-example
						fetch-country
						no-use-browser-locale
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import { ref, inject } from 'vue'
import { useUsersStore } from "../../stores/user";
import { storeToRefs } from "pinia";

polyfillCountryFlagEmojis()

// Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
const users = useUsersStore();
// Mis estados los paso a objetos porque en la tienda están en reactivos //
const {usuariosRegistro} = storeToRefs(users);
const {} = users;

const estados = inject('estados')

let results = ref()

const updatePhone = (r) => {
	if(r.isValid) {
		usuariosRegistro.value.phone_number = r.nationalNumber
		usuariosRegistro.value.country_code = '+'+r.countryCallingCode
	}
}

</script>

<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 7px 0px;
}
.success-style {
	div div {
		border-width: 0 !important;
		border: 2px solid #66df4a !important;
		border-color: #66df4a !important;
	}
}
</style>