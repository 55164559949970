<template>
    <div class="col-12 d-flex justify-content-center align-items-center px-0 content">
        <form action="" @submit.prevent="fetchInfoPerfilPersonal" class="row__width">
            <div class="row justify-content-center">
                <div class="col-12 d-flex justify-content-center align-items-center px-0 mt-lg-4">
                    <div class="row justify-content-start row__width">
                        <Container @drop="onDrop($event)" drag-handle-selector=".yes-draggable" class="collapses px-0">
                            <Draggable v-for="(item, id) in modules.content" :key="item">
                                <div class="accordion d-flex flex-column justify-content-center align-items-center draggable-item px-3" id="accordionExample">
                                    <div class="accordion-item row row__width justify-content-center">
                                        <h2 class="accordion-header mb-0 ps-0" :id="'heading'+id">
                                            <p class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+id" aria-expanded="false" aria-controls="collapse">
                                                <div class="col-9 d-flex justify-content-start align-items-center ps-0">
                                                    <span class="yes-draggable">
                                                        <img src="../../../img/subir-productos-icons/cursos-icons/drag.svg" alt="" draggable="false">
                                                    </span>
                                                    <div class=" d-flex flex-column justify-content-center align-items-start ps-1">
                                                        <h3 v-show="!item[0].title">{{ $t('message.module')+' '+(id+1) }}</h3>
                                                        <h3 v-show="item[0].title">{{ item[0].title }}</h3>
                                                    </div>
                                                </div>
                                                <div class="d-flex justify-content-end align-items-center buttons">
                                                    <button class="eliminar" type="button" @click="deleteModule(item[0].modId, modules.content, id)"><img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt=""></button>
                                                </div>
                                            </p>
                                        </h2>
                                        <div :id="'collapse'+id" class="col-12 accordion-collapse collapse" :aria-labelledby="'heading'+id" data-bs-parent="#accordionExample">
                                            <div class="accordion-body row justify-content-center px-4 pt-0">
                                                <!-- <Nombre :title="item[0].title" @update-title="item[0].title = $event"/> -->
                                                <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0 input">
                                                    <h3>{{ $t('message.title') }}</h3>
                                                    <input type="text" v-model="item[0].title" @focusout="setModTitle(item[0].title, item[0].titleId, item[0].modId)">
                                                </div>
                                                <div class="col-12 d-flex justify-content-center align-items-center px-0 mt-3">
                                                    <div class="row row__width justify-content-center">
                                                        <div class="col-5 col-sm-6 col-lg d-flex justify-content-start align-items-start ps-0">
                                                            <h3>{{ $t('message.modulesClasses') }}</h3>
                                                        </div>
                                                        <div class="col-7 col-sm-6 col-lg-5 col-xl-3 d-flex justify-content-center align-items-center pe-0">
                                                            <div class="dropdown">
                                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <div class="d-flex justify-content-between align-items-center w-100">
                                                                        <h3>Añadir clase</h3>
                                                                        <i class="arrow"></i>
                                                                    </div>
                                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                        <li><a class="dropdown-item" href="#" @click="addLesson(id, 1)">
                                                                            <span>
                                                                                <img src="../../../img/subir-productos-icons/cursos-icons/clases.svg" alt="">
                                                                            </span>
                                                                            Video
                                                                        </a></li>
                                                                        <li><a class="dropdown-item" href="#" @click="addLesson(id, 2)">
                                                                            <span>
                                                                                <img src="../../../img/subir-productos-icons/cursos-icons/cuestionario.svg" alt="">
                                                                            </span>
                                                                            {{ $t('message.questionnaire') }}
                                                                        </a></li>
                                                                    </ul>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 d-flex justify-content-center align-items-center error" :class="item[0].hasQuiz ? '' : 'd-none'">
                                                            <h4 class="text-center">
                                                                Ya tienes un cuestionario en este módulo
                                                            </h4>
                                                        </div>
                                                        <Container @drop="onInnerDrop(id, $event)" drag-handle-selector=".yes-draggable" class="px-0">
                                                            <Draggable v-for="(lesson, index) in item[0].lessons" :key="lesson">
                                                                <div class="col-12 d-flex justify-content-center align-items-center ps-2 px-lg-0 ofertas my-3"> 
                                                                    <div class="row row__width justify-content-center draggable-item">
                                                                        <div class="col d-flex justify-content-center align-items-center py-3 py-lg-auto pe-0">
                                                                            <!------- VIDEO ------->
                                                                            <div class="row row__width justify-content-start" v-if="lesson.lessonType == 1">
                                                                                <div class="col-12 col-xl max-width-2 px-0 d-flex justify-content-start align-items-center ejemplo">
                                                                                    <div class="row row__width justify-content-center">
                                                                                        <div class="col-6 col-lg-12 d-flex justify-content-start align-items-center ps-1 ps-lg-2">
                                                                                            <div class="d-flex justify-content-start align-items-end align-items-lg-center yes-draggable h-100">
                                                                                                <img src="../../../img/subir-productos-icons/cursos-icons/drag.svg" alt="" draggable="false">
                                                                                            </div>
                                                                                            <img :src="lesson.content.find(item => item.type === 0)?.url || require('../../../img/subir-productos-icons/thumbs-icons/video.svg')" alt="" class="img">
                                                                                        </div>
                                                                                        <div class="col-6 d-flex flex-column justify-content-start align-items-start d-lg-none pe-0">
                                                                                            <h4 class="mb-2">{{ $t('message.title') }}</h4>
                                                                                            <h5>{{ lesson.title }}</h5>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-12 col-xl d-flex justify-content-center align-items-center px-0">
                                                                                    <div class="row row__width justify-content-start ">
                                                                                        <div class="col-3 d-flex flex-column justify-content-start align-items-start pe-0 d-none d-lg-flex">
                                                                                            <h4 class="mb-2">{{ $t('message.title') }}</h4>
                                                                                            <h5>{{ lesson.title }}</h5>
                                                                                        </div>
                                                                                        <div class="col-12 col-lg-4 d-flex flex-column justify-content-start align-items-start pt-2 pt-lg-0">
                                                                                            <h4 class="mb-2">{{ $t('message.contents') }}</h4>
                                                                                            <div class="d-flex justify-content-center align-items-center">
                                                                                                <span class="span-noti" v-show="lesson.content.some(item => item.type === 1)">
                                                                                                    <div class="noti"><p>{{ lesson.content.filter(item => item.type === 1).length }}</p></div>
                                                                                                    <img src="../../../img/subir-productos-icons/cursos-icons/video.svg" alt="">
                                                                                                </span>
                                                                                                <span class="span-noti" v-show="lesson.content.some(item => item.type === 5)">
                                                                                                    <div class="noti"><p>{{ lesson.content.filter(item => item.type === 5).length }}</p></div>
                                                                                                    <img src="../../../img/subir-productos-icons/cursos-icons/link.svg" alt="">
                                                                                                </span>
                                                                                                <span class="span-noti" v-show="lesson.content.some(item => item.type === 3)">
                                                                                                    <div class="noti"><p>{{ lesson.content.filter(item => item.type === 3).length }}</p></div>
                                                                                                    <img src="../../../img/subir-productos-icons/cursos-icons/download.svg" alt="">
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-12 col-lg-3 d-flex flex-column justify-content-around align-items-start pt-2 pt-lg-0 ps-lg-4 ps-xl-0">
                                                                                            <h4 class="mb-2">{{ $t('message.typeOfClass') }}</h4>
                                                                                            <div class="d-flex justify-content-center align-items-center mt-3 mt-lg-0">
                                                                                                <span>
                                                                                                    <img src="../../../img/subir-productos-icons/cursos-icons/clases.svg" alt="">
                                                                                                </span>
                                                                                                <p>{{ $t('message.video') }}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <!------- CUESTIONARIO ------->
                                                                            <div class="row row__width justify-content-start" v-if="lesson.lessonType == 2">
                                                                                <div class="col-12 col-xl max-width-2 px-0 d-flex justify-content-start align-items-center ejemplo">
                                                                                    <div class="row row__width justify-content-center">
                                                                                        <div class="col-6 col-lg-12 d-flex justify-content-start align-items-center ps-1 ps-lg-2">
                                                                                            <div class="d-flex justify-content-start align-items-end align-items-lg-center yes-draggable h-100">
                                                                                                <img src="../../../img/subir-productos-icons/cursos-icons/drag.svg" alt="" draggable="false" class="drag">
                                                                                            </div>
                                                                                            <img src="../../../img/subir-productos-icons/thumbs-icons/cuestionario.svg" alt="" class="img">
                                                                                        </div>
                                                                                        <div class="col-6 d-flex flex-column justify-content-start align-items-start d-lg-none pe-0">
                                                                                            <h4 class="mb-2">{{ $t('message.title') }}</h4>
                                                                                            <h5>{{ lesson.title }}</h5>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-12 col-xl d-flex justify-content-center align-items-center px-0">
                                                                                    <div class="row row__width justify-content-start ">
                                                                                        <div class="col-3 d-flex flex-column justify-content-around align-items-start d-none d-lg-flex">
                                                                                            <h4 class="mb-2">{{ $t('message.title') }}</h4>
                                                                                            <h5>{{ lesson.title }}</h5>
                                                                                        </div>
                                                                                        <div class="col-12 col-lg-4 d-flex flex-column justify-content-around align-items-start pt-2 pt-lg-auto">
                                                                                            
                                                                                        </div>
                                                                                        <div class="col-12 col-lg-4 col-xl-3 d-flex flex-column justify-content-around align-items-start pt-2 pt-lg-auto ps-lg-4 ps-xl-0">
                                                                                            <h4 class="mb-2">{{ $t('message.typeOfClass') }}</h4>
                                                                                            <div class="d-flex justify-content-center align-items-center mt-3 mt-lg-0">
                                                                                                <span>
                                                                                                    <img src="../../../img/subir-productos-icons/cursos-icons/cuestionario.svg" alt="">
                                                                                                </span>
                                                                                                <p>{{ $t('message.questionnaire') }}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col max-width d-flex justify-content-center align-items-center eliminar">
                                                                            <button type="button" @click="editLesson(lesson.lessonId, lesson.evId, lesson.quizId, item[0].lessons, index)"><img src="../../../img/subir-productos-icons/cursos-icons/editar.svg" alt="" ></button>
                                                                            <button type="button" @click="deleteLesson(lesson.lessonId, id, item[0].lessons, index)"><img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt="" ></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Draggable>
                                                        </Container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Draggable>
                        </Container>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup>
import Nombre from '../../../components/Inputs/AgregarModulo/Nombre.vue';

import { onMounted, provide, ref, watch, inject } from 'vue';
import axios from "axios"
import { useI18n } from "vue-i18n"
import { useRouter } from 'vue-router';
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../../stores/user";
import { useLanguageStore } from "../../../stores/langs"
import { useVideoStore } from "../../../stores/video";

import { Container, Draggable } from "vue-dndrop";

const users = useUsersStore();
const { access } = storeToRefs(users);
const { url, validate } = users;

const {locale} = useI18n({ useScope: 'global' })
const langs = useLanguageStore()
const {} = langs

const video = useVideoStore();
const { } = storeToRefs(video);
const {verifiedPlataform, } = video;

//Recibe los modulos existentes y su contenido
const urlFetchModules = '/api/product/course/modules/'
//Guarda el nombre del curso en el idioma elegido
const urlSetModTitle = '/api/product/creating/add/module/translation'
//Elimina un modulo
const urlDeleteModule = '/api/product/creating/delete/module/'
//Reordena los modulos
const urlSetNewModPos = '/api/product/creating/module/reorder'

//Recibe las lecciones existentes y su contenido
const urlFetchLesson = '/api/product/course/modules/lessons/'
//Crea una leccion nueva
const urlAddLesson = '/api/product/creating/module/lesson'
//Elimina una leccion
const urlDeleteLesson = '/api/product/creating/module/delete/lesson/'
//Reordena las lecciones
const urlSetNewLessonPos = '/api/product/creating/module/lesson/reorder'
//Crea la nueva configuracion
const urlEditLesson = '/api/product/creating/module/lesson/config'

let product = inject('product')
let modules = inject('modules')
let information = inject('information')
let listNotes = inject('listNotes')

let editTask = inject('editTask')
let editQuest = inject('editQuest')
let actualActivity = inject('actualActivity')
let copyActualActivity = inject('copyActualActivity')
let currentItem = inject('currentItem')
let currentId = inject('currentId')

let videoIDRef = inject('videoIDRef')

let owner_id = inject('owner_id')
let product_id = inject('product_id')

const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

const onDrop = (dropResult) => {
    modules.value.content = applyDrag(modules.value.content, dropResult);
    let modPositions = []
    for(let i = 0; i < modules.value.content.length; i++){
        modules.value.content[i][0].modPos = i
        modPositions.push([modules.value.content[i][0].modId, i])
    }
    setNewModPos(modPositions)
};

const onInnerDrop = (id, dropResult) => {
    modules.value.content[id][0].lessons = applyDrag(modules.value.content[id][0].lessons, dropResult);
    let lessonPositions = []
    for(let i = 0; i < modules.value.content[id][0].lessons.length; i++){
        modules.value.content[id][0].lessons[i].lessonPos = i
        lessonPositions.push([modules.value.content[id][0].lessons[i].lessonId, i])
    }
    setNewLessonPos(lessonPositions, modules.value.content[id][0])
};

const editActivity = (item, id) => {
    if (item[id].lessonType == 1){
        editTask.value = true
        actualActivity.value = null
    }
    else if (item[id].lessonType == 2){
        editQuest.value = true
        actualActivity.value = null
    }
    currentItem.value = item;
    currentId.value = id;
    actualActivity.value = item[id]
    copyActualActivity.value = JSON.parse(JSON.stringify(item[id]))
}

const fetchModules = async() => {
    let pId = product.value.id
    try {
        await axios.get(url+urlFetchModules+pId).then((result) => {
            console.log(result)
            //console.log(modules.value.content)
            if(JSON.stringify(modules.value.content) !== JSON.stringify(result.data)){
                modules.value.content = []
                modules.value.content = result.data
            }
        })
    } catch (error) {
        console.error(error)
    }
}
fetchModules()

const setNewModPos = async(modPositions) => {
    let pId = product.value.id
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.post(url+urlSetNewModPos, {pId: pId, modPositions: modPositions}, {headers: headers.value}).then((result) => {
            //console.log(result)
            fetchModules()
        })
    } catch (error) {
        console.error(error)
    }
}

const setModTitle = async(title, titleId, modId) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    let lang = information.value.lang
    try {
        await axios.post(url+urlSetModTitle, {title: title, titleId: titleId, modId: modId, lang: lang, owner_id: owner_id.value, product_id: product_id.value}, {headers: headers.value}).then((result) => {
            console.log(result)
            //fetchModules()
        })
    } catch (error) {
        console.error(error)
    }
}

const deleteModule = async(modId, array, id) => {
    let modPositions = []
    array.splice(id, 1);
    for(let i = 0; i < modules.value.content.length; i++){
        modPositions.push([modules.value.content[i][0].modId, i])
    }
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.delete(url+urlDeleteModule+modId, {headers: headers.value}).then((result) => {
            //console.log(result)
            setNewModPos(modPositions)
        })
    } catch (error) {
        console.log(error)
    }
}

//LECCIONES

const fetchLessons = async(mod) => {
    console.log(mod)
    console.log(modules.value.content[mod.modPos][0])
    try {
        await axios.get(url+urlFetchLesson+mod.modId).then((result) => {
            console.log(result)
            //modules.value.content = result.data[0]
            if(JSON.stringify(modules.value.content[mod.modPos][0].lessons) !== JSON.stringify(result.data)){
                modules.value.content[mod.modPos][0].lessons = []
                modules.value.content[mod.modPos][0].lessons = result.data
            }
        })
    } catch (error) {
        console.error(error)
    }
}
//fetchLessons(1)

const addLesson = async(id, lessonType, lessonId) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    let mod = modules.value.content[id][0];
    let modId = mod.modId 
    let lessonPos = mod.lessons.length
    mod.hasQuiz = false
    if(lessonType == 2){
        if (mod.lessons.some(lesson => lesson.lessonType == 2)) {
            mod.hasQuiz = true
        }
    }
    if(!mod.hasQuiz){
        try {
            await axios.post(url+urlAddLesson, {modId: modId, lessonType: lessonType, lessonId: lessonId, lessonPos: lessonPos, owner_id: owner_id.value, product_id: product_id.value}, {headers: headers.value}).then((result) => {
                console.log(result)
                if (result.status == 201){
                    if(result.data.lessonType == 1){
                        modules.value.content[id][0].lessons.push({
                            lessonType: result.data.lessonType,
                            lessonId: result.data.lessonId,
                            lessonContentId: result.data.lessonContentId,
                            lessonPos: result.data.lessonPos,
                            title: '',
                            description: '',
                            lang: 'es',
                            content: []
                        })
                    }
                    else if(result.data.lessonType == 2){
                        modules.value.content[id][0].lessons.push({
                            lessonType: result.data.lessonType,
                            lessonId: result.data.lessonId,
                            lessonPos: result.data.lessonPos,
                            title: '',
                            description: '',
                            lang: 'es',
                            limitTime: 15,
                            questions: [],
                            questionsShown: 0,
                            config: {
                                mandatory: false,
                                repeatable: false,
                                checkMinimumGrade: false,
                                checkQuestionsShown: false,
                                minimumGrade: listNotes.value.quizValue,
                                answersVisible: false,
                            }
                        })
                        console.log(listNotes.value.quizValue)
                    }
                }
            })
        } catch (error) {
            console.error(error)
        }
    }
}

const editLesson = async(lessonId, evId, quizId, item, id) => {
    videoIDRef.value = ''
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.post(url+urlEditLesson, {lessonId: lessonId, evId: evId, quizId: quizId, owner_id: owner_id.value, product_id: product_id.value}, {headers: headers.value}).then((result) => {
            console.log(result)
            console.log(item)
            console.log(id)
            actualActivity.value = null
            currentItem.value = item;
            currentId.value = id;
            actualActivity.value = item[id]
            console.log(actualActivity.value)
            editTask.value = true
            editQuest.value = false
            if(result.data.lessonType == 2){
                editQuest.value = true
                editTask.value = false
                actualActivity.value.quizId = result.data.quizId
                actualActivity.value.evId = result.data.evId
            }
            copyActualActivity.value = JSON.parse(JSON.stringify(item[id]))
        })
        if(actualActivity.value.video.includes('vimeo')){
            const segments = actualActivity.value.video.split('/');
            const lastSegment = segments[segments.length - 1];
            actualActivity.value.video = `https://player.vimeo.com/video/${lastSegment}`;
            console.log(actualActivity.value.video)
        }
        if(actualActivity.value.video){
            videoIDRef.value = ''
            videoIDRef.value =  await verifiedPlataform(actualActivity.value.video);
            videoIDRef.value.url = actualActivity.value.video
            console.log(videoIDRef.value)
        }
    } catch (error) {
        console.error(error)
    }
}

const deleteLesson = async(lessonId, module_id, array, id) => {
    array.splice(id, 1);
    let lessonPositions = []
    for(let i = 0; i < modules.value.content[module_id][0].lessons.length; i++){
        lessonPositions.push([modules.value.content[module_id][0].lessons[i].lessonId, i])
    }
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.delete(url+urlDeleteLesson+lessonId, {headers: headers.value}).then((result) => {
            console.log(result)
            setNewLessonPos(lessonPositions, modules.value.content[module_id][0])
        })
    } catch (error) {
        console.log(error)
    }
}

const setNewLessonPos = async(lessonPositions, mod) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.post(url+urlSetNewLessonPos, {lessonPositions: lessonPositions}, {headers: headers.value}).then((result) => {
            console.log(result)
            fetchLessons(mod)
        })
    } catch (error) {
        console.error(error)
    }
}
</script>

<style lang="scss" scoped>
h2{
    font-weight: 600;
    color: var(--colorSecondary);
    font-size: 22px;
    text-align: left;
    margin-bottom: 10px !important;
    width: 100%; 
}
p{
    color: var(--colorSecondary);
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    padding: 2px 0px;
}
.collapses{
    user-select: none;
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        padding: 7px 0px;
    }
    h6{
        color:#B8BCC8;
        font-size: 15px;
        font-weight: 400;
        text-align: left;
        padding: 5px 0px;
    }
    .accordion{
        width: 100%;
        .accordion-item{
            margin: 15px 0px;
            border: 0;
            border-radius: 10px;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: #F9F9F9;
        }
        .accordion-item:last-of-type .accordion-button.collapsed{
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .accordion-header{
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            min-height: 77px;
            .accordion-button{
                border-radius: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                background-color: #F9F9F9;
                &:focus{
                    border-color: inherit;
                    box-shadow: none;
                }
                span{
                    min-height: 40px;
                    min-width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                h3{
                    text-align: left;
                    font-size: 22px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break:keep-all;
                }
                button{
                    background-color: transparent;
                    border: 2px solid #E4E4E4;
                    border-radius: 50%;
                    min-height: 40px;
                    min-width: 40px;
                    margin-left: 10px;
                }
            }
            .accordion-button:not(.collapsed){
                background-color: inherit;
                box-shadow: none;
            }
            .accordion-button::after{
                position: absolute;
                right: 0%;
                background-image: url('../../../img/subir-productos-icons/arrow-gris-abajo.svg');
                background-size: 50%;
                background-position: center;
                background-color: transparent;
                border: 2px solid #E4E4E4;
                border-radius: 50%;
                height: 40px;
                width: 40px;
            }
            .accordion-body{
                span{
                    border: 2px solid #E9E3E3;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h3{
                        text-align: center;
                    }
                }
                button{
                    background-color: transparent;
                    border: 2px solid #E4E4E4;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    margin-left: 10px;
                }
            }
            .buttons{
                position: absolute;
                right: 12vw;
                @media (min-width: 500px) {
                    right: 11vw;
                }
                @media (min-width: 992px) {
                    right: 10%;
                }
                @media (min-width: 1100px) {
                    right: 9%;
                }
                @media (min-width: 1200px) {
                    right: 8%;
                }
                @media (min-width: 1400px) {
                    right: 7.5%;
                }
            }
            
        }
        .dropdown{
            width: 100%;
            box-shadow: 2px 2px 10px hsla(0, 0%, 0%, 0.15);
            border-radius: 10px;
            button{
                width: 100%;
                background-color: var(--bgPrincipal);
                color: var(--colorSecondary);
                font-size: 16px;
                font-weight: 600;
                border: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                border-radius: 10px;
                padding: 0 20px;
                h3{
                    padding: 20px 0;
                }
                .dropdown-menu{
                    background-color: var(--bgPrincipal);
                    width: 100%;
                    border: 0;
                    padding: 20px 20px 0 20px;
                    height: auto;
                    border-radius: 0 0 10px 10px ;
                    a:hover, a:focus{
                        background-color: inherit;
                    }
                    a{
                        padding: 0 0 10px 0;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: #2E4765;
                        font-size: 15px;
                        font-weight: 400;
                    }
                    span{
                        min-height: 40px;
                        min-width: 40px;
                        border: 2px solid #E4E4E4;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;
                    }
                    li{
                        width: 100%;
                    }
                    @media (max-width: 991px) {
                        padding: 10px;
                    }
                }
                @media (max-width: 991px) {
                    padding: 10px;
                    h3{
                        padding: 10px 0;
                    }
                }
            }
            .dropdown-toggle::after{
                display: none;
            }
            .arrow{
                width: 25px;
                height: 15px;
                background-repeat: no-repeat;
                background-image: url('../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all ease 0.2s;
                background-size: 75%;
                background-position: center;
            }
        }
        .dropdown-toggle.show{
            .dropdown-menu{
                box-shadow: 2px 2px 10px hsla(0, 0%, 0%, 0.15);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &:after{
                    content: '';
                    position: absolute;
                    background-color: var(--bgPrincipal);
                    width: 100%;
                    height: 10px;
                    top: -7px;
                }
            }
            .arrow{
                transform: rotate(180deg);
                transition: all ease 0.2s;
            }
        }
        .ofertas{
            border: 1px solid #E4E4E4;
            background-color: var(--bgPrincipal);
            box-shadow: 0 3px 6px #00000029;
            border-radius: 15px;
            min-height: 110px;
            padding-left: 10px;
            h4{
                color: var(--colorSecondary);
                font-weight: 600;
                font-size: 16px;
            }
            h5{
                color: #2E4765;
                font-weight: 300;
                font-size: 14px;
            }
            .eliminar{
                border-left: 2px solid #E4E4E4;
                button{
                    background-color: transparent;
                    border: 2px solid #E4E4E4;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    margin-left: 10px;
                    &:first-child{
                        margin: 0;
                    }
                }
                @media (max-width: 1100px) {
                    flex-direction: column;
                    button{
                        margin: 5px 0;
                    }
                }
            }
            .ejemplo{
                border-radius: 5px;
                .img{
                    height: 100%;
                    width: 100%;
                    width: 100%;
                    border-radius: 5px;
                    margin-left: 5px;
                    max-height: 83px;
                    object-fit: cover;
                    @media (min-width: 992px) {
                        margin-left: 5px;
                        min-width: 118px;
                        max-width: 118px;
                        margin-left: 10px;
                    }
                }
            }
            span{
                height: 40px;
                width: 40px;
                border: 2px solid #E4E4E4;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
                p{
                    font-size: 14px;
                    color: #2E4765;
                    font-weight: 300;
                }
            }
            .max-width{
                max-width: 18%;
                @media (min-width: 992px) {
                    max-width: 13%;
                }
                @media (min-width: 1100px) {
                    max-width: 20%;
                }
                @media (min-width: 1400px) {
                    max-width: 11%;
                }
            }
            .max-width-2{
                @media (min-width: 992px) {
                    max-width: 20%;
                }
            }
            .span-noti{
               position: relative;
               margin-right: 15px;
                .noti{
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: var(--colorPrimary);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: -5px;
                    right: -5px;
                    p{
                        color: var(--bgPrincipal);
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.ofertas{
    border: 1px solid #E4E4E4;
    background-color: var(--bgPrincipal);
    border-radius: 15px;
    min-height: 110px;
    h4{
        color: var(--colorSecondary);
        font-weight: 600;
        font-size: 16px;
        text-align: left;
    }
    h5{
        color: #2E4765;
        font-weight: 300;
        font-size: 14px;
        text-align: left;
    }
    .eliminar{
        border-left: 2px solid #E4E4E4;
        button{
            background-color: transparent;
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            margin-left: 10px;
        }
    }
    .ejemplo{
        border-radius: 5px;
        height: 83px;
        .img{
            max-height: 100%;
            max-width: 100%;
            width: 100%;
            object-fit: none;
        }
    }
    
}
.checkboxes{
    input[type=radio]{
        display: none;
    }
    label{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .span{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 2px solid #E4E4E4;
            min-width: 28px;
            min-height: 28px;
            left: 0;
        }
        .rojo{
            background-color: #EF4836;
        }
        .verde{
            background-color: #37BFA3;
        }
        .azul{
            background-color: #1679FC;
        }
        i{
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            display: block;
        }
        .star{
            background-image: url('../../../img/subir-productos-icons/cursos-icons/star.svg');
        }
        .flame{
            background-image: url('../../../img/subir-productos-icons/cursos-icons/flame.svg');
        }
    }
    input:checked + label .span{
        border-color: #000;
        &::after{
            display: block;
        }
    }
    input:checked + label .star{
        background-image: url('../../../img/subir-productos-icons/cursos-icons/star-active.svg');
    }
    input:checked + label .flame{
        background-image: url('../../../img/subir-productos-icons/cursos-icons/flame-active.svg');
    }
    .col{
        max-width: 32px;
    }
    .col-perso{
        max-width: 45px;
    }
    .bg-azul{
        background-color: rgba(22, 122, 252, 0.17);
        padding: 15px;
        border-radius: 5px;
        margin: 30px 0;
        h5{
            font-weight: 500;
            color: #242134;
            font-size: 15px;
            padding: 5px 0;
        }
    }
    .time{
        .span{
            border: 2px solid var(--colorPrimary);
            height: 44px;
            width: 44px;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 12px;
            color: var(--colorPrimary);
            padding: 10px;
        }
        input:checked + label .span{
            background-color: var(--colorPrimary);
            border-color: #E4E4E4;
            color: var(--bgPrincipal);
            &::after{
                display: block;
            }
        }
        .margin{
            margin-top: 30px;
        }
    }
}

.input{
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        padding: 7px 0px;
    }
    input{
        width: 100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: #FCFCFC;
        height: 50px;
        padding-left: 15px;
        outline: 0;
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }   
}
.error{
    border: 2px solid #F6BEBE;
    background-color: #FCEEEE;
    min-height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    padding: 0 10px;
    margin: 10px 0;
    h4{
        color: var(--colorRed);
        font-size: 16px;
        font-weight: 400;
    }
}
</style>