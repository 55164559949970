<template>
    <div class="modal fade colaboradores" id="hola125" tabindex="-1" aria-labelledby="hola125" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header row">
                    <div class="col-8 d-flex justify-content-center align-items-center px-0">
                        <div class="row justify-content-start row__width">
                            <div class="col-5 d-flex justify-content-start align-items-center">
                                <h5 class="modal-title" id="exampleModal59">Colaboradores</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 d-flex justify-content-center align-items-center px-0 tablas">
                        <div class="row justify-content-center row__width">
                            <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0">
                                <div class="scroll d-flex justify-content-center align-items-center">
                                    <ul class="nav nav-tabs row justify-content-center" id="myTab" role="tablist">
                                        <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Profesional</button>
                                        </li>
                                        <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Sponsor</button>
                                        </li>
                                        <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Alumno</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-1 d-flex justify-content-center align-items-center px-0">
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-body row ver__perfiles">
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                        <div class="tab-content row justify-content-center row__width" id="myTabContent">
                            <div class="col-12 tab-pane fade show active scroll-y" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div class="row justify-content-center ">
                                    <div class="col-12 d-flex justify-content-center align-items-center px-0 colaborador" v-for="(colaborador, id) in colaboradores">
                                        <div class="row justify-content-center row__width">
                                            <div class="col-2 col-lg-1 d-flex justify-content-center align-items-center width">
                                                <img src="../../img/home-icons/cursos-icons/creador-ejemplo.png" alt="" class="img">
                                            </div>
                                            <div class="col d-flex flex-column justify-content-center align-items-start">
                                                <h3>{{ colaboradores[id].titulo }}</h3>
                                                <h4>{{ colaboradores[id].subtitulo }}</h4>
                                            </div>
                                            <div class="col d-flex justify-content-center align-items-center ps-0 width2">
                                                <VerPerfilColaboradores/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div class="row justify-content-center ">
                                    <div class="col-12 d-flex justify-content-center align-items-center px-0 colaborador" v-for="(colaborador, id) in colaboradores">
                                        <div class="row justify-content-center row__width">
                                            <div class="col-2 col-lg-1 d-flex justify-content-center align-items-center width">
                                                <img src="../../img/home-icons/cursos-icons/creador-ejemplo.png" alt="" class="img">
                                            </div>
                                            <div class="col d-flex flex-column justify-content-center align-items-start">
                                                <h3>{{ colaboradores[id].titulo }}</h3>
                                                <h4>{{ colaboradores[id].subtitulo }}</h4>
                                            </div>
                                            <div class="col d-flex justify-content-center align-items-center ps-0 width2">
                                                <VerPerfilColaboradores/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                <div class="row justify-content-center ">
                                    <div class="col-12 d-flex justify-content-center align-items-center px-0 colaborador" v-for="(colaborador, id) in colaboradores">
                                        <div class="row justify-content-center row__width">
                                            <div class="col-2 col-lg-1 d-flex justify-content-center align-items-center width">
                                                <img src="../../img/home-icons/cursos-icons/creador-ejemplo.png" alt="" class="img">
                                            </div>
                                            <div class="col d-flex flex-column justify-content-center align-items-start">
                                                <h3>{{ colaboradores[id].titulo }}</h3>
                                                <h4>{{ colaboradores[id].subtitulo }}</h4>
                                            </div>
                                            <div class="col d-flex justify-content-center align-items-center ps-0 width2">
                                                <VerPerfilColaboradores/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import VerPerfilColaboradores from "../Botones/VerPerfilColaboradores.vue";
import { useGeneralStore } from "../../stores/general"    
import { storeToRefs } from "pinia";

const general = useGeneralStore();
const {colaboradores} = storeToRefs(general);
const {} = general;
</script>

<style lang="scss" scoped>
.colaboradores{
    .modal-header{
        border-bottom: 0;
    }
    .modal-header .btn-close{
        background-image: url('../../img/modales-icon/cerrar.svg');
        opacity: 1;
    }
    .modal-content{
        padding: 20px 30px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 30px;
    }
    .modal-dialog{
        max-width: 70%;
    }
    .contador__botones{
        p, h3{
            font-weight: 700;
            font-size: 18px;
            color: var(--colorSecondary);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 3px;
        }
        h3{
            font-weight: 400;
            padding-left: 3px;
            padding-top: 2px;
        }
        button{
            background-color: var(--bgPrincipal);
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #F5F5F5;
            border-radius: 21px;
            width: 42px;
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .col__perso{
            max-width: max-content;
        }
    }
    h5{
        font-weight: 600;
        font-size: 22px;
        color: var(--colorSecondary);
        text-align: left;
    }
    h3{
        font-family: 'Proxima Nova';
        font-weight: 700;
        font-size: 16px;
    }
    h4{
        font-family: 'Proxima Nova';
        font-weight: 400;
        font-size: 15px;
    }
    .tablas{
        h6{
            font-weight: 600;
            font-size: 18px;
            color: var(--colorSecondary);
            text-align: left;
            padding: 2vh 0 6vh 0;
        }
        .nav-link{
            width: 100%;
            font-weight: 400;
            font-family: 'Proxima Nova';
            font-size: 16px;
            color: #0E1133;
            position: relative;
            padding: 10px 0;
            border: 0;
        }
        .nav-link.active{
            font-weight: 700;
            border: 0;
            color: var(--colorSecondary);
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 5px;
                bottom: 0;
                left: 0;
                border-radius: 50px 50px 0px 0px;
                background-color: var(--colorPrimary);
                
            }
            @media (min-width: 992px) {
                &::before{
                    height: 4px;
                }   
            }
        }
        .scroll{
            overflow-x: scroll;
            max-width: 100%;
            ul{
                min-width: max-content;
                li{
                    min-width: max-content;
                }
            }
            @media (min-width: 992px) {
                overflow: hidden;
                max-width: none;
                width: 100%;
                ul{
                    max-width: 100%;
                    min-width: 1vw;
                    width: 100%;
                }
            }
        }
    }
    .btn-close{
        outline: 0;
        box-shadow: none;
        &:focus{
            box-shadow: none;
            border-color: transparent;
        }
    }
    .scroll-y{
        max-height: 17vh;
        overflow-y: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar{
            background-color: rgba(219, 219, 219, 0.25);
            border-radius: 200px;
            width: 8px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: rgb(219, 219, 219);
            border-radius: 50px;
        }
    }
    .colaborador{
        padding: 10px 0;
        border-bottom: 1px solid #b8c1db42;
        &:last-child{
            border-bottom: 0;
        }
    }
    .width{
        max-width: 60px;
    }
    .img{
        height: 55px;
        width: 55px;
        border-radius: 50%;
    }
    .width2{
        max-width: 150px;
    }
}
</style>