<template>
    <div class="row justify-content-start justify-content-lg-center row__width detallesPlanes">
        <div class="contenedor justify-content-start justify-content-lg-center">
            <div class="scroll col-12 d-flex flex-column justify-content-center align-items-start px-0">
                <div class="row justify-content-center justify-content-lg-start row__width mb-3">
                    <div class="col-7 col-lg-5 d-flex justify-content-start align-items-center px-lg-0 me-lg-5">
                        <h6>{{ $t('message.plansDetails') }}</h6>
                    </div>
                    <div class="col col-lg-2 d-flex justify-content-center align-items-center px-0 me-lg-5">
                        <h5>{{ $t('message.singlePayment') }}</h5>
                    </div>
                    <div class="col col-lg-2 d-flex justify-content-center align-items-center px-0">
                        <h4>{{ $t('message.annualPayment') }}</h4>
                    </div>
                </div>
                <DetallesPlanesRowV1 v-for="(filas, id) in tabla" :tituloV1="tabla[id].titulo" :img='tabla[id].img' :img2='tabla[id].img2' :color="tabla[id].color"/>
            </div>
        </div>
        <div class="col-12 d-flex justify-content-start  justify-content-lg-center align-items-center mt-lg-4 px-0">
            <p>{{ $t('message.pricesAndMethods') }}</p>
        </div>
        <div class="col-12 d-flex justify-content-start justify-content-lg-center align-items-center tarjetas px-0">
            <span><img src="../../img/carrito-icons/visa.svg" alt=""></span>
            <span><img src="../../img/carrito-icons/mastercard.svg" alt="" class="grande"></span>
            <img src="../../img/carrito-icons/american.svg" alt="" class="american">
            <span><img src="../../img/carrito-icons/paypal.svg" alt="" class="paypal"></span>
        </div>
    </div>
</template>

<script setup>
    import DetallesPlanesRowV1 from './DetallesPlanesRowV1.vue';
    import { ref } from "vue";

    const tabla = ref([
        {titulo: 'Acceso a un plan de formación gratuito con más de 40 horas de contenido por valor de 290USD.', img: 1, img2: 2, color: true,},
        {titulo: 'Invitación a eventos con las marcas', img: 3, img2: 2, color: false,},
        {titulo: 'Acceso a webinars', img: 1, img2: 2, color: true, negrita: 'En Vivo', color: true,},
        {titulo: '5% de Descuento sobre el precio tarifa de todos los cursos', img: 3, img2: 2, color: false,},
        {titulo: 'Códigos de descuento en tiendas de nuestro directorio de marcas', img: 1, img2: 2, color: true,},
    ])
</script>

<style lang="scss" scoped>

.detallesPlanes{
    @media (max-width: 991px) {
        .contenedor{
            overflow-x: scroll;
            overflow-y: hidden;
            margin: 10px 0;
        }
        .scroll{
            min-width: 150vw;
            margin-bottom: 20px;
        }
    }
    h6{
        font-weight: 600;
        font-size: 22px;
        color: #050823;
        text-align: left;
    }
    h5, h4{
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
    }
    h4{
        font-size: 18px;
    }
    p{
        font-size: 14px;
        font-weight: 400;
        color: #7D93B2;
        padding: 10px 0px;
        text-align: left;
    }
    .tarjetas{
        margin: 0px 0 5px 0;
        span{
            min-width: 45px;
            height: auto;
            min-height: 20px;
            box-shadow: 0px 3px 6px #00000029;
            background-color: var(--bgPrincipal);
            border-radius: 8px;
            padding: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            img{
                height: 13px;
            }
            .grande{
                height: 14px;
                max-width: 40px;
            }
        }
        .american{
            margin-right: 10px;
            height: 20px;
        }
        .paypal{
            min-width: 55px;
        }
    }
    @media (min-width: 992px) {
        margin: 20px 0;
    }
}
</style>