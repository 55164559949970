<template>
    <div class="row justify-content-center justify-content-lg-between row__width planes">
        <PlanPlus v-for="(plan, id) in planes" 
        :titulo="planes[id].titulo" :precio="planes[id].precio" :subtitulo="planes[id].subtitulo" :src="planes[id].src"/>
    </div>
</template>

<script setup>
    import PlanPlus from './PlanPlus.vue';
    import { ref } from "vue";

    const planes = ref([
        {titulo: 'ÚNICO PAGO', precio:'MX/ $1200', subtitulo:'PAGO UNICO / GRATIS DE POR VIDA', src:require('../../img/plus-icons/pago-unico.svg') },
        {titulo: 'PAGO ANUAL', precio:'MX/ $500', subtitulo:'1 AÑO GRATIS POR LA COMPRA DE CUALQUIER CURSO.', src:require('@/img/plus-icons/pago-anual.svg')}
    ])
</script>

<style lang="scss" scoped>

</style>