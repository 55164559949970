<template>
    <div class="row justify-content-evenly row__width px-lg-5 mt-3 addReferrals" v-if="!actual_gallery">
        <div class="col-3 d-flex flex-column justify-content-center align-items-center mt-3 gallery" @click="actual_gallery = null, actual_gallery = gallery" v-for="(gallery, index) in gallery.specializations">
            <div class="d-flex justify-content-center row__width">
                <h3>{{ gallery.translation.name }}</h3>
            </div>
        </div>
    </div>
    <div class="row justify-content-start row__width px-lg-5 addReferrals" v-if="actual_gallery">
        <div class="col-12 d-flex flex-column justify-content-start align-items-start mt-3 gallery-show">
            <div class="d-flex justify-content-between ps-3 mt-3 mb-4 row__width">
                <h2>{{ actual_gallery.translation.name }}</h2>
            </div>
            <div class="d-flex justify-content-start row__width">
                <div class="gallery-container">
                    <div class="thumbnail-list">
                        <div class="thumbnail-wrapper" v-for="image in actual_gallery.files" :key="image.id">
                            <img
                                :src="image.file"
                                alt="Gallery Thumbnail"
                                @click="openLightbox(image)"
                                class="thumbnail"
                            />
                        </div>
                    </div>
                    <div v-if="lightbox_open" class="lightbox">
                        <div class="backdrop" @click="closeLightbox"></div>
                        <div class="lightbox-content">
                            <div class="navigation">
                                <span @click="previousImage" class="arrow left-arrow">❮</span>
                                <img :src="selected_image?.file" alt="Expanded Image" class="expanded-image" />
                                <span @click="nextImage" class="arrow right-arrow">❯</span>
                            </div>
                            <div class="lightbox-thumbnails" ref="thumbnail_container">
                                <img
                                    v-for="image in actual_gallery.gallery"
                                    :key="image.id"
                                    :src="image.file"
                                    alt="Lightbox Thumbnail"
                                    class="lightbox-thumbnail"
                                    :class="{'lightbox-thumbnail': true, 'selected': image.id === selected_image?.id}"
                                    @click="selectImage(image)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 d-flex flex-column justify-content-center align-items-center mt-3 border-top">
            <div class="row justify-content-center justify-content-lg-between px-5 mt-3 row__width">
                <div class="col-5 col-lg-3 d-flex justify-content-center align-items-center ps-0">
                    <button id="save-button" ref="buttonRef" type="button" class="btn__inicio"
                        @click="actual_gallery = null"
                        v-if="actual_gallery?.files"
                    >
                        {{$t('message.return')}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { inject, nextTick, onBeforeMount, ref, watch } from "vue";

    const props = defineProps({
        gallery:[],
    })

    const actual_gallery = inject('actual_gallery')
    const selected_image = ref(null)
    const lightbox_open = ref(false)
    const thumbnail_container = ref(null)

    const openLightbox = (image) => {
    selected_image.value = image
    lightbox_open.value = true
    }

    const closeLightbox = () => {
    lightbox_open.value = false
    selected_image.value = null
    }

    const selectImage = (image) => {
    selected_image.value = image
    }

    const previousImage = () => {
    const current_index = actual_gallery.value.files.findIndex(image => image.id === selected_image.value.id)
    const previous_index = (current_index - 1 + actual_gallery.value.files.length) % actual_gallery.value.files.length
    selected_image.value = actual_gallery.value.files[previous_index]
    }

    const nextImage = () => {
    const current_index = actual_gallery.value.files.findIndex(image => image.id === selected_image.value.id)
    const next_index = (current_index + 1) % actual_gallery.value.files.length
    selected_image.value = actual_gallery.value.files[next_index]
    }

    const scrollToSelectedThumbnail = () => {
        if (thumbnail_container.value) {
            const selected_thumbnail = thumbnail_container.value.querySelector('.selected')
            if (selected_thumbnail) {
                const container_width = thumbnail_container.value.offsetWidth
                const thumbnail_width = selected_thumbnail.offsetWidth
                const scroll_position = selected_thumbnail.offsetLeft - (container_width / 2) + (thumbnail_width / 2)
                thumbnail_container.value.scrollTo({
                    left: scroll_position,
                    behavior: 'smooth'
                })
            }
        }
    }

    onBeforeMount(async () => {
        watch(selected_image, () => {
            if (lightbox_open.value) {
                nextTick(() => scrollToSelectedThumbnail())
            }
        })
    })
</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 20px 0px;
    margin: 20px 0;
}
.productos{
    border-radius: 15px;
    min-height: 270px;
    padding: 0px 10px;
    max-height: max-content;
    position: relative;
    &:hover, &:focus{
        .hover{
            display: block;
        }
    }
    .img{
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 15px;
    }
    .hover{
        position: absolute;
        left: 0;
        bottom: 5%;
        width: 100%;
        display: none;
        h4{
            color: var(--bgPrincipal);
            font-weight: 600;
            font-size: 17px;
            padding-left: 5px;
        }
    }
    @media (min-width: 992px) {
        max-width: 343px;
    }
}

.gallery {
    flex-wrap: wrap;
    box-sizing: border-box;
    border: 2px solid #E9E3E3;
    border-radius: 15px;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
    &:hover {
        border-color: var(--colorPrimary);
    }
    h3 {
        padding-left: 5px;
    }
}

.gallery-show {
    h2 {
        font-weight: 600;
        font-size: 24px;
    }
}

.gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ECECEC;
    border-radius: 15px;
}

.thumbnail-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
}

.thumbnail-wrapper {
    position: relative;
}

.thumbnail {
    width: 100px;
    height: 100px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 10px;
}

.thumbnail-new {
    border: 2px dotted #E9E3E3;
    padding-left: 5px;
}

.thumbnail:hover {
    transform: scale(1.1);
}

.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
}

.lightbox-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10001;
}

.expanded-image {
    max-width: 90%;
    max-height: 70vh;
    margin-bottom: 20px;
    border-radius: 15px;
}

.lightbox-thumbnails {
    position: fixed;
    bottom: 10px;
    width: 100%;
    display: flex;
    gap: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: 10002;
}

.lightbox-thumbnail {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 10px;
    border: 2px solid transparent;
}

.lightbox-thumbnail .delete-button {
    display: none;
}

.lightbox-thumbnail:hover {
    transform: scale(1.1);
}

.lightbox-thumbnail.selected {
    border-color: var(--colorPrimary);
}

.navigation {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow {
    font-size: 2rem;
    color: white !important;
    cursor: pointer;
    user-select: none;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    width: 10%;
    z-index: 10002;
    &:hover {
        color: var(--colorPrimary) !important;
    }
}

.left-arrow {
    left: 0;
}

.right-arrow {
    right: 0;
}

.btn__inicio{
    border: 2px solid #B8C1DB;
    color: #B8C1DB;
    background-color: var(--bgPrincipal);
    width: 100%;
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 27px;
    font-size: 16px;
    font-weight: 600;
    &:hover, &:focus{
        background-color: #B8C1DB;
        color: var(--colorBTNPrimario);
        border-color: transparent;
    }
}
</style>