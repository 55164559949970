<template>
    <div class="container-fluid">
        <NavbarBack class="" v-if="cambioHeader"/>
        <NavbarBuscadorBack v-else/>
        <HeaderTitulo class="d-none d-lg-flex" :tituloHeader="$t('message.HomePresets')"/>
        <div class="row justify-content-center position-relative">
            <div class="col-4 d-none d-lg-flex justify-content-center align-items-start ps-0" v-if="mapaDesplegado"></div>
            <div class="col-3 justify-content-center align-items-start ps-0 mt-lg-5" v-if="filtrosDesplegados">
                <BodyFiltros/>
            </div>
            <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0 px-lg-auto position-relative" 
                :class="filtrosDesplegados == true ? 'col-lg-8 justify-content-lg-start' : 'col-lg-11' && mapaDesplegado == true ? 'col-lg-7 justify-content-lg-start' : 'col-lg-11' ">
                <Filtros class=""/>
                <!---- ASESORÍAS ---->
                <!-- <TitulosSecciones :titulo="$t('message.titleConsultingResults')" :subtitulo="$t('message.subtitleConsultingResults')" :haySubtitulo="true" class="d-lg-none"/>
                <TituloYOpcionesV4 :titulo="$t('message.titleConsultingResults')" :subtitulo="$t('message.subtitleConsultingResults')"
                 class="d-none d-lg-flex mt-lg-0" idBack="prevSliderAsesorias" idNext="nextSliderAsesorias" pagination="pagAsesorias"/>
                <OrdenarPorSelect class="d-lg-none"/>
                <Asesorias/>
                <BotonesSlidersHome class="d-lg-none" idBack="prevSliderAsesorias" idNext="nextSliderAsesorias"/> -->
                <!---- CURSOS ---->
                <!-- <TitulosSecciones :titulo="$t('message.coursesPhotography')" :haySubtitulo="false" class="d-none "/> -->
                <TituloYOpcionesResultadosCursos class="mt-2" :titulo="$t('message.coursesPhotography')" idNextV2="nextSliderCursosboton" idBackV2="prevSliderCursosboton" pagination="pagCursos"/>
                <SliderMobileSeccionesHomeV2/>
                <!-- <BotonesSlidersHome class="d-none" idNext="nextSliderCursosboton" idBack="prevSliderCursosboton" pagination="pagCursosMobile"/> -->
                <!---- MARCAS ---->
                
                <!-- <TitulosSecciones :titulo="$t('message.findBestPhotographs')" :haySubtitulo="false" class="d-lg-none"/>
                <OrdenarPorSelect class="d-lg-none"/>
                <TituloYOpcionesV3 :titulo="$t('message.findBestPhotographs')" idNext="nextSlider7boton" idBack="prevSlider7boton" class="d-none d-lg-flex" pagination="pagMarcas"/>
                <Proveedores class="d-lg-none"/>
                <ProveedoresV2 class="d-none d-lg-flex marginBottom"/>
                <BotonesSlidersHome  class="d-lg-none" idNext="nextSlider7boton" idBack="prevSlider7boton"/> -->
                <!---- PRESETS ---->
                <!-- <TitulosSecciones :titulo="$t('message.downloadables')" :haySubtitulo="false" class="d-lg-none"/>
                <OrdenarPorSelect class="d-lg-none"/>
                <TituloYOpcionesV2 :titulo="$t('message.presets')" class="d-none d-lg-flex" idNext="nextSliderPresetsboton" idBack="prevSliderPresetsboton" pagination="pagPresets"/>
                <Presets/> -->
                <!---- PROFESORES ---->
                <!-- <TitulosSecciones :titulo="$t('message.titleDirectory')" :haySubtitulo="false" class=""/> -->
                <TituloYOpcionesV3 :titulo="$t('message.titleDirectoryProfessionals')" class="" idNext="nextSliderFotosboton" idBack="prevSliderFotosboton" pagination="pagProfesional"/>
                <DirectorioDeFotos/>
                <!-------->
                <!-- <panelSeccion class="d-lg-none"/>
                <PanelSeccionV3 :class="filtrosDesplegados == true ? 'd-none' : 'd-flex' || mapaDesplegado == true ? 'd-none' : 'd-flex'"/> -->
                <!-- <TitulosSeccionesV2 :titulo="$t('message.exploreCategories')" :class=" filtrosDesplegados == true ? 'd-none' : 'd-flex' || mapaDesplegado == true ? 'd-none' : 'd-flex' "/>
                <CategoriasListado :class=" filtrosDesplegados == true ? 'd-none' : 'd-flex' || mapaDesplegado == true ? 'd-none' : 'd-flex' "/> -->
            </div>
            <div class="col-4 d-none d-lg-flex justify-content-center align-items-start ps-0" v-if="mapaDesplegado"></div>
            <div class="col d-none d-lg-flex justify-content-center align-items-start ps-0 mt-lg-5 position" v-if="mapaDesplegado">
                <MapaFiltros/>
            </div>
            <div class="filtros-absolute" v-if="filtrosMapa" @click="cerrarTodo">
                
            </div>
            <div class="filtros row d-flex justify-content-center align-items-start" v-if="filtrosMapa">
                <div class="col-11 d-flex justify-content-center align-items-center px-0">
                    <FiltrosV2 :funcionMostrarFiltros="funcionMostrarFiltros" :funcionMapa="funcionMapa2"/>
                </div>
                <div class="col-3 d-flex justify-content-center align-items-start ps-0">
                    <BodyFiltros/>
                </div>
                <div class="col-8 d-flex flex-column justify-content-center align-items-center px-0 position-relative">
                    <!---- ASESORÍAS ---->
                    <!-- <TituloYOpcionesV4 :titulo="$t('message.titleConsultingResults')" :subtitulo="$t('message.subtitleConsultingResults')"
                    class="d-none d-lg-flex" idBack="prevSliderAsesorias" idNext="nextSliderAsesorias" pagination="pagAsesorias"/>
                    <Asesorias/> -->
                    <!---- CURSOS ---->
                    <!-- <TituloYOpcionesResultadosCursos class="mt-5" :titulo="$t('message.coursesPhotography')" idNextV2="nextSliderCursosboton" idBackV2="prevSliderCursosboton" pagination="pagCursos"/> -->
                    <!-- <SliderMobileSeccionesHomeV3 class="mt-0 pt-0"/> -->
                    <!---- MARCAS ---->
                    <!-- <TituloYOpcionesV3 :titulo="$t('message.findBestPhotographs')" idNext="nextSlider7boton" idBack="prevSlider7boton" class="d-none d-lg-flex" pagination="pagMarcas"/>
                    <ProveedoresV2 class="d-none d-lg-flex marginBottom"/> -->
                    <!---- PRESETS ---->
                    <!-- <TituloYOpcionesV2 :titulo="$t('message.presets')" class="d-none d-lg-flex" idNext="nextSliderPresetsboton" idBack="prevSliderPresetsboton" pagination="pagPresets"/>
                    <Presets/> -->
                    <!---- PROFESORES ---->
                    <!-- <TituloYOpcionesV3 :titulo="$t('message.titleDirectoryProfessionals')" class="d-none d-lg-flex" idNext="nextSliderFotosboton" idBack="prevSliderFotosboton" pagination="pagProfesional"/> -->
                    <!-- <DirectorioDeFotos/> -->
                </div>
            </div>
        </div>
        <div class="row justify-content-center mt-lg-5 pt-lg-5 d-none" :class=" filtrosDesplegados == true ? 'd-lg-flex' : 'd-none' || mapaDesplegado == true ? 'd-lg-flex' : 'd-none' ">
            <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0 px-lg-auto " >
                <!-- <PanelSeccionV3/>
                <TitulosSeccionesV2 :titulo="$t('message.exploreCategories')"/>
                <CategoriasListado/> -->
            </div>
        </div>
        <div class="espacio2"></div>
        <FooterHome />
        <Footer :activeHome="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
    
</template>

<script setup>
    import Filtros from "../../components/Filtros/Filtros.vue";
    import FiltrosV2 from "../../components/Filtros/FiltrosV2.vue";
    import Footer from "../../components/Footer/Footer.vue";
    import TitulosSecciones from "../../components/Titulo/TitulosSecciones.vue";
    import OrdenarPorSelect from "../../components/Ordenar/PorSelect.vue";
    import panelSeccion from "../../components/PanelSeccion/PanelSeccion.vue";
    import FooterHome from "../../components/Footer/FooterHome.vue";
    import NavbarBuscadorBack from "../../components/Navs/NavbarBuscadorBack.vue";
    import NavbarBack from "../../components/Navs/Navbar.vue";
    import TitulosSeccionesV2 from "../../components/Titulo/TitulosSeccionesV2.vue";
    import CategoriasListado from "../../components/Categorias/CategoriasListado.vue";
    import BotonesSlidersHome from "../../components/Botones/SlidersHome.vue";
    import TituloYOpcionesResultadosCursos from "../../components/Titulo/TituloYOpciones.vue";
    import HeaderTitulo from "../../components/Titulo/HeaderTitulo.vue";
    import PanelSeccionV3 from "../../components/PanelSeccion/PanelSeccionV3.vue";
    import BodyFiltros from "../../components/Filtros/Body.vue";
    import SliderMobileSeccionesHomeV2 from "../../components/Sliders/SeccionesHomeV2.vue";
    import SliderMobileSeccionesResultadosCursosV2 from "../../components/Sliders/SeccionesResultadosCursosV2.vue";
    import MapaFiltros from "../../components/Filtros/Mapa.vue";
    import SliderMobileSeccionesHomeV3 from "../../components/Sliders/SeccionesHomeV3.vue";
    import SliderMobileSeccionesResultadosCursosV3 from "../../components/Sliders/SeccionesResultadosCursosV3.vue";
    import Asesorias from "../../components/Sliders/Asesorias.vue";
    import TituloYOpcionesV4 from "../../components/Titulo/TituloYOpcionesV4.vue";
    import TituloYOpcionesV3 from "../../components/Titulo/TituloYOpcionesV3.vue";
    import Proveedores from "../../components/Sliders/Proveedores.vue";
    import ProveedoresV2 from "../../components/Sliders/ProveedoresV2.vue";
    import TituloYOpcionesV2 from "../../components/Titulo/TituloYOpcionesV2.vue";
    import Presets from "../../components/Sliders/Presets.vue";
    import DirectorioDeFotos from "../../components/Sliders/DirectorioDeFotos.vue"
    import { useGeneralStore } from "../../stores/general"    
    import { useUsersStore } from "../../stores/user";
    import { storeToRefs } from "pinia";
    import { ref, provide } from 'vue';

    const general = useGeneralStore();
    const {filtrosDesplegados, filtrosMapa, mapaDesplegado} = storeToRefs(general);
    const {} = general;
    const users = useUsersStore();
    const {cambioHeader} = storeToRefs(users);

    let priceRange = ref({
        minimum: null,
        maximum: null
    })
    provide('priceRange', priceRange)

    const funcionMostrarFiltros = () => {
        if(mapaDesplegado.value == true ){
            if(filtrosMapa.value == false){
                filtrosMapa.value = true 
            }else{
                filtrosMapa.value = false
            }
            //console.log(this.filtrosMapa.value)
        }else{
            if(filtrosDesplegados.value == false){
                filtrosDesplegados.value = true 
            }else{
                filtrosDesplegados.value = false
            }
        }
    }
    const funcionMapa = () => {
        if(filtrosDesplegados.value == true ){
            filtrosDesplegados.value = false
            //console.log('hola3')
        }
        else if(mapaDesplegado.value == false){
            mapaDesplegado.value = true 
            //console.log('hola4')
        }else{
            mapaDesplegado.value = false
            console.log(mapaDesplegado.value)
            //console.log('hola5')
        }
    }

    const funcionMapa2 = () => {
        if(mapaDesplegado.value == true){
            //console.log(this.mapaDesplegado.value, 'hola1')
            mapaDesplegado.value = false 
            filtrosMapa.value = false
        }else{
            mapaDesplegado.value = false
            //console.log(this.mapaDesplegado.value, 'hola1')
        }
    }

    const cerrarTodo = () => {
        mapaDesplegado.value = false
        filtrosMapa.value = false
    }
    
</script>

<style lang="scss" scoped>
body{
    max-width: 100% !important;
}
.position{
    position: absolute;
    right: 0;
    max-width: 34%;
    height: 100%;
    top: 0;
}
.filtros-absolute{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.35);
    width: 100vw;
    min-height:  102.5%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    z-index: 5;
    
}
.filtros{
    position: absolute;
    background-color: #FFFFFF;
    max-width: 95vw;
    min-height:  102.5%;
    z-index: 10;
    left: 0;
    top: 0;
    
}
</style>