<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start ps-lg-0">
        <NavbarCertificados :seccion="$t('message.productsPanel')"/>
        <NavLateralPanel :site="true"/>
        <div class="div-grey right" id="div-grey">
            <button class="boton-toggler" id="toggleSidebar" type="button" @click="toggleSidebar">
                <img src="../../../img/home-icons/arrow-left-blanco.svg" alt="">
            </button>
        </div>
        <div  class="col ms-3 mt-3 d-flex justify-content-center align-items-start p-3 classSpecial" :style="isSidebarOpen ? 'padding-left: 300px !important;'  : 'padding-left: 50px !important;'">
            <!--- LISTA DE CURSOS --->
            <div class="row justify-content-center justify-content-lg-start row__width" v-if="!productSheet && professionalProducts">
                <div class="col-12 col-lg d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 caja">
                    <div class="row justify-content-center row__width">
                        <ProductosPanelProfesor v-if="professionalProducts.length > 0"/>
                        <!--- SIN PRODUCTOS --->
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas" v-else>
                            <div class="row justify-content-center  row__width">
                                <div class="col-12 d-flex justify-content-center align-items-center">
                                    <h6>{{ $t('message.dontHaveProductsYet') }}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PanelSeccionPanel/>
                </div>
            </div>
            <!--- DENTRO DE UN CURSO --->
            <div class="row justify-content-center justify-content-lg-start row__width sheet" v-else>
                <template v-if="professionalProduct">
                    <div class="col-12 d-flex justify-content-start align-items-center px-0">
                        <button @click="productSheet = false"  class="p-0">
                            <img src="../../../img/home-icons/arrow-left-negra.svg" alt="">
                        </button>
                    </div>
                    <div class="col-12 d-flex justify-content-center align-items-center mt-4">
                        <div class="row justify-content-center row__width slide slide2" v-if="professionalProduct">
                            <div class="col-3 col-lg-2 d-flex justify-content-center align-items-center px-0 caption" v-if="professionalProduct.image">
                                <img :src="professionalProduct.image" alt="">
                            </div>
                            <div class="col-9 col-lg-8 d-flex flex-column justify-content-center align-items-start">
                                <h3>{{ professionalProduct.name }}</h3>
                            </div>
                            <div class="col d-flex justify-content-center align-items-center px-0 mt-3 mt-lg-0">
                                <router-link :to="'/editar-curso/1/'+professionalProduct.product_id" class="btn__primario mirar__ahora">    
                                    Editar
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas mt-3 px-0">
                        <div class="scroll d-flex justify-content-start justify-content-lg-center align-items-center">
                            <ul class="nav nav-tabs row justify-content-start" id="myTab" role="tablist">
                                <li class="nav-item col-10 col-lg-4 d-flex justify-content-center align-items-center px-0" role="presentation">
                                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Centro de evaluaciones</button>
                                </li>
                                <!-- <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">{{ $t('message.actions') }}</button>
                                </li>
                                <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">{{ $t('message.presets') }}</button>
                                </li>
                                <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                    <button class="nav-link" id="contact-tab2" data-bs-toggle="tab" data-bs-target="#contact2" type="button" role="tab" aria-controls="contact2" aria-selected="false">{{ $t('message.eBooks') }}</button>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                    <!-- LISTA DE TAREAS/EVALUACIONES -->
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas px-0" v-if="!qualifyContent">
                        <div class="tab-content row justify-content-center  row__width mt-4" id="myTabContent">
                            <div class="col-12 tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div class="row justify-content-center slide slide3">
                                    <h5>Tareas</h5>
                                    <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas mt-2 px-0">
                                        <div class="scroll d-flex justify-content-start align-items-center">
                                            <ul class="nav nav-tabs row justify-content-start" id="myTab" role="tablist">
                                                <li class="nav-item col-6 col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                                    <button class="nav-link active" id="new-task-tab" data-bs-toggle="tab" data-bs-target="#new-task" type="button" role="tab" aria-controls="new-task" aria-selected="true">Pendientes</button>
                                                </li>
                                                <li class="nav-item col-6 col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                                    <button class="nav-link" id="new-evaluations-tab" data-bs-toggle="tab" data-bs-target="#new-evaluations" type="button" role="tab" aria-controls="new-evaluations" aria-selected="false">Calificadas</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas">
                                        <div class="tab-content row justify-content-center  row__width" id="myTabContent">
                                            <div class="col-12 tab-pane fade show active" id="new-task" role="tabpanel" aria-labelledby="new-task-tab">
                                                <div class="px-0" v-if="tasks">
                                                    <div class="row justify-content-center justify-content-lg-start slide slide4" v-for="(task, index) in tasks.filter(task => task.status == 0)" :key="task.id">
                                                        <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                            <div class="row justify-content-center row__width">
                                                                <div class="col-12 col-lg-5 d-flex flex-column justify-content-center align-items-start">
                                                                    <h6>Nombre del alumno</h6>
                                                                    <h4>{{ task.studentName }}</h4>
                                                                </div>
                                                                <div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-start">
                                                                    <h6>Fecha</h6>
                                                                    <h4 v-if="task.upload_at">{{ formattedDate(task.upload_at) }}</h4>
                                                                </div>
                                                                <div class="col-12 col-lg-3 d-flex justify-content-center align-items-center mt-2 mt-lg-0" >
                                                                    <button class="btn__primario" @click="task.evaluation = '1', qualify(true, task)" v-if="task.status == 0">
                                                                        Calificar
                                                                    </button>
                                                                    <button class="success" @click="qualify(true, task)"  v-else-if="task.status == 1">
                                                                        Aprobado
                                                                    </button>
                                                                    <button class="failed" @click="qualify(true, task)"  v-else>
                                                                        Desaprobado
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 tab-pane fade" id="new-evaluations" role="tabpanel" aria-labelledby="new-evaluations-tab">
                                                <div class="px-0" v-if="tasks">
                                                    <div class="row justify-content-center justify-content-lg-start slide slide4" v-for="(task, index) in tasks.filter(task => task.status == 1 || task.status == 2)" :key="task.id">
                                                        <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                            <div class="row justify-content-center row__width">
                                                                <div class="col-12 col-lg-5 d-flex flex-column justify-content-center align-items-start">
                                                                    <h6>Nombre del alumno</h6>
                                                                    <h4>{{ task.studentName }}</h4>
                                                                </div>
                                                                <div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-start">
                                                                    <h6>Fecha</h6>
                                                                    <h4 v-if="task.upload_at">{{ formattedDate(task.upload_at) }}</h4>
                                                                </div>
                                                                <div class="col-12 col-lg-3 d-flex justify-content-center align-items-center mt-2 mt-lg-0">
                                                                    <button class="btn__primario" @click="task.evaluation = '1', qualify(true, task)" v-if="task.status == 0">
                                                                        Calificar
                                                                    </button>
                                                                    <button class="success" @click="qualify(true, task)" v-else-if="task.status == 1">
                                                                        Aprobado
                                                                    </button>
                                                                    <button class="failed" @click="qualify(true, task)" v-else>
                                                                        Desaprobado
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center slide slide3">
                                    <h5>Proyectos finales</h5>
                                    <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas mt-2 px-0">
                                        <div class="scroll d-flex justify-content-start align-items-center">
                                            <ul class="nav nav-tabs row justify-content-start" id="myTab" role="tablist">
                                                <li class="nav-item col-6 col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                                    <button class="nav-link active" id="task-tab" data-bs-toggle="tab" data-bs-target="#task" type="button" role="tab" aria-controls="task" aria-selected="true">Pendientes</button>
                                                </li>
                                                <li class="nav-item col-6 col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                                    <button class="nav-link" id="evaluations-tab" data-bs-toggle="tab" data-bs-target="#evaluations" type="button" role="tab" aria-controls="evaluations" aria-selected="false">Calificados</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas">
                                        <div class="tab-content row justify-content-center  row__width" id="myTabContent">
                                            <div class="col-12 tab-pane fade show active" id="task" role="tabpanel" aria-labelledby="task-tab">
                                                <div class="px-0" v-if="fps">
                                                    <div class="row justify-content-center justify-content-lg-start slide slide4" v-for="(fp, index) in fps.scores.filter(fp => fp.status == 0)" :key="fp.id">
                                                        <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                            <div class="row justify-content-center row__width">
                                                                <div class="col-12 col-lg-5 d-flex flex-column justify-content-center align-items-start">
                                                                    <h6>Nombre del alumno</h6>
                                                                    <h4>{{ fp.student[0].name }}</h4>
                                                                </div>
                                                                <div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-start">
                                                                    <h6>Fecha</h6>
                                                                    <h4 v-if="fp.upload_date">{{ formattedDate(fp.upload_date) }}</h4>
                                                                </div>
                                                                <div class="col-12 col-lg-3 d-flex justify-content-center align-items-center mt-2 mt-lg-0" >
                                                                    <button class="btn__primario" @click="qualify(true, null, fp)" v-if="fp.status == 0">
                                                                        Calificar
                                                                    </button>
                                                                    <button class="success" @click="qualify(true, null, fp)"  v-else-if="fp.status == 1">
                                                                        Aprobado
                                                                    </button>
                                                                    <button class="failed" @click="qualify(true, null, fp)"  v-else>
                                                                        Desaprobado
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 tab-pane fade" id="evaluations" role="tabpanel" aria-labelledby="evaluations-tab">
                                                <div class="px-0" v-if="fps">
                                                    <div class="row justify-content-center justify-content-lg-start slide slide4" v-for="(fp, index) in fps.scores.filter(fp => fp.status == 1 || fp.status == 2)" :key="fp.id">
                                                        <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                            <div class="row justify-content-center row__width">
                                                                <div class="col-12 col-lg-5 d-flex flex-column justify-content-center align-items-start">
                                                                    <h6>Nombre del alumno</h6>
                                                                    <h4>{{ fp.student[0].name }}</h4>
                                                                </div>
                                                                <div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-start">
                                                                    <h6>Fecha</h6>
                                                                    <h4 v-if="fp.upload_date">{{ formattedDate(fp.upload_date) }}</h4>
                                                                </div>
                                                                <div class="col-12 col-lg-3 d-flex justify-content-center align-items-center mt-2 mt-lg-0" >
                                                                    <button class="btn__primario" @click="qualify(true, null, fp)" v-if="fp.status == 0">
                                                                        Calificar
                                                                    </button>
                                                                    <button class="success" @click="qualify(true, null, fp)"  v-else-if="fp.status == 1">
                                                                        Aprobado
                                                                    </button>
                                                                    <button class="failed" @click="qualify(true, null, fp)"  v-else>
                                                                        Desaprobado
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-12 tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                
                            </div>
                            <div class="col-12 tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                
                            </div>
                            <div class="col-12 tab-pane fade" id="contact2" role="tabpanel" aria-labelledby="contact-tab2">
                                
                            </div> -->
                        </div>
                    </div>
                    <!-- CALIFICANDO -->
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center mt-4 px-0" v-else>
                        <!-- TAREA -->
                        <div class="row justify-content-center justify-content-lg-start slide slide5 row__width" v-if="actualTask.taskId">
                            <div class="col-12 d-flex justify-content-between align-items-center px-0 flex-cambio">
                                <h5 class="px-0">Calificando tarea</h5>
                                <h4>Fecha: {{ formattedDate(actualTask.upload_at) }}</h4>
                            </div>
                            <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                <h4>{{ actualTask.taskName }}</h4>
                            </div>
                            <div class="col-12 d-flex justify-content-center align-items-center px-0 profile">
                                <div class="row justify-content-center row__width">
                                    <div class="col-2 col-lg-1 d-flex justify-content-start align-items-center px-0">
                                        <img src="../../../img/home-icons/profesor-ejemplo.png" alt="">
                                    </div>
                                    <div class="col d-flex justify-content-start align-items-center">
                                        <h3>{{ actualTask.studentName }}</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-center align-items-center px-0 evaluation">
                                <img :src="actualTask.file" alt="">
                            </div>
                            <div class="col-12 d-flex flex-column justify-content-center align-items-start mt-4 description">
                                <div class="row justify-content-center row__width width">
                                    <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                        <h3>Comentario</h3>
                                        <p>{{actualTask.comment ? actualTask.comment.length : '0'}}/400</p>
                                    </div>
                                    <div class="col-12 d-flex justify-content-center align-items-center px-0 position-relative">
                                        <span>
                                            <h6>B</h6>
                                        </span>
                                        <textarea name="" id="" cols="30" rows="10" maxlength="400" v-model="actualTask.comment"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-center align-items-start mt-4 px-0">
                                <div class="row justify-content-between row__width width">
                                    <div class="col-12 col-lg-4 d-flex justify-content-center align-items-center px-0">
                                        <div class="row separador row__width tipo__reunion">
                                            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                <input type="checkbox" id="hola" @change="checkboxFunction($event.srcElement)" :checked="actualTask.status == 2">
                                                <label for="hola" class="row">
                                                    <div class="col-6 d-flex justify-content-center align-items-center">
                                                        <p class="left">Aprobado</p>
                                                    </div>
                                                    <div class="col-6 d-flex justify-content-center align-items-center px-0">
                                                        <p class="right">Desaprobado</p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4 d-flex justify-content-end align-items-center px-0 mt-3 mt-lg-0">
                                        <button class="btn__primario" @click="qualifyTask(actualTask.taskId, actualTask.evaluation, actualTask.comment)" v-if="actualTask.status == 0">
                                            Calificar
                                        </button>
                                        <button class="btn__primario" @click="qualify(false)" v-else>
                                            Cerrar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- PROYECTO FINAL -->
                        <div class="row justify-content-center justify-content-lg-start slide slide5 row__width" v-else-if="actualTask.fp_id">
                            <div class="col-12 d-flex justify-content-between align-items-center px-0 flex-cambio">
                                <h5 class="px-0">Calificando tarea</h5>
                                <h4>Fecha: {{ formattedDate(actualTask.upload_date) }}</h4>
                            </div>
                            <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                <h4>{{ actualTask.title }}</h4>
                            </div>
                            <div class="col-12 d-flex justify-content-center align-items-center px-0 profile">
                                <div class="row justify-content-center row__width">
                                    <div class="col-2 col-lg-1 d-flex justify-content-start align-items-center px-0">
                                        <img :src="actualTask.student[0].avatar" alt="">
                                    </div>
                                    <div class="col d-flex justify-content-start align-items-center">
                                        <h3>{{ actualTask.student[0].name }}</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-center align-items-center px-0 evaluation">
                                <img :src="actualTask.file" alt="">
                            </div>
                            <div class="col-12 d-flex flex-column justify-content-center align-items-start mt-4 description">
                                <div class="row justify-content-center row__width width">
                                    <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                        <h3>Comentario</h3>
                                        <p>{{actualTask.comment ? actualTask.comment.length : '0'}}/400</p>
                                    </div>
                                    <div class="col-12 d-flex justify-content-center align-items-center px-0 position-relative">
                                        <span>
                                            <h6>B</h6>
                                        </span>
                                        <textarea name="" id="" cols="30" rows="10" maxlength="400" v-model="actualTask.comment"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-center align-items-start mt-4 px-0">
                                <div class="row justify-content-between row__width width">
                                    <div class="col-12 col-lg-4 d-flex justify-content-center align-items-center px-0">
                                        <div class="row separador row__width tipo__reunion">
                                            <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                                                <h3>Nota: ({{ actualTask.minimumGrade }}+ aprueba)</h3> <!-- Falta recibir en endpoint minimumGrade del FP -->
                                                <input type="number" id="fp-number" v-model="actualTask.score" min="0" max="100" @input="validateNumber(actualTask.score)">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4 d-flex justify-content-end align-items-center px-0 mt-3 mt-lg-0">
                                        <button class="btn__primario" @click="qualifyFP(actualTask.studentScoreId, actualTask.score, actualTask.comment)" v-if="actualTask.status == 0">
                                            Calificar
                                        </button>
                                        <button class="btn__primario" @click="qualify(false)" v-else>
                                            Cerrar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <FooterCertificados :site="true" class="d-lg-none"/>
        <div class="espacio2"></div>
        <div class="container-loader" v-if="loader">
            <div class="custom-loader"></div>
        </div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../components/Navs/NavbarCertificados.vue"
import FooterCertificados from "../../../components/Footer/FooterCertificados.vue"
import NavLateralPanel from "../../../components/Navs/NavLateralPanel.vue"
import PanelSeccionPanel from "../../../components/PanelSeccion/PanelSeccionPanel.vue"
// PROFESOR
import ProductosPanelProfesor from "../../../components/Panel/Cursos/Productos/ProductosPanelProfesor.vue"
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../stores/user"
import { onBeforeMount, ref, provide, watch, inject, computed, nextTick, onBeforeUnmount, onMounted } from 'vue';
import axios from 'axios'

const users = useUsersStore();
const { access, idUser, professionalData } = storeToRefs(users);
const { url, validate } = users;

const isSidebarOpen = ref(true)
const prueba = ref(false)
const professionalProducts = ref()
const professionalProduct = ref({})
const productSheet = ref(false)
const qualifyContent = ref(false)
let loader = ref(true)
let tasks = ref()
let fps = ref()
let actualTask = ref()

let courses = inject('courses')

const algo = onBeforeMount( () => {
    access.value = [5, 4]
    //console.log(access.value) 
    validate();
}) 

provide('professionalProducts', professionalProducts)
provide('isSidebarOpen', isSidebarOpen)
provide('productSheet', productSheet)
provide('qualifyContent', qualifyContent)

const toggleSidebar = () => {
    isSidebarOpen.value = !isSidebarOpen.value
    if(document.getElementById('sidebar').classList.contains('d-none')){
        document.getElementById('sidebar').classList.remove('d-none')
    } else{
        document.getElementById('sidebar').classList.add('d-none')
    }
    if(document.getElementById('div-grey').classList.contains('right')){
        document.getElementById('div-grey').classList.remove('right')
        document.getElementById('div-grey').classList.add('left')
    } else{
        document.getElementById('div-grey').classList.remove('left')
        document.getElementById('div-grey').classList.add('right')
    }
}

const formattedDate = (taskDate)=>{
    if (!taskDate) return ''
    const date = new Date(taskDate)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}

function checkboxFunction(checkboxElement) {
    console.log(checkboxElement)
    if (checkboxElement.checked) {
        actualTask.value.evaluation = "2";
    } else {
        actualTask.value.evaluation = "1";
    }
}

const query = async() => {
    loader.value = true
    //console.log(professionalData.value.id)
    let lang = 'es'
    let idProf = JSON.parse(localStorage.getItem('idProf'))
    let id = idProf.id
    //console.log(id)
    try {
        await axios.get(url+'/api/professionals/get/'+id+'/products/'+lang).then(result => {
            console.log(result)
            professionalProducts.value = result.data
            
            professionalProducts.value = result.data.map((product) => {
                // Encontrar la primera presentación que contenga una imagen
                const firstImagePresentation = product.presentations.find(presentation => presentation.element === 'image');
                // Si se encontró una presentación con imagen, agregar la propiedad image al curso con el valor de la fuente de la imagen
                if (firstImagePresentation) {
                    product.image = firstImagePresentation.source;
                }
                return product;
            })
        })
        console.log(professionalProducts.value)
        setTimeout(() => {
            loader.value = false
        }, 500)
    } catch (error) {
        console.log(error)
        setTimeout(() => {
            loader.value = false
        }, 500)
    }
}
query()

const fetchProfProduct = async(x) => {
    loader.value = true
    //console.log(professionalData.value.id)
    let lang = 'es'
    let idProf = JSON.parse(localStorage.getItem('idProf'))
    let id = idProf.id
    //console.log(id)
    try {
        await axios.get(url+'/api/course/'+lang+'/asd/'+x).then(result => {
            console.log(result)
            professionalProduct.value = result.data
            console.log(result.data)
            professionalProduct.value.presentation.find((item) => {
                console.log(item);
                if(item.image){
                    professionalProduct.value.image = item.image
                }
            })
        })
        setTimeout(() => {
            loader.value = false
        }, 500)
    } catch (error) {
        console.log(error)
        setTimeout(() => {
            loader.value = false
        }, 500)
    }
}

provide('fetchProfProduct', fetchProfProduct)

const fetchStatus = async() => {
    let uId = idUser.value.id
    let pId = route.params.slug.split('-').pop()
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    try {
        await axios.get(url+'/api/student/get/courses/status/'+uId+'/'+pId, { headers: headers.value }).then((result) => {
            // console.log(result)
        })
    } catch (error) {
        console.log(error)
    }
}
// fetchStatus()

const fetchTasksProf = async(courseId) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.get(url+'/api/professor/get/course/tasks/'+courseId, {headers: headers.value}).then(result => {
            console.log(result)
            tasks.value = ''
            if(Object.keys(result.data).length >= 1){
                tasks.value = result.data
            } else if(result.data.length >= 1){
                tasks.value = result.data
            }
        })
    } catch (error) {
        console.log(error)
    }
}

provide('fetchTasksProf', fetchTasksProf)

const qualifyTask = async(taskId, evaluation, comment) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    try {
        await axios.post(url+`/api/professor/evaluate/course/task`, { taskId: taskId, evaluation: evaluation, comment: comment }, { headers: headers.value }).then(result => {
            console.log(result)
            qualify(false)
        })
    } catch (error) {
        console.log(error)
    }
}

const fetchFPProf = async(courseId) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.get(url+'/api/get/course/fp/'+courseId, {headers: headers.value}).then(result => {
            console.log(result)
            fps.value = ''
            if(Object.keys(result.data).length >= 1){
                fps.value = result.data
                console.log(fps.value)
            }
        })
    } catch (error) {
        console.log(error)
    }
}

provide('fetchFPProf', fetchFPProf)

const qualifyFP = async(studentScoreId, score, comment) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    try {
        await axios.post(url+`/api/professor/evaluate/course/fp`, { studentScoreId: studentScoreId, score: score, comment: comment }, { headers: headers.value }).then(result => {
            console.log(result)
            qualify(false)
        })
    } catch (error) {
        console.log(error)
    }
}

const qualify = (x, task = null, fp = null) =>{
    qualifyContent.value = x
    if(task){
        actualTask.value = task
    }
    if(fp){
        actualTask.value = fp
        actualTask.value.title = fps.value.title
        actualTask.value.minimumGrade = fps.value.minimumGrade
    }
    console.log(actualTask.value)
}

const validateNumber = (newValue) => {
    if (newValue < 0) {
        actualTask.value.score = 0
    } else if (newValue > 100) {
        actualTask.value.score = 100
    }
}

</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #fff;
    min-height: 100vh;
    @media (min-width: 992px) {
        margin-top: 77px;
    }
}
.contenedor{
   @media (min-width: 992px) {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding:20px 0 30px 0;
        min-height: 80vh;
   }
}
.border-bottom2{
    @media (min-width:992px) {
        border-bottom: 1px solid #E4E4E4;
        padding-bottom: 20px;
    }
}
.classSpecial{
    width: 70vw !important;
}
.tablas{
    h6{
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
        text-align: left;
        padding: 2vh 0 6vh 0;
    }
}
.sheet{
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 28px;
    padding: 25px;
    animation: 0.3s fadein ease;
    h2{
        font-size: 20px;
        font-weight: 600;
        color: var(--colorSecondary);
        margin-top: 20px;
    }
    .scroll-2{
        max-height: 80vh;
        overflow-y: scroll !important;
        overflow-x: hidden;
        &::-webkit-scrollbar{
            -webkit-appearance: none;
            width: 4px;
            height: 4px;
            background-color: #29292b1e;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #29292b1e;
        }
    }
    .slide{
        background-color: #FFFFFF;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        min-height: auto;
        padding: 10px 0;
        margin-bottom: 20px;
        .see-more{
            font-size: 14px;
            color: var(--colorPrimary);
            font-weight: 500;
            text-decoration: underline;
        }
        .border-left{
            border-left: 1px solid rgba($color: #000000, $alpha: 0.2);
        }
        h3{
            color: #0E1133;
            font-weight: 700;
            font-size: 16px;
            text-align: left;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        h4{
            font-weight: 400;
            font-size: 16px;
            color: #B8C1DB;
            text-align: left;
            padding-top: 3px;
            max-height: 60px;
            max-width: 100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            
        }
        h5{
            color: #0E1133;
            font-weight: 600;
            font-size: 22px;
            text-align: left;
            padding: 10px 15px;
            text-align: left;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            word-break: break-all;
        }
        .acciones{
            color: #B8C1DB;
            font-size: 14px;
            font-weight: 500;
        }
        .dropdown-toggle{
            color: var(--colorSecondary);
            font-weight: 500;
            font-size: 13px;
            text-decoration: underline;
            padding: 0;
            img{
                margin-left: 5px;
            }
            &::after{
                display: none;
            }
            @media (min-width: 425px) {
                font-size: 16px;
            }
        }
        .caption{
            position: relative;
            height:83px ;
            img{
                border-radius: 10px;
                width: 100%;
                height:83px ;
                object-fit: cover;
            }
            &::before{
                content: '';
                position: absolute;
                bottom: 0;
                width: 108%;
                max-width: 100%;
                height: 100%;
                background: transparent linear-gradient(180deg, #252E4800 0%, #081026 100%) 0% 0% no-repeat padding-box;
                filter: opacity(1);
                border-radius: 0px 0px 10px 10px;
                z-index: 1;
            }
        }
    }
    .slide2{
        background-color: #FFFFFF;
        box-shadow: none;
        border-radius: 0;
        min-height: auto;
        padding: 0;
        h3{
            font-size: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
        }
    }
    .slide3{
        background-color: #ebebeb29;
        box-shadow: 0px 3px 10px #0000002d;
    }
    .slide4{
        margin: 20px 0;
        background-color: #f5f5f5;
        h6{
            padding-top: 5px;
            padding-bottom: 10px;
            font-weight: 600;
            font-size: 16px;
            text-align: left;
        }
        h4{
            padding-top: 0;
            font-weight: 500;
            text-align: left;
        }
    }
    .slide5{
        background-color: #F5F5F5;
        box-shadow: 0px 3px 10px #0000002d;
        padding: 25px;
        h4{
            color: #697891;
            text-align: left;
        }
        .profile{
            margin: 20px 0;
            img{
                max-height: 50px;
                width: 50px;
                object-fit: cover;
                border-radius: 50%;
            }
        }
        .evaluation{
            img{
                width: 100%;
                height: 400px;
                border-radius: 20px;
                object-fit: cover;
            }
        }
    }
    .mirar__ahora{
        font-size: 14px;
        width: 90%;
        height: 40px;
        border-radius: 20px;
        border: 2px solid var(--colorSecondary);
    }
    .tipo__reunion{
        label{
            height: 30px;
            width: 100%;
            box-shadow: 0px 0px 6px #00000029;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            cursor: pointer;
            background-color: #EBEEF9;
            &::before{
                content: '';
                position: absolute;
                height: 100%;
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #38E6A7;
                border-radius:  28px;
                left: 0;
                transition: all 0.5s;
            }
            p{
                font-weight: 500;
                font-size: 14px;
                color: var(--bgPrincipal);
                z-index: 1;
                transition: all 0.5s;
                &:first-child{
                    margin-right: 0%;
                }
                &:last-child{
                    margin-left: 0%;
                }
            }
            .left{
                color: var(--bgPrincipal);
                font-weight: 600;
            }
            .right{
                color: var(--colorSecondary);
            }
        }
        input:checked + label{
            &::before{
                left: 49%;
                width: 50%;
                background-color: #FF576E;
            }
            p{
                color: var(--bgPrincipal);
                font-weight: 600;
                
            }
            .left{
                color: var(--colorSecondary);
                font-weight: 500;
            }
        }
        @media (min-width: 992px) {
            label{
                .right{
                    color: var(--colorSecondary);
                }
            }
            input:checked + label{
                .left{
                    color: var(--colorSecondary);
                }
            }
        }
    }
    .description{
        h3{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            padding: 7px 0px;
        }
        p{
            color: var(--colorPrimary);
            font-size: 14px;
            font-weight: 400;
            text-align: left;
            padding: 7px 0px;
        }
        .width{
            width: -webkit-fill-available;
        }
        textarea{
            width: 100%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: #FCFCFC;
            height: 20vh;
            padding-top: 50px;
            padding-left: 15px;
            outline: 0;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                
                color: #697891;
            }
            
        }
        span{
            background-color: #E4E4E4;
            height: 40px;
            width: 99.6%;
            position: absolute;
            top: 2px;
            border-radius: 10px 10px 0 0 ;
            padding: 10px 15px;
            h6{
                color: var(--colorSecondary);
                font-size: 16px;
                font-weight: 600;
                text-align: left;
            }
        }
    }
    .success{
        background-color: var(--bgPrincipal);
        border: 2px solid var(--colorGreen);
        color: var(--colorGreen);
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        border-radius: 27px;
        width: 100%;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        &:hover{
            background-color: var(--colorGreen);
            color: var(--bgPrincipal);
            border-color: transparent;
            outline: 0;
        }
        &:focus{
            background-color: var(--colorGreen);
            color: var(--bgPrincipal);
            border-color: transparent;
            outline: 0;
        }
    }
    .failed{
        background-color: var(--bgPrincipal);
        border: 2px solid var(--colorRed);
        color: var(--colorRed);
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        border-radius: 27px;
        width: 100%;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        &:hover{
            background-color: var(--colorRed);
            color: var(--bgPrincipal);
            border-color: transparent;
            outline: 0;
        }
        &:focus{
            background-color: var(--colorRed);
            color: var(--bgPrincipal);
            border-color: transparent;
            outline: 0;
        }
    }
}

.tablas{
    h6{
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
        text-align: left;
        padding: 2vh 0 6vh 0;
    }
    .nav-link{
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        color: #B8C1DB;
        position: relative;
        padding: 10px 0;
        border: 0;
    }
    .nav-link.active{
        font-weight: 700;
        border: 0;
        color: var(--colorSecondary);
        background-color: transparent;
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 5px;
            bottom: 0;
            left: 0;
            background-color: var(--colorPrimary);
            
        }
        @media (min-width: 992px) {
            &::before{
                height: 4px;
            }   
        }
    }
    .scroll{
        overflow-x: scroll;
        max-width: 100%;
        min-width: 100%;
        ul{
            min-width: 100%;
            padding: 0 20px;
        }
        @media (min-width: 992px) {
            overflow: hidden;
            max-width: none;
            width: 100%;
            ul{
                max-width: 100%;
                min-width: 1vw;
                width: 100%;
            }
        }
    }
}
@keyframes fadein {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
.container-loader{
    background-color: var(--bgPrincipal);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1045;
}
.custom-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#1679FC 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#1679FC);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation:s3 1s infinite linear;
}
.flex-cambio{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 991px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start; 
    }
}
@keyframes s3{ 
  100%{transform: rotate(1turn)}
}

</style>