<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start ps-lg-0">
        <NavbarCertificados :seccion="$t('message.settings')"/>
        <NavLateralPanelAdmin :stats="true" :financial="true"/>
        <div class="div-grey right" id="div-grey">
            <button class="boton-toggler" id="toggleSidebar" type="button" @click="toggleSidebar">
                <img src="../../../../img/home-icons/arrow-left-blanco.svg" alt="">
            </button>
        </div>
        <div class="col d-flex flex-column justify-content-center align-items-center align-items-lg-start classSpecial" :style="isSidebarOpen ? 'padding-left: 300px !important;'  : 'padding-left: 50px !important;'">
            <!--- INFORMACIÓN --->
            <form class="row justify-content-center row__width contenedor">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 ">
                    <div class="row justify-content-center row__width titulo py-3">
                        <div class="col d-flex justify-content-start align-items-center ms-lg-4 px-0 px-lg-auto">
                            <!-- <router-link class="btn_none px-0 d-flex justify-content-center align-items-center pe-3" to="/dashboard">
                                <img src="../../../../img/home-icons/arrow-left-negra.svg" alt="">
                            </router-link> -->
                            <h2>Estadísticas financieras</h2>
                        </div>
                        <div class="col d-flex justify-content-end align-items-center">
                            <button class="download">
                                <img src="../../../../img/estadisticas-icons/excel.svg" alt="">
                            </button>
                            <button class="download">
                                <img src="../../../../img/estadisticas-icons/pdf.svg" alt="">
                            </button>
                            <button class="btn__primario">
                                Descargar reporte
                            </button>
                        </div>
                    </div>
                    <div class="row justify-content-center row__width px-0 px-lg-4">
                        <div class="col-12  d-flex flex-column justify-content-center align-items-center tablas">
                            <div class="scroll d-flex justify-content-center justify-content-lg-start align-items-center" > 
                                <ul class="nav nav-tabs row justify-content-center justify-content-lg-start py-0" id="myTab" role="tablist">
                                    <li class="nav-item col col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link active" id="general-tab" data-bs-toggle="tab" data-bs-target="#general" type="button" role="tab" aria-controls="general" aria-selected="true">Resumen general</button>
                                    </li>
                                    <li class="nav-item col col-lg-2 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link " id="purchases-tab" data-bs-toggle="tab" data-bs-target="#purchases" type="button" role="tab" aria-controls="purchases" aria-selected="false">Ventas</button>
                                    </li>
                                    <li class="nav-item col col-lg-2 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link" id="referred-tab" data-bs-toggle="tab" data-bs-target="#referred" type="button" role="tab" aria-controls="referred" aria-selected="true">Referidos</button>
                                    </li>
                                    <li class="nav-item col col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link " id="projects-tab" data-bs-toggle="tab" data-bs-target="#projects" type="button" role="tab" aria-controls="projects" aria-selected="false">Cierre de proyectos</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido px-0">
                            <div class="tab-content row justify-content-center row__width" id="myTabContent">
                                <!--- GENERAL --->
                                <div class="col-12 tab-pane fade show active px-0" id="general" role="tabpanel" aria-labelledby="general-tab">
                                    <div class="row justify-content-center">
                                        <div class="col-12 d-flex justify-content-start align-items-center">
                                            <h4>
                                                <img src="../../../../img/estadisticas-icons/actualizacion.svg" alt="">
                                                Última actualización 3 de marzo a las 12:00hs am
                                            </h4>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center align-items-center px-0 mt-3">
                                            <div class="row justify-content-center row__width">
                                                <div class="col-4 d-flex justify-content-center align-items-start pe-0">
                                                    <div class="row justify-content-center  row__width div_shadow">
                                                        <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                                            <h5>
                                                                Alumnos inscriptos
                                                            </h5>
                                                            <img src="../../../../img/estadisticas-icons/alumnos.svg" alt="">
                                                        </div>
                                                        <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                                            <h6>100</h6>
                                                            <p class="percent">+15%</p>
                                                            <p>Último mes</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4 d-flex justify-content-center align-items-start pe-0">
                                                    <div class="row justify-content-center  row__width div_shadow">
                                                        <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                                            <h5>
                                                                Cursos inscriptos
                                                            </h5>
                                                            <img src="../../../../img/estadisticas-icons/cursos.svg" alt="">
                                                        </div>
                                                        <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                                            <h6>100</h6>
                                                            <p class="percent">+15%</p>
                                                            <p>Último mes</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4 d-flex justify-content-center align-items-start pe-0">
                                                    <div class="row justify-content-center  row__width div_shadow" >
                                                        <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                                            <h5>
                                                                Ingresos netos
                                                            </h5>
                                                            <img src="../../../../img/estadisticas-icons/ingresos.svg" alt="">
                                                        </div>
                                                        <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                                            <h6>100</h6>
                                                            <p class="percent">+15%</p>
                                                            <p>Último mes</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4 d-flex justify-content-center align-items-start pe-0">
                                                    <div class="row justify-content-center  row__width div_shadow">
                                                        <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                                            <h5>
                                                                Promedio general
                                                            </h5>
                                                            <img src="../../../../img/estadisticas-icons/promedios.svg" alt="">
                                                        </div>
                                                        <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                                            <h6>100</h6>
                                                            <p class="percent negative">+15%</p>
                                                            <p>Último mes</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4 d-flex justify-content-center align-items-start pe-0">
                                                    <div class="row justify-content-center  row__width div_shadow">
                                                        <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                                            <h5>
                                                                Nivel de satisfacción
                                                            </h5>
                                                            <img src="../../../../img/estadisticas-icons/satisfacion.svg" alt="">
                                                        </div>
                                                        <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                                            <h6>100</h6>
                                                            <p class="percent">+15%</p>
                                                            <p>Último mes</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4 d-flex justify-content-center align-items-start pe-0">
                                                    <div class="row justify-content-center  row__width div_shadow" >
                                                        <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                                            <h5>
                                                                Nivel de deserción
                                                            </h5>
                                                            <img src="../../../../img/estadisticas-icons/desercion.svg" alt="">
                                                        </div>
                                                        <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                                            <h6>100</h6>
                                                            <p class="percent">+15%</p>
                                                            <p>Último mes</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--- VENTAS --->
                                <div class="col-12 tab-pane fade px-0" id="purchases" role="tabpanel" aria-labelledby="purchases-tab">
                                    
                                </div>
                                <!--- REFERIDOS --->
                                <div class="col-12 tab-pane fade px-0" id="referred" role="tabpanel" aria-labelledby="referred-tab">
                                    
                                </div>
                                <!--- PROYECTOS --->
                                <div class="col-12 tab-pane fade px-0" id="projects" role="tabpanel" aria-labelledby="projects-tab">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <FooterCertificados :upload="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../../components/Navs/NavbarCertificados.vue";
import FooterCertificados from "../../../../components/Footer/FooterCertificados.vue";
import NavLateralPanelAdmin from "../../../../components/Navs/NavLateralPanelAdmin.vue";
import TituloAdminAjustes from "../../../../components/Titulo/TituloAdminAjustes.vue";

import { useI18n } from "vue-i18n"
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../../stores/user"
import { useLanguageStore } from "../../../../stores/langs"
import { onBeforeMount, ref, provide, watch } from "vue"



const {locale} = useI18n({ useScope: 'global' })

const users = useUsersStore();
const {access} = storeToRefs(users);
const {} = users;
const {validate} = users;

const langs = useLanguageStore();
const {listLangs} = storeToRefs(langs);

const listTypes = ref([])
const listModalities = ref([])
const listProfs = ref([])
const listBenefits = ref([])
const listIcons = ref([])
const listValues = ref([])
const listFaqs = ref([])
provide('listTypes', listTypes)
provide('listModalities', listModalities)
provide('listProfs', listProfs)
provide('listBenefits', listBenefits)
provide('listIcons', listIcons)
provide('listValues', listValues)
provide('listFaqs', listFaqs)

const algo = onBeforeMount( () => {
    access.value = [1]
    //console.log(access.value)
    validate();
})

const isSidebarOpen = ref(true)
//provide(isSidebarOpen, 'isSidebarOpen')

const views = ref(0)
provide("views", views)

const viewsProducts = ref(0)
provide("viewsProducts", viewsProducts)

const buttons = ref(true)
provide("buttons", buttons)

const buttons2 = ref(true)
provide("buttons2", buttons2)

const tabs = ref(true)
provide("tabs", tabs)

const toggleSidebar = () => {
    isSidebarOpen.value = !isSidebarOpen.value
    if(document.getElementById('sidebar').classList.contains('d-none')){
        document.getElementById('sidebar').classList.remove('d-none')
    } else{
        document.getElementById('sidebar').classList.add('d-none')
    }
    if(document.getElementById('div-grey').classList.contains('right')){
        document.getElementById('div-grey').classList.remove('right')
        document.getElementById('div-grey').classList.add('left')
    } else{
        document.getElementById('div-grey').classList.remove('left')
        document.getElementById('div-grey').classList.add('right')
    }
}

</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #F5F5F5;
    min-height: 100vh;
    @media (min-width: 992px) {
        margin-top: 77px;
        background-color: #F5F5F5;
    }
}
.titulo{
    h2{
        font-weight: 700;
        color: var(--colorSecondary);
        font-size: 32px;
        margin-bottom: 0;
    }
    h3{
        font-size: 16px;
        font-weight: 600;
        color: var(--colorSecondary);
        padding: 0px 0 30px 0;
        @media (min-width: 992px) {
            padding-bottom: 0;
        }
    }
    img{
        height: 20px;
    }
    button{
        margin-left: 10px;
    }
    .download{
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        height: 44px;
        width: 44px;
        border: 0;
    }
    .btn__primario{
        max-width: 200px;
    }
}
.contenedor{
    animation: fade-in 0.2s ease-in forwards;
    .tablas{  
        background-color: transparent;
        min-height: 56px;
        margin-bottom: 10px;
        border-radius: 10px;
        ul{
            background-color: transparent;
            padding: 8px 0;
            min-width: 100%;
        }
        .nav-item{
            padding: 0;
        }
        .nav-link{
            width: 100%;
            position: relative;
            padding: 10px 0;
            border: 0;
            background-color: transparent;
            border-radius: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 16px;
            color: #B8C1DB;
            h3{
                font-weight: 500;
                font-size: 16px;
                color: #B8C1DB;
            }
            @media (min-width: 992px) {
                color: #0E1133;
                h3{
                    color: #0E1133;
                }
            }
        }
        .nav-link.active{
            font-weight: 700;
            border-color: transparent;
            color: var(--colorSecondary);
            background-color: transparent;
            border-radius: 0;
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 5px;
                bottom: 0px;
                left: 0;
                background-color: var(--colorPrimary);
                border-radius: 10px;
            }
        }
        .scroll{
            width: 100%;
            height: 100%;
        }
        @media (max-width: 991px) {
            border-radius: 0px;
            margin-bottom: 10px;
        }
        .nav-tabs{
            border-bottom: 2px solid #B8C1DB;
        }
    } 
    .contenido{
        h4{
            font-weight: 400;
            font-size: 15px;
            color: #B8C1DB;
            text-align: left;
            img{
                margin-right: 5px;
            }
        }
    }
    .div_shadow{
        box-shadow: 0px 3px 10px #00000029;
        border-radius: 20px;
        background-color: #fff;
        height: 105px;
        padding: 15px;
        margin-bottom: 15px;
        h5{
            font-weight: 600;
            font-size: 18px;
            color: var(--colorSecondary);
        }
        h6{
            font-weight: 700;
            font-size: 24px;
            color: #374557;
        }
        p{
            font-size: 14px;
            font-weight: 400;
            color:#494D4C;
            margin: 0 5px;
        }
        .percent{
            font-weight: 600;
            color:#38E6A7;
            margin-right: 0;
        }
        .negative{
            color: #FF576E;
        }
    }
}

.classSpecial{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000016;
    border-radius: 20px;
    padding: 20px 30px;
    margin-top: 30px;
    @media (max-width: 991px) {
        padding-bottom: 30vw;
    }
    @media (min-width: 992px) {
        width: 70vw !important;
    }
}

@keyframes fade-in {
    0% { opacity: 0; }
   90% { opacity: 0.9; }
  100% { opacity: 1; }
}
</style>