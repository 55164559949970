<template>
    <div class="d-flex flex-column justify-content-start align-items-center galeries pt-3 pb-3 px-1 me-3" @click="actual_gallery = gallery, actual_tab = 2" v-for="(gallery, id) in gallery.specializations">
        <img :src="gallery.files[0].file" alt="" class="img">
        <div class="d-flex sub-info ps-3 pt-2 row__width">
            <img :src="avatar" alt="avatar" class="avatar">
            <div class="col d-flex flex-column">
                <h5 class="d-flex title px-3 pt-1">{{ gallery.translation.name }}</h5>
                <div class="col d-flex justify-content-start align-items-center px-3">
                    <div class="col-6 d-flex justify-content-start align-items-center">
                        <img src="../../img/perfil-icons/galeria-icons/like-red.svg" alt="">
                        <h5 class="mt-1 ms-2">{{ gallery.files[0].likes }}</h5>
                    </div>
                    <div class="col-6 d-flex justify-content-start align-items-center">
                        <img src="../../img/perfil-icons/galeria-icons/visualizaciones.svg" alt="" class="icon">
                        <h5 class="mt-1 ms-2">{{ gallery.files[0].views }}</h5>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script setup>
    import { inject, ref } from 'vue';

    const props = defineProps({
        avatar: '',
        gallery:{
            type: Object
        }
    })

    const actual_tab = inject('actual_tab')
    const actual_gallery = inject('actual_gallery')
    
</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 20px 5px;
    .swiper-wrapper{
        margin: 20px 0;
    }
}
.galeries{
    background-color: transparent;
    border-radius: 20px;
    max-height: max-content;
    max-width: 250px;
    cursor: pointer;
    &:hover{
        box-shadow: 0px 3px 6px #00000029;
        background-color: #fff;
    }
    .img{
        height: 200px;
        width: 220px;
        object-fit: cover;
        border-radius: 20px;
    }
}

.sub-info{
    .title{
        font-weight: 700;
    }
    img{
        width: 20px;
        height: 20px;
    }
    .icon{
        width: 22px;
        height: 22px;
    }
    .avatar{
        height: 50px;
        width: 50px;
        object-fit: cover;
        border-radius: 50%;
    }
}
</style>