<template>
    <div class="row justify-content-center row__width item">
        <div class="col-4 d-flex justify-content-center align-items-center px-0 ejemplo">
            <img :src="image" alt="" class="">
        </div>
        <div class="col d-flex flex-column justify-content-start align-items-start position-relative">
            <h2>{{titulo}}</h2>
            <div class="d-flex justify-content-between justify-content-lg-start align-items-center pt-3 row__width">
                <h5 v-if="discount">$ {{discount}}</h5><h3>$ {{prize}}</h3>
            </div>
            <button>
                <img src="../../img/carrito-icons/delete.svg" alt="" @click="deleteCart(id)">
            </button>
        </div>
        <!--- OFERTA PRODUCTOS ADICIONALES --->
        <div class="col-12 d-flex flex-column justify-content-center align-items-center collapses px-0 d-none">
            <div class="accordion d-flex flex-column justify-content-center align-items-center" id="accordionExample">
                <div class="accordion-item row row__width justify-content-center">
                    <h2 class="accordion-header mb-0 px-0 row__width" :id="'heading'+id">
                        <p class="accordion-button py-4 collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+id" aria-expanded="false" aria-controls="collapse">
                            <div class="col-9 d-flex justify-content-start align-items-center ps-1 pe-0">
                                <h2>{{ $t('message.additionalProductsV2') }}</h2>
                            </div>
                        </p>
                    </h2>
                    <div :id="'collapse'+id" class="col-12 accordion-collapse collapse" :aria-labelledby="'heading'+id">
                        <div class="accordion-body row justify-content-end py-0 px-0">
                            <div class="col px-0 d-flex flex-column justify-content-center align-items-center excepcion">
                                <div class="row row__width justify-content-center">
                                    <div class="col-3 d-flex justify-content-center align-items-start px-0 ejemplo">
                                        <img src="../../img/carrito-icons/ejemplo1.svg" alt="" class="img">
                                    </div>
                                    <div class="col-8 d-flex flex-column justify-content-start align-items-start position-relative">
                                        <h2>{{titulo}}</h2>
                                        <div class="d-flex justify-content-between align-items-center pt-3 row__width">
                                            <h5>$ {{}}</h5><h3>$ {{}}</h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 d-flex justify-content-center align-items-center eliminar position-relative">
                                <button class="d-flex justify-content-center align-items-center">
                                    <img src="../../img/carrito-icons/delete.svg" alt="">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { storeToRefs } from "pinia"
    import { useCartStore } from "../../stores/cart"

    const carts = useCartStore();
    const { arrayCheckout } = storeToRefs(carts);
    const { deleteCart } = carts

    const props = defineProps({
        id: '',
        titulo: '',
        discount: '',
        prize: '',
        image: ''
    })
</script>

<style lang="scss" scoped>
.item{
    padding: 20px 0 25px 0;
    border-bottom: 1px solid #6978911c;
    .ejemplo{
        position: relative;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 15px;
        }
        &::before{
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 70%;
            background: transparent linear-gradient(180deg, #ffffff00 0%, var(--colorSecondary) 100%) 0% 0% no-repeat padding-box;
            filter: opacity(1);
            border-radius: 15px;
            z-index: 1;
            left: 0;
        }
    }
    h2{
        font-weight: 600;
        font-size: 16px;
        text-align: start;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 140px;
        color: var(--colorSecondary);
    }
    h3{
        font-weight: 700;
        font-size: 20px;
        color: var(--colorSecondary);
        text-align: left;
        white-space: nowrap;
        width: 55px;
        @media (min-width: 992px) {
            padding-left: 3vw;
        }
    }
    h5{
        font-weight: 400;
        font-size: 16px;
        color: #B8C1DB;
        text-decoration: line-through;
        text-align: left;
        white-space: nowrap;
        width: 55px;
    }
    button{
        border: 0;
        background-color: transparent;
        outline: 0;
        position: absolute;
        top: 0;
        right: 0;
    }
}
.collapses{
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        padding: 7px 0px;
    }
    h6{
        color:#B8BCC8;
        font-size: 15px;
        font-weight: 400;
        text-align: left;
        padding: 5px 0px;
    }
    .accordion{
        width: 100%;
        .accordion-item{
            border: 0;
            border-radius: 10px;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: #F9F9F9;
            margin: 15px 0;
        }
        .accordion-item:last-of-type .accordion-button.collapsed{
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .accordion-header{
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            .accordion-button{
                border-radius: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                background-color: #F9F9F9;
                &:focus{
                    border-color: inherit;
                    box-shadow: none;
                }
                span{
                    height: 70px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                h3{
                    text-align: center;
                    font-size: 22px;
                }
                button{
                    background-color: transparent;
                    border: 2px solid #E4E4E4;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    margin-left: 10px;
                }
            }
            .accordion-button:not(.collapsed){
                background-color: inherit;
                box-shadow: none;
            }
            .accordion-button::after{
                position: absolute;
                right: 3%;
                background-image: url('../../img/subir-productos-icons/arrow-abajo-azul.svg');
                background-size: 50%;
                background-position: center;
                background-color: transparent;
                border-radius: 50%;
                height: 40px;
                width: 40px;
            }
            .accordion-body{
                span{
                    border: 2px solid #E9E3E3;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h3{
                        text-align: center;
                    }
                }
                button{
                    background-color: transparent;
                    border: 2px solid #E4E4E4;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    margin-left: 10px;
                }
            }
            .buttons{
                position: absolute;
                right: 17%;
                @media (min-width: 992px) {
                    right: 7%;
                }
            }
            
        }
        h2{
            max-width: none;
        }
        h5{
            font-weight: 400;
            font-size: 16px;
            color: #B8C1DB;
            text-decoration: line-through;
            text-align: left;
        }
        h6{
            color: #B8C1DB;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            height: 30px;
        }
        .eliminar{
            border-left: 2px solid #E4E4E4;
            button{
                background-color: transparent;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                border: 0;
                position: relative;
            }
        }
    }
    .padding{
        padding: 0 11%;
    }
}
</style>