<template>
    <div class="row justify-content-center inputs px-3 row__width">
        <div class="col-12 d-flex justify-content-between align-items-center recordar px-0">
            <div class="d-flex justify-content-center align-items-center">
                <input type="checkbox" :placeholder="$t('message.password')" id="recordarLoginCheck" v-model="usuarioRecordar.recordar" @change="remember">
                <label for="recordarLoginCheck" class="d-flex justify-content-start align-items-center"><p>{{$t('message.remember')}}</p></label>
            </div>
            <router-link to="/recuperar">{{$t('message.forget')}}</router-link>
        </div>
    </div>
</template>

<script setup>
import { inject, ref } from 'vue'
import { useGeneralStore } from "../../stores/general"    
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../stores/user";
///----------///
const general = useGeneralStore();
const {textos} = storeToRefs(general);
const {} = general;
// Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
const users = useUsersStore();
// Mis estados los paso a objetos porque en la tienda están en reactivos //
const {datosUsuario} = storeToRefs(users);
const {} = users;
const usuarioRecordar = inject('usuarioRecordar')
const usuario = inject('usuario')
const remember = () =>{
    if(usuarioRecordar.value.recordar === true){
        //console.log(usuarios.value)
        localStorage.setItem('userDataRemember', JSON.stringify(usuario.value))
    }else{
        localStorage.removeItem('userDataRemember');
    }
}
</script>

<style lang="scss" scoped>
.recordar{
    padding-top: 10px;
    a{
        color: var(--colorPrimary);
        font-size: 14px;
        font-weight: 400;
    }
    input{
        display: none;
    }
    label{
        height: 15px;
        width: 15px;
        border: 1px solid #697891;
        border-radius: 5px;
        position: relative;
        p{
            font-size: 14px;
            font-weight: 400;
            color: #697891;
            padding-left: 25px;
        }
    }
    input:checked + label::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #1679FC;
        background-image: url('../../img/inicio-icons/tick.svg');
        background-size: 80%;
        background-repeat: no-repeat;
        background-position: center;
    }
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        padding: 7px 0px;
        margin-right: 10px;
        margin-top: 0;
    }
    h3 + a{
        color: #1679FC;
        font-size: 16px;
        font-weight: 600;
    }
    @media (min-width: 992px) {
        label{
            width: 18px;
            height: 18px;
        }
        label p{
            font-size: 15px;
        }
        a{
            font-size: 15px;
        }
    }
}
</style>