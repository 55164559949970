<template>
    <nav class="navbar navbar-light fixed-top py-0">
        <div class="container-fluid d-flex justify-content-center align-items-center px-0 px-lg-auto">
            <div class="row justify-content-center row__width">
                <div class="col-12 d-flex justify-content-center align-items-center">
                    <div class="row justify-content-center justify-content-lg-between align-items-center row__width max-height">
                        <div class="col-12 col-lg-3 d-flex justify-content-between align-items-center px-0">
                            <div class="d-flex justify-content-start align-items-center navbar-brand"> 
                                <router-link class="btn_none mx-2" to="/mis-productos">
                                    <img src="../../img/menu-icons/arrow-left-negro.svg" alt="" class="arreglo__img"> {{ $t('message.backDashboard') }}
                                </router-link>
                            </div>
                            <button class="navbar-toggler d-none d-lg-flex" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasModulos" aria-controls="offcanvasModulos">
                                <span class=""><img src="../../img/viendo-icons/despliegue.svg" alt=""></span>
                            </button>
                            <div class="d-flex justify-content-end align-items-center d-lg-none">
                                <button class=" navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasModulos" aria-controls="offcanvasModulos">
                                    <span class="mx-2"><img src="../../img/viendo-icons/nav.svg" alt=""></span>
                                </button>
                                <ul class="p-0 col-4 mb-0 d-flex justify-content-center align-items-center ms-2">
                                    <li class="p-0 nav-item dropdown w-100 d-flex justify-content-center align-items-center ">
                                        <a class="nav-link dropdown-toggle d-flex justify-content-start align-items-center" href="#" id="offcanvasNavbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="ajustes"></i>
                                        </a>
                                        <ul class="dropdown-menu ajustesDropdown" aria-labelledby="offcanvasNavbarDropdown">
                                            <li v-for="(input, id) in inputs">
                                                <SwicthViendoCurso :id="id" :checked="input.disable" :titulo="input.titulo"/>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-7 d-flex justify-content-center align-items-center botones row__lg__nav px-0 d-none d-lg-flex">
                            <div class="row justify-content-end align-items-center row__width">
                                <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                    <div class="row justify-content-end row__width">
                                        <div class="col-2 d-flex justify-content-center align-items-center px-0">
                                            <button type="button" @click="previousLesson" :class="actualLessonId == 0 ? 'disabled' : ''">
                                                <span><img src="../../img/viendo-icons/arrow-right-negra.svg" alt=""></span>
                                                {{ $t('message.previous') }}
                                            </button>
                                        </div>
                                        <div class="col-9 d-flex justify-content-center align-items-center px-0">
                                            <button type="button" class="siguiente" @click="nextLesson" :class="nextBlock ? 'disabled' : ''">
                                                {{ $t('message.continueV2') }}: <h4>{{ nextPageName }}</h4>
                                                <span><img src="../../img/viendo-icons/arrow-left-azul.svg" alt=""></span>
                                            </button>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <NavModulos v-if="backdrop"/>
</template>

<script setup>
import SwicthViendoCurso from "../Inputs/SwicthViendoCurso.vue";
import MenuViendo from "../Navs/MenuViendo.vue";
import { ref, inject, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import NavModulos from "./NavModulos.vue";

const route = useRoute();
const router = useRouter();
const inputs = ref([
    {titulo: 'Autoplay', disable: false},
    {titulo: 'Autocompletar', disable: true},
])

const toggleSidebar = () => {
    isSidebarOpen.value = !isSidebarOpen.value
    if(document.getElementById('sidebar').classList.contains('d-none')){
        document.getElementById('sidebar').classList.remove('d-none')
    } else{
        document.getElementById('sidebar').classList.add('d-none')
    }
    if(document.getElementById('div-grey').classList.contains('right')){
        document.getElementById('div-grey').classList.remove('right')
        document.getElementById('div-grey').classList.add('left')
    } else{
        document.getElementById('div-grey').classList.remove('left')
        document.getElementById('div-grey').classList.add('right')
    }
}

let modules = inject('modules')
let response = inject('response')
let lessonIds = inject('lessonIds')
let nextPageName = inject('nextPageName')
let currentLessonInfo = inject('currentLessonInfo')
let modComplete = inject('modComplete')
let backdrop = inject('backdrop')
let actualLessonId = inject('actualLessonId')
let courseProgress = inject('courseProgress')
let success = inject('success')
let nextBlock = inject('nextBlock')

const goToLesson = (id) => {
    console.log(id)
    actualLessonId.value = id.id
}

const previousLesson = () => {
    nextBlock.value = false
    const index = lessonIds.value.findIndex((lesson) => lesson.id === actualLessonId.value)
    if(success.value == true){
        goToLesson(lessonIds.value[lessonIds.value.length - 1])
        success.value = false
    } else if (index > 0) {
        if(index - 1 == 0){
            modules.value = 0
        }
        goToLesson(lessonIds.value[index - 1])
    }
}

const nextLesson = () => {
    console.log(lessonIds.value)
    const index = lessonIds.value.findIndex((lesson) => lesson.id === actualLessonId.value)
    console.log(index)
    nextBlock.value = false
    success.value = false
    if (index < lessonIds.value.length - 1) {
        goToLesson(lessonIds.value[index + 1])
    } else if (index === lessonIds.value.length - 1) {
        success.value = true
        nextBlock.value = true
        actualLessonId.value = -1
    }
    if(index + 1 <= lessonIds.value.length - 1 && lessonIds.value[index + 1].isBlocked){
        console.log("BLOQUEO")
        nextBlock.value = true
    }
}

const getNextLessonTitle = () => {
    const index = lessonIds.value.findIndex((lesson) => lesson.id === actualLessonId.value)
    console.log(index)
    if (index < lessonIds.value.length - 1 && index >= 0) {
        nextPageName.value = lessonIds.value[index + 1].title
    } else if (index == -1 || index >= lessonIds.value.length - 1) {
        nextPageName.value = 'Finalizar curso'
    }
}
onMounted(() =>{
    getNextLessonTitle()
    console.log(lessonIds.value)
})
watch(lessonIds, () => {
    // console.log("Watch - lessonIds")
    getNextLessonTitle()
})
watch(actualLessonId, (newValue, oldValue) => {
    // console.log("Watch - actualLessonId")
    getNextLessonTitle()
    const index = lessonIds.value.findIndex((lesson) => lesson.id === actualLessonId.value)
    console.log(index, oldValue)
    if(index + 1 <= lessonIds.value.length - 1 && lessonIds.value[index + 1].isBlocked){
        success.value = false
        nextBlock.value = true
    } else if(index == -1 && oldValue){
        // success.value = true
        nextBlock.value = true
    } else {
        success.value = false
        nextBlock.value = false
    }
})
// watch(actualLessonId, () => {
//     if (index === lessonIds.value.length - 1) {
//         success.value = true
//         nextBlock.value = true
//         actualLessonId.value = -1
//     }
//     if(index + 1 <= lessonIds.value.length - 1 && lessonIds.value[index + 2].isBlocked){
//         console.log("BLOQUEO")
//         nextBlock.value = true
//     }
// })
</script>

<style lang="scss" scoped>
.navbar{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    height: 68px;
    a{
        img{
            max-height: 50px;
            @media (min-width: 992px) {
                max-height: 50px;
            }
        }
    }
    .max-height{
        max-height: 100%;
    }
    .container-fluid{
        height: 100%;
        position: relative;
    }
    .navbar-toggler{
        border: 0;
        padding: 0;
        outline: 0;
        .navbar-toggler-icon{
            background-image: url('../../img/menu-icons/menu-icon.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            height: 25px;
            width: 29px;
        }
        &:focus{
            outline: 0;
            box-shadow: none;
        }
    }
    .nav-link{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--enlacesNav);
        font-size: 15px;
        font-weight: 500;
        text-align: left;
        margin: 10px 0px;
        .position{
            position: absolute;
            right: 0;
        }
        i{
            width: 30px;
            height: 30px;
            display: block;
            background-repeat: no-repeat;
            background-position: center;
        }
        .ajustes{
            background-image: url('../../img/viendo-icons/ajustes.svg');
        }
        &:focus, &:hover{
            &::before{
                content: '';
                position: absolute;
                top: -9px;
                background-color: var(--colorPrimary);
                width: 110%;
                height: 5px;
                border-radius: 0px 0px 10px 10px;
            }
        }
    }
    .show .ajustes{
        background-image: url('../../img/viendo-icons/ajustes-a.svg');
    }
    .navbar-brand{
        button{
            font-weight: 600;
            font-size: 16px;
            color: var(--colorSecondary);
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                padding-right: 10px;
            }
        }
    }
    .dropdown-toggle::after{
        border: 0;
    }
    ul{
        list-style: none;
        left: auto;
        right: 0;
        
        padding: 15px;
        h4{
            color: var(--grisPrimario);
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            padding: 0 0 10px 0;
        }
        li{
            padding: 0;
            width: 100% ;
        }
        .ajustesDropdown{
            box-shadow: 0px 3px 15px #00000029;
            border-radius: 10px;
            border: 0;
            min-width: 230px;
        }
    }
    .botones{
        span{
            background-color: var(--bgPrincipal);
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #F5F5F5;
            border-radius: 21px;
            min-width: 42px;
            min-height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        button, h4{
            border: 0;
            background-color: transparent;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 400;
            font-size: 16px;
            color: #697891;
            padding: 10px 10% 10px 0;
        }
        .siguiente{
            font-weight: 600;
            padding: 10px 0 10px 2%; 
            border-left: 1px solid #E4E4E4;
        }
        h4{
            border: 0;
            padding: 0 0 0 10px;
        }
        .width{
            width: max-content;
        }
    }
    
    
}
.navbar-nav .dropdown-menu{
    position: absolute;
}
.disabled {
    opacity: 0.5;
    pointer-events: none;
}

</style>