<template>
    <div class="row justify-content-center row__width position-relative">
        <swiper-container
            :slidesPerView="1"
            :slidesPerGroup="1"
            :navigation="{
                nextEl: '#nextSliderCursos',
                prevEl: '#prevSliderCursos'
            }"
            :breakpoints="{
                375: {
                    spaceBetween: -15,
                    slidesPerView: 1
                },
                425: {
                    spaceBetween: -10,
                    slidesPerView: 2
                },
                992: {
                    spaceBetween: 20,
                    slidesPerView: 3
                },
                1100: {
                    spaceBetween: 20,
                    slidesPerView: 3
                },
                1300: {
                    spaceBetween: -30,
                    slidesPerView: 3
                },
                1400: {
                    spaceBetween: -100,
                    slidesPerView: 3
                },
                1800: {
                    spaceBetween: -120,
                    slidesPerView: 3
                },
                1800: {
                    spaceBetween: -140,
                    slidesPerView: 4
                },
            }"
            class="mySwiper" >
            <swiper-slide v-for="(slide, id) in courses" class="d-flex justify-content-start align-items-center">
                <div class="col-11 col-lg d-flex flex-column justify-content-start align-items-center productos">
                    <div class="row justify-content-center row__width">
                        <div class="col-12 d-flex justify-content-center align-items-center px-0 position-relative">
                            <img :src="slide.image" alt="" class="img">
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center mt-3" v-if="slide.names !== undefined">
                            <h5>{{ slide.names[lang]}}</h5>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper-container>
    </div>
</template>

<script setup>
    import { ref, inject, onMounted } from "vue";

    const props = defineProps({
        courses: {
            type: Object
        }
    })
    
    const slider = ref({})
    const courses = inject('courses')
    const lang = ref('')
    onMounted( () => {
        lang.value = JSON.parse(localStorage.getItem('lang'))
    } )
</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 20px 0px;
    margin: 20px 0;
}
.productos{
    background-color: #fff;
    box-shadow: 0px 10px 12px #868E9633;
    border-radius: 15px;
    height: 350px;
    padding: 10px;
    max-height: max-content;
    h5{
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
        text-align: left;
        width: 100%;
    }
    .img{
        object-fit: cover;
        width: 100%;
        height: 28vh;
        border-radius: 10px;
    }
    .position-relative{
        &::before{
            content: '';
            position: absolute;
            background-color: #090F20;
            opacity: 0.52;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }
    @media (min-width: 992px) {
        max-width: 351px;
    }
}
</style>