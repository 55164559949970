<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start ps-lg-0">
        <NavbarCertificados :seccion="$t('message.setting')"/>
        <NavLateralPanel :settings="true"/>
        <div class="div-grey right" id="div-grey">
            <button class="boton-toggler" id="toggleSidebar" type="button" @click="toggleSidebar">
                <img src="../../../img/home-icons/arrow-left-blanco.svg" alt="">
            </button>
        </div>
        <div class="col ms-3 mt-3 d-flex justify-content-center align-items-start p-lg-3" :style="isSidebarOpen ? 'padding-left: 300px !important;'  : 'padding-left: 50px !important;'">
            <div class="row justify-content-center justify-content-lg-start row__width">
                <div class="col-11 col-lg d-flex flex-column justify-content-start align-items-center px-0 contenedor">
                    <div class="row justify-content-center row__width border-bottom">
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center">
                            <TituloAjustes titulo="Ajustes"/>
                        </div>
                    </div>
                    <div class="row justify-content-center row__width">
                        <div class="col-12 ps-lg-5 d-flex flex-column justify-content-center align-items-center">
                            <AjustesPanel/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterCertificados :account="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../components/Navs/NavbarCertificados.vue"
import FooterCertificados from "../../../components/Footer/FooterCertificados.vue"
import NavLateralPanel from "../../../components/Navs/NavLateralPanel.vue"
import atrasBTNCertificados from "../../../components/Botones/Atras.vue"
import TituloAjustes from "../../../components/Titulo/TituloAjustes.vue"
import AjustesPanel from "../../../components/Panel/Ajustes/AjustesPanel.vue"
// Importo mi método
import { storeToRefs } from "pinia"
// Llamo a mi tienda de Pinia en forma de Setup 
import { useUsersStore } from "../../../stores/user"
import { useLanguageStore } from "../../../stores/langs"
import { onBeforeMount, ref, provide } from "vue"
// Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
const users = useUsersStore()
const langs = useLanguageStore()
// Mis estados los paso a objetos porque en la tienda están en reactivos //
const {access} = storeToRefs(users)
const {} = users;
const {validate} = users;
const {listLangs} = storeToRefs(langs)

const algo = onBeforeMount( () => {
    access.value = [1, 2, 3, 4, 5]
    //console.log(access.value) 
    validate();
})

const isSidebarOpen = ref(true);
const toggleSidebar = () => {
    isSidebarOpen.value = !isSidebarOpen.value
    if(document.getElementById('sidebar').classList.contains('d-none')){
        document.getElementById('sidebar').classList.remove('d-none')
    } else{
        document.getElementById('sidebar').classList.add('d-none')
    }
    if(document.getElementById('div-grey').classList.contains('right')){
        document.getElementById('div-grey').classList.remove('right')
        document.getElementById('div-grey').classList.add('left')
    } else{
        document.getElementById('div-grey').classList.remove('left')
        document.getElementById('div-grey').classList.add('right')
    }
}

provide(isSidebarOpen, 'isSidebarOpen')
</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #fff;
    min-height: 100vh;
    @media (min-width: 992px) {
        margin-top: 77px;
    }
}
.contenedor{
   @media (min-width: 992px) {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding:20px 0 30px 0;
        min-height: 80vh;
   }
}
.border-bottom2{
    @media (min-width:992px) {
        border-bottom: 1px solid #E4E4E4;
        padding-bottom: 20px;
    }
}
</style>