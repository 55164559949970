<template>
    <nav class="navbar navbar-light fixed-top">
        <div class="container-fluid d-flex justify-content-center align-items-center px-lg-0">
            <div class="row justify-content-center row__width">
                <div class="col-12 col-lg-11 d-flex justify-content-center align-items-center px-0">
                    <div class="row justify-content-center justify-content-lg-between align-items-center row__width">
                        <div class="col-12 col-lg-3 d-flex justify-content-between justify-content-lg-start align-items-center px-0">
                            <div class="d-flex justify-content-start align-items-center px-0">
                                <!-- <button class="navbar-toggler me-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar5" aria-controls="offcanvasNavbar5">
                                    <span class="navbar-toggler-icon"></span>
                                </button> -->
                                <router-link to="/" class="navbar-brand ms-3 mx-lg-0 d-flex justify-content-center align-items-center" href="#"><img src="../../img/Logo.png" alt=""></router-link>
                            </div>
                            <button class="btn_none d-none"><img src="../../img/menu-icons/lupa.svg" alt=""></button>
                        </div>
                        <div class="col-lg d-flex justify-content-center align-items-center row__lg__nav px-0 d-none d-lg-flex">
                            <div class="row justify-content-end align-items-center row__width">
                                <SeccionNavHome/>
                                <ModalFoto/>
                            </div>
                        </div>
                        <MenuHome/>
                        <Menu/>
                        <Notifications/>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script setup>
import { ref } from "vue";
import { useGeneralStore } from "../../stores/general"    
import { useUsersStore } from "../../stores/user";
import { storeToRefs } from "pinia";
import MenuHome from "./MenuHome.vue";
import Menu from "../Carrito/Menu.vue";
import Notifications from "../Carrito/Notifications.vue";
import SeccionNavHome from "./SeccionNavHome.vue";
import ModalFoto from "./ModalFoto.vue";
import { RouterLink } from 'vue-router';
import { getCurrentInstance, onMounted } from 'vue';
    
const general = useGeneralStore();
const {textos} = storeToRefs(general);
const {} = general;

const users = useUsersStore();
const {cambioHeader, idUser} = storeToRefs(users);

const validarHeader = () => {
    idUser.value = JSON.parse(localStorage.getItem('idUser'))
    if (idUser.value != null){
        console.log(idUser.value)
        if (idUser.value.team != 0){
            cambioHeader.value = false
        }
        else {
            cambioHeader.value = true
        }
    }
    console.log(`validarHeader${cambioHeader.value}`)
}
validarHeader()

const carrito = ref(true)


</script>

<style lang="scss" scoped>
.navbar{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    height: 68px;
    a{
        img{
            max-height: 50px;
            @media (min-width: 992px) {
                max-height: 50px;
            }
        }
    }
    .container-fluid{
        height: 100%;
    }
    .navbar-toggler{
        border: 0;
        padding: 0;
        outline: 0;
        .navbar-toggler-icon{
            background-image: url('../../img/menu-icons/menu-icon.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            height: 25px;
            width: 29px;
        }
        &:focus{
            outline: 0;
            box-shadow: none;
        }
    }
    .nav-link{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--enlacesNav);
        font-size: 15px;
        font-weight: 500;
        text-align: left;
        margin: 10px 0px;
        position: relative;
        img{
            margin-right: 20px;
        }
        .position{
            position: absolute;
            right: 0;
        }
        i{
            margin-right: 15px;
            min-height: 23px;
            min-width: 23px;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
        }
        .cursos{
            background-image: url('../../img/menu-icons/cursos.svg');
        }
        .categorias{
            background-image: url('../../img/menu-icons/categorias.svg');
        }
        .escuela{
            background-image: url('../../img/menu-icons/escuela.svg');
        }
        .concursos{
            background-image: url('../../img/menu-icons/concursos.svg');
        }
        .nosotros{
            background-image: url('../../img/menu-icons/nosotros.svg');
        }
        .contacto{
            background-image: url('../../img/menu-icons/contacto.svg');
        }
        .premium{
            background-image: url('../../img/menu-icons/premium.svg');
        }
        &:focus, &:hover{
            color: var(--colorPrimary);
            font-weight: 700;
            &::before{
                content: '';
                background-color: var(--colorPrimary);
                height: 100%;
                position: absolute;
                width: 5px;
                border-radius: 10px;
                left: -8.5%;
                top: 0;
            }
            .cursos{
                background-image: url('../../img/menu-icons/cursos_active.svg');
            }
            .categorias{
                background-image: url('../../img/menu-icons/categorias_active.svg');
            }
            .escuela{
                background-image: url('../../img/menu-icons/escuela_active.svg');
            }
            .concursos{
                background-image: url('../../img/menu-icons/concursos_actives.svg');
            }
            .nosotros{
                background-image: url('../../img/menu-icons/nosotros_active.svg');
            }
            .contacto{
                background-image: url('../../img/menu-icons/contacto_active.svg');
            }
            .premium{
                background-image: url('../../img/menu-icons/premium-active.svg');
            }
        }
    }
    .navbar-brand{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary);
        p{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorPrimary);
        }
    }
    .dropdown-toggle::after{
        border: 0;
    }
    ul{
        h4{
            color: var(--grisPrimario);
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            padding: 0 0 10px 0;
        }
        li{
            padding: 0;
        }
    }
    .paises{
        position: absolute;
        border-radius: 20px;
        top: -290px;
        background-color: var(--bgPrincipal);
        box-shadow: 0px 3px 6px #0000002a;
        right: 10%;
        padding: 20px;
        width: 250px;
        display: none;
        li{
            width: 100%;
            padding: 10px 0px 10px 0px;
            img{
                margin-left: 10px;
            }
        }
        .borde{
            border-top: 1px solid #c6bacc7c;
            padding: 15px 0px;
            img{
                margin-right: 10px;
                margin-left: 0;
            }
        }
    }
    .btns{
        margin: 10px 0px;
    }
    .ul_precios{
        padding-left: 0;
        list-style: none;
        padding-bottom: 40px;
        li{
            a{
                color: var(--colorSecondary);
                font-size: 16px;
                font-weight: 500;
                text-align: left;
            }
        }
        .nav-link{
            &:focus, &:hover{
                color: var(--colorPrimary);
                font-weight: 700;
                &::before{
                    display: none;
                }
            }
        }
    }
    .boder-top{
        border-top: 1px solid rgba(184, 193, 219, 0.5);
        h6{
            color: var(--grisSecundario);
            font-size: 16px;
            font-weight: 500;
            text-align: left;
        }
        .form-check-input{
            border-color: var(--colorPrimary);
            position: relative;
            display: flex;
            align-items: center;
        }
        .form-switch .form-check-input {
            height: 25px;
            width: 48px;
            background-image: url('../../img/menu-icons/switch_normal.svg');
            background-size: 42% 100%;
            background-position-x: 2px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                right: 5px;
            }
            &::after{
                display: none;
            }
        }
        .form-switch .form-check-input:checked{
            background-image: url('../../img/menu-icons/switch_active.svg');
            background-position-x: 25px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                filter: brightness(10);
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                left: 5px;
            }
        }
        .form-switch .form-check-input:focus{
            box-shadow: none;
        }

    }
    .navbar-nav .dropdown-menu{
        position: absolute;
    }
    .row__lg__nav{
        .btn_none{
            border: 0;
            background-color: transparent;
            &:focus{
                outline: none;
            }
        }
    }
    .offcanvas-carrito{
        min-width: 436px;
        padding: 20px;
        .offcanvas-title{
            font-size: 20px;
            font-weight: 700;
            color: var(--colorSecondary);
        }
        .div{
            min-height: 80%;
            padding: 20px;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: #FCFCFC;
            h2{
                font-size: 20px;
                font-weight: 700;
                color: var(--colorSecondary);
            }
            img{
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 50%;
                margin-bottom: 20px;
            }
            h3{
                font-size: 16px;
                font-weight: 400;
                color: #697891;
                text-align: center;
                padding: 10px 0px;
            }
        }
        .offcanvas .btn-close{
            background-image: url('../../img/carrito-icons/cerrar-modal.svg');
            opacity: 1;
        }
        .borde{
            border: 1px solid rgba(184, 193, 219, 0.5);
        }
        .modal-carrito{
            .offcanvas-header{
                margin-bottom: 10px !important;
            }
            h4{
                font-weight: 700;
                font-size: 22px;
                color: var(--colorSecondary);
            }
            p{
                font-weight: 500;
                font-size: 16px;
                color: #697891;
                padding: 10px 0px;
            }
            .btn{
                max-width: 90%;
            }
        }
    }
    .offcanvas-backdrop{
        backdrop-filter: blur(3px);
        @media (min-width: 992px) {
            top: 95px !important;
            backdrop-filter: blur(10px);
            background-color: var(--colorSecondary);
        }
    }
    @media (min-width: 992px) {
        height: 95px;
    }
    .foto-perfil{
        max-height: 40px;
    }
}
.offcanvas{
    padding: 10px 10px;
    max-width: 85%;
    border-radius: 0px 0px 15px 0px;
    .offcanvas-header{
        align-items: flex-start;
        padding: 10px 25px;
    }
    .btn-close{
        background-image: url('../../img/menu-icons/cerrar.svg');
    }
    .offcanvas-body{
        padding: 10px 25px;
    }
}
.offcanvas-backdrop.show{
    backdrop-filter: blur(3px);
}
.navbar-nav .dropdown-menu{
    position: absolute;
}
</style>