<template>
    <div class="row separador row__width tipo__reunion">
        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
            <input type="checkbox" id="hola">
            <label for="hola">
                <p class="left">Soy Fotografo</p>
                <p class="right">Soy empresa</p>
            </label>
        </div>
    </div>
</template>

<script setup>
    
</script>

<style lang="scss" scoped>
.tipo__reunion{
    label{
        height: 50px;
        width: 100%;
        border: 2px solid var(--colorPrimary);
        border-radius: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 20px 0px;
        cursor: pointer;
        &::before{
            content: '';
            position: absolute;
            border: 2px solid var(--colorPrimary);
            height: 87%;
            width: 53%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--colorPrimary);
            border-radius:  28px;
            left: 1%;
            transition: all 0.5s;
        }
        p{
            font-weight: 500;
            font-size: 16px;
            color: var(--bgPrincipal);
            z-index: 1;
            transition: all 0.5s;
            &:first-child{
                margin-right: 5%;
            }
            &:last-child{
                margin-left: 6%;
            }
        }
        .left{
            color: var(--bgPrincipal);
            font-weight: 600;
        }
        .right{
            color: #707070;
        }
    }
    input{
        display: none;
    }
    input:checked + label{
        &::before{
            left: 49%;
            width: 50%;
            
        }
        p{
            color: var(--bgPrincipal);
            font-weight: 600;
            
        }
        .left{
            color: #707070;
            font-weight: 400;
        }
    }
    @media (min-width: 992px) {
        label{
            min-height: 60px;
            p{
                &:first-child{
                    margin-right: 11%;
                }
                &:last-child{
                    margin-left: 11%;
                }
            }
            .right{
                color: var(--colorSecondary);
            }
        }
        input:checked + label{
            .left{
                color: var(--colorSecondary);
            }
        }
    }
}
</style>