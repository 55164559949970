<template>
    <div class="row justify-content-center asesorias__profesionales mt-5 pb-lg-5">
        <div class="col-12 d-flex justify-content-center align-items-center px-0">
            <div class="row justify-content-center row__width">
                <div class="col-12 d-flex flex-column justify-content-center align-items-center pb-lg-5 px-0">
                    <h2>{{ $t('message.benefitsAsPhotographer') }}</h2>
                </div>
                <div class="col-12 d-flex justify-content-center align-items-center px-0 py-3 py-lg-5 px-0">
                    <div class="row justify-content-center row__width">
                        <AsesoriasPuntosExplicativos v-for="(punto, id) in puntos" 
                            :titulo="puntos[id].titulo"
                            :subtitulo="puntos[id].subtitulo" 
                            :src="puntos[id].img"
                            :dLgFLex="puntos[id].dLgFlex"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { mapState } from "vuex";
    import { ref } from "vue";
    import AsesoriasPuntosExplicativos from "../CarritoPlus/AsesoriasPuntosExplicativos.vue";

    const puntos = ref([
        {
            titulo: 'Participa en Master Class Awards', 
            subtitulo: 'Presenta tus fotos y participa en el concurso de fotografía de Master Class Awards.', 
            img:require('@/img/plus-icons/awards.svg'),
            dLgFlex: false
        },
        {
            titulo: 'Forma parte del Ranking', 
            subtitulo: 'Forma parte del Ranking de fotógrafos a nivel mundial dentro del directorio.', 
            img:require('@/img/plus-icons/star.svg'),
            dLgFlex: false
        },
        {
            titulo: 'Acceso a Contenido Exclusivo', 
            subtitulo: 'Obtén acceso a contenido exclusivo en la plataforma de Master Class Photographers.', 
            img:require('@/img/plus-icons/contenido-exclusivo.svg'),
            dLgFlex: false
        },
        {
            titulo: 'Master Class Rewards', 
            subtitulo: 'Acceso al programa de lealtad «Master Class Rewards», y acumula puntos por compra.', 
            img:require('@/img/plus-icons/awards.svg'),
            dLgFlex: true
        },
        {
            titulo: 'Descuentos', 
            subtitulo: 'Descuento en nuestras tiendas de fotografía y acceso a pre-ventas exclusivas.', 
            img:require('@/img/plus-icons/star.svg'),
            dLgFlex: true
        },
        {
            titulo: 'Códigos de Usuario', 
            subtitulo: 'Comparte tu código y por cada persona que realice su primer pago recibe beneficios.', 
            img:require('@/img/plus-icons/contenido-exclusivo.svg'),
            dLgFlex: true
        },
    ])
</script>

<style lang="scss" scoped>
.asesorias__profesionales{
    h2{
        font-weight: 700;
        font-size: 20px;
        color: var(--colorSecondary);
        text-align: center;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 15px;
        &::before{
            content: '';
            position: absolute;
            bottom: 0;
            height: 4px;
            border-radius: 5px;
            width: 15%;
            background-color: var(--colorSecondary);
        }
        @media (min-width: 400px) {
            font-size: 25px;
        }
        @media (min-width: 992px) {
            font-size: 30px;
        }
    }
    .v2, .v3{
        font-size: 26px;
        text-align: left;
        &::before{
            display: none;
        }
    }
    h4{
        font-size: 16px;
        font-weight: 400;
        color: #697891;
        text-align: left;
        padding: 10px 0;
        line-height: 27px;
        strong{
            color: inherit;
            font-size: inherit;
        }
    }
    h5{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary); 
        padding-bottom: 10px;
    }
    .lista{
        ul{
            padding: 0;
            li{
                font-size: 16px;
                font-weight: 400;
                color: #697891;
                position: relative;
                padding-left: 20px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                padding-bottom: 10px;
                text-align: left;
                &::before{
                    content: '';
                    position: absolute;
                    background-image: url('../../img/home-icons/cursos-icons/tick-listado-asesorias.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 15px;
                    width: 15px;
                    left: 0;
                    margin-top: 5px;
                }
            }
        }
    }
    .nav-tabs{
        height: 60px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 28px;
        button{
            border: 0;
            border-radius: 50%;
            font-weight: 500;
            font-size: 16px;
            color: var(--colorSecondary);
            height: 85%;
            width: 90%;
            transition: color .1s ease-in-out;
            &:focus{
                outline: 0;
            }
        }
        .active{
            background-color: var(--colorPrimary);
            color: var(--bgPrincipal);
            font-weight: 700;
            border-radius: 28px;
        }
    }
    .spanV2{
        margin-bottom: 10px;
    }
    p{
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
        padding-left: 10px;
    }
}
</style>