//Importo librerías y métodos
import { defineStore } from 'pinia'
import { computed, onBeforeMount, onBeforeUnmount, onMounted, onUpdated, ref, watchEffect } from 'vue'
import axios from "axios"
import { onBeforeRouteLeave, useRouter } from 'vue-router';
import { useI18n } from "vue-i18n"

// Declaro urls globales de APIS
const url = process.env.VUE_APP_BASE_URL
const urlLogin = '/api/auth/login'
const urlValidateUser = '/api/validate/user'
const urlUsuarioData = '/api/user'
const urlLogout = '/api/auth/logout'
const urlValidateToken = '/api/token_validator'

export const useUsersStore = defineStore('user', () => {
  const {locale} = useI18n({ useScope: 'global' })
  //CONSTS
  const validarMail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const router = useRouter()

  const datosUsuario = ref({
  })
  const beforeInfomation  = ref({
  })

// PROFESSIONAL DATA
  const professionalData = ref({
  })
  const beforeProfessionalData  = ref({
  })
//

  const idUser = ref({
      id: '',
      team : 0
  })

  const cambioHeader = ref(true)
  const usuariosRegistro = ref({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    birthdate:'',
    country_code:'',
    phone_number:'',
    gender:'',
    country:'',
  })
  const usuarioRegistroInfoExtra = ref(Boolean)
  const access = ref()
  const token = JSON.parse(localStorage.getItem('token'))
  const headers = ref({'Authorization': `Bearer ${token}`})
  const infoMail = ref({
    email: '',
  })
  const tokenAccess = ref({})
  const mailValido = ref(Boolean)

  const userData = ref({

  })
  // FUNCTIONS
  const restructureParams = (data) => {
    if (data.personal_data) {
      return { ...data, ...data.personal_data }
    }
    return data
  }
  const convertDateFormat = (dateString) => {
    if (!dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
      throw new Error('Formato de fecha no válido. Debe ser YYYY-MM-DD.')
    }
    const [year, month, day] = dateString.split('-')
    return `${day}-${month}-${year}`
  }
  const validate = () => {
    let stop = false
    if (!idUser.value || !idUser.value.id || !idUser.value.team) {
      const storedUser = localStorage.getItem('idUser');
      if (storedUser) {
        idUser.value = JSON.parse(storedUser);
      }
    }
    console.log(idUser.value)
    stop = access.value.some(element => idUser.value.team === element);
    if (!stop){
      router.push('/iniciar')
    }
  }
  const logout = async () => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    localStorage.removeItem('token')
    localStorage.removeItem('idData')
    localStorage.removeItem('idUser')
    localStorage.removeItem('userData')
    localStorage.removeItem('tokenGoogleData')
    cambioHeader.value = true
    if(idUser.value){
      try {
        const response = await axios.post(url+urlLogout, {}, {headers: headers.value})
        console.log(response)
        if (response.data.status == 'Success') {
          console.log('logout')
          router.push('/iniciar')
        }
      }
      catch (error) {
        console.log('logout error')
        router.push('/iniciar')
      }
    }else{
      console.log('logout else')
      router.push('/iniciar')
    }
  }
  const localLang = () => {
    //console.log('Me ejecuto')
    locale.value = JSON.parse(localStorage.getItem('lang'))
    //console.log('Me ejecuto')
  }
  const usuarioData = async() => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    idUser.value = JSON.parse(localStorage.getItem('idUser'))
    if(idUser.value){
        try {
            await axios.get(url+urlUsuarioData, {headers: headers.value}).then((result) => {
              console.log(result)
              let resultParse = result.data.data
              userData.value = resultParse
              userData.value.birthdate = convertDateFormat(result.data.data.birthdate);
              console.log(userData.value)
              userData.value.image = resultParse.profile_photo_url
              localStorage.setItem('lang', JSON.stringify(resultParse.locale_setting.toLowerCase()))
              const date = new Date(userData.value.created_at);
              const year = date.getFullYear();
              const month = date.getMonth() + 1;
              const day = date.getDate();
              userData.value.created_at_formated = `${day}-${month}-${year}`
              localLang()
            })
        } catch (error) {
          console.log(error)
        }
    }
  }
  usuarioData()

  const getDate = (dateString) => { 
    //console.log('holi')
      if(dateString){
          const date = new Date(dateString); 
          const year = date.getFullYear(); 
          const month = date.getMonth() + 1; 
          const day = date.getDate(); 
          //console.log('holi 2')
          return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
      }
      //console.log('holi 3')
  }
  

  const userProfessionalData = async() =>{
    let idData = JSON.parse(localStorage.getItem('idData'))
    let lang = JSON.parse(localStorage.getItem('lang'))
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    if(idData){
        const urlProfessionalData = `/api/user/professional/data`
        try {
            await axios.get(url+urlProfessionalData, {headers: headers.value}).then((result) => {
              console.log(result)
              if (result.data.data !== 'not a professional') {
                professionalData.value = result.data.data
                let id = {id: professionalData.value.id}
                localStorage.setItem('idProf', JSON.stringify(id))
                //console.log(beforeProfessionalData.value)
                if(professionalData.value.social_networks){
                  if(!Array.isArray(professionalData.value.social_networks)){
                    professionalData.value.social_networks = JSON.parse(professionalData.value.social_networks)
                  }
                }
                if(!professionalData.value.social_networks){
                  professionalData.value.social_networks = []
                }
              }
            })
        } catch (error) {
          console.log(error)
        }
    }else{

    }
  }

  const validateToken = async() => {
    const token = JSON.parse(localStorage.getItem('token'))
    console.log(token)
    if(token){
      const headers = ref({'Authorization': `Bearer ${token}`})
      try {
        await axios.post(url+urlValidateToken, {}, {headers: headers.value}).then((result) => {
          console.log(result)
        })
      }
      catch (error) {
        console.log(error)
        localStorage.removeItem('token')
        localStorage.removeItem('idData')
        localStorage.removeItem('idUser')
        localStorage.removeItem('userData')
        localStorage.removeItem('tokenGoogleData')
        router.push('/iniciar')
        cambioHeader.value = true
      }
    }
    else {
      localStorage.removeItem('token')
      localStorage.removeItem('idData')
      localStorage.removeItem('idUser')
      localStorage.removeItem('userData')
      localStorage.removeItem('tokenGoogleData')
      // router.push('/iniciar')
      cambioHeader.value = true
    }
  }
  validateToken()

  return {datosUsuario, cambioHeader, usuariosRegistro, 
    tokenAccess, usuarioRegistroInfoExtra, mailValido, idUser, url, urlLogin, urlValidateUser, access,
     validate, logout, headers, validarMail, infoMail, usuarioData, userProfessionalData, beforeInfomation, userData, professionalData}
})