<template>
    <div class="accordion row justify-content-center row__width">
        <div class="accordion-item col-12 " v-if="cart">
            <h2 class="accordion-header" id="headingTwo">
              <button class="accordion-button" aria-expanded="true">
                  Resumen: {{ cart.length }} productos
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                  <!-- <ListadoItemsCarritoV2/> -->
                  <div class="col-12 d-flex flex-column justify-content-center align-items-center items px-0">
                      <ItemsCarritoV2 v-for="(item, index) in cart"
                                        :titulo="item.name"
                                        :discount="item.price.showPrice"
                                        :prize="item.price.realPrice"
                                        :image="item.image"
                                        :id="index"
                                        :key="exchangeUpdate"
                                          />               
                  </div>
                  <div class="col-12 d-flex flex-column justify-content-center align-items-center promocionado px-0">
                    <div class="row justify-content-center row__width d-none">
                      <div class="col-12 d-flex justify-content-start align-items-center px-0">
                        <h6>{{$t('message.promoted')}}</h6>
                      </div>
                      <div class="col-12 d-flex justify-content-start align-items-center px-0">
                        <img src="../../img/carrito-icons/promocion.svg" alt="">
                        <h5>{{$t('message.codeApplied')}} <strong>lofd445644</strong></h5>
                      </div>
                    </div>
                    <DetallesCarritoV3 class="d-none d-lg-flex mt-lg-4"/>
                    <BTNPrimario :textoBTNPrimario="$t('message.finishBuy')" class="mt-5 d-none d-lg-flex" @click="registerCheckout()"/>
                    <CondicionesCheckout/>
                  </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script setup>
  import BTNPrimario from "../Botones/PrimarioCheckout.vue";
  import CondicionesCheckout from "../Condiciones/CondicionesCheckout.vue";
  import ItemsCarritoV2 from "../../components/Carrito/ItemsV2.vue";
  import DetallesCarritoV3 from "./DetallesV3.vue";
  import { ref, inject, provide } from 'vue';
  import axios from 'axios';
  import { storeToRefs } from "pinia"
  import { useCartStore } from "../../stores/cart"

  const carts = useCartStore();
  const { exchangeUpdate } = storeToRefs(carts);
  const {  } = carts

  const registerCheckout = inject('registerCheckout')

  let cart = inject('cart')

</script>

<style lang="scss" scoped>
.accordion{
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 20px;
  margin: 20px 0px;
  @media (min-width: 992px) {
    margin-top: 0;
  }
  .accordion-item{
    border-radius: 20px;
    @media (min-width: 992px) {
      padding: 0 20px;
    }
  }
  .accordion-item:first-of-type{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .accordion-item:last-of-type .accordion-button.collapsed{
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .accordion-item:last-of-type{
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .accordion-item:first-of-type .accordion-button{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .accordion-button:not(.collapsed){
    color: inherit;
    background-color: inherit;
    box-shadow: none;
  }
  .accordion-button:focus{
    outline: 0;
    border-color: inherit;
    box-shadow: none;
  }
  .accordion-button::after{
    background-image: url('../../img/home-icons/cursos-icons/arrow-abajo-negro.svg');
    background-size: 70%;
    background-position: center;
    width: 20px;
    height: 20px;
    display: none;
  }
  .accordion-button{
    font-weight: 600;
    font-size: 18px;
    color: var(--colorSecondary);
    padding-top: 30px;
  }
  .accordion-body{
    padding: 0 10px;
  }
}
.promocionado{
  padding: 20px 0px;
  h6{
    font-weight: 600;
    font-size: 18px;
    color: var(--colorSecondary);
    padding-bottom: 10px;
    text-align: left;
  }
  h5{
    font-weight: 400;
    font-size: 18px;
    color: #697891;
    padding-left: 7px;
    text-align: left;
    strong{
      font-size: 18px;
      color: #697891;
      text-align: left;
    }
  }
}
</style>