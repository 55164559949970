<template>
    <ul class="nav nav-tabs row justify-content-center row__width" id="myTab" role="tablist">
        <div class="col d-flex justify-content-start align-items-center">
                <linksTabsHomeCursos v-for="(link , id) in tabHomecursos" 
                :key="id" 
                :id="id"
                :titulo="tabHomecursos[id].titulo" 
                :active="tabHomecursos[id].active" 
                :display="tabHomecursos[id].displayM"
                :src="tabHomecursos[id].src"
                />
            
        </div>
        <div class="tab-content justify-content-center row__width px-0" id="myTabContent">
            <tabpaneHomeCursos/>
        </div>
    </ul>
</template>

<script setup>
    import linksTabsHomeCursos from "./LinksTabsHomeCursos.vue";
    import tabpaneHomeCursos from "./TabpaneHomeCursos.vue";
    import { useGeneralStore } from "../../stores/general"    
    import { storeToRefs } from "pinia";
    
    const general = useGeneralStore();
    const {tabHomecursos} = storeToRefs(general);
    const {} = general;
    
</script>

<style lang="scss" scoped>
.tabs{
    .tab-pane{
        padding: 0;
    }
    .detalle-tabs{
        .descripcion{
            padding: 0px 0px;
        }
            
    }
}
//.scroll{
//    @media (min-width: 992px) and (max-width: 1373px) {
//        max-width: 100%;
//        height: 65px;
//        overflow-x: scroll;
//        overflow-y: hidden;
//    }
//}

</style>