<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center items px-0">
        <ItemsCarritoV2 v-for="(item, index) in items" :titulo="items[index].titulo" :precioPromo="items[index].precioPromo" :precio="items[index].precio"/>               
    </div>
</template>

<script setup>
    import ItemsCarritoV2 from "./ItemsV2.vue";
    import { useGeneralStore } from "../../stores/general"    
    import { storeToRefs } from "pinia";
    
    const general = useGeneralStore();
    const {items} = storeToRefs(general);
    const {} = general;
</script>

<style lang="scss" scoped>

</style>