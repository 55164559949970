<template>
    <div class="row justify-content-start row__width">
        <div class="col-6 col-md-6 col-lg-3 max-width d-flex justify-content-center align-items-center ps-0 px-md-2" v-for="(modaliti, id) in listModalities">
            <div class="row justify-content-center row__width modalidad">
                <div class="col-12 px-0 d-flex justify-content-center align-items-start">
                    <input type="radio" :value="modaliti.id" :id="modaliti.name" name="modalities" v-model="modality" :checked="modaliti.name == modality">
                    <label :for="modaliti.name">
                        <div class="row justify-content-center row__width h-100">
                            <div class="col-10 d-flex justify-content-start align-items-center">
                                <div class="span"></div>
                                <h3>{{ modaliti.name }}</h3>
                            </div>
                            <div class="col-12 d-flex flex-column justify-content-center align-items-start px-1">
                                <h4>{{ modaliti.description }}</h4>
                            </div>
                            <div class="col-12 d-flex justify-content-center align-items-end">
                                <img :src="modaliti.icon" alt="">
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useUsersStore } from "../../../stores/user"    
import { storeToRefs } from "pinia";
import axios from 'axios';

const route = useRoute();
const router = useRouter();

const users = useUsersStore();
const { access } = storeToRefs(users);
const { url, validate } = users;

const urlFetchMod = '/api/categories/class/'

const listModalities = inject('listModalities')
let modality = inject('modality')
let type = inject('type')
//Actualiza las especialidades
const getMod = async(type) => {
    try {
        await axios.get(url+urlFetchMod+'es'+'/'+type).then((result) => {
            //console.log(result)
            listModalities.value = []
            if (result.status == 200){
                for(let i = 0; i < result.data.length; i++){
                    listModalities.value.push( result.data[i] )
                }
            }
        })
    } catch (error) {
        console.error(error)
    }
}
getMod(route.params.type)
type.value = route.params.type

</script>

<style lang="scss" scoped>
.modalidad{
    box-shadow: 2px 2px 10px #00000026;
    border-radius: 30px;
    height: auto;
    min-height: 310px;
    margin: 20px 0;
    padding: 20px 20px 0 20px;
    h3{
        font-weight: 700;
        font-size: 18px;
        color: #343A40;
        padding-left: 10px;
    }
    h4{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        margin: 10px 0 !important;
        text-align: left;
    }
    input{
        display: none;
    }
    label{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        cursor: pointer;
        height: 100%;
        .span{
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 2px solid #E4E4E4;
            width: 28px;
            height: 28px;
            left: 0;
            &::after{
                content: '';
                position: absolute;
                border-radius: 50%;
                background-color: var(--colorPrimary);
                width: 20px;
                height: 20px;
                display: none;
            }
        }
    }
    input:checked + label .span{
        border-color: var(--colorPrimary);
        &::after{
            display: block;
        }
    }
    img{
        max-width: 100%;
    }
    @media (max-width: 991px) {
        padding: 20px 10px 0 10px;
        h3{
            font-size: 16px;
        }
        h4{
            font-size: 14px;
            text-align: center;
        }
        .span{
            max-height: 22px;
            max-width: 22px;
            top: 5px;
            &:after{
                max-height: 14px;
                max-width: 14px;
            }
        }
        img{
            max-height: 120px;
        }
    }
}
.max-width{
    @media (max-width: 991px) {
        max-width: auto;
    }
    @media (min-width: 992px) {
        max-width: 28%;
    }
    @media (min-width: 1200px) {
        max-width: 20%;
    }
}
</style>