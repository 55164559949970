//Importo librerías y métodos

import { defineStore } from 'pinia'
import { ref, watchEffect, computed, onMounted, createApp, onBeforeMount } from 'vue'
import App from '@/App.vue'
import axios from "axios"
import { createI18n } from "vue-i18n";
import { useI18n } from "vue-i18n"

const es = ref('')
let langWeb = JSON.parse(localStorage.getItem('lang'))
if (langWeb == null){langWeb = "es", localStorage.setItem('lang', JSON.stringify(langWeb))}

const prueba2 = (async() =>{
    try {
        await axios.get(url+urlLanguage).then((result) => {
            return es.value = JSON.stringify(result.data.data[0].message)
            
            //console.log(es.value)
        })
    }
    catch (error) {
        console.log(error)
    }
})

export const useLanguageStore = defineStore('lang', () => {
    const {locale} = useI18n({ useScope: 'global' })
    // Declaro urls globales de APIS
    const url = process.env.VUE_APP_BASE_URL
    const apiObjetos = '/api/categories/types'
    const urlLanguage = '/api/lang/get/full'
    const urlLangList = '/api/lang/list'
    const urlSetLang = '/api/user/set/language'

    const imprimir = ref([])
    
    const setDefaultLang = ref({
        id: '',
        lang: ''
    })

    const listLangs = ref('')
    /*const langFilter = onMounted(async() =>{
        let objetosFiltrados = ''
        try {
            await axios.get(url+apiObjetos).then((result) => {
                let array = result.data
                array.forEach(function(elements) {
                    let arrayFiltrar = elements.translations
                    objetosFiltrados = arrayFiltrar.filter((elemento) => {
                        //console.log(elemento.language)
                        return elemento.language == langWeb.value
                    })
                    //console.log(objetosFiltrados)
                })
            })
            imprimir.value.push(objetosFiltrados)
            console.log(imprimir.value)
        }
        catch (error) {
            console.log(error)
        }
    })*/
    
    /*const prueba = onMounted(async() =>{
        try {
            await axios.get(url+apiObjetos+'/'+langWeb.value).then((result) => {
                console.log(url+apiObjetos+langWeb.value)
                console.log(result)
            })
        }
        catch (error) {
            console.log(error)
        }
    })*/
    
    const userId = ref('')

    const computedConsole = () =>{
        
    }
    const setListLang = onMounted(async() => {
        try {
            await axios.get(url+urlLangList).then((result) => {
                console.log(result)
                listLangs.value = result.data.data
            })
        } catch (error) {
            console.log(error)
        }
    })

    const setLang = async(lang) => {
        const token = JSON.parse(localStorage.getItem('token'))
        const headers = ref({'Authorization': `Bearer ${token}`})
        //console.log(headers.value)
        const newLang = lang
        localStorage.setItem('lang', JSON.stringify(newLang))
        if (!userId.value) {
            userId.value = JSON.parse(localStorage.getItem('idUser'))
        }
        locale.value = newLang
        //console.log(headers.value)
        if(userId.value != null){
            setDefaultLang.value.lang = newLang
            setDefaultLang.value.id = userId.value.id
            try {
                await axios.post(url+urlSetLang, setDefaultLang.value, {headers: headers.value}).then((result) => {
                    //console.log(result)
                })
            } catch (error) {
                console.log(error)
            }
        }
    }
    

    return {langWeb, imprimir, computedConsole, url, apiObjetos, urlLanguage, urlLangList, urlSetLang, setLang, setDefaultLang, listLangs}
})

export const i18n = new createI18n({
    /*locale: window.navigator.language.split('-')[0],*/
    locale: langWeb,
    legacy: false,
    messages: {
        en:{
          message: require('../../public/locales/en.json')
          
        },
        es:{
          message: require('../../public/locales/es.json')
        },
    },
})



createApp(App).use(i18n)