<template>
    <div class="col d-flex flex-column justify-content-center align-items-start mt-3 skills colaborators px-0">
        <h3>{{ $t('message.produceBy') }}</h3>
        <multiselect2 
        v-model="information.producer"
        :value="information.producer"
        :close-on-select="true"
        :searchable="false"
        :create-option="false"
        :options="listProducers"
        :disabled="idUser.team == 3"
        label="es" trackBy="es" valueProp="id" :placeholder="$t('message.select')" :noResultsText="$t('message.withoutResults')"/>
        <div class="row justify-content-start align-items-center error d-none" id="producer-empty">
            <p>Por favor, seleccione un marketplace</p>
        </div>
    </div>
    
</template>

<script setup>
import Multiselect2 from '@vueform/multiselect'
import { useGeneralStore } from "../../../stores/general"
import { inject } from "vue"
import { useUsersStore } from "../../../stores/user"
import { storeToRefs } from "pinia"
import axios from 'axios';

const users = useUsersStore()
const { idUser } = storeToRefs(users)
const { url } = users

//Marketplaces
const urlFetchMarketplace = '/api/marketplaces/'

const information = inject('information')
const listProducers = inject('listProducers')

//Recibe los marketplaces
const fetchMarketplace = async() => {
    let idUser = JSON.parse(localStorage.getItem('idUser'))
    let isProf = ''
    if (idUser.team == 3){
        isProf = '/p'
    }
    else {
        isProf = '/a'
    }
    try {
        await axios.get(url+urlFetchMarketplace+'es'+isProf).then((result) => {
            console.log(result)
            listProducers.value = []
            if (result.status == 200){
                if(isProf == '/p'){
                    information.value.producer = result.data[0].id
                }
                for(let i = 0; i < result.data.length; i++){
                    listProducers.value.push( result.data[i] )
                }
            }
        })
    } catch (error) {
        console.error(error)
    }
}
fetchMarketplace()
 
</script>

<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: #FCFCFC;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    color: #697891;
    font-size: 15px;
    font-weight: 400;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
.valido{
    background-image: url('../../../img/panel-icons/input-valido.svg');
    background-repeat: no-repeat;
    background-position: 98% center;
}
.error{
    padding: 10px 0;
    p{
        color: #FF6A6A;
        font-size: 16px;
        font-weight: 400;
    }
}
</style>