<template>
    <HeaderTitulo class="d-none d-lg-flex" :tituloHeader="$t('message.HomePresets')"/>
    <div class="row justify-content-center">
        <div class="col-12 col-lg-11 d-flex flex-column justify-content-center align-items-center px-0">
            <div class="row justify-content-center justify-content-lg-between row__width">
                <div class="col-12 col-lg-7 d-flex flex-column justify-content-center align-items-center px-0  position-relative">
                    <ProgresoCarrito :uno="true" :dos="true" :tres="true" />
                </div>
                <div class="col-12 d-flex justify-content-center align-items-center px-0  position-relative">
                    <TituloCarrito :oculto="true" :titulo="$t('message.titleCart')"/>
                </div>
                <div class="col-12 col-lg-7 d-flex flex-column justify-content-start align-items-center px-0  position-relative">
                    <div class="row justify-content-center row__width">
                        <div class="col-11 col-lg-12 d-flex flex-column justify-content-center align-items-center px-0">
                            <MetodosDePago/>
                            <FooterCarrito class="d-none d-lg-flex"/>
                            <ResumenCestaCheckout class="d-lg-none"/>
                            <DetallesCarritoV3 class=" d-lg-none"/>
                            <BTNPrimario :textoBTNPrimario="$t('message.finishBuy')" class="mt-5 d-lg-none" @click="payMP()"/>
                            <CondicionesCheckout class="d-lg-none"/>
                            <FooterCarrito class="d-lg-none"/>
                        </div>
                    </div>
                </div>
                <div class="col-4 col-xxl max-width d-none d-lg-flex justify-content-center align-items-start px-0">
                    <div class="box-shadow row__width">
                        <ResumenCestaCheckout class="d-none d-lg-flex"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import BTNPrimario from "../../components/Botones/Primario.vue";
import DetallesCarritoV3 from "../../components/Carrito/DetallesV3.vue";
import ProgresoCarrito from "../../components/Carrito/Progreso.vue";
import FooterCarrito from "../../components/Footer/FooterCarrito.vue";
import TituloCarrito from "../../components/Titulo/TituloCarrito.vue";
import ResumenCestaCheckout from "../../components/Carrito/ResumenCestaCheckout.vue";
import CondicionesCheckout from "../../components/Condiciones/CondicionesCheckout.vue";
import HeaderTitulo from "../../components/Titulo/HeaderTitulo.vue";
import MetodosDePago from "../../components/Carrito/MetodosDePago.vue";
import { ref, inject, provide } from 'vue';
import axios from 'axios';
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../stores/user";
import { useCartStore } from "../../stores/cart"
import { useConfigStore } from "../../stores/config";

const users = useUsersStore();
const {  } = storeToRefs(users);
const { url } = users;

const carts = useCartStore();
const { cart } = storeToRefs(carts);
const {  } = carts

const config = useConfigStore();
const { coinSelected} = storeToRefs(config);

const urlBuyProd = '/api/generate/payment/link/'

let selectedMethod = ref()

provide('selectedMethod', selectedMethod)

const props = defineProps({
    pagoSeguro: Boolean
})

//Funcion de inicializacion de pago
const payMP = async() => {
    let params = ref()
    let platform = selectedMethod.value // 5 = MERCADOPAGO
    let paymentType = 'one_time'
    let billingCicle = 'null'
    let quantity = 1
    let currency = coinSelected.value
    let coupon = null
    let payer = ''
    params.value = {
        platform: platform,
        payment_type: paymentType,
        billing_cicle: billingCicle,
        currency: currency,
        products: [],
        payer: payer
    }
    cart.value.forEach(element => {
        params.value.products.push({
            id: element.productId,
            price_id: element.price.id,
            quantity: quantity,
            coupon: coupon
        })
        console.log(params.value)
    })
    console.log(params.value)
    const idData = JSON.parse(localStorage.getItem('idData'))
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.post(url+urlBuyProd+idData.id+'/0', params.value, {headers: headers.value}).then((result) => {
            console.log(result)
            window.location.href = result.data.preference_init_point
        })
    } catch (error) {
        console.log(error)
    }
}

provide('payMP', payMP)

</script>

<style lang="scss" scoped>

p{
    font-weight: 500;
    font-size: 16px;
    color: #697891;
    padding: 10px 0px;
    margin-bottom: 30px;
}
@media (min-width: 1024px){
    .max-width{
        max-width: 33%;
        margin-top: 40px;
    }
}
@media (min-width: 1440px){
    .max-width{
        max-width: 34%;
    }
}
</style>