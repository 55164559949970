<template>
    <div class="col-12 col-lg-11 col-lg-11 d-flex flex-column justify-content-center align-items-center">
        <div class="row justify-content-center row__width">
            <SobreV2    :userName="userName"
                        :userAbout="userAbout"
                        :courses="courses"
                        :avatar="avatar"
                        :gallery="gallery"
                        :courses_uploaded="courses_uploaded"
                        :images_uploaded="images_uploaded" 
                        v-if="actual_tab == 1"
                        />
                       
            <div class="col-12 px-0" v-if="actual_tab == 2">
                <div class="row justify-content-center justify-content-lg-between div__slider cambio__Desk">
                    <div class="col-12 col-lg-5 d-flex justify-content-center align-items-start  mt-lg-4 d-none">
                        <TipoCurso/>
                    </div>
                    <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center tablas2 px-0 my-4 d-none">
                        <div class="scroll2 d-flex justify-content-start align-items-center">
                            <ul class="nav nav-tabs row justify-content-start" id="myTab" role="tablist">
                                <li class="nav-item col col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                    <button class="nav-link active" id="todos-tab" data-bs-toggle="tab" data-bs-target="#todos" type="button" role="tab" aria-controls="todos" aria-selected="true">{{ $t('message.all') }}</button>
                                </li>
                                <li class="nav-item col col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                    <button class="nav-link" id="online-tab" data-bs-toggle="tab" data-bs-target="#online" type="button" role="tab" aria-controls="online" aria-selected="false">Online</button>
                                </li>
                                <li class="nav-item col col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                    <button class="nav-link" id="presencial-tab" data-bs-toggle="tab" data-bs-target="#presencial" type="button" role="tab" aria-controls="presencial" aria-selected="false">{{ $t('message.inPerson') }}</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <PerfilGaleria :gallery="gallery"/>
                    <!-- <div class="col-12 d-flex justify-content-center justify-content-lg-start align-items-center px-0 botones mt-4">
                        <button id="prevSliderMarca"><img src="../../img/home-icons/arrow-left-negra.svg" alt=""></button>
                        <div><h4>01/</h4><h5>10</h5></div>
                        <button id="nextSliderMarca"><img src="../../img/home-icons/arrow-rigth-negro.svg" alt=""></button>
                    </div> -->
                </div>
            </div>
            <div class="col-12" v-if="actual_tab == 3">
                <div class="row justify-content-center">
                    <div class="col-12 d-flex justify-content-center align-items-start div__slider cambio__Desk mt-lg-4">
                        <div class="row justify-content-center row__width">
                            <div class="col-10 d-flex justify-content-center align-items-start">
                                <div class="row justify-content-start row__width">
                                    <Valoraciones/>
                                    <TituloHomeCursos :tituloCurso="$t('message.opinionsCourse')" class="px-0"/>
                                    <Opinion/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12" v-if="actual_tab == 4">
                <div class="row justify-content-center d-none">
                    <ServiciosV2/>
                    <div class="col-12 d-flex justify-content-center align-items-start div__slider cambio__Desk mt-lg-4">
                        <div class="row justify-content-center row__width">
                            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                <div class="row justify-content-center justify-content-lg-between row__width">
                                    <div class="col-12  d-flex flex-column justify-content-center  align-items-start mb-4 m-lg-0 px-0">
                                        <div class="row justify-content-center row__width">
                                            <div class="col d-flex flex-column justify-content-center align-items-start">
                                                <h2>Asesorias</h2>
                                                <h3 class="d-none d-lg-flex">Desde 50USD</h3>
                                            </div>
                                            <div class="col d-flex justify-content-end align-items-center botones px-0">
                                                <div class="d-none d-lg-flex"><h4>01/</h4><h5>10</h5></div>
                                                <button id="prevSliderMarca"><img src="../../img/home-icons/arrow-left-negra.svg" alt=""></button>
                                                <button id="nextSliderMarca"><img src="../../img/home-icons/arrow-rigth-negro.svg" alt=""></button>
                                            </div>
                                        </div>
                                        <h3 class="d-lg-none">Desde 50USD</h3>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-center align-items-center">
                                <AsesoriasServicios/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center align-items-start div__slider cambio__Desk mt-lg-4">
                        <div class="row justify-content-center row__width">
                            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                <div class="row justify-content-center justify-content-lg-between row__width">
                                    <div class="col-12 col-lg-7 d-flex flex-column justify-content-center  align-items-start mb-4 m-lg-0 px-0">
                                        <h2>Cursos</h2>
                                        <h3>Tiene 200 {{ $t('message.productsV2') }}</h3>
                                    </div>
                                    <FiltrosYBuscadorCursosV2 class="d-lg-none"/>
                                    <div class="col-lg-5 d-flex justify-content-center align-items-center px-0 d-none d-lg-flex">
                                        <div class="row justify-content-end row__width">
                                            <div class="col-6 d-flex justify-content-end align-items-center botones px-0">
                                                <div><h4>01/</h4><h5>10</h5></div>
                                                <button id="prevSliderMarca"><img src="../../img/home-icons/arrow-left-negra.svg" alt=""></button>
                                                <button id="nextSliderMarca"><img src="../../img/home-icons/arrow-rigth-negro.svg" alt=""></button>
                                            </div>
                                            <div class="col-3 d-flex justify-content-end align-items-center buttons px-0">
                                                <button class="buscar"><img src="../../img/home-icons/lupa.svg" alt=""></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas2 px-0 my-4">
                                <div class="scroll2 d-flex justify-content-start align-items-center">
                                    <ul class="nav nav-tabs row justify-content-start" id="myTab" role="tablist">
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link active" id="todos-tab" data-bs-toggle="tab" data-bs-target="#todos" type="button" role="tab" aria-controls="todos" aria-selected="true">{{ $t('message.all') }}</button>
                                        </li>
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link" id="online-tab" data-bs-toggle="tab" data-bs-target="#online" type="button" role="tab" aria-controls="online" aria-selected="false">Online</button>
                                        </li>
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link" id="presencial-tab" data-bs-toggle="tab" data-bs-target="#presencial" type="button" role="tab" aria-controls="presencial" aria-selected="false">{{ $t('message.inPerson') }}</button>
                                        </li>
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link" id="vivo-tab" data-bs-toggle="tab" data-bs-target="#vivo" type="button" role="tab" aria-controls="vivo" aria-selected="false">Vivo</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12  d-flex justify-content-center align-items-center px-0">
                                <div class="row justify-content-center row__width" id="myTabContent3">
                                    <div class="col-12 tab-pane fade show active" id="todos" role="tabpanel" aria-labelledby="todos-tab">
                                        <Cursos/>
                                    </div>
                                    <div class="col-12 tab-pane fade" id="online" role="tabpanel" aria-labelledby="online-tab">
                                        <Cursos/>
                                    </div>
                                    <div class="col-12 tab-pane fade" id="presencial" role="tabpanel" aria-labelledby="presencial-tab">
                                        <Cursos/>
                                    </div>
                                    <div class="col-12 tab-pane fade" id="vivo" role="tabpanel" aria-labelledby="vivo-tab">
                                        <Cursos/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-center justify-content-lg-start align-items-center px-0 botones mt-4 d-lg-none">
                                <button id="prevSliderMarca"><img src="../../img/home-icons/arrow-left-negra.svg" alt=""></button>
                                <div><h4>01/</h4><h5>10</h5></div>
                                <button id="nextSliderMarca"><img src="../../img/home-icons/arrow-rigth-negro.svg" alt=""></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center align-items-start div__slider cambio__Desk mt-lg-4">
                        <div class="row justify-content-center row__width">
                            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                <div class="row justify-content-center justify-content-lg-between row__width">
                                    <div class="col-12 col-lg-7 d-flex flex-column justify-content-center  align-items-start mb-4 m-lg-0 px-0">
                                        <h2>{{ $t('message.productsV2') }}</h2>
                                        <h3>Tiene 200 {{ $t('message.productsV2') }}</h3>
                                    </div>
                                    <FiltrosYBuscadorCursosV2 class="d-lg-none"/>
                                    <div class="col-lg-5 d-flex justify-content-center align-items-center px-0 d-none d-lg-flex">
                                        <div class="row justify-content-end row__width">
                                            <div class="col-6 d-flex justify-content-end align-items-center botones px-0">
                                                <div><h4>01/</h4><h5>10</h5></div>
                                                <button id="prevSliderMarca"><img src="../../img/home-icons/arrow-left-negra.svg" alt=""></button>
                                                <button id="nextSliderMarca"><img src="../../img/home-icons/arrow-rigth-negro.svg" alt=""></button>
                                            </div>
                                            <div class="col-3 d-flex justify-content-end align-items-center buttons px-0">
                                                <button class="buscar"><img src="../../img/home-icons/lupa.svg" alt=""></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas2 px-0 my-4">
                                <div class="scroll2 d-flex justify-content-start align-items-center">
                                    <ul class="nav nav-tabs row justify-content-start" id="myTab" role="tablist">
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link active" id="todos-tab" data-bs-toggle="tab" data-bs-target="#todos" type="button" role="tab" aria-controls="todos" aria-selected="true">{{ $t('message.all') }}</button>
                                        </li>
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link" id="online-tab" data-bs-toggle="tab" data-bs-target="#online" type="button" role="tab" aria-controls="online" aria-selected="false">Online</button>
                                        </li>
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link" id="presencial-tab" data-bs-toggle="tab" data-bs-target="#presencial" type="button" role="tab" aria-controls="presencial" aria-selected="false">{{ $t('message.inPerson') }}</button>
                                        </li>
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link" id="vivo-tab" data-bs-toggle="tab" data-bs-target="#vivo" type="button" role="tab" aria-controls="vivo" aria-selected="false">Vivo</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12  d-flex justify-content-center align-items-center">
                                <div class="row justify-content-center row__width" id="myTabContent3">
                                    <div class="col-12 tab-pane fade show active" id="todos" role="tabpanel" aria-labelledby="todos-tab">
                                        <ProductosPerfilUsuario/>
                                    </div>
                                    <div class="col-12 tab-pane fade" id="online" role="tabpanel" aria-labelledby="online-tab">
                                        <ProductosPerfilUsuario/>
                                    </div>
                                    <div class="col-12 tab-pane fade" id="presencial" role="tabpanel" aria-labelledby="presencial-tab">
                                        <ProductosPerfilUsuario/>
                                    </div>
                                    <div class="col-12 tab-pane fade" id="vivo" role="tabpanel" aria-labelledby="vivo-tab">
                                        <ProductosPerfilUsuario/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-center justify-content-lg-start align-items-center px-0 botones mt-4 d-lg-none">
                                <button id="prevSliderMarca"><img src="../../img/home-icons/arrow-left-negra.svg" alt=""></button>
                                <div><h4>01/</h4><h5>10</h5></div>
                                <button id="nextSliderMarca"><img src="../../img/home-icons/arrow-rigth-negro.svg" alt=""></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center align-items-start div__slider cambio__Desk mt-lg-4">
                        <div class="row justify-content-center row__width">
                            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                <div class="row justify-content-center justify-content-lg-between row__width">
                                    <div class="col-12  d-flex flex-column justify-content-center  align-items-start mb-4 m-lg-0 px-0">
                                        <div class="row justify-content-center row__width">
                                            <div class="col d-flex flex-column justify-content-center align-items-start">
                                                <h2>Sesión de fotos</h2>
                                                <h3 class="d-none d-lg-flex">Desde 50USD</h3>
                                            </div>
                                            <div class="col d-flex justify-content-end align-items-center botones px-0">
                                                <div class="d-none d-lg-flex"><h4>01/</h4><h5>10</h5></div>
                                                <button id="prevSliderMarca"><img src="../../img/home-icons/arrow-left-negra.svg" alt=""></button>
                                                <button id="nextSliderMarca"><img src="../../img/home-icons/arrow-rigth-negro.svg" alt=""></button>
                                            </div>
                                        </div>
                                        <h3 class="d-lg-none">Desde 50USD</h3>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-center align-items-center">
                                <AsesoriasServicios/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 px-0" v-if="actual_tab == 5">
                <div class="row justify-content-center justify-content-lg-between div__slider cambio__Desk d-none">
                    <div class="col-12 d-flex justify-content-center align-items-start div__slider cambio__Desk mt-lg-4">
                        <div class="row justify-content-center row__width">
                            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                <div class="row justify-content-center justify-content-lg-between row__width">
                                    <div class="col-12 col-lg-7 d-flex flex-column justify-content-center  align-items-start mb-4 m-lg-0 px-0">
                                        <h2>Cursos</h2>
                                        <h3>Tiene 200 {{ $t('message.productsV2') }}</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas2 px-0 my-4">
                                <div class="scroll2 d-flex justify-content-start align-items-center">
                                    <ul class="nav nav-tabs row justify-content-start" id="myTab" role="tablist">
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link active" id="todos-tab" data-bs-toggle="tab" data-bs-target="#todos" type="button" role="tab" aria-controls="todos" aria-selected="true">{{ $t('message.all') }}</button>
                                        </li>
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link" id="online-tab" data-bs-toggle="tab" data-bs-target="#online" type="button" role="tab" aria-controls="online" aria-selected="false">Online</button>
                                        </li>
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link" id="presencial-tab" data-bs-toggle="tab" data-bs-target="#presencial" type="button" role="tab" aria-controls="presencial" aria-selected="false">{{ $t('message.inPerson') }}</button>
                                        </li>
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link" id="vivo-tab" data-bs-toggle="tab" data-bs-target="#vivo" type="button" role="tab" aria-controls="vivo" aria-selected="false">Vivo</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12  d-flex justify-content-center align-items-center">
                                <div class="row justify-content-center row__width" id="myTabContent3">
                                    <div class="col-12 tab-pane fade show active" id="todos" role="tabpanel" aria-labelledby="todos-tab">
                                        <Beneficios/>
                                    </div>
                                    <div class="col-12 tab-pane fade" id="online" role="tabpanel" aria-labelledby="online-tab">
                                        <Beneficios/>
                                    </div>
                                    <div class="col-12 tab-pane fade" id="presencial" role="tabpanel" aria-labelledby="presencial-tab">
                                        <Beneficios/>
                                    </div>
                                    <div class="col-12 tab-pane fade" id="vivo" role="tabpanel" aria-labelledby="vivo-tab">
                                        <Beneficios/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-center justify-content-lg-start align-items-center px-0 botones mt-4">
                                <button id="prevSliderBeneficios"><img src="../../img/home-icons/arrow-left-negra.svg" alt=""></button>
                                <div><h4>01/</h4><h5>10</h5></div>
                                <button id="nextSliderBeneficios"><img src="../../img/home-icons/arrow-rigth-negro.svg" alt=""></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import FiltrosYBuscadorCursosV2 from "../Filtros/FiltrosYBuscadorCursosV2.vue";
    import productosMarcasV3 from "../Sliders/ProductosMarcasV3.vue";
    import AsesoriasServicios from "../Sliders/AsesoriasServicios.vue";
    import SobreV2 from "./Marca/SobreV2.vue";
    import TipoCurso from "../Tipos/TipoCurso.vue";
    import PerfilGaleria from "../Sliders/PerfilGaleria.vue";
    import Valoraciones from "../Cursos/Valoraciones.vue";
    import TituloHomeCursos from "../Titulo/TituloHomeCursos.vue";
    import Opinion from "./Opiniones.vue";
    import ServiciosV2 from "./Marca/Secciones/ServiciosV2.vue";
    import Beneficios from "../Panel/Productos/Beneficios.vue";
    import Cursos from "../../components/Sliders/CursosOfrecidos.vue";
	import { useGeneralStore } from "../../stores/general"
    import { storeToRefs } from "pinia";
    import ProductosPerfilUsuario from "../Sliders/ProductosPerfilUsuario.vue";
import { inject } from "vue";

    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;

    const props = defineProps({
        userName: '',
        userAbout: '',
        courses_uploaded: '',
        images_uploaded: '',
        courses: {
            type: Object
        },
        avatar: '',
        gallery:{
            type: Object
        },
        cv: ''
    })

    const actual_tab = inject('actual_tab')
</script>

<style lang="scss" scoped>
.sobre_marca{
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    padding: 15px 5px;
    h2{
        font-weight: 700;
        font-size: 25px;
        color: var(--colorSecondary);
        text-align: left;
        padding-bottom: 5px;
    }
    h3{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
        padding: 5px 0;
    }
    h4{
        font-weight: 700;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
        padding-left: 5px;
        
    }
    .scroll__y{
        max-height: 10vh;
        overflow-y: scroll;
    }
    @media (min-width: 992px) {
        padding: 20px 30px;
        .scroll__y{
            max-height: none;
        }
        h3{
            padding: 10px 0 20px 0;
        }
    }
}
.div__slider{
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 15px;
    background-color: #FFFFFF;
    min-height: 20vh;
    padding: 20px 10px;
    margin: 20px 0;
    h2{
        font-weight: 700;
        font-size: 25px;
        color: var(--colorSecondary);
        text-align: left;
    }
    h3{
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
        padding-top: 10px;
    }
    .botones{
        button{
            background-color: var(--bgPrincipal);
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #F5F5F5;
            border-radius: 21px;
            width: 42px;
            height: 42px;
            margin: 0 3px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        h4, h5{
            font-weight: 700;
            font-size: 18px;
            color: var(--colorSecondary);
            text-align: center;
            padding: 0 3px;
        }
        h5{
            font-weight: 400;
        }
        div{
            width: min-content;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 10px;
        }
    }
    h6{
        font-weight: 500;
        font-size: 16px;
        color: #92A3C5;
        text-align: left;
        padding-top: 10px;
    }
    h4{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary);
        text-align: left;
        padding-top: 25px;
    }
}
.div__slider2{
    h2{
        font-weight: 700;
        font-size: 25px;
        color: var(--colorSecondary);
        text-align: left;
    }
    .botones{
        button{
            background-color: var(--bgPrincipal);
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #F5F5F5;
            border-radius: 21px;
            width: 42px;
            height: 42px;
            margin: 0 3px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
.cambio__Desk{
    @media (min-width: 992px) {
        padding: 30px;
    }
}
.buttons{
    button{
        border-radius: 50%;
        border: 0;
        box-shadow: 0px 3px 6px #00000029;
        height: 45px;
        width: 45px;
        margin-left: 10px;
        background-color: var(--colorPrimary);
    }
    .buscar{
        img{
            height: 25px;
        }
    }
}
.tablas2{  
    min-height: 56px;
    margin-bottom: 30px;
    .nav-link{
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        color: #B8C1DB;
        position: relative;
        padding: 10px 0;
        border: 0;
        @media (min-width: 992px) {
            color: #0E1133;
        }
    }
    .nav-link.active{
        font-weight: 700;
        border: 0;
        color: var(--colorSecondary);
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 5px;
            bottom: 0;
            left: 0;
            background-color: var(--colorPrimary);
            
        }
        @media (min-width: 992px) {
            &::before{
                height: 4px;
            }   
        }
    }
    .scroll2{
        overflow-x: scroll;
        overflow-y: hidden;
        max-width: 100%;
        height: 100%;
        background-color: #fff;
        box-shadow: 0px 3px 10px #00000029;
        border-radius: 10px;
        ul{
            min-width: 270vw;
            height: 100%;
            button{
                height: 100%;
                min-width: 150px;
            }
        }
        @media (min-width: 992px) {
            overflow: hidden;
            max-width: none;
            width: 100%;
            ul{
                max-width: 100%;
                min-width: 1vw;
                width: 100%;
            }
        }
    }
}
.swiper-pagination{
    margin-top: 5px !important;
}
</style>