<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center mt-5 border-top">
        <div class="row justify-content-between row__width">
            <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center px-0">
                <div class="row justify-content-between justify-content-lg-start row__width">
                    <div class="col-5 d-flex justify-content-center  align-items-center ps-0">
                        <Cancelar @click="back"/>
                    </div>
                    <div class="col-7 d-flex justify-content-center align-items-center pe-0">
                        <button id="save-button" ref="buttonRef" type="button" class="btn_save" @click="save">
                            {{$t('message.saveAndBack')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Cancelar from "../../components/Botones/Cancelar.vue";
import { inject, ref } from 'vue';
import axios from 'axios';
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../stores/user";

const users = useUsersStore();
const { access } = storeToRefs(users);
const { url, validate } = users;

//Guarda la configuracion
const urlSaveLessonConfig = '/api/product/creating/module/lesson/config/save'
const urlSavePrice = '/api/product/creating/prices/'

let product = inject('product')
let precification = inject('precification')

let actualActivity = inject('actualActivity')

let editTask = inject('editTask')
let editQuest = inject('editQuest')
let viewEditPrice = inject('viewEditPrice')
let copyActualActivity = inject('copyActualActivity')
let currentItem = inject('currentItem')
let currentId = inject('currentId')

let tabRef = inject('tabRef')
let titleRef = inject('titleRef')
let descRef = inject('descRef')
let qShownRef = inject('qShownRef')
let titleRefError = inject('titleRefError')
let descRefError = inject('descRefError')
let qShownRefError = inject('qShownRefError')

let owner_id = inject('owner_id')
let product_id = inject('product_id')

let buttonRef = ref()
let hasError = ref(false)

const toggleClass = (elements) => {
    elements.forEach(element => {
        console.log(elements)
        console.log(element)
        let ref = element.ref.value
        element.add.forEach(cls => ref.classList.add(cls))
        element.remove.forEach(cls => ref.classList.remove(cls))
    })
}
const toggleError = (errors) => {
    errors.forEach(error => {
        const element = document.getElementById(error.elementId)
        const inputElement = document.getElementById(error.inputElementId)
        const isValid = error.isValid

        element.classList.toggle('d-none', isValid)
        inputElement.classList.toggle('error', !isValid)

        if (!isValid) {
            hasError.value = true
            console.log('!isValid')
        }
    })
}
const save = () => {
    switch(actualActivity.value.lessonType) {
        case 1: break
        case 2: saveLessonQuiz(); break
        default: savePrice()
    }
    editTask.value = false
    viewEditPrice.value = false
}
const back = () => {
    hasError.value = false
    editTask.value = false;
    editQuest.value = false;
    viewEditPrice.value = false;
    currentItem.value[currentId.value] = copyActualActivity.value;
    actualActivity.value = copyActualActivity.value;
};

const saveLessonQuiz = async() => {
    hasError.value = false
    console.log(actualActivity.value);
    if(actualActivity.value.title && actualActivity.value.description && actualActivity.value.questionsShown >= 1){
        const token = JSON.parse(localStorage.getItem('token'))
        const headers = ref({'Authorization': `Bearer ${token}`})
        actualActivity.value.lang = 'es'
        let { config, ...rest } = actualActivity.value
        let newObj = { ...rest, ...config }
        newObj.owner_id = owner_id.value
        newObj.product_id = product_id.value
        try {
            console.log(actualActivity.value)
            await axios.post(url+urlSaveLessonConfig, newObj, {headers: headers.value}).then((result) => {
                console.log(result)
                actualActivity.value.lessonTransId = result.data.lessonTransId
            })
        } catch (error) {
            console.error(error)
        }
        editQuest.value = false
        toggleClass([
            {ref: titleRefError, add: ['d-none'], remove: []},
            {ref: descRefError, add: ['d-none'], remove: []},
            {ref: qShownRefError, add: ['d-none'], remove: []},
            {ref: titleRef, add: [], remove: ['error']},
            {ref: descRef, add: [], remove: ['error']},
            {ref: qShownRef, add: [], remove: ['error']},
            {ref: tabRef, add: [], remove: ['error-btn']},
            {ref: buttonRef, add: [], remove: ['error-btn']}
        ])
    }
    else {
        editQuest.value = true
        hasError.value = false

        toggleError([
            { elementId: 'title-empty', inputElementId: 'title-input-empty', isValid: actualActivity.value.title },
            { elementId: 'description-empty', inputElementId: 'description-textarea-empty', isValid: actualActivity.value.description },
            { elementId: 'questionShown-empty', inputElementId: 'questionShown-input-empty', isValid: actualActivity.value.questionsShown >= 1 }
        ])

        let tabButton = document.getElementById('info2-tab')
        tabButton.classList.toggle('error-btn', hasError)
        buttonRef.value.classList.toggle('error-btn', hasError)
    }
}

const savePrice = async() => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    console.log(actualActivity.value)
    if(actualActivity.value.coin.id){
        actualActivity.value.coin = actualActivity.value.coin.id
    }
    try {
        await axios.post(url+urlSavePrice+product.value.id, actualActivity.value, {headers: headers.value}).then((result) => {
            console.log(result)
            console.log(actualActivity.value)
        })
    } catch (error) {
        console.error(error)
    }
}
</script>

<style lang="scss" scoped>
.btn_save{
    background-color: var(--colorPrimary);
    color: var(--bgPrincipal);
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border-radius: 27px;
    width: 100%;
    text-decoration: none;
    min-height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
}
.border-top{
    border-top: 2px solid #E4E4E4;
    padding-top: 20px;
}
.error-btn{
    background-color: var(--colorRed) !important;
    border: 2px solid var(--colorRed) !important;        
}
</style>