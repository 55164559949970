<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center valoraciones">
        <div class="row justify-content-center row__width">
            <div class="col-12 col-lg-3 d-flex flex-column justify-content-center align-items-center">
                <h2>{{$t('message.numRatingCourse')}}</h2>
                <div class="d-flex justify-content-center align-items-center pb-3">
                    <img src="../../img/home-icons/cursos-icons/rateV2.svg" alt="" v-for="(rate, id) in rates">
                </div>
                <h3>{{$t('message.ratingCourse')}}</h3>
            </div>
            <div class="col-12 col-lg-9 d-flex flex-column justify-content-center align-items-center px-0">
                <div class="row justify-content-between align-items-center row__width" v-for="(progreso, id) in progresos">
                    <div class="progress col-10 d-flex justify-content-start align-items-center px-0">
                        <div class="progress-bar" role="progressbar" :style="`width:`+progresos[id].progreso+`%;`" style="height: 100%; border-radius: 50px;" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="col-2 d-flex justify-content-end align-items-center px-0">
                        <p>{{progresos[id].titulo}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue'

    const progresos = ref([
        {progreso: 90, titulo: '90%'},
        {progreso: 10, titulo: '10%'},
        {progreso: 0, titulo: '0%'},
        {progreso: 0, titulo: '0%'},
    ])

    const rates = ref([
        {},
        {},
        {},
        {},
        {},
    ])
</script>


<style lang="scss" scoped>
.valoraciones{
    h2{
        font-weight: 700;
        font-size: 50px;
        color: var(--colorSecondary);
        padding: 5px 0px;
    }
    h3{
        font-weight: 400;
        font-size: 16px;
        color: #B8C1DB;
    }
    img{
        padding: 0 2px;
    }
    p{
        font-weight: 400;
        font-size: 16px;
        color: #B8C1DB;
    }
    .progress{
        height: 10px;
    }
    @media (min-width: 992px) {
        margin: 20px 0 50px 0;
        h2{
            color: #374557;
        }
    }
}
</style>