<template>
    <div class="row justify-content-center row__width">
        <div class="col-12 d-flex flex-column justify-content-center align-items-center boder-top">
            <div class="row justify-content-center row__width">
                <div class="col-12 d-flex justify-content-center align-items-center addReferrals px-0 mt-4">
                    <div class="row justify-content-center row__width">
                        <div class="col-12 d-flex justify-content-start align-items-center mb-3">
                            <button class="btn_none px-0 d-flex justify-content-center align-items-center pe-3" @click.prevent="setView(0)">
                                <img src="../../../../img/home-icons/arrow-left-negra.svg" alt="">
                            </button>
                            <h4>{{ $t('message.manageDefaultFAQ') }}</h4>
                        </div>
                    </div>  
                </div>
                <h1>Preguntas frecuentes</h1>
                <!--- COLLAPSE PADRE --->
                <div class="col-12 d-flex flex-column justify-content-center align-items-center collapses px-0">
                    <div class="accordion d-flex flex-column justify-content-center align-items-center" id="accordionFather">
                        <!--- PRODUCTOS --->
                        <div class="accordion-item row row__width justify-content-center" v-for="(type, id) in listTypes">
                            <h2 class="accordion-header px-0" id="heading">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseFAQ'+type.name" aria-expanded="false" :aria-controls="'collapseFAQ'+type.name">
                                    <span>
                                        <h3 class="question">?</h3>
                                    </span>
                                    <div class=" d-flex flex-column justify-content-center align-items-start ps-4">
                                        <h3>{{ type.name }}</h3>
                                        <h4>{{ $t('message.setUpCoursesFAQ') }}{{ type.name }}</h4>
                                    </div>
                                </button>
                            </h2>
                            <div :id="'collapseFAQ'+type.name" class="col-12 accordion-collapse collapse" aria-labelledby="heading">
                                <div class="accordion-body row justify-content-center px-0">
                                    <!--- COLLAPSE PREGUNTAS --->
                                    <div class="row justify-content-start row__width">
                                        <!--- TITULO Y AGREGAR --->
                                        <div class="col-12 d-flex justify-content-center align-items-center px-0 mt-4" >
                                            <div class="row justify-content-center row__width">
                                                <div class="col-12 col-lg-8 d-flex flex-column justify-content-center align-items-start px-0">
                                                    <h3>Modalidades</h3>
                                                </div>
                                                <div class="col-12 col-lg-4 d-flex  justify-content-center align-items-start px-0">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <!--- PRREGUNTA --->
                                        <div class="accordion d-flex flex-column justify-content-center align-items-center collapses2" id="accordionExample2">
                                            <div class="accordion-item row row__width justify-content-center align-items-start" v-for="(modality, id2) in type.modalities">
                                                <h2 class="accordion-header px-0 justify-content-center" id="headingFAQ">
                                                    <p class="accordion-button collapsed position-relative" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseFAQS'+type.name+modality.id" aria-expanded="false" :aria-controls="'collapseFAQS'+type.name+modality.id">
                                                        <div class=" d-flex flex-column justify-content-center align-items-start ps-3 py-2">
                                                            <h3>{{ modality.name }}</h3>
                                                        </div>
                                                    </p>
                                                    <div :id="'collapseFAQS'+type.name+modality.id" class="col-11 accordion-collapse collapse px-0" aria-labelledby="headingFAQ" data-bs-parent="#accordionExample2">
                                                        <!--- TITULO Y AGREGAR --->
                                                        <div class="col-12 d-flex justify-content-center align-items-center px-0" >
                                                            <div class="row justify-content-center row__width">
                                                                <div class="col-12 col-lg-7 d-flex flex-column justify-content-center align-items-start px-0">
                                                                    <h3>Añadir preguntas</h3>
                                                                    <p class="p">Aquí puede administrar las preguntas de la modalidad</p>
                                                                </div>
                                                                <div class="col-12 col-lg-5 d-flex justify-content-end align-items-start px-0">
                                                                    <button type="button" class="agregar-cursos-btn" @click="showInputAdd(1, type.name)">
                                                                        <span>
                                                                            <img src="../../../../img/panel-icons/mas.svg" alt="">
                                                                        </span>
                                                                        <p>Añadir nueva pregunta</p>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div :id="'addFaqInputs'+type.name" class="col-12 accordion-collapse px-0 d-none input" aria-labelledby="headingFAQSub">
                                                            <div class="accordion-body row justify-content-center">
                                                                <div class="col-12 d-flex justify-content-center align-items-center">
                                                                    <div class="row  row__width justify-content-end">
                                                                        <span class="col-1">
                                                                            <h3>P</h3>
                                                                        </span>
                                                                        <div class="col d-flex flex-column justify-content-center align-items-starts pe-0">
                                                                            <input type="text" placeholder="Escribir pregunta aqui" name="" id="question" v-model="newQuestion">
                                                                        </div>
                                                                        <div class="col-2 d-flex justify-content-end align-items-center pe-0 position">
                                                                            <button type="button" class="button btn-save" @click="addFaq(type.id, modality.id, newQuestion, newAnswer, type.name), addCheck(1, type.name)">
                                                                                <i class="save"></i>
                                                                            </button>
                                                                            <button type="button" class="button ms-3" @click="addCheck(1, type.name)">
                                                                                <img src="../../../../img/subir-productos-icons/incorrect.svg" alt="">
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 d-flex justify-content-center align-items-center mt-3 px-0">
                                                                    <div class="row  row__width justify-content-center">
                                                                        <div class="col d-flex justify-content-center align-items-center pe-0  ms-4">
                                                                            <div class="row row__width justify-content-center ">
                                                                                <span class="">
                                                                                    <h3>R</h3>
                                                                                </span>
                                                                                <div class="col d-flex flex-column justify-content-center align-items-start ps-3 pe-0 pe-lg-auto">
                                                                                    <input type="text" placeholder="Escribir respuesta aqui" name="" id="answer" v-model="newAnswer">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="accordion-body row justify-content-center collapses2 collapses3">
                                                            <div class="accordion d-flex flex-column justify-content-center align-items-center" id="accordionExample6">
                                                                <div class="accordion-item row row__width justify-content-center align-items-start" :id="'questionElement'+type.id+index" v-for="(faq, index) in listFaqs">
                                                                    <h2 class="accordion-header px-0 justify-content-center" :id="'question2'+type.id+index" v-if="faq.class_id == type.id && faq.type_id == modality.id">
                                                                        <p class="accordion-button collapsed position-relative" type="button" data-bs-toggle="collapse" :data-bs-target="'#question1'+type.id+index" aria-expanded="false" aria-controls="collapse">
                                                                            <span>
                                                                                <h3>{{ index+1 }}</h3>
                                                                            </span>
                                                                            <div class=" d-flex flex-column justify-content-center align-items-start ps-3">
                                                                                <h3>{{ faq.translations.es.q }}</h3>
                                                                            </div>
                                                                            <div class="col-1 d-flex justify-content-end align-items-center pe-0 position">
                                                                                <button type="button" @click="deleteFaq(faq.id)">
                                                                                    <img src="../../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt="">
                                                                                </button>
                                                                            </div>
                                                                        </p>
                                                                        <div :id="'question1'+type.id+index" class="col-12 accordion-collapse collapse px-0" :aria-labelledby="'question2'+type.id+index">
                                                                            <div class="accordion-body row justify-content-center">
                                                                                <div class="col-12 d-flex justify-content-center align-items-center">
                                                                                    <div class="row  row__width justify-content-end">
                                                                                        <span class="col-1">
                                                                                            <h3>{{ index+1 }}</h3>
                                                                                        </span>
                                                                                        <div class="col d-flex flex-column justify-content-center align-items-starts pe-0">
                                                                                            <input type="text" name="" id="question" v-model="faq.translations.es.q">
                                                                                        </div>
                                                                                        <div class="col-1 d-flex justify-content-end align-items-center pe-0 max-width position">
                                                                                            <button type="button" :id="'btn-save'+type.id+index" class="button btn-save" @click="editFaq(faq.translations.es.q, faq.translations.es.r, faq.translations.id, 'btn-save'+type.id+index)">
                                                                                                <i class="save"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-12 d-flex justify-content-center align-items-center mt-3 px-0">
                                                                                    <div class="row  row__width justify-content-center">
                                                                                        <div class="col d-flex justify-content-center align-items-center pe-0  ms-4">
                                                                                            <div class="row row__width justify-content-center ">
                                                                                                <span class="">
                                                                                                    <h3>R</h3>
                                                                                                </span>
                                                                                                <div class="col d-flex flex-column justify-content-center align-items-start ps-3 pe-0 pe-lg-auto">
                                                                                                    <input type="text" name="" id="answer" v-model="faq.translations.es.r">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <!--- FIN COLLAPSE PREGUNTAS --->
                                </div>
                            </div>
                        </div>
                        <!--- FIN CURSOS --->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject } from 'vue';
import axios from 'axios';
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../../stores/user"

const users = useUsersStore();
const {  } = storeToRefs(users);
const { url } = users;
const urlAddFaq = '/api/faq/add'
const urlEditFaq = '/api/faq/edit/translation/'
const urlFetchFaqs = '/api/faq/get/all/'
const urlDeleteFaq = '/api/faq/remove/'

const newQuestion = ref(null)
const newAnswer = ref(null)
const listTypes = inject('listTypes')
const listFaqs = inject('listFaqs')

const views = inject('views')
const buttons = inject('buttons')


const showInputAdd = (action, id) => {
    if (action == 1){
        let element = document.getElementById('addFaqInputs'+id)
        element.classList.remove('d-none')
        newQuestion.value = null
        newAnswer.value = null
    }
}
const addCheck = (action, id) => {
    if (action == 1){
        let element = document.getElementById('addFaqInputs'+id)
        element.classList.add('d-none')
    }
    //console.log(listProfs.value)
}

const saveQuestionsIcon = (x, questionId) => {
    let button = document.getElementById(questionId)
    if(x == 1){
        button.classList.add('done')
        setTimeout(() => {
            button.classList.remove('done')
        }, 1500)
    }else{
        button.classList.add('undone')
        setTimeout(() => {
            button.classList.remove('undone')
        }, 1500)
    }
}

const fetchFaqs = async() => {
    try {
        await axios.get(url+urlFetchFaqs+'es').then((result) => {
            console.log(result)
            listFaqs.value = []
            if (result.status == 200){
                for(let i = 0; i < result.data.length; i++){
                    listFaqs.value.push( result.data[i] )
                }
            }
        })
    } catch (error) {
        console.error(error)
    }
}
fetchFaqs()

const addFaq = async(typeId, modId, question, response) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    const lang = 'es'
    try {
        await axios.post(url+urlAddFaq, { class_id: typeId, type_id: modId, question: question, response: response, language: lang}, {headers: headers.value}).then((result) => {
            console.log(result)
            fetchFaqs()
        })
    } catch (error) {
        console.error(error)
    }
}

const editFaq = async(question, response, id, questionId) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    const lang = 'es'
    try {
        await axios.post(url+urlEditFaq+id, {question: question, response: response, language: lang}, {headers: headers.value}).then((result) => {
            console.log(result)
            fetchFaqs()
            saveQuestionsIcon(1, questionId)
        })
    } catch (error) {
        console.error(error)
        saveQuestionsIcon(2, questionId)
    }
}

const deleteFaq = async(id) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.delete(url+urlDeleteFaq+id, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 200){
                fetchFaqs()
                console.log('Eliminado')
            }
            else if (result.status == 403){
                console.log('No se puede borrar')
            }
        })
    } catch (error) {
        console.error(error)
    }
}


const setView = (x) =>{
    //console.log('me ejecuto')
    views.value = x
    buttons.value = true
    return {buttons}
}

</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #fff;
    min-height: 100vh;
    @media (min-width: 992px) {
        margin-top: 77px;
    }
}
.contenedor{
    box-shadow: 0px 3px 10px #00000029;
    border: 1px solid #ECECEC;
    background-color: var(--bgPrincipal);
    border-radius: 40px;
    min-height: 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
    @media (min-width: 992px) {
        border-radius: 50px;
        padding: 22px 15px;
    }
    @media (min-width: 992px) {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding: 40px 0 30px 0;
        min-height: auto;
    } 
    .filtrosMobile{
        h5{
            color: var(--colorSecondary);
            font-weight: 700;
            font-size: 22px;
            text-align: left;
        }
        h6{
            color: #697891;
            font-weight: 500;
            font-size: 16px;
            text-align: left;
            padding-top: 10px;
        }
        .contenedor{
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #ECECEC;
            background-color: var(--bgPrincipal);
            border-radius: 40px;
            min-height: 50px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 15px;
            @media (min-width: 992px) {
                border-radius: 40px;
            }
            @media (max-width: 991px) {
                width: 100%;
            }
            .img{
                padding: 0 20px;
            }
            input{
                border: 0;
                color: #B8C1DB;
                font-weight: 400;
                font-size: 14px;
                width: 100%;
                &::placeholder{
                    color: #B8C1DB;
                }
                &:focus{
                    outline: 0;
                }
                @media (min-width: 992px) {
                    color: #697891;
                    font-size: 16px;
                    width: 93%;
                }
            }
            button{
                position: absolute;
                right: 7px;
                border: 0;
                min-height: 45px;
                width: 45px;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 50%;
                background-color: var(--colorPrimary);
                justify-content: center;
                align-items: center;
                img{
                    padding: 0;
                    max-height: 22px;
                }
                @media (min-width: 992px) {
                    right: 10px;
                }
            }
            .border-left{
                border-left: 1px solid rgba(184, 193, 219, 0.4);
            }
        }
    }
    .div{
        background-color: #fff;
        box-shadow: 0px 3px 10px #00000029;
        border-radius: 20px;
        padding: 40px 0 30px 0;
        min-height: auto;
        margin: 40px 0;
        input{
            border: 0;
            color: #B8C1DB;
            font-weight: 400;
            font-size: 14px;
            width: 100%;
            &::placeholder{
                color: #B8C1DB;
            }
            &:focus{
                outline: 0;
            }
            @media (min-width: 992px) {
                color: #697891;
                font-size: 18px;
                width: 80%;
            }
        }
        button{
            position: absolute;
            right: 7px;
            border: 0;
            min-height: 60px;
            width: 60px;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 50%;
            background-color: var(--colorPrimary);
            justify-content: center;
            align-items: center;
            img{
                padding: 0;
            }
            @media (min-width: 992px) {
                right: 20px;
            }
        }
        .scroll{
            overflow-y: scroll;
            overflow-x: hidden;
            max-width: 100%;
            max-height: 500px;
            &::-webkit-scrollbar{
                -webkit-appearance: none;
                width: 5px;
                height: 5px;
            }
            &::-webkit-scrollbar-track{
                -webkit-appearance: none;
                background-color:#dbdbdb73;
                border-radius: 5px;
                width: 5px;
                height: 5px;
            }
            &::-webkit-scrollbar-thumb{
                -webkit-appearance: none;
                background-color:#DBDBDB;
                border-radius: 5px;
                width: 5px;
                height: 5px;
            }
        }
    }
    .addReferrals{
        input{
            width: 94%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: var(--bgPrincipal);
            height: 50px;
            padding-left: 15px;
            outline: 0;
            color: #697891;
            font-size: 15px;
            font-weight: 400;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                color: #697891;
            }
            @media (max-width: 991px) {
                width: 83%;
            }
        }
        .button{
            background-color: #fff;
            border: 2px solid #E9E3E3;
            outline: 0;
            border-radius: 25px;
            min-height: 40px;
            min-width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                height: 20px;
            }
        }
        h4{
            color: var(--colorSecondary);
            font-weight: 700;
            font-size: 22px;
            text-align: left;
        }
        h5{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 16px;
            text-align: left;
            padding-top: 30px;
        }
        .div-gris{
            background-color: #F5F5F5;
            border-radius: 15px;
            padding: 30px;
            input{
                width: 100%;
            }
        }
        .copy{
            background-color: #fff;
            border: 2px solid var(--colorPrimary);
            color: var(--colorPrimary);
            font-weight: 600;
            font-size: 16px;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: 0 10px;
            width: 160px;
            position: absolute;
            right: -13px;
            img{
                margin-right: 10px;
            }
        }
    }   
}
.classSpecial{
   @media (min-width: 992px) {
        width: 70vw !important;
   }
}
.collapses{
    h3{
        font-weight: 700;
        font-size: 18px;
    }
    .question{
        font-size: 30px;
        margin-bottom: 0 !important;
    }
    h4{
        font-weight: 400;
        font-size: 15px;
        color: #B8BCC8;
        text-align: left;
        padding-top: 5px;
    }
    .accordion{
        width: 100%;
        .accordion-item{
            margin: 15px 0px;
            border: 0;
            border-radius: 10px;
            
            p{
                font-size: 14px;
                font-weight: 400;
                color: var(--colorSecondary);
            }
        }
        .accordion-item:last-of-type .accordion-button.collapsed{
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .accordion-header{
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: #F9F9F9;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            .accordion-button{
                border-radius: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                background-color: #F9F9F9;
                &:focus{
                    border-color: inherit;
                    box-shadow: none;
                }
                span{
                    border: 2px solid #E9E3E3;
                    min-height: 50px;
                    min-width: 50px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                @media (max-width: 991px) {
                    justify-content: flex-start;
                    align-items: flex-start;
                }
            }
            .accordion-button:not(.collapsed){
                background-color: inherit;
                box-shadow: none;
            }
            .accordion-button::after{
                position: absolute;
                right: 5%;
                background-image: url('../../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                background-size: 100%;
                background-position: center;
                @media (max-width: 991px) {
                    margin-top: 5px;
                }
            }
        }
    }
    @media (min-width: 992px) {
        margin: 10px 0 10px 0;
    }
    .collapses2{
        padding: 0;
        margin: 0;
        h3{
            font-size: 16px;
        }
        .accordion{
            width: 100%;
            .accordion-item{
                margin: 10px 0px;
                border: 0;
                border-radius: 10px;
            }
            .accordion-item:last-of-type .accordion-button.collapsed{
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            .accordion-header{
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 10px;
                background-color: var(--bgPrincipal);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
                .accordion-button{
                    border-radius: 10px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    background-color: var(--bgPrincipal);
                    &:focus{
                        border-color: inherit;
                        box-shadow: none;
                    }
                    span{
                        border: 2px solid #E9E3E3;
                        min-height: 40px;
                        min-width: 40px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        h3{
                            text-align: center;
                        }
                    }
                }
                .accordion-button:not(.collapsed){
                    background-color: inherit;
                    box-shadow: none;
                }
                .accordion-button::after{
                    position: absolute;
                    right: 2.5%;
                    background-image: url('../../../../img/subir-productos-icons/arrow-gris-abajo.svg');
                    background-size: 50%;
                    background-position: center;
                    border: 2px solid #E9E3E3;
                    border-radius: 50%;
                    min-height: 40px;
                    min-width: 40px;
                }
                .accordion-body, .input{
                    input,select{
                        width: 100%;
                        border: 2px solid #E9E3E3;
                        border-radius: 10px;
                        background-color: #FCFCFC;
                        height: 50px;
                        padding-left: 15px;
                        outline: 0;
                        color: #697891;
                        font-size: 15px;
                        font-weight: 400;
                        -webkit-appearance: none;
                        &:focus, &:hover{
                            border: 2px solid #1679FC;
                        }
                        &::placeholder{
                            color: #697891;
                        }
                    }
                    span{
                        border: 2px solid #E9E3E3;
                        height: 40px;
                        width: 40px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        h3{
                            text-align: center;
                        }
                    }
                    .button{
                        background-color: transparent;
                        border: 2px solid #E4E4E4;
                        border-radius: 50%;
                        min-height: 40px;
                        min-width: 40px;
                        margin-left: 10px;
                    }
                }
            }
        }
        
        @media (min-width: 992px) {
            margin: 10px 0 10px 0;
        }
    }
    
    .max-width{
        width: 7%;
    }
    .position-relative{
        .position{
            position: absolute;
            right: 8%;
            button{
                background-color: transparent;
                border: 2px solid #E4E4E4;
                border-radius: 50%;
                min-height: 40px;
                min-width: 40px;
                
            }
        }
    }
    .collapses3{
        margin-top: 0;
        margin-bottom: 10px;
    }
}
.agregar-cursos-btn{
    box-shadow: 0 3px 6px #00000016;
    border-radius: 43px;
    height: 60px;
    margin: 10px 0;
    border: 0;
    background-color: var(--bgPrincipal);
    padding-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    max-width: 300px;
    p{
        font-weight: 600 !important;
        font-size: 16px !important;
        color: var(--colorSecondary);
    }
    span{
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
    }
}
.border-top{
    border-top: 2px solid #E4E4E4;
    padding-top: 20px;
}

.input{
    input,select{
        width: 100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: #FCFCFC;
        height: 50px;
        padding-left: 15px;
        outline: 0;
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        -webkit-appearance: none;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }
    span{
        border: 2px solid #E9E3E3;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        h3{
            text-align: center;
        }
    }
    .button{
        background-color: transparent;
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        min-height: 40px;
        min-width: 40px;
        margin-left: 10px;
    }
}

.btn-save{
    i{
        min-width: 23px;
        min-height: 23px;
        display: block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('../../../../img/subir-productos-icons/cursos-icons/save.svg');

    }
    transition: all 0.2s;
}
.done{
    i{
        background-image: url('../../../../img/subir-productos-icons/cursos-icons/correct.svg');
    }
    border: 2px solid #66DF4A !important;
}
.undone{
    i{
        background-image: url('../../../../img/subir-productos-icons/cursos-icons/incorrect.svg');
    }
    border: 2px solid #FF576E !important;
}
</style>