<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start ps-lg-0">
        <NavbarCertificados :seccion="$t('message.settings')"/>
        <NavLateralPanelAdmin :stats="true" :academic="true"/>
        <div class="div-grey right" id="div-grey">
            <button class="boton-toggler" id="toggleSidebar" type="button" @click="toggleSidebar">
                <img src="../../../../img/home-icons/arrow-left-blanco.svg" alt="">
            </button>
        </div>
        <div class="col d-flex flex-column justify-content-center align-items-center align-items-lg-center classSpecial" :style="isSidebarOpen ? 'padding-left: 300px !important;'  : 'padding-left: 50px !important;'">
            <!--- INFORMACIÓN --->
            <form class="row justify-content-center row__width contenedor">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-center align-items-center px-0 ">
                    <div class="row justify-content-center row__width titulo py-3">
                        <div class="col d-flex justify-content-start align-items-center px-0 px-lg-auto">
                            <!-- <router-link class="btn_none px-0 d-flex justify-content-center align-items-center pe-3" to="/dashboard">
                                <img src="../../../../img/home-icons/arrow-left-negra.svg" alt="">
                            </router-link> -->
                            <div>
                                <h2>Estadísticas académicas</h2>
                                <h4>Aquí podras ver el avance de los usuarios en tus cursos</h4>
                            </div>
                        </div>
                        <div class="col d-flex justify-content-end align-items-center">
                            <button class="btn__primario">
                                Enviar reporte
                            </button>
                        </div>
                    </div>
                    <div class="row justify-content-center row__width px-lg-4">
                        <div class="col-12  d-flex flex-column justify-content-center align-items-center tablas">
                            <div class="scroll d-flex justify-content-center justify-content-lg-start align-items-center" > 
                                <ul class="nav nav-tabs row justify-content-center justify-content-lg-start py-0" id="myTab" role="tablist">
                                    <li class="nav-item col col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link active" id="general-tab" data-bs-toggle="tab" data-bs-target="#general" type="button" role="tab" aria-controls="general" aria-selected="true">Resumen general</button>
                                    </li>
                                    <li class="nav-item col col-lg-2 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link " id="purchases-tab" data-bs-toggle="tab" data-bs-target="#purchases" type="button" role="tab" aria-controls="purchases" aria-selected="false">Por alumnos</button>
                                    </li>
                                    <li class="nav-item col col-lg-2 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link" id="referred-tab" data-bs-toggle="tab" data-bs-target="#referred" type="button" role="tab" aria-controls="referred" aria-selected="true">Por profesores</button>
                                    </li>
                                    <li class="nav-item col col-lg-2 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link " id="projects-tab" data-bs-toggle="tab" data-bs-target="#projects" type="button" role="tab" aria-controls="projects" aria-selected="false">Por cursos</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido px-0">
                            <div class="tab-content row justify-content-center mx-2" id="myTabContent">
                                <!--- GENERAL --->
                                <div class="col-12 tab-pane fade show active px-0" id="general" role="tabpanel" aria-labelledby="general-tab">
                                    <div class="row justify-content-center mx-2 mx-lg-0">
                                        <div class="col-12 d-flex justify-content-start align-items-center">
                                            <h4>
                                                <img src="../../../../img/estadisticas-icons/actualizacion.svg" alt="">
                                                Última actualización 3 de marzo a las 12:00hs am
                                            </h4>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center align-items-center px-0 mt-3">
                                            <div class="row justify-content-center row__width">
                                                <div class="col-12 col-md-6 col-lg-4 d-flex justify-content-center align-items-start px-0 ps-lg-3">
                                                    <div class="row justify-content-center  row__width div_shadow">
                                                        <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                                            <h5>
                                                                Alumnos inscriptos
                                                            </h5>
                                                            <img src="../../../../img/estadisticas-icons/alumnos.svg" alt="">
                                                        </div>
                                                        <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                                            <h6>100</h6>
                                                            <p class="percent">+15%</p>
                                                            <p>Último mes</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4 d-flex justify-content-center align-items-start px-0 ps-lg-3">
                                                    <div class="row justify-content-center  row__width div_shadow">
                                                        <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                                            <h5>
                                                                Cursos inscriptos
                                                            </h5>
                                                            <img src="../../../../img/estadisticas-icons/cursos.svg" alt="">
                                                        </div>
                                                        <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                                            <h6>100</h6>
                                                            <p class="percent">+15%</p>
                                                            <p>Último mes</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4 d-flex justify-content-center align-items-start px-0 ps-lg-3">
                                                    <div class="row justify-content-center  row__width div_shadow" >
                                                        <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                                            <h5>
                                                                Ingresos netos
                                                            </h5>
                                                            <img src="../../../../img/estadisticas-icons/ingresos.svg" alt="">
                                                        </div>
                                                        <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                                            <h6>100</h6>
                                                            <p class="percent">+15%</p>
                                                            <p>Último mes</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4 d-flex justify-content-center align-items-start px-0 ps-lg-3">
                                                    <div class="row justify-content-center  row__width div_shadow">
                                                        <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                                            <h5>
                                                                Descargas
                                                            </h5>
                                                            <img src="../../../../img/estadisticas-icons/descargas.svg" alt="">
                                                        </div>
                                                        <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                                            <h6>100</h6>
                                                            <p class="percent negative">+15%</p>
                                                            <p>Último mes</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4 d-flex justify-content-center align-items-start px-0 ps-lg-3">
                                                    <div class="row justify-content-center  row__width div_shadow">
                                                        <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                                            <h5>
                                                                Promedios
                                                            </h5>
                                                            <img src="../../../../img/estadisticas-icons/promedios.svg" alt="">
                                                        </div>
                                                        <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                                            <h6>100</h6>
                                                            <p class="percent">+15%</p>
                                                            <p>Último mes</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4 d-flex justify-content-center align-items-start px-0 ps-lg-3">
                                                    <div class="row justify-content-center  row__width div_shadow" >
                                                        <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                                            <h5>
                                                                Productos más vendidos
                                                            </h5>
                                                            <img src="../../../../img/estadisticas-icons/productos.svg" alt="">
                                                        </div>
                                                        <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                                            <h6>100</h6>
                                                            <p class="percent">+15%</p>
                                                            <p>Último mes</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center align-items-center">
                                            <div class="row justify-content-between row__width div__shadow__stats">
                                                <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                    <div class="row separador row__width tipo__reunion">
                                                        <div class="col-3 d-flex justify-content-center align-items-center px-0">
                                                            <input type="checkbox" id="hola">
                                                            <label for="hola" class="row">
                                                                <div class="col-6 d-flex justify-content-center align-items-center">
                                                                    <p class="left">Semanal</p>
                                                                </div>
                                                                <div class="col-6 d-flex justify-content-center align-items-center px-0">
                                                                    <p class="right">Anual</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col max-width d-flex justify-content-center align-items-center px-0">
                                                    <div class="row justify-content-center row__width div__shadow__stats">
                                                        <div class="col-12 d-flex justify-content-start align-items-center">
                                                            <img src="../../../../img/estadisticas-icons/actualizacion-azul.svg" alt="">
                                                            <p>Última actualización 3 de marzo a las 12:00hs am</p>
                                                        </div>
                                                        <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                                                            <h2>
                                                                600
                                                            </h2>
                                                            <h3>
                                                                Esta semana
                                                            </h3>
                                                        </div>
                                                        <div>
                                                            <apexchart width="200" type="line" :options="options" :series="series"></apexchart>
                                                        </div>
                                                        <div class="col-12 d-flex justify-content-start align-items-center py-3">
                                                            <img src="../../../../img/estadisticas-icons/arrow-up-green.svg" alt="">
                                                            <p class="percent">+15%</p>
                                                        </div>
                                                        <div class="col-12 d-flex justify-content-center align-items-center">
                                                            <button class="btn__primario">
                                                                Enviar reporte
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-8 px-0">
                                                    <div class="px-0">
                                                        <apexchart  type="bar" :options="optionsColumns" :series="seriesColumns"></apexchart>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12  d-flex flex-column justify-content-center align-items-center tablas">
                                            <div class="scroll d-flex justify-content-center justify-content-lg-start align-items-center" > 
                                                <ul class="nav nav-tabs row justify-content-center justify-content-lg-start py-0" id="myTab2" role="tablist">
                                                    <li class="nav-item col col-lg-2 d-flex justify-content-center align-items-center" role="presentation">
                                                        <button class="nav-link active" id="students-tab" data-bs-toggle="tab" data-bs-target="#students" type="button" role="tab" aria-controls="students" aria-selected="true">Alumnos</button>
                                                    </li>
                                                    <li class="nav-item col col-lg-2 d-flex justify-content-center align-items-center" role="presentation">
                                                        <button class="nav-link " id="professor-tab" data-bs-toggle="tab" data-bs-target="#professor" type="button" role="tab" aria-controls="professor" aria-selected="false">Profesor</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido px-0">
                                            <div class="tab-content row justify-content-center row__width" id="myTab2Content">
                                                <div class="col-12 tab-pane fade show active px-0" id="students" role="tabpanel" aria-labelledby="students-tab">
                                                    <div class="row justify-content-center row__width">
                                                        <div class="col-12 d-flex justify-content-center align-items-center px-0 mt-3">
                                                            <div class="row justify-content-start row__width">
                                                                <div class="col max-width2 d-flex justify-content-center align-items-start px-0 ps-lg-3">
                                                                    <div class="row justify-content-center  row__width div_shadow div_shadow2">
                                                                        <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                                                            <img src="../../../../img/estadisticas-icons/video-azul.svg" alt="">
                                                                            <div class="d-flex">
                                                                                <img src="../../../../img/estadisticas-icons/arrow-down-red.svg" alt="">
                                                                                <p class="percent negative">- 15%</p>
                                                                                <p>/Mes</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0">
                                                                            <h5 class="pt-2">
                                                                                Cursos finalizados
                                                                            </h5>
                                                                            <p class="progress_p">56%</p>
                                                                            <div class="progress">
                                                                                <div class="progress-bar" role="progressbar" style="width: 50%; height: 15px;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col max-width2 d-flex justify-content-center align-items-start px-0 ps-lg-3">
                                                                    <div class="row justify-content-center  row__width div_shadow div_shadow2">
                                                                        <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                                                            <img src="../../../../img/estadisticas-icons/video-verde.svg" alt="">
                                                                            <div class="d-flex">
                                                                                <img src="../../../../img/estadisticas-icons/arrow-up-green.svg" alt="">
                                                                                <p class="percent">+ 15%</p>
                                                                                <p>/Mes</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0">
                                                                            <h5 class="pt-2">
                                                                                Cursos en progreso
                                                                            </h5>
                                                                            <p class="progress_p">56%</p>
                                                                            <div class="progress">
                                                                                <div class="progress-bar green" role="progressbar" style="width: 50%; height: 15px;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 tab-pane fade px-0" id="professor" role="tabpanel" aria-labelledby="professor-tab">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--- VENTAS --->
                                <div class="col-12 tab-pane fade px-0" id="purchases" role="tabpanel" aria-labelledby="purchases-tab">
                                    <div class="row justify-content-center row__width">
                                        <div class="col-12 d-flex justify-content-start align-items-center">
                                            <h4>
                                                <img src="../../../../img/estadisticas-icons/actualizacion.svg" alt="">
                                                Última actualización 3 de marzo a las 12:00hs am
                                            </h4>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center align-items-center px-0 mt-3">
                                            <div class="row justify-content-start row__width">
                                                <div class="col max-width2 d-flex justify-content-center align-items-start pe-0">
                                                    <div class="row justify-content-center  row__width div_shadow div_shadow2">
                                                        <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                                            <img src="../../../../img/estadisticas-icons/alumnos.svg" alt="">
                                                        </div>
                                                        <div class="col-12 d-flex flex-column justify-content-start align-items-start px-0">
                                                            <h5 class="pt-2">
                                                                Total usuarios
                                                            </h5>
                                                            <p class="bold">250</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col max-width2 d-flex justify-content-center align-items-start pe-0">
                                                    <div class="row justify-content-center  row__width div_shadow div_shadow2">
                                                        <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                                            <img src="../../../../img/estadisticas-icons/video-azul.svg" alt="">
                                                            <div class="d-flex">
                                                                <img src="../../../../img/estadisticas-icons/arrow-down-red.svg" alt="">
                                                                <p class="percent negative">- 15%</p>
                                                                <p>/Mes</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0">
                                                            <h5 class="pt-2">
                                                                Cursos finalizados
                                                            </h5>
                                                            <p class="progress_p">56%</p>
                                                            <div class="progress">
                                                                <div class="progress-bar" role="progressbar" style="width: 50%; height: 15px;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col max-width2 d-flex justify-content-center align-items-start pe-0">
                                                    <div class="row justify-content-center  row__width div_shadow div_shadow2">
                                                        <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                                            <img src="../../../../img/estadisticas-icons/video-verde.svg" alt="">
                                                            <div class="d-flex">
                                                                <img src="../../../../img/estadisticas-icons/arrow-up-green.svg" alt="">
                                                                <p class="percent">+ 15%</p>
                                                                <p>/Mes</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0">
                                                            <h5 class="pt-2">
                                                                Cursos en progreso
                                                            </h5>
                                                            <p class="progress_p">56%</p>
                                                            <div class="progress">
                                                                <div class="progress-bar green" role="progressbar" style="width: 50%; height: 15px;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center align-items-center div px-3 pb-2">
                                            <div class="row justify-content-center mx-2 mx-lg-0 ">
                                                <div class="col-12 d-flex justify-content-center align-items-center filtrosMobile">
                                                    <div class="row justify-content-center align-items-center row__width">
                                                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start">
                                                            <h5>{{ $t('message.users') }}</h5>
                                                            <h6>1200 {{ $t('message.users') }}</h6>
                                                        </div>
                                                        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center contenedor px-0">
                                                            <input type="text" placeholder="Buscar usuarios">
                                                            <button class="button"><img src="../../../../img/home-icons/lupa.svg" alt=""></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido px-0">
                                                    <div class="row justify-content-center row__width scroll my-3">
                                                        <div class="col-12 d-flex flex-column justify-content-center align-items-center collapses">
                                                            <div class="accordion d-flex flex-column justify-content-center align-items-center" id="accordionExample">
                                                                <div class="accordion-item row row__width justify-content-center">
                                                                    <h2 class="accordion-header mb-0 py-lg-3" id="heading">
                                                                        <div class="col-9 col-lg-1 d-flex justify-content-start align-items-center ps-1 pe-0 ">
                                                                            <div class="row row__width justify-content-center justify-content-lg-start pt-3 pt-lg-0">
                                                                                <div class="col-4 col-lg-12 d-flex justify-content-center align-items-center img">
                                                                                    <img src="../../../../img/perfil-icons/img-ejemplo.svg" alt="">
                                                                                </div>
                                                                                <div class="col col-lg-6 d-flex flex-column justify-content-center align-items-start px-0 d-lg-none">
                                                                                    <h5>{{ $t('message.name') }}</h5>
                                                                                    <h6>Carlos</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col col-lg-8 d-flex justify-content-center align-items-center px-0 seccion d-none d-lg-flex">
                                                                            <div class="row row__width justify-content-start align-items-center">
                                                                                <div class="col col-lg-6 d-flex flex-column justify-content-center align-items-start px-0">
                                                                                    <h5>{{ $t('message.name') }}</h5>
                                                                                    <h6>Carlos</h6>
                                                                                </div>
                                                                                <div class="col d-flex flex-column justify-content-center align-items-start px-0 excepcion">
                                                                                    <h5>Cursos finalizados</h5>
                                                                                    <h6>2</h6>
                                                                                </div>
                                                                                <div class="col d-flex flex-column justify-content-center align-items-start px-0">
                                                                                    <h5>{{ $t('message.sales') }}</h5>
                                                                                    <h6>400</h6>
                                                                                </div>
                                                                                <div class="col d-flex flex-column justify-content-center align-items-start px-0 excepcion">
                                                                                    <h5>Cursos en progreso</h5>
                                                                                    <h6>2</h6>
                                                                                </div>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        <div class="col d-flex justify-content-center align-items-center d-none d-lg-flex">
                                                                            <router-link class="btn__primario" to="/estadisticas-academicas-usuario/0">
                                                                                <img src="../../../../img/estadisticas-icons/ojo.svg" alt="">
                                                                                Estadísticas
                                                                            </router-link>
                                                                        </div>
                                                                        <p class="accordion-button py-0 px-0 collapsed ps-lg-3 h-100 d-lg-none" data-bs-toggle="collapse" data-bs-target="#collapse" aria-expanded="false" aria-controls="collapse">
                                                                            
                                                                        </p>
                                                                    </h2>
                                                                    <div id="collapse" class="col-12 accordion-collapse collapse d-lg-none" aria-labelledby="heading" data-bs-parent="#accordionExample">
                                                                        <div class="accordion-body row justify-content-end py-0 px-0">
                                                                            <div class="col-6 d-flex flex-column justify-content-center align-items-start ps-1 excepcion">
                                                                                <div class="row row__width justify-content-start align-items-center">
                                                                                    <div class="col col-lg-6 d-flex flex-column justify-content-center align-items-start px-0">
                                                                                        <h5>{{ $t('message.name') }}</h5>
                                                                                        <h6>Carlos</h6>
                                                                                    </div>
                                                                                    <div class="col d-flex flex-column justify-content-center align-items-start px-0 excepcion">
                                                                                        <h5>Cursos finalizados</h5>
                                                                                        <h6>2</h6>
                                                                                    </div>
                                                                                    <div class="col d-flex flex-column justify-content-center align-items-start px-0">
                                                                                        <h5>{{ $t('message.sales') }}</h5>
                                                                                        <h6>400</h6>
                                                                                    </div>
                                                                                    <div class="col d-flex flex-column justify-content-center align-items-start px-0 excepcion">
                                                                                        <h5>Cursos en progreso</h5>
                                                                                        <h6>2</h6>
                                                                                    </div>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 d-flex justify-content-center align-items-center eliminar">
                                                                                <router-link class="btn__primario" to="/estadisticas-academicas-usuario/0">
                                                                                    <img src="../../../../img/estadisticas-icons/ojo.svg" alt="">
                                                                                    Estadísticas
                                                                                </router-link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--- REFERIDOS --->
                                <div class="col-12 tab-pane fade px-0" id="referred" role="tabpanel" aria-labelledby="referred-tab">
                                    
                                </div>
                                <!--- PROYECTOS --->
                                <div class="col-12 tab-pane fade px-0" id="projects" role="tabpanel" aria-labelledby="projects-tab">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <FooterCertificados :upload="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../../components/Navs/NavbarCertificados.vue";
import FooterCertificados from "../../../../components/Footer/FooterCertificados.vue";
import NavLateralPanelAdmin from "../../../../components/Navs/NavLateralPanelAdmin.vue";
import TituloAdminAjustes from "../../../../components/Titulo/TituloAdminAjustes.vue";

import { useI18n } from "vue-i18n"
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../../stores/user"
import { useLanguageStore } from "../../../../stores/langs"
import { onBeforeMount, ref, provide, watch } from "vue"

import ApexCharts from 'apexcharts'


const {locale} = useI18n({ useScope: 'global' })

const users = useUsersStore();
const {access} = storeToRefs(users);
const {} = users;
const {validate} = users;

const langs = useLanguageStore();
const {listLangs} = storeToRefs(langs);

const listTypes = ref([])
const listModalities = ref([])
const listProfs = ref([])
const listBenefits = ref([])
const listIcons = ref([])
const listValues = ref([])
const listFaqs = ref([])
provide('listTypes', listTypes)
provide('listModalities', listModalities)
provide('listProfs', listProfs)
provide('listBenefits', listBenefits)
provide('listIcons', listIcons)
provide('listValues', listValues)
provide('listFaqs', listFaqs)

const algo = onBeforeMount( () => {
    access.value = [1]
    //console.log(access.value)
    validate();
})

const isSidebarOpen = ref(true)
//provide(isSidebarOpen, 'isSidebarOpen')

const views = ref(0)
provide("views", views)

const viewsProducts = ref(0)
provide("viewsProducts", viewsProducts)

const buttons = ref(true)
provide("buttons", buttons)

const buttons2 = ref(true)
provide("buttons2", buttons2)

const tabs = ref(true)
provide("tabs", tabs)


const options = ref({
    chart: {
        id: 'vuechart-example',
        background: 'transparent',
        offsetX: 0,
        offsetY: 0,
        width: '100%',
        toolbar: {
            show: false,
        },
        margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        }
    },
    xaxis: {
        floating: false,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        }
    },
    yaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        }
    },
    dataLabels: {
        enabled: false
    },
    legend: {
      show: false,
    },
    labels: {
        show: false,
    },
    grid: {
        show: false,
        padding: {
            top: 0,
            right: 10,
            bottom: 0,
            left: -10
        }, 
    },
    tooltip: {
      enabled: false,
    },
    stroke: {
        // Ajustar la curva del gráfico de línea
        curve: 'smooth'
    },   
    colors: ['#1679FC']
})
const series = ref([{
    name: 'series-1',
    data: [10, 40, 25, 80, 39, 100, 20, 91]
}])

const optionsColumns = ref({
    chart: {
        id: 'vuechart-example',
        background: 'transparent',
        offsetX: 0,
        offsetY: 0,
        width: '100%',
        height: '100%',
        toolbar: {
            show: false,
        },
        margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        }
    },
    xaxis: {
        categories: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
        floating: false,
        labels: {
          show: true,
            style: {
                colors: ['#768BC4'],
                fontSize: '20px',
                fontFamily: 'Proxima Nova, Arial, sans-serif',
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label',
            },
        },
        title:{
            style: {
                colors: ['#768BC4'],
                fontSize: '20px',
                fontFamily: 'Proxima Nova, Arial, sans-serif',
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label',
            },
        },
        group:{
            style: {
                colors: ['#768BC4'],
                fontSize: '20px',
                fontFamily: 'Proxima Nova, Arial, sans-serif',
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label',
            },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        style: {
            colors: ['#768BC4'],
            fontSize: '20px',
            fontFamily: 'Proxima Nova, Arial, sans-serif',
            fontWeight: 400,
            cssClass: 'apexcharts-xaxis-label',
        },
        
    },
    yaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        }
    },
    dataLabels: {
        enabled: false
    },
    legend: {
      show: false,
    },
    labels: {
        show: false,
    },
    grid: {
        show: false,
        padding: {
            top: 0,
            right: 10,
            bottom: 0,
            left: -10
        }, 
    },
    tooltip: {
      enabled: false,
    },
    stroke: {
        // Ajustar la curva del gráfico de línea
        curve: 'smooth'
    },   
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '70%',
            endingShape: 'rounded',
            borderRadius: 5,
            borderRadiusApplication: 'end',
        },
    },
    colors: ['#1679FC', '#38C2E6', '#B9F995', '#FFCF5C', '#A8D5FE', '#B8C1DB']
})
const seriesColumns = ref([
    {
        name: 'Net Profit',
        data: [44, 55, 57, 56, 61, 30, 50]
    }, 
    {
        name: 'Revenue',
        data: [76, 85, 101, 98, 87, 20, 80]
    }, 
    {
        name: 'Free Cash Flow',
        data: [35, 41, 36, 26, 45, 90, 30]
    },
    {
        name: 'Net Profit',
        data: [44, 55, 57, 56, 61, 40, 10]
    },
    {
        name: 'Revenue',
        data: [76, 85, 101, 98, 87, 50, 60]
    }, 
    {
        name: 'Free Cash Flow',
        data: [35, 41, 36, 26, 45, 10, 70]
    },
])

const toggleSidebar = () => {
    isSidebarOpen.value = !isSidebarOpen.value
    if(document.getElementById('sidebar').classList.contains('d-none')){
        document.getElementById('sidebar').classList.remove('d-none')
    } else{
        document.getElementById('sidebar').classList.add('d-none')
    }
    if(document.getElementById('div-grey').classList.contains('right')){
        document.getElementById('div-grey').classList.remove('right')
        document.getElementById('div-grey').classList.add('left')
    } else{
        document.getElementById('div-grey').classList.remove('left')
        document.getElementById('div-grey').classList.add('right')
    }
}

</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #F5F5F5;
    min-height: 100vh;
    padding-bottom: 50px;
    @media (min-width: 992px) {
        margin-top: 77px;
        background-color: #F5F5F5;
    }
}
.titulo{
    h2{
        font-weight: 700;
        color: var(--colorSecondary);
        font-size: 32px;
        margin-bottom: 0;
    }
    h3{
        font-size: 16px;
        font-weight: 600;
        color: var(--colorSecondary);
        padding: 0px 0 30px 0;
        @media (min-width: 992px) {
            padding-bottom: 0;
        }
    }
    h4{
        font-weight: 400;
        font-size: 15px;
        color: #B8C1DB;
        text-align: left;
        padding: 5px 0;
    }
    img{
        height: 20px;
    }
    button{
        margin-left: 10px;
    }
    .download{
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        height: 44px;
        width: 44px;
        border: 0;
    }
    .btn__primario{
        max-width: 200px;
    }
}
.contenedor{
    animation: fade-in 0.2s ease-in forwards;
    .tablas{  
        background-color: transparent;
        min-height: 56px;
        margin-bottom: 10px;
        border-radius: 10px;
        ul{
            background-color: transparent;
            padding: 8px 0;
            min-width: 100%;
        }
        .nav-item{
            padding: 0;
        }
        .nav-link{
            width: 100%;
            position: relative;
            padding: 10px 0;
            border: 0;
            background-color: transparent;
            border-radius: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 16px;
            color: #B8C1DB;
            h3{
                font-weight: 500;
                font-size: 16px;
                color: #B8C1DB;
            }
            @media (min-width: 992px) {
                color: #0E1133;
                h3{
                    color: #0E1133;
                }
            }
        }
        .nav-link.active{
            font-weight: 700;
            border-color: transparent;
            color: var(--colorSecondary);
            background-color: transparent;
            border-radius: 0;
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 5px;
                bottom: 0px;
                left: 0;
                background-color: var(--colorPrimary);
                border-radius: 10px;
            }
        }
        .scroll{
            width: 100%;
            height: 100%;
        }
        @media (max-width: 991px) {
            border-radius: 0px;
            margin-bottom: 10px;
        }
        .nav-tabs{
            border-bottom: 2px solid #B8C1DB;
        }
    } 
    .contenido{
        h4{
            font-weight: 400;
            font-size: 15px;
            color: #B8C1DB;
            text-align: left;
            img{
                margin-right: 5px;
            }
        }
    }
    .div_shadow{
        box-shadow: 0px 3px 10px #00000029;
        border-radius: 20px;
        background-color: #fff;
        height: 105px;
        padding: 15px;
        margin-bottom: 15px;
        h5{
            font-weight: 600;
            font-size: 18px;
            color: var(--colorSecondary);
        }
        h6{
            font-weight: 700;
            font-size: 24px;
            color: #374557;
        }
        p{
            font-size: 14px;
            font-weight: 400;
            color:#494D4C;
            margin: 0 5px;
        }
        .percent{
            font-weight: 600;
            color:#38E6A7;
            margin-right: 0;
        }
        .negative{
            color: #FF576E;
        }
    }
    .div_shadow2{
        padding: 10px 20px;
        height: 170px;
        .progress_p{
            font-size: 16px;
            font-weight: 500;
            color: #697891;
            padding-top: 10px;
        }
        .progress{
            margin: 10px 0;
            border-radius: 50px;
            width: 40%;
        }
        p{
            color: #E4E4E4;
            margin: 0;
        }
        .progress-bar{
            border-radius: 50px;
            background-color: var(--colorPrimary);
        }
        .green{
            background-color: #38E6A7;
        }
        .bold{
            font-size: 22px;
            font-weight: 700;
            color: var(--colorSecondary);
            padding-top: 10px;
        }
    }
    .tipo__reunion{
        margin-bottom: 10px;
        label{
            height: 35px;
            width: 100%;
            box-shadow: 0px 0px 6px #00000029;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin: 10px 0px;
            cursor: pointer;
            background-color: #ECF0F5;
            &::before{
                content: '';
                position: absolute;
                border: 2px solid var(--colorPrimary);
                height: 100%;
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: var(--colorPrimary);
                border-radius:  28px;
                left: 1%;
                transition: all 0.5s;
            }
            p{
                font-weight: 500;
                font-size: 14px;
                color: var(--bgPrincipal);
                z-index: 1;
                transition: all 0.5s;
                &:first-child{
                    margin-right: 0%;
                }
                &:last-child{
                    margin-left: 0%;
                }
            }
            .left{
                color: var(--bgPrincipal);
                font-weight: 600;
            }
            .right{
                color: var(--colorSecondary);
            }
        }
        input{
            display: none;
        }
        input:checked + label{
            &::before{
                left: 49%;
                width: 50%;
            }
            p{
                color: var(--bgPrincipal);
                font-weight: 600;
                
            }
            .left{
                color: var(--colorSecondary);
                font-weight: 500;
            }
        }
        @media (min-width: 992px) {
            label{
                .right{
                    color: var(--colorSecondary);
                }
            }
            input:checked + label{
                .left{
                    color: var(--colorSecondary);
                }
            }
        }
    }
    .div__shadow__stats{
        box-shadow: 0px 3px 10px #00000029;
        border-radius: 20px;
        background-color: #fff;
        padding: 20px;
        p{
            font-size: 12px;
            font-weight: 500;
            color: var(--colorPrimary);
            text-align: left;
            padding-left: 5px;
        }
        h2{
            color: var(--colorSecondary);
            font-weight: 700;
            font-size: 30px;
            padding-top: 20px;
        }
        h3{
            font-size: 14px;
            font-weight: 400;
            color: #768BC4;
        }
        .percent{
            font-weight: 600;
            color:#38E6A7;
            margin-right: 0;
        }
        .btn__primario{
            height: 35px;
        }
        .btn__primario{
            font-size: 14px;
        }
    }
    .max-width{
        max-width: 240px;
    }
    .max-width2{
        max-width: 350px;
    }
    .div{
        background-color: #fff;
        box-shadow: 0px 3px 10px #00000029;
        border-radius: 20px;
        padding: 40px 0 30px 0;
        min-height: auto;
        margin: 40px 0;
        input{
            border: 0;
            color: #B8C1DB;
            font-weight: 400;
            font-size: 14px;
            width: 100%;
            &::placeholder{
                color: #B8C1DB;
            }
            &:focus{
                outline: 0;
            }
            @media (min-width: 992px) {
                color: #697891;
                font-size: 18px;
                width: 80%;
            }
        }
        .button{
            position: absolute;
            right: 7px;
            border: 0;
            min-height: 60px;
            width: 60px;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 50%;
            background-color: var(--colorPrimary);
            justify-content: center;
            align-items: center;
            img{
                padding: 0;
            }
            @media (min-width: 992px) {
                right: 20px;
            }
        }
        .scroll{
            overflow-y: scroll;
            overflow-x: hidden;
            max-width: 100%;
            max-height: 500px;
            &::-webkit-scrollbar{
                -webkit-appearance: none;
                width: 5px;
                height: 5px;
            }
            &::-webkit-scrollbar-track{
                -webkit-appearance: none;
                background-color:#dbdbdb73;
                border-radius: 5px;
                width: 5px;
                height: 5px;
            }
            &::-webkit-scrollbar-thumb{
                -webkit-appearance: none;
                background-color:#DBDBDB;
                border-radius: 5px;
                width: 5px;
                height: 5px;
            }
        }
    }
    .collapses{
        h3{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            padding: 7px 0px;
        }
        h6{
            color:#B8BCC8;
            font-size: 15px;
            font-weight: 400;
            text-align: left;
            padding: 5px 0px;
        }
        .accordion{
            width: 100%;
            .accordion-item{
                border: 0;
                border-radius: 10px;
                box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
                border-radius: 10px;
                background-color: #F9F9F9;
                margin-bottom: 15px;
                
            }
            .accordion-item:last-of-type .accordion-button.collapsed{
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            .accordion-button{
                border-radius: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                background-color: #F9F9F9;
                &:focus{
                    border-color: inherit;
                    box-shadow: none;
                }
                span{
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 15px;
                    font-weight: 400;
                    font-size: 14px;
                    text-align: center;
                    text-transform: uppercase;
                    padding: 5px 10px;
                    min-width: 110px;
                }
                .inactive{
                    background-color: #F5F5F5;
                    color: #707070;
                }
                .active{
                    background-color: #38E6A7;
                    color: #38E6A7;
                }
                .pause{
                    color: #FEB230;
                    background-color: #FEB230;
                }
                h3{
                    text-align: center;
                    font-size: 22px;
                }
                @media (min-width: 992px) {
                    border-radius: 10px;
                    background-color: var(--bgPrincipal);
                    position: relative;
                    height: 100px;
                }
            }
            .accordion-button:not(.collapsed){
                background-color: inherit;
                box-shadow: none;
                @media (min-width: 992px) {
                    background-color: var(--bgPrincipal);
                    box-shadow: inherit;
                }
            }
            .accordion-button::after{
                position: absolute;
                right: 5%;
                background-image: url('../../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                background-size: 50%;
                background-position: center;
                background-color: transparent;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                @media (min-width: 992px) {
                    display: none;
                }
            }
            .accordion-header{
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                
                .accordion-body{
                    span{
                        border: 2px solid #E9E3E3;
                        height: 40px;
                        width: 40px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        h3{
                            text-align: center;
                        }
                    }
                    button{
                        background-color: transparent;
                        border: 2px solid #E4E4E4;
                        border-radius: 50%;
                        height: 40px;
                        width: 40px;
                        margin-left: 10px;
                    }
                }
                .buttons{
                    position: absolute;
                    right: 17%;
                    @media (min-width: 992px) {
                        right: 7%;
                    }
                }
                
            }
            h5{
                color: var(--colorSecondary);
                font-size: 16px;
                font-weight: 700;
                text-align: left;
                padding-bottom: 10px;
            }
            h6{
                color: #B8C1DB;
                font-size: 16px;
                font-weight: 400;
                text-align: left;
                height: 30px;
            }
            .text-bold{
                font-weight: 600;
            }
            .eliminar{
                button{
                    background-color: transparent;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    border: 0;
                }
            }
            .img{
                img{
                    height: 58px;
                    width: 58px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
            .seccion{
                .col{
                    max-width: 20%;
                }
                .excepcion{
                    max-width: 35%;
                }
            }
        }
        .padding{
            padding: 0 11%;
        }
        .btn__primario{
            max-width: 176px;
            img{
                padding-right: 5px;
            }
        }
    } 
    .filtrosMobile{
        h5{
            color: var(--colorSecondary);
            font-weight: 700;
            font-size: 22px;
            text-align: left;
        }
        h6{
            color: #697891;
            font-weight: 500;
            font-size: 16px;
            text-align: left;
            padding-top: 10px;
        }
        .contenedor{
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #ECECEC;
            background-color: var(--bgPrincipal);
            border-radius: 40px;
            min-height: 50px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 15px;
            @media (min-width: 992px) {
                border-radius: 40px;
            }
            @media (max-width: 991px) {
                width: 100%;
            }
            .img{
                padding: 0 20px;
            }
            input{
                border: 0;
                color: #B8C1DB;
                font-weight: 400;
                font-size: 14px;
                width: 100%;
                &::placeholder{
                    color: #B8C1DB;
                }
                &:focus{
                    outline: 0;
                }
                @media (min-width: 992px) {
                    color: #697891;
                    font-size: 16px;
                    width: 93%;
                }
            }
            button{
                position: absolute;
                right: 7px;
                border: 0;
                min-height: 45px;
                width: 45px;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 50%;
                background-color: var(--colorPrimary);
                justify-content: center;
                align-items: center;
                img{
                    padding: 0;
                    max-height: 22px;
                }
                @media (min-width: 992px) {
                    right: 10px;
                }
            }
            .border-left{
                border-left: 1px solid rgba(184, 193, 219, 0.4);
            }
        }
    }
}

.classSpecial{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000016;
    border-radius: 20px;
    padding: 20px 30px;
    margin-top: 30px;
    @media (max-width: 991px) {
        padding-bottom: 30vw;
    }
    @media (min-width: 992px) {
        width: 70vw !important;
    }
}

@keyframes fade-in {
    0% { opacity: 0; }
   90% { opacity: 0.9; }
  100% { opacity: 1; }
}
.apexcharts-text .apexcharts-xaxis-label, .apexcharts-xaxis-label{
    font-size: 20px;
    font-weight: 400;
    font-family: 'Proxima Nova';
    color:#768BC4 !important;
}
</style>