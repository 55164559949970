<template>
    <div class="row justify-content-center row__width item">
        <div class="col-4 d-flex justify-content-center align-items-center px-0 ejemplo">
            <img :src="image" alt="" class="thumbnail-cart">
        </div>
        <div class="col d-flex flex-column justify-content-start align-items-start position-relative">
            <h2>{{name}}</h2>
            <h3>$ {{prize}}</h3>
            <h5 v-if="discount">$ {{discount}}</h5>
            <button>
                <img src="../../img/carrito-icons/delete.svg" alt="" @click="deleteCart(id)">
            </button>
        </div>
    </div>
</template>

<script setup>
    import { storeToRefs } from "pinia"
    import { useCartStore } from "../../stores/cart"

    const carts = useCartStore();
    const { cart, totalPrice, totalDiscount } = storeToRefs(carts);
    const { deleteCart } = carts

    const props = defineProps({
        name: '',
        discount: '',
        prize: '',
        image: '',
        id: ''
    })
</script>

<style lang="scss" scoped>
.item{
    padding: 25px 0px;
    border-bottom: 1px solid #6978911c;
    .ejemplo{
        position: relative;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        &::before{
            content: '';
            position: absolute;
            background-image: url('../../img/carrito-icons/filtro.svg');
            background-repeat: no-repeat;
            background-size: 100% 150%;
            z-index: 2;
            min-height: 100%;
            width: 100%;
        }
    }
    h2{
        font-weight: 600;
        font-size: 16px;
        text-align: start;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 165px;
        color: var(--colorSecondary);
    }
    h3{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary);
        white-space: nowrap;
        width: 55px;
        margin-top: 10px;
    }
    h5{
        font-weight: 600;
        font-size: 18px;
        color: #B8C1DB;
        text-decoration: line-through;
        white-space: nowrap;
        width: 55px;
    }
    button{
        border: 0;
        background-color: transparent;
        outline: 0;
        position: absolute;
        top: 0;
        right: 0;
    }
}
.thumbnail-cart {
    border-radius: 10px;
    height: 85px !important;
    object-fit: cover !important;
}
</style>