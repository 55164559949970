<template>
    <footer>
        <div class="row justify-content-center bg-azul">
            <div class="col-10 d-flex justify-content-center align-items-start px-0 pt-5">
                <div class="row justify-content-center row__width">
                    <div class="col-12 d-flex col-lg-3 flex-column justify-content-center justify-content-lg-start align-items-start">
                        <img src="../../img/home-icons/logo-footer.svg" alt="">
                        <h5>{{$t('message.find')}}</h5>
                        <div class="row">
                            <div class="col-12 d-flex justify-content-start align-items-center px-0 pt-2 d-none d-lg-flex">
                                <a href=""><img src="../../img/home-icons/fb-footer.svg" alt=""></a>
                                <a href=""><img src="../../img/home-icons/tw-footer.svg" alt=""></a>
                                <a href=""><img src="../../img/home-icons/pinterest-footer.svg" alt=""></a>
                                <a href=""><img src="../../img/home-icons/linkedin-footer.svg" alt=""></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center px-0 d-lg-none">
                        <a href=""><img src="../../img/home-icons/fb-footer.svg" alt=""></a>
                        <a href=""><img src="../../img/home-icons/tw-footer.svg" alt=""></a>
                        <a href=""><img src="../../img/home-icons/pinterest-footer.svg" alt=""></a>
                        <a href=""><img src="../../img/home-icons/linkedin-footer.svg" alt=""></a>
                    </div>
                    <div class="col-12 col-lg-3 d-flex flex-column justify-content-center justify-content-lg-start align-items-start">
                        <h4>{{$t('message.needHelp')}}</h4>
                        <div class="d-flex justify-content-center align-items-start py-3">
                            <img src="../../img/home-icons/ubi.svg" alt="">
                            <h3>{{$t('message.address')}}</h3>
                        </div>
                        <div class="d-flex justify-content-center align-items-start py-3">
                            <img src="../../img/home-icons/tel.svg" alt="">
                            <h3>{{$t('message.phone')}}</h3>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center my-3 d-lg-none">
                        <div class="accordion row row__width" id="accordionExample">
                            <div class="accordion-item col-12 px-0">
                                <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    {{$t('message.plans')}}
                                </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <h3>1</h3>
                                </div>
                                </div>
                            </div>
                            <div class="accordion-item col-12 px-0">
                                <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    {{$t('message.legal')}}
                                </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                <h3>2</h3>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3 d-flex flex-column justify-content-center justify-content-lg-start align-items-start d-none d-lg-flex">
                        <h4>{{$t('message.formationPlansUppercase')}}</h4>
                        <div class="d-flex justify-content-center align-items-start">
                            <h5 class="planes">{{$t('message.coursesPhotography')}}</h5>
                        </div>
                        <div class="d-flex justify-content-center align-items-start">
                            <h5 class="planes">{{$t('message.coursesFreePhotography')}}</h5>
                        </div>
                        <div class="d-flex justify-content-center align-items-start">
                            <h5 class="planes">{{$t('message.photographyWorkshops')}}</h5>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3 d-flex flex-column justify-content-center justify-content-lg-start align-items-start d-none d-lg-flex">
                        <h4>{{$t('message.legal')}}</h4>
                        <div class="d-flex justify-content-center align-items-start">
                            <a>{{$t('message.noticeOfPrivacy')}}</a>
                        </div>
                        <div class="d-flex justify-content-center align-items-start">
                            <a>{{$t('message.termsV2')}}</a>
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-start d-lg-none">
                        <a href="" class="enlace">{{$t('message.noticePrivacy')}}</a>
                        <a href="" class="enlace">{{$t('message.terms')}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center bg-gris">
            <div class="col-10 d-flex justify-content-center align-items-center max-heigth d-lg-none pt-3">
                <h5>{{$t('message.copy')}}</h5>
            </div>
            <div class="col-10 d-flex justify-content-center align-items-center max-heigth d-lg-none">
                <img src="../../img/home-icons/visa.svg" alt="">
                <img src="../../img/home-icons/mastercard.svg" alt="">
                <img src="../../img/home-icons/american.svg" alt="">
                <img src="../../img/home-icons/paypal.svg" alt="">
            </div>
            
            <div class="col-lg-5 d-flex justify-content-start align-items-center max-heigth d-none d-lg-flex">
                <img src="../../img/home-icons/visa.svg" alt="">
                <img src="../../img/home-icons/mastercard.svg" alt="">
                <img src="../../img/home-icons/american.svg" alt="">
                <img src="../../img/home-icons/paypal.svg" alt="">
            </div>
            <div class="col-lg-5 d-flex justify-content-end align-items-center max-heigth d-none d-lg-flex pt-3">
                <h5>{{$t('message.copy')}}</h5>
            </div>
        </div>
    </footer>
</template>

<script setup>
    
</script>


<style lang="scss" scoped>
footer{
    .bg-azul{
        background-color: var(--colorSecondary);
        @media (max-width: 991px) {
            min-height: 75vh;
        }
        h5{
            font-weight: 400;
            font-size: 14px;
            color: #B8C1DB;
            line-height: 27px;
            padding: 20px 0px;
            text-align: left;
        }
        a{
            margin-left: 10px;
        }
        h4{
            font-weight: 600;
            font-size: 16px;
            color: var(--bgPrincipal);
            padding: 20px 0px;
        }
        h3, .enlace{
            font-weight: 500;
            font-size: 14px;
            color: #B1BBCD;
            padding: 0px 0px 0px 10px;
            text-align: left;
        }
        .accordion-button,.accordion-item{
            background-color: transparent;
            border: 0;
            padding-left: 0;
            padding-right: 0;
            font-weight: 600;
            font-size: 14px;
            color: var(--bgPrincipal);
            text-transform: uppercase;
        }
        .accordion-button::after{
            background-image: url('../../img/home-icons/arrow-blanca-abajo.svg');
            background-repeat: no-repeat;
        }
        .accordion-button:focus{
            box-shadow: none;
        }
        .accordion-button:not(.collapsed){
            border: 0;
            box-shadow: none;
        }
        .enlace{
            text-decoration: none;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 5px 0px;
            padding: 0;
        }
        @media (min-width: 992px) {
            height: 37vh;
            h3{
                font-size: 16px;
                color: #B8C1DB;
                font-weight: 400;
                letter-spacing: 0.3px;
                &:first-child{
                    padding-top: 20px;
                }
            }
            h5, a{
                font-size: 16px;
                padding: 10px 0px;
                line-height: 33px;
                font-weight: 400;
                color: #B8C1DB;
            }
            h4{
                font-family: 'Proxima Nova';
                font-weight: 600;
                font-size: 18px;
            }
            .planes, a{
                line-height: 20px;
            }
        }
    }
    .bg-gris{
        height: 10vh;
        max-height: 84px;
        background-color: #F5F5F5;
        h5{
            color: #B8C1DB;
            font-weight: 400;
            font-size: 14px;
        }
        img{
            margin: 0 5px;
        }
        .max-heigth{
            max-height: 30px;
        }
    }
}
</style>