<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center profesor" v-if="professional">
        <div class="row justify-content-center row__width pt-3">
            <div class="col d-flex justify-content-center align-items-center ps-0 position-relative">
                <img :src="professional.data.banner" alt="" class="" v-if="professional">
            </div>
        </div>
        <div class="row justify-content-center row__width" v-if="professional.data">
            <div class="col-3 d-flex justify-content-center align-items-center px-2">
                <img :src="professional.user.profile_photo_url" alt="" class="perfil" v-if="professional">
            </div>
            <div class="col d-flex justify-content-center align-items-center px-4">
                <div class="row justify-content-center">
                    <div class="col-12 d-flex justify-content-between align-items-center py-1">
                        <h2>{{professional.data.full_name}}</h2><img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" class="d-none">
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center py-1">
                        <img src="../../img/home-icons/cursos-icons/ubi-negro.svg" alt="" class="pe-2"><h3>De {{professional.data.city}}</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center py-1" v-if="product.professions.length > 0">
                        <img src="../../img/home-icons/cursos-icons/camara-gris.svg" alt="" class="pe-2"><h4>{{product.professions[0].name}}</h4>
                    </div>
                    <!-- <div class="col-12 d-flex justify-content-start align-items-center py-1">
                        <img src="../../img/home-icons/cursos-icons/rate.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate-gris.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate-gris.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate-gris.svg" alt="">
                        <h5>{{$t('message.rating')}}</h5>
                        <p>{{$t('message.opinions')}}</p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { inject, ref } from 'vue';

let product = inject('product')
let professional = inject('professional')

</script>


<style lang="scss" scoped>
.mySwiper{
    width: 100%;
    max-width: 100%;
    img{
        max-width: 90%;
        height: 100%;
        border-radius: 10px;
        width: 90%;
        box-shadow: 0px 3px 6px #00000029;
        max-height: 170px;
    }
}
 .profesor{ 
    padding: 20px 0px;
    h2{
        font-weight: 700;
        font-size: 18px;
        color: var(--colorSecondary);
    }
    h3{
        font-weight: 400;
        font-size: 14px;
        color: var(--colorSecondary);
        padding: 0;
    }
    h4{
        font-weight: 400;
        font-size: 14px;
        color: #B8C1DB;
        padding: 0;
    }
    h5{
        font-family: 'Proxima Nova';
        font-weight: 500;
        font-size: 17px;
        color: #FEB230;
        padding: 0px 5px;
    }
    p{
        font-family: 'Proxima Nova';
        font-weight: 500;
        font-size: 17px;
        color: #697891;
    }
    .perfil{
        object-fit: cover;
        height: 102px;
        width: 102px;
        border-radius: 50%;
        @media (min-width: 992px) {
            height: 72px;
            width: 72px;
        }
    }
}

</style>