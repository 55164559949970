<template>
    <div class="row justify-content-center row__width position-relative">
        <swiper-container
            :slidesPerView="1"
            :navigation="{
                nextEl: '#nextSliderMarca',
                prevEl: '#prevSliderMarca'
            }"
            :pagination="true"
            :loop="false"
            :breakpoints="{
                375: {
                    spaceBetween: 20
                },
                768: {
                    spaceBetween: 10,
                    slidesPerView: 2
                },
                992: {
                    spaceBetween: -20,
                    slidesPerView: 2
                },
                1100: {
                    spaceBetween: -160,
                    slidesPerView: 2
                },
                1300: {
                    spaceBetween: 10,
                    slidesPerView: 3
                },
                1400: {
                    spaceBetween: 10,
                    slidesPerView: 3
                },
                1800: {
                    spaceBetween: 10,
                    slidesPerView: 4
                },
            }"
            class="mySwiper" >
            <swiper-slide v-for="(slide, id) in slider" class="d-flex justify-content-start align-items-center">
                <div class="col-12 col-lg d-flex flex-column justify-content-center align-items-center productos">
                    <div class="row justify-content-center row__width ocultar">
                        <div class="col-4 d-flex justify-content-center align-items-center px-0 img__contenedor">
                            <img src="../../img/home-icons/cursos-icons/portafolio-ejemplo.png" alt="" class="img">
                        </div>
                        <div class="col-8 d-flex justify-content-center align-items-center detalles px-0">
                            <div class="row justify-content-center row__width ">
                                <div class="col-12 d-flex justify-content-center align-items-center pe-0">
                                    <div class="row justify-content-center row__width ">
                                        <div class="col-12 d-flex  justify-content-between align-items-start px-0">
                                            <h3>Asesoria Grupal</h3>
                                            <h5><img src="../../img/panel-icons/time.svg" alt=""> 1 hora</h5>
                                        </div>
                                        <div class="col-12 d-flex justify-content-start align-items-center px-0 pb-3 border-bottom">
                                            <h4>Desde 50 USD</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-center align-items-center pe-0 pt-3">
                                    <BTNPrimario textoBTNPrimario="Solicitar"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper-container>
    </div>
</template>

<script setup>
    import BTNPrimario from "../Botones/Primario.vue";
    import { ref } from "vue";

    const slider = ref([
        {modalidad: 0, botonProducto: 0},
        {modalidad: 3, botonProducto: 1},
        {modalidad: 3, botonProducto: 2},
    ])

</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 20px 5px;
    .swiper-wrapper{
        margin: 20px 0;
    }
}
.productos{
    background-color: #fff;
    box-shadow: 0px 10px 12px #868E9633;
    border-radius: 15px;
    min-height: 120px;
    padding: 10px 20px 10px 10px;
    max-height: max-content;
    h5{
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
        text-align: left;
    }
    .span{
        box-shadow: 0px 3px 15px #00000029;
        background-color: #fff;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0%;
        top: -6%;
        img{
            height: 50%;
        }
    }
    .img{
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        height: 100px;
    }
    &:focus, &:hover{
        border: 2px solid var(--colorPrimary);

    }
    .detalles{
        span{
            background-color: var(--colorPrimary);
            height: 50px;
            width: 50px;
            border-radius: 50%;
            font-size: 18px;
            color: #FAFAFA;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        h3{
            font-size: 18px;
            color: var(--colorSecondary);
            font-weight: 700;
            text-align: left;
            padding-bottom: 10px;
        }
        h4{
            font-size: 15px;
            color: #B8C1DB;
            font-weight: 400;
            text-align: left;
        }
        h5{
            font-size: 16px;
            color: #B8C1DB;
            font-weight: 400;
            text-align: left;
            padding-bottom: 10px;
        }
    }
    @media (min-width: 992px) {
        max-width: 400px;
        .img{
            height: 135px;
        }
        .detalles{
            span{
                height: 65px;
                width: 65px;
                font-size: 22px;
            }
            h3{
                font-size: 18px;
            }
            h4, h5{
                font-size: 16px;
            }
        }
    }
}
</style>