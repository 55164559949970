<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center items px-0" v-if="cart">
        <ItemsCarrito v-for="(item, index) in cart"
                        :name="item.name"
                        :discount="item.price.showPrice"
                        :prize="item.price.realPrice"
                        :image="item.image"
                        :id="index"
                    />
    </div>
</template>

<script setup>
    import ItemsCarrito from "./Items.vue";
    import { inject } from 'vue';
    import { storeToRefs } from "pinia"
    import { useCartStore } from "../../stores/cart"

    const carts = useCartStore();
    const { totalPrice, totalDiscount } = storeToRefs(carts);
    const {  } = carts

    let cart = inject('cart')
</script>

<style lang="scss" scoped>

</style>