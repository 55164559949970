<template>
    <div class="container-fluid usuario-sesion">
        <Navbar class="d-lg-none"/>
        <NavbarBuscador class="d-none d-lg-flex"/>
        <div class="row justify-content-center align-items-center mt-lg-5">
            <div class="col-11 mt-2 mt-lg-5 pt-5 d-none d-lg-flex">
                <Volver_Inicio/>
            </div>
            <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                <div class="row justify-content-center row__width titulo">
                    <div class="col-12 d-flex justify-content-center align-items-center my-4">
                        <img src="../../img/plus-icons/pago-aprobado.svg" alt="">
                    </div>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                        <h2>¡Gracias! Su pedido está en estado:</h2><span>Pendiente</span>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center max-width">
                <div class="row justify-content-center row__width detalles" v-if="payment && payment.data">
                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                        <p>{{payment.formattedDate}} | #{{payment.paymentId}} | {{payment.email}}</p>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pb-4" v-for="(price, index) in payment.data.items">
                        <h3 class="title-fix">{{price[0].title}} # {{index + 1}}</h3>
                        <h3>{{price[0].currency_id}} ${{price[0].unit_price}}</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pb-3">
                        <h5>{{$t('message.subtotal')}}:</h5>
                        <h3>{{payment.data.currency_id}} ${{payment.data.items_subtotal}}</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pb-4" >
                        <h5>{{ $t('message.disccounts') }}:</h5>
                        <h3>{{payment.data.currency_id}} - ${{payment.data.items_discount}}</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center px-0 pt-3">
                        <h6>{{ $t('message.total') }}</h6>
                        <h6>{{payment.data.currency_id}} ${{payment.data.items_total}}</h6>
                    </div>
                    <div class="col-12 d-flex justify-content-end align-items-center px-0">
                        <p class="metodo">{{ payment.data.method }}</p>
                    </div>
                </div>
                <BTNPrimario :textoBTNPrimario="$t('message.viewCourseNow')" class="margin-top d-none"/>
                <div class="row justify-content-center row__width pt-lg-4">
                    <div class="col-12  d-flex justify-content-center align-items-center">
                        <p class="recuerda">{{ $t('message.checkYourEmail') }}</p>
                    </div>
                </div>
                <VolverPagina titulo="Volver al inicio" class="d-lg-none"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import Navbar from '../../components/Navs/Navbar.vue';
import Volver_Inicio from '../../components/Volver/Volver_Inicio.vue';
import BTNPrimario from "../../components/Botones/Primario.vue";
import VolverPagina from "../../components/Volver/VolverPagina.vue";
import NavbarBuscador from "../../components/Navs/NavbarBuscador.vue";
import { useUsersStore } from "../../stores/user"
import { useCartStore } from "../../stores/cart"
import { storeToRefs } from "pinia"
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
import { ref, onMounted, onBeforeMount } from 'vue'

const user = useUsersStore()
const {  } = storeToRefs(user)
const { url } = user;

const carts = useCartStore()
const { cart, totalPrice, totalDiscount } = storeToRefs(carts)
const {  } = carts

const urlPaymentResult = '/api/payment/get/info/' // external_reference

const router = useRouter()
const route = useRoute()
console.log(route.query)

const payment = ref({})

const checkJSON = (data) => {
    try {
        // Intenta parsear y luego convertir de nuevo a string
        return JSON.parse(data);
    } catch (e) {
        // Si hay un error, simplemente retorna el dato original
        return data;
    }
}

const fetchPayment = async() => {
    cart.value = []
    localStorage.removeItem('Cart')
    let externalReference = route.query.external_reference
    try {
        await axios.get(url+urlPaymentResult+externalReference).then((result) => {
            console.log(result)
            payment.value = result.data
            payment.value.data = checkJSON(result.data.data)
            //Setea la fecha
            let date = new Date(result.data.created_at)
            let day = date.getDate()
            let month = date.getMonth() + 1
            let year = date.getFullYear()
            //Setea el id de pago
            payment.value.data.items_total = 0
            payment.value.data.items_subtotal = 0
            payment.value.data.items_discount = 0
            payment.value.data.items.forEach((element) => {
                payment.value.data.items_total += element[0].unit_price
            })
            payment.value.data.currency_id = result.data.data.items[0][0].currency_id
            payment.value.data.method = result.data.data.items[0][1].method
            payment.value.formattedDate = `${day}/${month}/${year}`
            payment.value.paymentId = route.query.collection_id
            payment.value.email = result.data.user.email
            result.data.data.items.forEach(element => {
                payment.value.data.items_subtotal += Number(element[1].item_price.toString().slice(0, -1))
                payment.value.data.items_discount += Number((element[1].original_discount_converted).toString().slice(0, -1))
            })
        })
    } catch (error) {
        console.log(error)
    }
}

onBeforeMount(async() => {
    await fetchPayment()
})
</script>

<style lang="scss" scoped>
.max__width__login__Desk{
    @media (min-width: 992px) {
        max-width: 30%;
    }
    @media (min-width: 1200px) {
        max-width: 51%;
    }
}
.margin-top{
    margin-top: 5vh;
}
.titulo{
    h2{
        font-weight: 700;
        font-size: 32px;
        color: var(--colorSecondary);
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 767px) {
            font-size: 22px;
        }
    }
    span{
        font-size: 32px;
        font-weight: 700;
        color: var(--colorPrimary);
        padding-left: 5px;
        @media (max-width: 767px) {
            font-size: 22px;
        }
    }
}
.detalles{
    p{
        font-size: 14px;
        font-weight: 400;
        color: #7D93B2;
        padding: 3vh 0;
        @media (min-width: 400px) {
            font-size: 16px;
        }
    }
    h3{
        font-weight: 500;
        font-size: 16px;
        color: var(--colorSecondary);
    }
    h5{
        font-weight: 700;
        font-size: 16px;
        color: var(--colorSecondary);
    }
    h6{
        font-size: 22px;
        font-weight: 700;
        color: var(--colorSecondary);
    }
    .metodo{
        font-weight: 400;
        font-size: 16px;
        color: #7D93B2;
        padding: 0;
    }
}
.recuerda{
    font-weight: 500;
    font-size: 14px;
    color: var(--colorSecondary);
    padding: 20px 0;
}
.max-width{
    @media (max-width: 768px) {
        width: 389px !important;
    }
    @media (min-width: 768px) {
        width: 515px !important;
    }
}
</style>