<template>
    <div class="row justify-content-center row__width contenedor">
        <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0 ">
            <div class="row justify-content-center row__width padding h-100">
                <!-- <AtrasAdmin/> -->
                <div class="col-12 col-lg-7 px-0 d-flex flex-column justify-content-center align-items-center mb-4">
                    <TituloPanelAdmin :title="$t('message.manageUsers')" :subtitle="$t('message.seeAndManageUsers')"/>
                </div>
                <div class="col-12 col-lg-4 px-0 px-lg-auto d-flex justify-content-start justify-content-lg-end align-items-center">
                    <button type="button" class="boton-nuevo-usuario" @click="page_id = 2">
                        <span>
                            <img src="../../../img/panel-icons/mas.svg" alt="">
                        </span>
                        {{ $t('message.addNewUser') }}
                    </button>
                </div>
            </div>
            <div class="row justify-content-center row__width px-lg-5 border-top pt-3">
                <div class="col-12 d-flex justify-content-center align-items-center div px-3 pb-2">
                    <div class="row justify-content-center row__width " >
                        <div class="col-12 d-flex justify-content-center align-items-center filtrosMobile">
                            <div class="row justify-content-start align-items-center row__width">
                                <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start">
                                    <h5>{{ $t('message.users') }}</h5>
                                    <h6 v-if="users_data">{{ users_data.total }} {{ $t('message.actives') }}</h6>
                                </div>
                                <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center contenedor px-0">
                                    <input type="text" :placeholder="$t('message.placeholderSearch')" v-model="search_query">
                                    <button type="button" @click="'' /* getSearchResults($event.target.value) */"><img src="../../../img/home-icons/lupa.svg" alt=""></button>
                                </div>
                                <div class="col col-sm-4 col-md-3 col-lg-3 d-flex justify-content-center align-items-center search-select ps-3 pe-0 my-3">
                                    <MazSelect
                                        class="row__width"
                                        :placeholder="$t('message.categories')"
                                        color="info"
                                        :options="options"
                                        name="role"
                                        search
                                        search-placeholder="Buscar en la lista"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido px-0">
                            <div class="row justify-content-center row__width scroll my-3">
                                <Usuarios v-for="(user, index) in users_data.users" :user="user" :index="index" :key="user.id" v-if="users_data"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import MazSelect from 'maz-ui/components/MazSelect'
    import Usuarios from "../../../components/Panel/Admin/Usuarios/Usuarios.vue";
    import TituloPanelAdmin from "../../../components/Titulo/TituloPanelAdmin.vue";
    import { storeToRefs } from "pinia"
    import { useUsersStore } from "../../../stores/user"
    import { onBeforeMount, ref, provide, inject, watch } from 'vue';
    import { useRouter } from 'vue-router';
    import axios from 'axios';

    const router = useRouter()

    const users = useUsersStore();
    const { access, headers } = storeToRefs(users);
    const { validate, url } = users;

    const algo = onBeforeMount( () => {
        access.value = [1]
        //console.log(access.value) 
        // validate();
    }) 

    let page_id = inject('page_id')

    const users_data = inject('users_data')

    const options = [
        {
            label: 'Label 1',
            value: true
        },
        {
            label: 'Label 2',
            value: true
        },
        {
            label: 'Label 3',
            value: true
        },
    ]
    
    const search_query = ref('')
    const search_results_list = ref([])
    const is_searching = ref(false)

    const getSearchResults = async (query) => {
        if (!is_searching.value) {
            is_searching.value = true
            try {
                search_results_list.value = []
                const response = await axios.get(`${url}/api/public/professionals/search?name=${query}`)
                console.log(response)
                // search_results_list.value = response.data.data
                for (let i = 0; i < 3; i++) {
                    search_results_list.value.push(response.data.data[i])
                }
            } catch (error) {
                console.log(error)
            }
            is_searching.value = false
        }
    }

</script>

<style lang="scss" scoped>
.contenedor{
    box-shadow: 0px 3px 10px #00000029;
    border: 1px solid #ECECEC;
    background-color: var(--bgPrincipal);
    border-radius: 40px;
    min-height: 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
    @media (min-width: 992px) {
        border-radius: 50px;
        padding: 22px 15px;
    }
    @media (min-width: 992px) {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding: 40px 0 30px 0;
        min-height: auto;
    } 
    .boton-nuevo-usuario{
        box-shadow: 0 3px 6px #00000016;
        border-radius: 43px;
        height: 60px;
        margin-bottom: 30px;
        border: 0;
        background-color: var(--bgPrincipal);
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
        padding-bottom: 5px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 15px;
        width: 100%;
        max-width: 300px;
        span{
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            min-width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .filtrosMobile{
        h5{
            color: var(--colorSecondary);
            font-weight: 700;
            font-size: 22px;
            text-align: left;
        }
        h6{
            color: #697891;
            font-weight: 500;
            font-size: 16px;
            text-align: left;
            padding-top: 10px;
        }
        .contenedor{
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #ECECEC;
            background-color: var(--bgPrincipal);
            border-radius: 40px;
            height: 60px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 15px;
            @media (min-width: 992px) {
                border-radius: 40px;
            }
            @media (max-width: 991px) {
                width: 100%;
            }
            input{
                border: 0;
                color: #B8C1DB;
                font-weight: 400;
                font-size: 14px;
                width: 100%;
                &::placeholder{
                    color: #B8C1DB;
                }
                &:focus{
                    outline: 0;
                }
                @media (min-width: 992px) {
                    color: #697891;
                    font-size: 16px;
                    width: 93%;
                }
            }
            button{
                position: absolute;
                right: 7px;
                border: 0;
                min-height: 45px;
                width: 45px;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 50%;
                background-color: var(--colorPrimary);
                justify-content: center;
                align-items: center;
                img{
                    padding: 0;
                    max-height: 22px;
                }
                @media (min-width: 992px) {
                    right: 10px;
                }
            }
        }
    }
}

.scroll {
    overflow-y: scroll;
}

.contenido {
    max-height: 60dvh;
}

.search-select{
    max-width: 200px;
}

.skeleton-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e0e0e0;
    margin-right: 10px;
}

.skeleton-text {
    width: 150px;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 4px;
}

.skeleton-avatar,
.skeleton-text {
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #f0f0f0;
    }
    100% {
        background-color: #e0e0e0;
    }
}
</style>