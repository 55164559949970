<template>
    <div class="row justify-content-center justify-content-lg-start my-3 row__width" :style="filtrosMapa == true ? 'z-index: -1;' : 'z-index: 1;'" v-if="sliderLength >= 1">
        <swiper-container
            :navigation="{
                nextEl: '#nextSliderCursosboton',
                prevEl: '#prevSliderCursosboton'
            }"
            :pagination="{
                el: '#pagCursos',
                type: 'fraction',
                currentClass: 'paginationCurrentStyle',
                totalClass: 'paginationTotalStyle'
            }"
            :slides-per-view="3"
            :slides-per-group="3"
            :spaceBetween="20"
            :breakpoints="{
                375: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                992: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                1875: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
            }"
            @swiper="onSwiper"
            class="mySwiper">
            <swiper-slide v-for="(slide, id) in sliderLength" class="d-flex justify-content-center align-items-center">
                <CursosFotografiaSlideV2    :rName="childProducts[id].names[lang]"
                                            :rSlug="childProducts[id].slug[lang]"
                                            :rOwner="childProducts[id].owner"
                                            :rOwnerAvatar="childProducts[id].owner_avatar"
                                            :rImage="childProducts[id].image"
                                            :rClas="childProducts[id].class[lang]"
                                            :rModality="childProducts[id].type[lang]"
                                            :rRealPrice="childProducts[id].realPrice"
                                            :rPrice="childProducts[id].showPrice"
                                            :rVerified="false"
                                            :rProductVerified="false"
                                            :rCreatedAt="0"
                                            :rCollabs="false" :key="componentKey"/>
                                            
            </swiper-slide>
        </swiper-container>
    </div>
    <div v-else class="pt-5 pb-5">
        <h1>{{ $t('message.withoutResults') }}</h1>
    </div>
    <Colaboradores/>
</template>

<script setup>
import Colaboradores from "../Modales/Colaboradores.vue";

import CursosFotografiaSlideV2 from "./CursosFotografiaV2.vue";
import { useGeneralStore } from "../../stores/general"
import { useConfigStore } from "../../stores/config"
import { storeToRefs } from "pinia";
import { ref, watch, nextTick, inject, onMounted } from 'vue';
import { useI18n } from "vue-i18n";
import axios from 'axios';

const general = useGeneralStore();
const {filtrosMapa, filtrosDesplegados, mapaDesplegado, reSearch} = storeToRefs(general);
const {} = general;

const config = useConfigStore();
const { coinSelected } = storeToRefs(config);

const {locale} = useI18n({ useScope: 'global' })
const lang = ref()
const X = ref('')

const url = process.env.VUE_APP_MEILISEARCH_URL
const urlApi = '/multi-search'

let priceRange = inject('priceRange')

//Setea parametros de busqueda para el post
const search = ref({
    queries:[{
        indexUid: 'product_products',
        q:''
    }]
})

//Precarga parametros de resultados de busqueda
let childProducts = ref(0)
const sliderLength = ref(0)
let componentKey = ref(0)

const onSwiper = (swiper) => {
    console.log(swiper)
    swiper.pagination.render()
}

//Setea valores segun el lenguaje -- POSIBLEMENTE NO SE USE EN EL FUTURO --
const setLang = () => {
    lang.value = locale.value
    //console.log(lang.value)
}
setLang()

//Hago un post del usuario x
const fetchSearch = async () => {
    let prod = null
    let moda = null
    let prof = null
    let spec = null
    let lang = 'es'
    delete search.value.queries[0].filter
    if (sessionStorage.getItem('searchValue')){
        X.value = JSON.parse(sessionStorage.getItem('searchValue'))
        search.value.queries[0].q = X.value
    }
    if(sessionStorage.getItem('searchValueProd')){
        prod = JSON.parse(sessionStorage.getItem('searchValueProd'))
        prod.forEach(product => {
            if(!search.value.queries[0].filter){
                search.value.queries[0].filter = `class.${lang} = '${product.name}'`
            } else{
                search.value.queries[0].filter += ` AND class.${lang} = '${product.name}'`
            }
        })
    }
    if(sessionStorage.getItem('searchValueModality')){
        moda = JSON.parse(sessionStorage.getItem('searchValueModality'))
        moda.forEach(modality => {
            if(!search.value.queries[0].filter){
                search.value.queries[0].filter = `type.${lang} = '${modality.name}'`
            } else{
                search.value.queries[0].filter += ` AND type.${lang} = '${modality.name}'`
            }
        })
    }
    if(sessionStorage.getItem('searchValueProf')){
        prof = JSON.parse(sessionStorage.getItem('searchValueProf'))
        prof.forEach(profession => {
            if(!search.value.queries[0].filter){
                search.value.queries[0].filter = `professions.name.${lang} = '${profession.name}'`
            } else{
                search.value.queries[0].filter += ` AND professions.name.${lang} = '${profession.name}'`
            }
        })
    }
    if(sessionStorage.getItem('searchValueSpec')){
        spec = JSON.parse(sessionStorage.getItem('searchValueSpec'))
        spec.forEach(speciality => {
            if(!search.value.queries[0].filter){
                search.value.queries[0].filter = `specializations.name.${lang} = '${speciality.name}'`
            } else{
                search.value.queries[0].filter += ` AND specializations.name.${lang} = '${speciality.name}'`
            }
        })
    }
    if(priceRange){
        if(priceRange.value.minimum && !search.value.queries[0].filter){
            search.value.queries[0].filter = `prices.price >= '${priceRange.value.minimum}'`
        } else if(priceRange.value.minimum && search.value.queries[0].filter){
            search.value.queries[0].filter += ` AND prices.price >= '${priceRange.value.minimum}'`
        }
        
        if(priceRange.value.maximum && !search.value.queries[0].filter){
            search.value.queries[0].filter = `prices.price <= '${priceRange.value.maximum}'`
        } else if(priceRange.value.maximum && search.value.queries[0].filter){
            search.value.queries[0].filter += ` AND prices.price <= '${priceRange.value.maximum}'`
        }
    }
    setLang()
    try {
        await axios.post(url+urlApi, search.value).then((result) => {
            childProducts.value = null
            sliderLength.value = null
            console.log(result)
            nextTick(() => {
                childProducts.value = result.data.results[0].hits
                sliderLength.value = result.data.results[0].hits.length
                managePrices()
            })
            sessionStorage.removeItem('searchValue')
        })
    }
    catch (error) {
        console.log(error)
    }
}
fetchSearch()

watch( reSearch, async () => {
    let prod = null
    let moda = null
    let prof = null
    let spec = null
    let lang = 'es'
    delete search.value.queries[0].filter
    if (sessionStorage.getItem('searchValue')){
        X.value = JSON.parse(sessionStorage.getItem('searchValue'))
        search.value.queries[0].q = X.value
    }
    if(sessionStorage.getItem('searchValueProd')){
        prod = JSON.parse(sessionStorage.getItem('searchValueProd'))
        prod.forEach(product => {
            if(!search.value.queries[0].filter){
                search.value.queries[0].filter = `class.${lang} = '${product.name}'`
            } else{
                search.value.queries[0].filter += ` AND class.${lang} = '${product.name}'`
            }
        })
    }
    if(sessionStorage.getItem('searchValueModality')){
        moda = JSON.parse(sessionStorage.getItem('searchValueModality'))
        moda.forEach(modality => {
            if(!search.value.queries[0].filter){
                search.value.queries[0].filter = `type.${lang} = '${modality.name}'`
            } else{
                search.value.queries[0].filter += ` AND type.${lang} = '${modality.name}'`
            }
        })
    }
    if(sessionStorage.getItem('searchValueProf')){
        prof = JSON.parse(sessionStorage.getItem('searchValueProf'))
        prof.forEach(profession => {
            if(!search.value.queries[0].filter){
                search.value.queries[0].filter = `professions.name.${lang} = '${profession.name}'`
            } else{
                search.value.queries[0].filter += ` AND professions.name.${lang} = '${profession.name}'`
            }
        })
    }
    if(sessionStorage.getItem('searchValueSpec')){
        spec = JSON.parse(sessionStorage.getItem('searchValueSpec'))
        spec.forEach(speciality => {
            if(!search.value.queries[0].filter){
                search.value.queries[0].filter = `specializations.name.${lang} = '${speciality.name}'`
            } else{
                search.value.queries[0].filter += ` AND specializations.name.${lang} = '${speciality.name}'`
            }
        })
    }
    if(priceRange.value.minimum && !search.value.queries[0].filter){
        search.value.queries[0].filter = `prices.price >= '${priceRange.value.minimum}'`
    } else if(priceRange.value.minimum && search.value.queries[0].filter){
        search.value.queries[0].filter += ` AND prices.price >= '${priceRange.value.minimum}'`
    }
    
    if(priceRange.value.maximum && !search.value.queries[0].filter){
        search.value.queries[0].filter = `prices.price <= '${priceRange.value.maximum}'`
    } else if(priceRange.value.maximum && search.value.queries[0].filter){
        search.value.queries[0].filter += ` AND prices.price <= '${priceRange.value.maximum}'`
    }
    setLang()
    try {
        await axios.post(url+urlApi, search.value).then((result) => {
            childProducts.value = null
            sliderLength.value = null
            console.log(result)
            nextTick(() => {
                childProducts.value = result.data.results[0].hits
                sliderLength.value = result.data.results[0].hits.length
                managePrices()
            })
            sessionStorage.removeItem('searchValue')
        })
    }
    catch (error) {
        console.log(error)
    }
})

const managePrices = () => {
    if(childProducts.value.length > 0){
        childProducts.value.forEach(prod => {
            if(Array.isArray(prod.prices) && prod.prices.length > 0){
                if(prod.prices[0].price > 0){
                    if(prod.prices[0].coin_type[0].abbr != coinSelected.value){
                        let rates = JSON.parse(localStorage.getItem('rates'))
                        let selectedRate = rates.find(r => r.from == prod.prices[0].coin_type[0].abbr && r.to == coinSelected.value)
                        prod.showPrice = parseFloat((prod.prices[0].price * selectedRate.rate).toFixed(2))
                    } else {
                        prod.showPrice = prod.prices[0].price
                    }
                    if(prod.prices[0].discount > 0){
                        prod.realPrice = parseFloat((prod.showPrice - (prod.showPrice * (prod.prices[0].discount / 100))).toFixed(2))
                    } else{
                        prod.realPrice = prod.showPrice
                        prod.showPrice = null
                    }
                } else{
                    // producto gratuito
                    prod.realPrice = 'GRATIS'
                    prod.showPrice = null
                }
            } else if(typeof prod.prices === 'object' && prod.prices !== null){
                if(prod.prices.price > 0){
                    if(prod.prices[0].coin_type[0].abbr != coinSelected.value){
                        let rates = JSON.parse(localStorage.getItem('rates'))
                        let selectedRate = rates.find(r => r.from == prod.prices[0].coin_type[0].abbr && r.to == coinSelected.value)
                        prod.showPrice = parseFloat((prod.prices[0].price * selectedRate.rate).toFixed(2))
                    } else {
                        prod.showPrice = prod.prices[0].price
                    }
                    if(prod.prices.discount > 0){
                        prod.realPrice = parseFloat((prod.prices.price - (prod.prices.price * (prod.prices.discount / 100))).toFixed(2))
                    } else{
                        prod.realPrice = prod.showPrice
                        prod.showPrice = null
                    }
                } else{
                    // producto gratuito
                    prod.realPrice = 'GRATIS'
                    prod.showPrice = null
                }
            }
        })
    }
    componentKey.value++
}

watch( coinSelected, async () => {
    managePrices()
}, {deep: true})
</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 20px 5px;
    margin: 20px 0;
}
swiper-slide {
  height: auto !important;
}
@media (max-width: 991px){
    .mySwiper3 swiper-slide{
        padding-bottom: 10px;
    }
}

</style>