<template>
    <div class="row justify-content-center justify-content-lg-start my-3 row__width" v-if="sliderLength >= 1">
        <swiper
            :navigation="{
                nextEl: '#nextSliderCursosboton',
                prevEl: '#prevSliderCursosboton'
            }"
            :pagination="{
                el: '#pagCursos',
                type: 'fraction',
                currentClass: 'paginationCurrentStyle',
                totalClass: 'paginationTotalStyle'
            }"
            :slidesPerView="3"
            :slidesPerGroup="3"
            :breakpoints="{
                992: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                1920: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
            }"
            @swiper="onSwiper"
            class="mySwiper">
            <swiper-slide v-for="(slide, id) in sliderLength" class="d-flex justify-content-center align-items-center">
                <!-- <CursosFotografiaSlideV2    :rName="childProducts[id].names[lang]"
                                            :rSlug="childProducts[id].slug[lang]"
                                            :rOwner="childProducts[id].owner"
                                            :rImage="childProducts[id].image"
                                            :rClas="childProducts[id].class[lang]"
                                            :rModality="childProducts[id].modality[lang]"
                                            :rMaxPrice="childProducts[id].detail.max_price"
                                            :rMinPrice="childProducts[id].detail.min_price"
                                            :rVerified="childProducts[id].slug"
                                            :rProductVerified="childProducts[id].slug"
                                            :rCreatedAt="childProducts[id].detail.created_at"
                                            :rCollabs="childProducts[id].detail.collabs"/> -->
            </swiper-slide>
        </swiper>
    </div>
    <div v-else class="pt-5 pb-5">
        <h1>{{ $t('message.withoutResults') }}</h1>
    </div>
</template>

<script setup>
import CursosFotografiaSlideV2 from "./CursosFotografiaV2.vue";

import { useGeneralStore } from "../../stores/general"
import { storeToRefs } from "pinia";
import { ref, watch, nextTick } from 'vue';
import { useI18n } from "vue-i18n";
import axios from 'axios';

import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Grid, Pagination, column } from "swiper";

const general = useGeneralStore();
const {filtrosDesplegados, mapaDesplegado, reSearch} = storeToRefs(general);
const {} = general;

const {locale} = useI18n({ useScope: 'global' })
const lang = ref()
const X = ref('')

const url = process.env.VUE_APP_MEILISEARCH_URL
const urlApi = '/multi-search'

//Setea parametros de busqueda para el post
const search = ref({
    queries:[{
        indexUid: 'product_products',
        q:''
    }]
})

//Precarga parametros de resultados de busqueda
const childProducts = ref(0)
const sliderLength = ref(0)

//Toma el elemento swiper
const onSwiper = (swiper) => {
    //console.log(swiper)
    swiper.pagination.init()
    swiper.pagination.render()
};

//Setea valores segun el lenguaje -- POSIBLEMENTE NO SE USE EN EL FUTURO --
const setLang = () => {
    lang.value = locale.value
    //console.log(lang.value)
}
setLang()

//Hago un post del usuario x
const fetchSearch = async () => {
    let prod = null
    let moda = null
    let prof = null
    let spec = null
    let lang = 'es'
    delete search.value.queries[0].filter
    if (sessionStorage.getItem('searchValue')){
        X.value = JSON.parse(sessionStorage.getItem('searchValue'))
        search.value.queries[0].q = X.value
    }
    if(sessionStorage.getItem('searchValueProd')){
        prod = JSON.parse(sessionStorage.getItem('searchValueProd'))
        prod.forEach(product => {
            if(!search.value.queries[0].filter){
                search.value.queries[0].filter = `class.${lang} = '${product.name}'`
            } else{
                search.value.queries[0].filter += ` AND class.${lang} = '${product.name}'`
            }
        })
    }
    if(sessionStorage.getItem('searchValueModality')){
        moda = JSON.parse(sessionStorage.getItem('searchValueModality'))
        moda.forEach(modality => {
            if(!search.value.queries[0].filter){
                search.value.queries[0].filter = `type.${lang} = '${modality.name}'`
            } else{
                search.value.queries[0].filter += ` AND type.${lang} = '${modality.name}'`
            }
        })
    }
    if(sessionStorage.getItem('searchValueProf')){
        prof = JSON.parse(sessionStorage.getItem('searchValueProf'))
        prof.forEach(profession => {
            if(!search.value.queries[0].filter){
                search.value.queries[0].filter = `professions.name.${lang} = '${profession.name}'`
            } else{
                search.value.queries[0].filter += ` AND professions.name.${lang} = '${profession.name}'`
            }
        })
    }
    if(sessionStorage.getItem('searchValueSpec')){
        spec = JSON.parse(sessionStorage.getItem('searchValueSpec'))
        spec.forEach(speciality => {
            if(!search.value.queries[0].filter){
                search.value.queries[0].filter = `specializations.name.${lang} = '${speciality.name}'`
            } else{
                search.value.queries[0].filter += ` AND specializations.name.${lang} = '${speciality.name}'`
            }
        })
    }
    setLang()
    try {
        await axios.post(url+urlApi, search.value).then((result) => {
            childProducts.value = null
            sliderLength.value = null
            console.log(result)
            nextTick(() => {
                childProducts.value = result.data.results[0].hits
                sliderLength.value = result.data.results[0].hits.length
            })
            sessionStorage.setItem('searchValue', "")
        })
    }
    catch (error) {
    console.log(error)
    }
}
fetchSearch()

watch( reSearch, async () => {
    let prod = null
    let moda = null
    let prof = null
    let spec = null
    let lang = 'es'
    delete search.value.queries[0].filter
    if (sessionStorage.getItem('searchValue')){
        X.value = JSON.parse(sessionStorage.getItem('searchValue'))
        search.value.queries[0].q = X.value
    }
    if(sessionStorage.getItem('searchValueProd')){
        prod = JSON.parse(sessionStorage.getItem('searchValueProd'))
        prod.forEach(product => {
            if(!search.value.queries[0].filter){
                search.value.queries[0].filter = `class.${lang} = '${product.name}'`
            } else{
                search.value.queries[0].filter += ` AND class.${lang} = '${product.name}'`
            }
        })
    }
    if(sessionStorage.getItem('searchValueModality')){
        moda = JSON.parse(sessionStorage.getItem('searchValueModality'))
        moda.forEach(modality => {
            if(!search.value.queries[0].filter){
                search.value.queries[0].filter = `type.${lang} = '${modality.name}'`
            } else{
                search.value.queries[0].filter += ` AND type.${lang} = '${modality.name}'`
            }
        })
    }
    if(sessionStorage.getItem('searchValueProf')){
        prof = JSON.parse(sessionStorage.getItem('searchValueProf'))
        prof.forEach(profession => {
            if(!search.value.queries[0].filter){
                search.value.queries[0].filter = `professions.name.${lang} = '${profession.name}'`
            } else{
                search.value.queries[0].filter += ` AND professions.name.${lang} = '${profession.name}'`
            }
        })
    }
    if(sessionStorage.getItem('searchValueSpec')){
        spec = JSON.parse(sessionStorage.getItem('searchValueSpec'))
        spec.forEach(speciality => {
            if(!search.value.queries[0].filter){
                search.value.queries[0].filter = `specializations.name.${lang} = '${speciality.name}'`
            } else{
                search.value.queries[0].filter += ` AND specializations.name.${lang} = '${speciality.name}'`
            }
        })
    }
    setLang()
    try {
        await axios.post(url+urlApi, search.value).then((result) => {
            childProducts.value = null
            sliderLength.value = null
            console.log(result)
            nextTick(() => {
                childProducts.value = result.data.results[0].hits
                sliderLength.value = result.data.results[0].hits.length
            })
            sessionStorage.removeItem('searchValue')
        })
    }
    catch (error) {
    console.log(error)
    }
})

</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 20px 0px;
    margin: 20px 0;
}
</style>