<template>
    <div class="row justify-content-center servicios row__width">
        <div class="col-12 d-flex flex-column justify-content-center align-items-start align-items-lg-center px-0 pb-lg-4">
            <h2>{{titulo}}</h2>
            <h4 v-if="haySubtitulo">{{subtitulo}}</h4>
        </div>
        <div class="col-12 col-lg-10 d-flex justify-content-center align-items-center px-0">
            <div class="row justify-content-start justify-content-lg-center align-items-center row__width">
                <Categorias v-for="(categoria, id) in categoriasNuevo" :key="id" :titulo="categoriasNuevo[id].nombre" :src="categoriasNuevo[id].src" :dLgFLex="categoriasNuevo[id].dLgFlex"/>
                <CategoriasVerMas/>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useGeneralStore } from "../../stores/general"
import { storeToRefs } from "pinia";
import Categorias from "./Categorias.vue";
import CategoriasVerMas from "./CategoriasVerMas.vue";

const props = defineProps({
    titulo: '',
    subtitulo: '',
    haySubtitulo :{
        type: Boolean,
        default: false
    }
})
    const general = useGeneralStore();
const {categoriasNuevo} = storeToRefs(general);
const {} = general;
</script>

<style lang="scss" scoped>
.servicios{
    margin-top: 20px;
}
h2{
    font-size: 28px;
    font-weight: 700;
    color: var(--colorSecondary);
    text-align: center;
    position: relative;
}
h4{
    color: #7D93B2;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    padding: 10% 0 7% 0;
}
</style>