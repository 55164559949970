<template>
    <div class="col-12 d-flex justify-content-center align-items-center px-0">
        <div class="row justify-content-start row__width modulos">
            <!-- <div class="col-12 col-lg-3 d-flex justify-content-center align-items-center" v-if="response.participants.length > 0">
                <a href="">
                    <div class="d-flex justify-content-end justify-content-lg-start align-items-center px-0 cupos">
                        <img src="" alt="" class="img1">
                        <img src="" alt="" class="img2">
                        <img src="" alt="" class="img3">
                        <div class="div"></div>
                    </div>
                    <h4>{{ participants }}</h4>
                </a>
            </div> -->
            <div class="col-12 col-lg-3 d-flex justify-content-center align-items-center" v-if="response.units">
                <a href="" class="row">
                    <div class="col-6 col-lg-2 d-flex justify-content-start justify-content-lg-center align-items-center">
                        <img src="../../img/perfil-icons/video.svg" alt="" class="video">
                        <h3 class="d-lg-none">Módulos</h3>
                    </div>
                    <div class="col-6 col-lg d-flex flex-column justify-content-center align-items-end align-items-lg-start ">
                        <h3 class="d-none d-lg-flex">Módulos</h3>
                        <h4 v-if="response.units.length == 1">{{ response.units.length }} módulo</h4>
                        <h4 v-else>{{ response.units.length }} módulos</h4>
                    </div>
                </a>
            </div>
            <div class="col-12 col-lg-3 d-flex justify-content-center align-items-center" v-if="response.units">
                <a href="" class="row" v-if="totalTimeVideo">
                    <div class="col-6 col-lg-2 d-flex justify-content-start justify-content-lg-center align-items-center">
                        <img src="../../img/perfil-icons/time.svg" alt="" class="video">
                        <h3 class="d-lg-none">Tiempo</h3>
                    </div>
                    <div class="col-6 col-lg d-flex flex-column justify-content-center align-items-end align-items-lg-start ">
                        <h3 class="d-none d-lg-flex">Tiempo</h3>
                        <h4>{{ totalTimeVideo[0] }}hs {{ totalTimeVideo[1] }}mins</h4>
                    </div>
                </a>
            </div>
            <div class="col-12 col-lg-3 d-flex justify-content-center align-items-center" v-if="response">
                <a href="" class="row">
                    <div class="col-6 col-lg-2 d-flex justify-content-start justify-content-lg-center align-items-center">
                        <img src="../../img/perfil-icons/resources.svg" alt="" class="video">
                        <h3 class="d-lg-none">{{ response.totalResources }} recursos</h3>
                    </div>
                    <div class="col-6 col-lg d-flex flex-column justify-content-center align-items-end align-items-lg-start ">
                        <h3 class="d-none d-lg-flex">Recursos </h3>
                        <h4>{{ response.totalResources }} recursos</h4>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject } from "vue";
import { useVideoStore } from "../../stores/video";
import { storeToRefs } from "pinia";


const video = useVideoStore();
const {totalTimeVideo} = storeToRefs(video);
const {} = video;

let response = inject('response')
</script>

<style lang="scss" scoped>

.modulos{
    .cupos{
        padding: 20px 0px;
        position: relative;
        img{
            height: 35px;
            border-radius: 50%;
            border: 2px solid var(--bgPrincipal);
            position: relative;
            object-fit: cover;
        }
        @media (min-width: 992px) {
            width: 70px;
        }
        .fotos{
            max-width: 80%;
            overflow: hidden;
        }
        .img2{
            left: -15px;
            @media (min-width: 992px) {
                position: absolute;
                left: 30%;
            }
        }
        .img3{
            left: -25px;
            @media (min-width: 992px) {
                position: absolute;
                left:60%;
            }
        }
        .div{
            border: 2px solid var(--bgPrincipal);
            background-color: var(--colorSecondary);
            border: 2px solid var(--bgPrincipal);
            border-radius: 50px;
            width: 32px;
            height: 32px;
            color: var(--bgPrincipal);
            font-weight: 500;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            left: -35px;
            @media (min-width: 992px) {
                position: absolute;
                left: 90%;
            }
        }
        
    }
    a{
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        background-color: #FFFFFF;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        margin-bottom: 10px;
        height: 62px;
        padding: 0 10px;
        h3{
            font-weight: 500;
            font-size: 18px;
            color: var(--colorSecondary);
        }
        h4{
            font-size: 16px;
            font-weight: 500;
            color: #697891;
        }
        .video{
            max-height: 23px;
            padding-right: 5px;
        }
        @media (min-width: 992px) {
            height: 80px;
            .video{
                padding-right: 0;
            }
            h3, .cupos + h4{
                color: var(--colorSecondary);
            }
            .cupos + h4{
                
                font-size: 16px;
            }
        }
    }
}
</style>