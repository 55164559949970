<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-start mt-4">
        <div class="row justify-content-center row__width width">
            <div class="col-12 d-flex justify-content-between align-items-center px-0" v-if="information">
                <h3>{{ $t('message.description') }}</h3>
                <p>{{information.description.length}}/400</p>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center px-0 position-relative">
                <span>
                    <h6>B</h6>
                </span>
                <textarea name="" id="" placeholder="Escribir aqui" cols="30" rows="10" maxlength="400" v-model="information.description"></textarea>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useGeneralStore } from "../../../stores/general"    
import { storeToRefs } from "pinia";
import { inject } from "vue";

const information = inject('information')
</script>

<style lang="scss" scoped>
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
p{
    color: var(--colorPrimary);
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    padding: 7px 0px;
}
.width{
    width: -webkit-fill-available;
}
textarea{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: #FCFCFC;
    height: 20vh;
    padding-top: 50px;
    padding-left: 15px;
    outline: 0;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        
        color: #697891;
    }
    
}
span{
    background-color: #E4E4E4;
    height: 40px;
    width: 99.5%;
    position: absolute;
    top: 2px;
    border-radius: 10px 10px 0 0 ;
    padding: 10px 15px;
    h6{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 600;
        text-align: left;
    }
}

</style>