<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-start mt-3 px-0 colaborators skills" v-if="isAdmin">
        <h3>Añadir dueño</h3>
        <multiselect2 
        v-model="information.creator"
        mode="tags"
        :close-on-select="false"
        :searchable="true"
        :create-option="false"
        :options="options"
        label="full_name" trackBy="full_name" valueProp="id" :multipleLabel="multipleLabel" :placeholder="$t('message.select')" :noResultsText="$t('message.withoutResults')"/>
    </div>
    <!-- <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start mt-3 px-0 ps-lg-0 pe-lg-3 colaborators skills" v-else>
        <h3>{{ $t('message.creator') }}</h3>
        <input type="text" v-model="userName" disabled>
    </div> -->
</template>

<script setup>
import axios from "axios"
import { useI18n } from "vue-i18n"
import Multiselect from 'vue-multiselect'
import Multiselect2 from '@vueform/multiselect'
import { ref, inject, onMounted, watch } from 'vue';
import { storeToRefs } from "pinia";
import { useLanguageStore } from "../../../stores/langs"  
import { useUsersStore } from "../../../stores/user";

const {locale} = useI18n({ useScope: 'global' })
const langs = useLanguageStore()
const {langWeb} = storeToRefs(langs)
const {} = langs

const options = ref([])
const isAdmin = ref(false)
const userName = ref(null)

let information = inject('information')

const users = useUsersStore()
const { userData } = storeToRefs(users)
const { url } = users

const urlListProf = '/api/professionals/list'

const idUser = JSON.parse(localStorage.getItem('idUser'))
if(idUser.team == 3){
    isAdmin.value = false
    information.value.creator = []
}
else if(idUser.team <= 2){
    isAdmin.value = true
    const listProf = async() =>{
        try {
            await axios.get(url+urlListProf).then((result) => {
                console.log(result)
                options.value = []
                options.value.push( userData.value )
                if (result.status == 200){
                    for(let i = 0; i < result.data.length; i++){
                        options.value.push( result.data[i] )
                    }
                }
            })
        }
        catch (error) {
            console.log(error)
        }
    }
    listProf()
}

//Actualiza el idioma de los textos de las habilidades

const multipleLabel = (values) => {
  return values.map((value) => {
    const option = options.find((option) => option.id === value)
    return option ? option.name : value
  }).join(', ')
}
</script>

<style lang="scss" scoped>
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
input,select{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: #FCFCFC;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    color: #697891;
    font-size: 15px;
    font-weight: 400;
    -webkit-appearance: none;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}

</style>