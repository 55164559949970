<template>
    <div class="row justify-content-center contenedor">
        <div class="col-12 d-flex justify-content-center align-items-center div px-3">
            <div class="row justify-content-center row__width ">
                <div class="col-12 d-flex justify-content-center align-items-center filtrosMobile">
                    <div class="row justify-content-center row__width">
                        <div class="col-12 col-lg d-flex justify-content-start align-items-center">
                            <button class="btn_none px-0 d-flex justify-content-center align-items-center pe-3" @click.prevent="setView(0)">
                                <img src="../../../../img/home-icons/arrow-left-negra.svg" alt="">
                            </button>
                            <div class="d-flex flex-column justify-content-center align-items-start">
                                <h5>Profesiones</h5>
                                <h6>{{ listProfs.length }} Profesiones</h6>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4 d-flex justify-content-end align-items-center">
                            <div class="row justify-content-center row__width">
                                <button type="button" class="col-4 agregar-cursos-btn" @click="showInputAdd(1)">
                                    <span><img src="../../../../img/panel-icons/perfil-mas.svg" alt=""></span>
                                    Agregar profesión
                                </button>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center contenedor px-0 my-4">
                            <input type="text" placeholder="Busca una profesión">
                            <button><img src="../../../../img/home-icons/lupa.svg" alt=""></button>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex flex-column justify-content-center align-items-center px-4 collapses">
                    <div class="accordion" id="accordionExample2">
                        <div class="accordion-item row justify-content-center mt-3" v-for="(profession, id) in listProfs">
                            <h2 class="accordion-header col d-flex justify-content-start align-items-center padding-start" :id="'headingProfs'+id">
                                <h5>{{ profession.name }}</h5>
                                <div class="d-flex justify-content-end align-items-center w-100">
                                    <button type="button" class="eliminar" @click="showInputAdd(3, id)">
                                        <img src="../../../../img/subir-productos-icons/cursos-icons/editar.svg" alt="">
                                    </button>
                                    <button type="button" class="eliminar" @click="deleteProf(profession.id)">
                                        <img src="../../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt="">
                                    </button>
                                </div>
                            <div class="accordion-button collapsed col-2 d-flex justify-content-center align-items-center" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseProfs'+id" aria-expanded="false" :aria-controls="'collapseProfs'+id">
                            </div>
                            </h2>
                            <div class="accordion-item row justify-content-center mt-3 d-none" :id="'editProfElement'+id">
                                <div class="col-12 d-flex justify-content-center align-items-center">
                                    <div class="row justify-content-center row__width py-3">
                                        <div class="col-12 d-flex justify-content-between align-items-center">
                                            <input type="text" placeholder="Añade una profesión" id="editProfName" v-model="profession.name">
                                            <button type="button" class="button ms-3" @click="editProf(profession.name, profession.id), addCheck(3, id)">
                                                <img src="../../../../img/subir-productos-icons/correct.svg" alt="">
                                            </button>
                                            <button type="button" class="button ms-3" @click="addCheck(3, id)">
                                                <img src="../../../../img/subir-productos-icons/incorrect.svg" alt="">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div :id="'collapseProfs'+id" class="accordion-collapse collapse" :aria-labelledby="'headingProfs'+id">
                                <div class="accordion-body row justify-content-center px-0 pt-0">
                                    <!--- COLLAPSE ESPECIALIDAD --->
                                    <div class="row justify-content-start align-items-center row__width ">
                                        <div class="col-10 d-flex justify-content-start align-items-center ps-3">
                                            <h4>Añadir especialización</h4>
                                        </div>
                                        <div class="col-2 d-flex justify-content-center justify-content-lg-end align-items-center px-0 pe-4">
                                            <div class="row justify-content-end row__width">
                                                <button type="button" class="col agregar-cursos-btn agregar-cursos-btn-4" @click="showInputAdd(2, id)">
                                                    <span><img src="../../../../img/panel-icons/perfil-mas.svg" alt=""></span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center align-items-center px-0 px-lg-1 mb-3" v-for="(speciality, index) in profession.types">
                                            <div class="row justify-content-center row__width">
                                                <div class="col-12 d-flex justify-content-between align-items-center">
                                                    <input type="text" placeholder="Añade una especialización" v-model="speciality.name" :id="'name'+id+index" disabled>
                                                    <button type="button" class="button ms-3" @click="showInputAdd(4, id, index)">
                                                        <img src="../../../../img/subir-productos-icons/cursos-icons/editar.svg" alt="">
                                                    </button>
                                                    <button type="button" class="button ms-3" @click="deleteSpec(speciality.id)">
                                                        <img src="../../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt="">
                                                    </button>
                                                </div>
                                                <div class="col-12 d-flex justify-content-between align-items-center mt-2 d-none" :id="'editSpecElement'+id+index">
                                                    <input type="text" placeholder="Añade una especialización" id="editSpecName" v-model="speciality.name">
                                                    <button type="button" class="button ms-3" @click="editSpec(speciality.name, speciality.id), addCheck(4, id, index)">
                                                        <img src="../../../../img/subir-productos-icons/correct.svg" alt="">
                                                    </button>
                                                    <button type="button" class="button ms-3" @click="addCheck(4, id, index)">
                                                        <img src="../../../../img/subir-productos-icons/incorrect.svg" alt="">
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center align-items-center px-0 px-lg-1 d-none" id="addSpecElement">
                                            <div class="row justify-content-center row__width">
                                                <div class="col-12 d-flex justify-content-between align-items-center">
                                                    <input type="text" placeholder="Añade una especialización" id="addSpecName" v-model="specName">
                                                    <button type="button" class="button ms-3" @click="addSpec(specName, profession.id)">
                                                        <img src="../../../../img/subir-productos-icons/correct.svg" alt="">
                                                    </button>
                                                    <button type="button" class="button ms-3" @click="addCheck(2)">
                                                        <img src="../../../../img/subir-productos-icons/incorrect.svg" alt="">
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--- FIN COLLAPSE ESPECIALIDAD --->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--- INPUT AÑADIR PROFESION --->
                    <div class="accordion-item row justify-content-center mt-3 w-100 d-none" id="addProfElement">
                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <div class="row justify-content-center row__width py-3">
                                <div class="col-12 d-flex justify-content-between align-items-center">
                                    <input type="text" placeholder="Añade una profesión" id="addProfName" v-model="profName">
                                    <button type="button" class="button ms-3" @click="addProf(profName)">
                                        <img src="../../../../img/subir-productos-icons/correct.svg" alt="">
                                    </button>
                                    <button type="button" class="button ms-3" @click="addCheck(1)">
                                        <img src="../../../../img/subir-productos-icons/incorrect.svg" alt="">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--- FIN INPUT AÑADIR PROFESION --->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject} from "vue"
import axios from 'axios';
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../../stores/user"

const users = useUsersStore();
const {  } = storeToRefs(users);
const { url } = users;

const urlFetchLinked = '/api/categories/linked/'
const urlAddProf = '/api/categories/professions'
const urlEditProf = '/api/categories/professions/edit/'
const urlFetchProfs = '/api/categories/professions/'
const urlDeleteProf = '/api/categories/professions/'
const urlAddSpec = '/api/categories/specializations'
const urlDeleteSpec = '/api/categories/specializations/'
const urlEditSpec = '/api/categories/specializations/edit/'
const urlFetchSpec = '/api/categories/specializations/'
const urlLinkProfSpec = '/api/categories/professions/link'

const profName = ref(null)
const profEditName = ref(null)
const specName = ref(null)
const specEdit = ref(null)
const listProfs = inject('listProfs')

const views = inject('views')
const buttons = inject('buttons')

const showInputAdd = (action, id = null, index = null) => {
    if (action == 1){
        let element = document.getElementById('addProfElement')
        element.classList.remove('d-none')
    }
    else if (action == 2){
        let element = document.getElementById('addSpecElement')
        element.classList.remove('d-none')
    }
    else if (action == 3){
        let element = document.getElementById('editProfElement'+id)
        element.classList.remove('d-none')
    }
    else if (action == 4){
        let element = document.getElementById('editSpecElement'+id+index)
        element.classList.remove('d-none')
    }
}
const addCheck = (action, id = null, index = null) => {
    if (action == 1){
        let element = document.getElementById('addProfElement')
        element.classList.add('d-none')
    }
    else if (action == 2){
        let element = document.getElementById('addSpecElement')
        element.classList.add('d-none')
    }
    else if (action == 3){
        let element = document.getElementById('editProfElement'+id)
        element.classList.add('d-none')
    }
    else if (action == 4){
        let element = document.getElementById('editSpecElement'+id+index)
        element.classList.add('d-none')
    }
}

const createProf = () => {
    listProfs.value.push({
        name: profName.value,
        id: null,
        specialities: []
    })
}

const createSpec = (array, id) => {
    array[id].specialities.push({
        name: specName.value,
        id: null
    })
}

const fetchLinked = async() => {
    try {
        await axios.get(url+urlFetchLinked+'es').then((result) => {
            console.log(result)
            listProfs.value = []
            if (result.status == 200){
                for(let i = 0; i < result.data.length; i++){
                    listProfs.value.push( result.data[i] )
                }
            }
        })
    } catch (error) {
        console.error(error)
    }
}
fetchLinked()

const fetchProfs = async() => {
    try {
        await axios.get(url+urlFetchProfs+'es').then((result) => {
            //console.log(result)
            listProfs.value = []
            if (result.status == 200){
                for(let i = 0; i < result.data.length; i++){
                    listProfs.value.push( result.data[i] )
                }
            }
            //console.log(listProfs.value)
        })
    } catch (error) {
        console.error(error)
    }
}

const fetchSpec = async() => {
    try {
        await axios.get(url+urlFetchSpec+'es').then((result) => {
            //console.log(result)
            listProfs.value = []
            if (result.status == 200){
                for(let i = 0; i < result.data.length; i++){
                    listProfs.value.push( result.data[i] )
                }
            }
            //console.log(listProfs.value)
        })
    } catch (error) {
        console.error(error)
    }
}

const addProf = async(name) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    const lang = 'es'
    try {
        await axios.post(url+urlAddProf, { name: name, description: null, language: lang}, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 201){
                fetchLinked()
            }
            else if(result.status == 422){
                console.log('Error de validacion')
            }
            else {
                console.log('Roldan lo rompio todo')
            }
        })
    } catch (error) {
        console.error(error)
    }
}

const editProf = async(name, id) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    const lang = 'es'
    try {
        await axios.post(url+urlEditProf+id, { name: name, description: null, language: lang}, {headers: headers.value}).then((result) => {
            console.log(result)
            fetchLinked()
        })
    } catch (error) {
        console.error(error)
    }
}

//Elimina el tipo de producto si no tiene productos relacionados
const deleteProf = async(id) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.delete(url+urlDeleteProf+id, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 201){
                fetchLinked()
                console.log('Eliminado')
            }
            else if (result.status == 403){
                console.log('No se puede borrar')
            }
        })
    } catch (error) {
        console.error(error)
    }
}

const addSpec = async(name, profId) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    const lang = 'es'
    try {
        await axios.post(url+urlAddSpec, { name: name, description: null, language: lang}, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 201){
                linkProfSpec(profId, result.data.id)
            }
            else if(result.status == 422){
                console.log('Error de validacion')
            }
            else {
                console.log('Roldan lo rompio todo')
            }
        })
    } catch (error) {
        console.error(error)
    }
}

const editSpec = async(name, id) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    const lang = 'es'
    try {
        await axios.post(url+urlEditSpec+id, { name: name, description: null, language: lang}, {headers: headers.value}).then((result) => {
            console.log(result)
            fetchLinked()
        })
    } catch (error) {
        console.error(error)
    }
}

const deleteSpec = async(id) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.delete(url+urlDeleteSpec+id, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 201){
                fetchLinked()
                console.log('Eliminado')
            }
            else if (result.status == 403){
                console.log('No se puede borrar')
            }
        })
    } catch (error) {
        console.error(error)
    }
}

const linkProfSpec = async(profId, specId) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.post(url+urlLinkProfSpec, {fromId: specId, toId: profId}, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 201){
                fetchLinked()
                console.log('Linkeado')
            }
            else if (result.status == 422){
                console.log('Fallo validacion')
            }
        })
    } catch (error) {
        console.error(error)
    }
}
const setView = (x) =>{
    //console.log('me ejecuto')
    views.value = x
    buttons.value = true
    return {buttons}
}
</script>

<style lang="scss" scoped>
.contenedor{
    animation: fade-in 0.2s ease-in forwards;
    .filtrosMobile{
        h5{
            color: var(--colorSecondary);
            font-weight: 700;
            font-size: 22px;
            text-align: left;
        }
        h6{
            color: #697891;
            font-weight: 500;
            font-size: 16px;
            text-align: left;
            padding-top: 10px;
        }
        .contenedor{
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #ECECEC;
            background-color: var(--bgPrincipal);
            border-radius: 40px;
            min-height: 50px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 15px;
            @media (min-width: 992px) {
                border-radius: 40px;
            }
            @media (max-width: 991px) {
                width: 100%;
            }
            .img{
                padding: 0 20px;
            }
            input{
                border: 0;
                color: #B8C1DB;
                font-weight: 400;
                font-size: 14px;
                width: 90%;
                margin-left: 15px;
                &::placeholder{
                    color: #B8C1DB;
                }
                &:focus{
                    outline: 0;
                }
                @media (min-width: 992px) {
                    color: #697891;
                    font-size: 16px;
                    width: 93%;
                }
            }
            button{
                position: absolute;
                right: 7px;
                border: 0;
                min-height: 45px;
                width: 45px;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 50%;
                background-color: var(--colorPrimary);
                justify-content: center;
                align-items: center;
                img{
                    padding: 0;
                    max-height: 22px;
                }
                @media (min-width: 992px) {
                    right: 10px;
                }
            }
            .border-left{
                border-left: 1px solid rgba(184, 193, 219, 0.4);
            }
        }
    }
    .div{
        background-color: #fff;
        box-shadow: 0px 3px 10px #00000029;
        border-radius: 20px;
        padding:30px;
        min-height: auto;
        input{
            border: 0;
            color: #B8C1DB;
            font-weight: 400;
            font-size: 14px;
            width: 100%;
            &::placeholder{
                color: #B8C1DB;
            }
            &:focus{
                outline: 0;
            }
            @media (min-width: 992px) {
                color: #697891;
                font-size: 18px;
                width: 80%;
            }
        }
    }
    .collapses{
        h3{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            padding: 7px 0px;
        }
        h6{
            color:#B8BCC8;
            font-size: 15px;
            font-weight: 400;
            text-align: left;
            padding: 5px 0px;
        }
        .accordion{
            width: 100%;
            .accordion-item{
                border: 0;
                border-radius: 10px;
                box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
                border-radius: 10px;
                background-color: #F9F9F9;
                margin-bottom: 15px;
                input{
                    width: 94%;
                    border: 2px solid #E9E3E3;
                    border-radius: 10px;
                    background-color: var(--bgPrincipal);
                    height: 50px;
                    padding-left: 15px;
                    outline: 0;
                    color: #697891;
                    font-size: 15px;
                    font-weight: 400;
                    &:focus, &:hover{
                        border: 2px solid #1679FC;
                    }
                    &::placeholder{
                        color: #697891;
                    }
                    @media (max-width: 991px) {
                        width: 83%;
                    }
                }
                .button{
                    background-color: #fff;
                    border: 2px solid #E9E3E3;
                    outline: 0;
                    border-radius: 25px;
                    min-height: 40px;
                    min-width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        height: 15px;
                    }
                }
                h4{
                    color: #0E1133;
                    font-weight: 500;
                    font-size: 16px;
                    text-align: left;
                }
                h5{
                    color: var(--colorSecondary);
                    font-weight: 600;
                    font-size: 16px;
                    text-align: left;
                }
                .padding-start{
                    padding-left: 25px;
                }
            }
            .accordion-item:last-of-type .accordion-button.collapsed{
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            .accordion-header{
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                min-height: 70px;
                
                
                .accordion-body{
                    span{
                        border: 2px solid #E9E3E3;
                        height: 40px;
                        width: 40px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        h3{
                            text-align: center;
                        }
                    }
                    button{
                        background-color: transparent;
                        border: 2px solid #E4E4E4;
                        border-radius: 50%;
                        height: 40px;
                        width: 40px;
                        margin-left: 10px;
                    }
                }
                
            }
            .accordion-button{
                background-color: #F9F9F9;
                padding: 10px;
                width: 70px;
            }
            .accordion-button:not(.collapsed){
                background-color: inherit;
                box-shadow: none;
            }
            .accordion-button::after{
                position: absolute;
                right: 20%;
                background-image: url('../../../../img/subir-productos-icons/arrow-gris-abajo.svg');
                background-size: 50%;
                background-position: center;
                background-color: transparent;
                border-radius: 50%;
                height: 40px;
                width: 40px;
            }
            .eliminar{
                background-color: transparent;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                border: 1px solid #B8BCC8;
                margin-left: 10px;
            }
        }
        .padding{
            padding: 0 11%;
        }
    }
    .academicas{
        background-color: #F5F5F5;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 15px;
        height: 110px;
        h4{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 16px;
        }
        h5{
            color: #2E4765;
            font-weight: 300;
            font-size: 14px;
        }
        p{
            color: #697891;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            padding-left: 10px;
        }
        .guardar{
            button{
                border: 2px solid var(--colorPrimary);
                background-color: var(--bgPrincipal);
                border-radius: 25px;
                height: 50px;
                font-weight: 600;
                font-size: 16px;
                color: var(--colorPrimary);
                width: 100%;
            }
        }
        input,select{
            width:100%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: #FCFCFC;
            height: 50px;
            padding-left: 15px;
            outline: 0;
            color: #697891;
            font-size: 15px;
            font-weight: 400;
            -webkit-appearance: none;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                color: #697891;
            }
        }
    }   
    .border-top{
        border-top: 2px solid #E4E4E4;
        padding-top: 20px;
    }
    .agregar-cursos-btn, .agregar-cursos-btn-3{
        box-shadow: 0 3px 6px #00000016;
        border-radius: 43px;
        height: 60px;
        margin: 20px 0;
        border: 0;
        background-color: var(--bgPrincipal);
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
        padding-bottom: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 15px;
        width: 100%;
        max-width: 300px;
        span{
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
        }
    }
    .agregar-cursos-btn-4{
        max-width: 50px;
        height: 50px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
            margin-right: 0;
        }
    }
    .faq{
        .collapses{
            h3{
                margin-bottom: 5px !important;
                font-weight: 700;
                font-size: 18px;
            }
            .question{
                font-size: 30px;
                margin-bottom: 0 !important;
            }
            h4{
                font-weight: 400;
                font-size: 15px;
                color: #B8BCC8;
                text-align: left;
            }
            .accordion{
                width: 100%;
                .accordion-item{
                    margin: 15px 0px;
                    border: 0;
                    border-radius: 10px;
                    
                    p{
                        font-size: 14px;
                        font-weight: 400;
                        color: var(--colorSecondary);
                    }
                }
                .accordion-item:last-of-type .accordion-button.collapsed{
                    border-bottom-right-radius: 10px;
                    border-bottom-left-radius: 10px;
                }
                .accordion-header{
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 10px;
                    background-color: #F9F9F9;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    
                }
                .accordion-button{
                    border-radius: 10px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    background-color: #F9F9F9;
                    width: auto;
                    &:focus{
                        border-color: inherit;
                        box-shadow: none;
                    }
                    span{
                        border: 2px solid #E9E3E3;
                        min-height: 50px;
                        min-width: 50px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    @media (max-width: 991px) {
                        justify-content: flex-start;
                        align-items: flex-start;
                    }
                }
                .accordion-button:not(.collapsed){
                    background-color: inherit;
                    box-shadow: none;
                }
                .accordion-button::after{
                    position: absolute;
                    right: 5%;
                    background-image: url('../../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                    background-size: 100%;
                    background-position: center;
                    @media (max-width: 991px) {
                        margin-top: 5px;
                    }
                }
            }
            @media (min-width: 992px) {
                margin: 20px 0 60px 0;
            }
            .collapses2{
                h3{
                    margin-bottom: 0px !important;
                    font-size: 16px;
                }
                .accordion{
                    width: 100%;
                    .accordion-item{
                        margin: 15px 0px;
                        border: 0;
                        border-radius: 10px;
                    }
                    .accordion-item:last-of-type .accordion-button.collapsed{
                        border-bottom-right-radius: 10px;
                        border-bottom-left-radius: 10px;
                    }
                    .accordion-header{
                        box-shadow: 0px 3px 6px #00000029;
                        border-radius: 10px;
                        background-color: var(--bgPrincipal);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        position: relative;
                        
                        .accordion-body{
                            input,select{
                                width: 100%;
                                border: 2px solid #E9E3E3;
                                border-radius: 10px;
                                background-color: #FCFCFC;
                                height: 50px;
                                padding-left: 15px;
                                outline: 0;
                                color: #697891;
                                font-size: 15px;
                                font-weight: 400;
                                -webkit-appearance: none;
                                &:focus, &:hover{
                                    border: 2px solid #1679FC;
                                }
                                &::placeholder{
                                    color: #697891;
                                }
                            }
                            span{
                                border: 2px solid #E9E3E3;
                                height: 40px;
                                width: 40px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                h3{
                                    text-align: center;
                                }
                            }
                            button{
                                background-color: transparent;
                                border: 2px solid #E4E4E4;
                                border-radius: 50%;
                                min-height: 40px;
                                min-width: 40px;
                                margin-left: 10px;
                            }
                        }
                    }
                    .accordion-button{
                        border-radius: 10px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        background-color: var(--bgPrincipal);
                        width: auto !important;
                        &:focus{
                            border-color: inherit;
                            box-shadow: none;
                        }
                        span{
                            border: 2px solid #E9E3E3;
                            min-height: 40px;
                            min-width: 40px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            h3{
                                text-align: center;
                            }
                        }
                    }
                    .accordion-button:not(.collapsed){
                        background-color: inherit;
                        box-shadow: none;
                    }
                    .accordion-button::after{
                        position: absolute;
                        right: 2.5%;
                        background-image: url('../../../../img/subir-productos-icons/arrow-gris-abajo.svg');
                        background-size: 50%;
                        background-position: center;
                        border: 2px solid #E9E3E3;
                        border-radius: 50%;
                        min-height: 40px;
                        min-width: 40px;
                    }
                }
                
                @media (min-width: 992px) {
                    margin: 20px 0 60px 0;
                }
            }

            
            .max-width{
                width: 7%;
            }
            .position-relative{
                .position{
                    position: absolute;
                    right: 8%;
                    button{
                        background-color: transparent;
                        border: 2px solid #E4E4E4;
                        border-radius: 50%;
                        min-height: 40px;
                        min-width: 40px;
                        
                    }
                }
            }
        }
    }
}
.accordion-item{
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    background-color: #F9F9F9;
    margin-bottom: 15px;
    input{
        width: 94%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: var(--bgPrincipal);
        height: 50px;
        padding-left: 15px;
        outline: 0;
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
        @media (max-width: 991px) {
            width: 83%;
        }
    }
    .button{
        background-color: #fff;
        border: 2px solid #E9E3E3;
        outline: 0;
        border-radius: 25px;
        min-height: 40px;
        min-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            height: 15px;
        }
    }
    h4{
        color: #0E1133;
        font-weight: 500;
        font-size: 16px;
        text-align: left;
    }
    h5{
        color: var(--colorSecondary);
        font-weight: 600;
        font-size: 16px;
        text-align: left;
    }
    .padding-start{
        padding-left: 25px;
    }
}
</style>