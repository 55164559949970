<template>
    <div class="container-fluid">
        <NavbarBuscadorBack/>
        <div class="row justify-content-center position-relative body">
            <div class="col-4 d-none d-lg-flex justify-content-center align-items-start ps-0" v-if="mapaDesplegado"></div>
            <div class="col-3 justify-content-center align-items-start ps-0 mt-lg-5" v-if="filtrosDesplegados">
                <Body/>
            </div>
            <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0 "
            :class="filtrosDesplegados == true ? 'col-lg-8 justify-content-lg-start' : 'col-lg-11' && mapaDesplegado == true ? 'col-lg-7 justify-content-lg-start' : 'col-lg-11' ">
                <Filtros class=""/>
                <TitulosSecciones :titulo="$t('message.titleDirectory')" :haySubtitulo="false" class="d-lg-none"/>
                <!-- <OrdenarPorSelect class="d-lg-none"/> -->
                <TituloYOpcionesResultadosCursosV3 :titulo="$t('message.titleDirectoryProfessionals')" class="d-none d-lg-flex" idNext="nextSliderFotosboton" idBack="prevSliderFotosboton" pagination="pagProfesional"/>
                <directorioDeFotos/>
                <BotonesSlidersHome class="d-lg-none" idNext="nextSliderFotosboton" idBack="prevSliderFotosboton" pagination="pagProfesional"/>
                <!-- <panelSeccion class="d-lg-none"/> -->
            </div>
            <div class="col-4 d-none d-lg-flex justify-content-center align-items-start ps-0" v-if="mapaDesplegado"></div>
            <div class="col d-none d-lg-flex justify-content-center align-items-start ps-0 mt-lg-5 position" v-if="mapaDesplegado">
                <Mapa/>
            </div>
            <div class="filtros-absolute" v-if="filtrosMapa" @click="cerrarTodo">
                
            </div>
            <div class="filtros row d-flex justify-content-center align-items-start" v-if="filtrosMapa">
                <div class="col-11 d-flex justify-content-center align-items-center px-0">
                    <FiltrosV2 :funcionMostrarFiltros="funcionMostrarFiltros" :funcionMapa="funcionMapa2"/>
                </div> 
                <div class="col-3 d-flex justify-content-center align-items-start ps-0">
                    <Body/>
                </div>
                <div class="col-8 d-flex flex-column justify-content-center align-items-center px-0 position-relative">
                    <TituloYOpcionesResultadosCursosV3 :titulo="$t('message.titleDirectoryProfessionals')" class="d-none d-lg-flex" idNext="nextSliderFotosboton" idBack="prevSliderFotosboton" pagination="pagProfesional"/>
                    <directorioDeFotos/>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0 mt-5">
                <!-- <panelSeccionV2 class="d-none d-lg-flex"/>
                <TitulosSeccionesV2 :titulo="$t('message.topTeachers')" :haySubtitulo="false" class="d-lg-none" />
                <TituloYOpcionesResultadosCursosV2 :titulo="$t('message.topTeachers')" class="d-none d-lg-flex" idNext="nextSliderProfesoresboton" idBack="prevSliderProfesoresboton" /> -->
            </div>
            <div class="col-11 col-lg-12 d-flex flex-column justify-content-center align-items-center px-0">        
                <!-- <TopProfesores/> -->
            </div>
        </div>
        <FooterHome/>
        <Footer :activeHome="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import Footer from "../../components/Footer/Footer.vue";
import TitulosSecciones from "../../components/Titulo/TitulosSecciones.vue";
import panelSeccion from "../../components/PanelSeccion/PanelSeccion.vue";
import FooterHome from "../../components/Footer/FooterHome.vue";
import directorioDeFotos from "../../components/Sliders/DirectorioDeFotos.vue";
import OrdenarPorSelect from "../../components/Ordenar/PorSelect.vue";
import Filtros from "../../components/Filtros/Filtros.vue";
import TitulosSeccionesV2 from "../../components/Titulo/TitulosSeccionesV2.vue";
import BotonesSlidersHome from "../../components/Botones/SlidersHome.vue";
import TopProfesores from "../../components/Sliders/TopProfesores.vue";
import NavbarBuscadorBack from "../../components/Navs/NavbarBuscadorBack.vue";
import TituloYOpcionesResultadosCursosV2 from "../../components/Titulo/TituloYOpcionesV2.vue";
import TituloYOpcionesResultadosCursosV3 from "../../components/Titulo/TituloYOpcionesV3.vue";
import panelSeccionV2 from "../../components/PanelSeccion/PanelSeccionV2.vue";
import Body from "../../components/Filtros/Body.vue";
import FiltrosV2 from "../../components/Filtros/FiltrosV2.vue";
import Mapa from "../../components/Filtros/Mapa.vue";
import { ref } from "vue";
import { useGeneralStore } from "../../stores/general"    
import { storeToRefs } from "pinia";

const general = useGeneralStore();
const {filtrosDesplegados, filtrosMapa, mapaDesplegado} = storeToRefs(general);
const {} = general;

const funcionMostrarFiltros = () => {
    if(mapaDesplegado.value == true ){
        if(filtrosMapa.value == false){
            filtrosMapa.value = true 
        }else{
            filtrosMapa.value = false
        }
        //console.log(this.filtrosMapa)
    }else{
        if(filtrosDesplegados.value == false){
            filtrosDesplegados.value = true 
        }else{
            filtrosDesplegados.value = false
        }
    }
}

const funcionMapa = () => {
    if(filtrosDesplegados.value == true ){
        filtrosDesplegados.value = false
        //console.log('hola3')
    }
    else if(mapaDesplegado.value == false){
        mapaDesplegado.value = true 
        //console.log('hola4')
    }else{
        mapaDesplegado.value = false
        //console.log(mapaDesplegado.value)
        //console.log('hola5')
    }
}

const funcionMapa2 = () => {
    if(mapaDesplegado.value == true){
        //console.log(this.mapaDesplegado, 'hola1')
        mapaDesplegado.value = false 
        filtrosMapa.value = false
    }else{
        mapaDesplegado.value = false
        //console.log(this.mapaDesplegado, 'hola1')
    }
}

const cerrarTodo = () => {
    mapaDesplegado = false
    filtrosMapa.value = false
}
</script>

<style lang="scss" scoped>
.body{
    @media (min-width:992px) {
        margin-top: 95px;
    }
}
.position{
    position: absolute;
    right: 0;
    max-width: 34%;
    height: 100%;
    top: 0;
}
.filtros-absolute{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.35);
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    z-index: 5;
    height: 105%;
    min-height: 80vw;
}
.filtros{
    position: absolute;
    background-color: #FFFFFF;
    max-width: 95vw;
    min-height:  102.5%;
    z-index: 10;
    left: 0;
    top: 0;
    
    min-height: 80vw;
}
</style>