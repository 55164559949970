<template>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0">
                <DecoracionHome class="d-none d-lg-flex"/>
                <Navbar class="" v-if="cambioHeader"/>
                <NavbarBuscador class="" v-else/>
                <TitulosHomeDesk class="z-index-home d-none d-lg-flex"/>
                <Buscador class="z-index-home mb-5"/>
                <!-- <Servicios class="z-index-home"/> -->
                <!---CURSOS--->
                <!-- <TitulosSecciones :titulo="$t('message.coursesPhotography')" :subtitulo="$t('message.subtitleCourses')"/> -->
                <!-- <OrdenarPorSeccion idNext="nextSliderCursosbotonHome" idBack="prevSliderCursosbotonHome" pagination="pagCursosMobile"/> -->
                <!-- <SliderMobileSeccionesHome/> -->
                <!-- <BotonesSlidersHome class="d-lg-none" idNext="nextSliderCursosbotonHome" idBack="prevSliderCursosbotonHome" pagination="pagCursosMobile"/> -->
                <!-- <TitulosSecciones :titulo="$t('message.titleCourses')" :haySubtitulo="false" class="d-lg-none "/> -->
                <TituloYOpcionesResultadosCursos class="mt-5" :titulo="$t('message.coursesPhotography')" idNextV2="nextSliderCursosboton" idBackV2="prevSliderCursosboton" pagination="pagCursos"/>
                <SliderMobileSeccionesHomeV2/>
                <!-- <BotonesSlidersHome class="d-lg-none" idNext="nextSliderCursosboton" idBack="prevSliderCursosboton" pagination="pagCursosMobile"/> -->
                <!---PACKS DE CURSOS--->
                <!-- <TitulosSecciones :titulo="$t('message.titlePackCourses')" :subtitulo="$t('message.subtitlePackCourses')"/>
                <categoriaSeccion idBack="prevSlider2boton" idNext="nextSlider2boton" pagination="pagPackCourses" class="" />
                <CursosListado/>
                <BotonesSlidersHome class="d-lg-none" idBack="prevSlider2boton" idNext="nextSlider2boton"/>
                <panelSeccion class="d-lg-none"/> -->
                <!-- <HomeV2 class="d-none d-lg-flex"/> -->
                <!---DIRECTORIO DE FOTOS--->
                <!-- <TitulosSecciones :titulo="$t('message.titleDirectory')" :subtitulo="$t('message.subtitleDirectory')"/> -->
                <!-- <BuscarExperto class="d-lg-none"/> -->
                <!-- <BuscarExpertoV2 class="d-none d-lg-flex" idBack="prevSliderFotosboton" idNext="nextSliderFotosboton" pagination="pagPhotoDirectory"/> -->
                <!-- <DirectorioDeFotosV2/> -->
                <!-- <BotonesSlidersHome class="d-lg-none" idBack="prevSliderFotosboton" idNext="nextSliderFotosboton"/> -->
                <!-- <TitulosSecciones :titulo="$t('message.titleDirectory')" :haySubtitulo="false" class="d-lg-none"/> -->
                <TituloYOpcionesV3 :titulo="$t('message.titleDirectoryProfessionals')" class="" idNext="nextSliderFotosboton" idBack="prevSliderFotosboton" pagination="pagProfesional"/>
                <DirectorioDeFotos/>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                <!---PRESET COMPARACIÓN--->
                <!-- <ComparacionImagen class="d-lg-none"/> -->
            </div>
            <div class="col-12 col-lg-11 d-flex flex-column justify-content-center align-items-center px-0">
                <!---CURSOS EN VIVO--->
                <!-- <TitulosSecciones :titulo="$t('message.titleCuorsesLive')" :subtitulo="$t('message.subtitleCuorsesLive')" :textCenter="true"/>
                <CursosEnVivo class=""/>
                <ProximosWebinars class="d-lg-none"/> -->
            </div>
            <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                <!---EVENTOS--->
                <!-- <ProximosWebinars class="d-none d-lg-flex"/> -->
            </div>
            <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0">
                <!--- PROVEEDORES --->
                <!-- <TitulosSecciones :titulo="$t('message.titleProveedores')" :haySubtitulo="false" :sinBefore="true"/>
                <BuscarExperto class="d-lg-none"/>
                <BuscarExpertoV2 class="d-none d-lg-flex" idBack="prevSlider7boton" idNext="nextSlider7boton"/>
                <Proveedores/>
                <BotonesSlidersHome class="d-lg-none" idBack="prevSlider7boton" idNext="nextSlider7boton"/> -->
                <!--- TESTIMONIOS --->
                <!-- <Testimonios class="d-lg-none"/> -->
            </div>
            <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                <!-- <Testimonios class="d-none d-lg-flex"/> -->
            </div>
            <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0">
                <div class="espacio2 d-none d-lg-flex"></div>
                <Unete class="d-lg-none"/>
                <Footer :activeHome="true" class="d-lg-none"/>
                <div class="espacio2"></div>
            </div>
        </div>
        <FooterHome/>
        <!-- <div class="espacio2 d-none d-lg-flex"></div> -->
    </div>
</template>

<script setup>
import Buscador from "../../components/Buscador/Buscador.vue";
import BuscarExperto from "../../components/Buscador/Experto.vue";
import BuscarExpertoV2 from "../../components/Buscador/ExpertoV2.vue";
import BotonesSlidersHome from "../../components/Botones/SlidersHome.vue";
import categoriaSeccion from '../../components/Categorias/CategoriaSeccion.vue';
import ComparacionImagen from "../../components/Cursos/ComparacionImagen.vue";
import CursosEnVivo from "../../components/Cursos/EnVivo.vue";
import CursosListado from "../../components/Cursos/Listado.vue";
import DecoracionHome from "../../components/Decoracion/DecoracionHome.vue";
import DirectorioDeFotosV2 from "../../components/Sliders/DirectorioDeFotosV2.vue";
import Footer from "../../components/Footer/Footer.vue";
import FooterHome from "../../components/Footer/FooterHome.vue";
import HomeV2 from "../../components/PanelSeccion/HomeV2.vue";
import Navbar from "../../components/Navs/Navbar.vue";
import OrdenarPorSeccion from "../../components/Ordenar/PorSeccion.vue";
import panelSeccion from "../../components/PanelSeccion/PanelSeccion.vue";
import Proveedores from "../../components/Sliders/Proveedores.vue";
import ProximosWebinars from "../../components/Sliders/ProximosWebinars.vue";
import Servicios from "../../components/Servicios/Servicios.vue";
import SliderMobileSeccionesHome from "../../components/Sliders/SeccionesHome.vue";
import SliderMobileSeccionesHomeV2 from "../../components/Sliders/SeccionesHomeV2.vue";
import DirectorioDeFotos from "../../components/Sliders/DirectorioDeFotos.vue"
import TituloYOpcionesV3 from "../../components/Titulo/TituloYOpcionesV3.vue";
import TituloYOpcionesResultadosCursos from "../../components/Titulo/TituloYOpciones.vue";
import Testimonios from "../../components/Sliders/Testimonios.vue";
import TitulosHomeDesk from "../../components/Titulo/TitulosHomeDesk.vue";
import TitulosSecciones from "../../components/Titulo/TitulosSecciones.vue";
import Unete from "../../components/PanelSeccion/Unete.vue";
import { useUsersStore } from "../../stores/user";
import NavbarBuscador from "../../components/Navs/NavbarBuscador.vue";
import { storeToRefs } from "pinia";
import { ref, provide } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import axios from "axios"
const router = useRouter()
const route = useRoute()
// Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
const users = useUsersStore();
// Mis estados los paso a objetos porque en la tienda están en reactivos //
const {cambioHeader, idUser, userData} = storeToRefs(users);
const {usuarioData} = users

const tokenGoogle = ref()
const url = process.env.VUE_APP_BASE_URL
const prueba2 = () => {
    tokenGoogle.value = route.query
    console.log(tokenGoogle.value)
    if(Object.keys(tokenGoogle.value).length !== 0){
        localStorage.setItem('token', JSON.stringify(tokenGoogle.value.token))
        router.push('/')
        cambioHeader.value = false
        const urlTeam = `/api/user/${tokenGoogle.value.id}/team/current`
        console.log(tokenGoogle.value.id)
        
        const setTeam = async() => {
            try {
                await axios.get(url+urlTeam).then((result) => {
                    console.log(result)
                    let resultParse = JSON.parse(result.data.data)
                    const userId = ref({})
                    userId.value.id = tokenGoogle.value.id
                    userId.value.team = resultParse.id
                    const dataId = ref({})
                    dataId.value.id = tokenGoogle.value.id
                    localStorage.setItem('idUser', JSON.stringify(userId.value))
                    localStorage.setItem('idData', JSON.stringify(dataId.value))
                })
                usuarioData()
            } catch (error) {
                console.log(error)
            }
        }
        setTeam()

    }
    // else{
    //     router.push('/iniciar')
    // }
}
prueba2()
console.log(cambioHeader.value)

let priceRange = ref({
        minimum: null,
        maximum: null
    })
provide('priceRange', priceRange)

</script>

<style lang="scss" scoped>
body{
    max-width: 100% !important;
}

</style>