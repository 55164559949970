<template>
    <div class="row justify-content-center row__width pb-3 mb-4">
        <div class="col-3 col-lg d-flex justify-content-center align-items-center align-items-lg-start px-0 perfil-foto">
            <img src="../../img/home-icons/cursos-icons/perfil-foto.png" alt="" class="">
        </div>
        <div class="col d-flex justify-content-start align-items-center flex-cambio">
            <div class="row justify-content-center row__width">
                <div class="col-12 d-flex justify-content-start align-items-center py-1 px-lg-0">
                    <h2>David Ojeda</h2>
                </div>
                <div class="col-12 d-flex justify-content-start align-items-center py-1 starts px-lg-0">
                    <div class="d-flex justify-content-center align-items-center">
                        <img src="../../img/home-icons/cursos-icons/rateV2.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rateV2.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rateV2.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rateV2.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rateV2.svg" alt="">
                    </div>
                    <h4>Hace 2 días</h4>
                </div>
            </div>
            <div class="row justify-content-center row__width d-none d-lg-flex">
                <div class="col-12 d-flex flex-column justify-content-start align-items-center py-1 px-0">
                    <div class="before" id="filtro5">
                        <p>
                            Es impresionante la pasión con la que cuenta Mónica Olvera y el conocimiento que tiene para explicar y resolver las dudas durante el curso, 
                            aprendí demasiado. Lo recomiendo completamente, ideal para comenzar a 
                            prepararte profesionalmente en el mundo de la fotografía de newborn por profesionales reconocidos.
                        </p>
                        <div id="desaparecer5" class="d-none">
                            <p>
                                Lo recomiendo completamente, ideal para comenzar a 
                                prepararte profesionalmente en el mundo de la 
                                fotografía de newborn por profesionales reconocidos.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center row__width d-none d-lg-flex">
                <div class="col-12 d-flex justify-content-start align-items-center botones px-0">
                    <h6>¿Te gusto este comentario?</h6>
                    <button><img src="../../img/home-icons/cursos-icons/like.svg" alt=""></button>
                    <button><img src="../../img/home-icons/cursos-icons/dislike.svg" alt=""></button>
                </div>
            </div>
            <div class="row justify-content-center row__width d-none d-lg-flex my-3 before_respuesta">
                <div class="col-3 col-lg d-flex justify-content-center align-items-center align-items-lg-start ps-0 perfil-foto">
                    <img src="../../img/home-icons/cursos-icons/perfil-foto.png" alt="" class="">
                </div>
                <div class="col d-flex justify-content-start align-items-center flex-cambio">
                    <div class="row justify-content-center row__width">
                        <div class="col-12 d-flex justify-content-start align-items-center py-1 px-lg-0">
                            <h2>David Ojeda</h2>
                        </div>
                        <div class="col-12 d-flex justify-content-start align-items-center py-1 starts px-lg-0">
                            <div class="d-flex justify-content-center align-items-center">
                                <img src="../../img/home-icons/cursos-icons/rateV2.svg" alt="">
                                <img src="../../img/home-icons/cursos-icons/rateV2.svg" alt="">
                                <img src="../../img/home-icons/cursos-icons/rateV2.svg" alt="">
                                <img src="../../img/home-icons/cursos-icons/rateV2.svg" alt="">
                                <img src="../../img/home-icons/cursos-icons/rateV2.svg" alt="">
                            </div>
                            <h4>Hace 2 días</h4>
                        </div>
                    </div>
                    <div class="row justify-content-center row__width d-none d-lg-flex">
                        <div class="col-12 d-flex flex-column justify-content-start align-items-center py-1 px-0">
                            <div class="before" id="filtro5">
                                <p>
                                    Es impresionante la pasión con la que cuenta Mónica Olvera y el conocimiento que tiene para explicar y resolver las dudas durante el curso, 
                                    aprendí demasiado. Lo recomiendo completamente, ideal para comenzar a 
                                    prepararte profesionalmente en el mundo de la fotografía de newborn por profesionales reconocidos.
                                </p>
                                <div id="desaparecer5" class="d-none">
                                    <p>
                                        Lo recomiendo completamente, ideal para comenzar a 
                                        prepararte profesionalmente en el mundo de la 
                                        fotografía de newborn por profesionales reconocidos.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center row__width d-none d-lg-flex">
                        <div class="col-12 d-flex justify-content-start align-items-center botones px-0">
                            <h6>¿Te gusto este comentario?</h6>
                            <button><img src="../../img/home-icons/cursos-icons/like.svg" alt=""></button>
                            <button><img src="../../img/home-icons/cursos-icons/dislike.svg" alt=""></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    
</script>

<style lang="scss" scoped>
.opinion{ 
    h2{
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
    }
    h4{
        font-weight: 400;
        font-size: 16px;
        color: #B8C1DB;
        padding-left: 10px;
    }
    .starts{
        img{
            padding-right: 3px;
        }
    }
    p{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
    }
    .perfil-foto{
        max-width: max-content;
        img{
            height: 68px;
            border-radius: 50%;
            max-width: 68px;
        }
    }
    h6{
        font-weight: 400;
        font-size: 12px;
        color: #B8C1DB;
    }
    .botones{
        button{
            border: 0;
            background-color: transparent;
            outline: 0;
            &:focus{
                outline: 0;
            }
        }
    }
    .before_respuesta{
        position: relative;
        &::before{
            content: '';
            position: absolute;
            background-color: #E4E4E4;
            border-radius: 50px;
            width: 2px;
            height: 110%;
            left: -5%;
        }
    }
    h5{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
    }
    .contenedor{
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        input{
            border: 2px solid #E9E3E3;
            background-color: #FCFCFC;
            border-radius: 10px;
            height: 50px;
            width: 100%;
            color: #697891;
            font-weight: 400;
            font-size: 15px;
            padding-left: 15px;
            &:hover, &:focus{
                outline: 0;
                border-color: var(--colorPrimary);
            }
        }
        img{
            position: absolute;
            margin-right: 20px;
        }

    }
    @media (min-width: 992px) {
        margin: 40px 0px;
        .perfil-foto{
           img{
                height: 70px;
                max-width: 78px;
           }
        }
        .flex-cambio{
            flex-direction: column;
        }
        .before{
            &::before{
                position: relative;
            }
        }
    }
}
</style>