<template>
    <div class="col-12 d-flex justify-content-start align-items-center position-relative px-0">
        <img :src="product.owner.image" alt="" class="perfil">
        <h3>{{ product.owner.name }}</h3>
        <h4 v-if="product.owner_type.type == 'professional'">{{ $t('message.professionalUppercase') }}</h4>
        <h4 v-else-if="product.owner_type.type == 'teacher'">{{ $t('message.teacherUppercase') }}</h4>
        <img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" class="verificado d-none">
        <span></span>
        <div class="row justify-content-center row__width ps-3 d-none" v-if="product.collabs.length > 0">
            <div class="col d-flex justify-content-start align-items-center position-relative imgs">
                <img :src="user.image" alt="" class="" style="border-radius: 50%;" v-for="(user, index) in product.collabs">
                <div class="div" v-if="product.collabs.length >=3">+{{ product.collabs.length }}</div>
                <p>{{ $t('message.collaborate') }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { inject } from 'vue';

    let product = inject('product')

</script>

<style lang="scss" scoped>
.verificado{
    padding-left: 5px;
}
.perfil{
    height: 50px;
    width: 50px;
    max-height: 50px;
    max-width: 50px;
    border-radius: 50%;
    object-fit: cover;
}
h3{
    font-weight: 500;
    font-size: 18px;
    color: var(--colorSecondary);
    padding-left: 10px;
    @media (min-width: 992px) {
        min-width: 130px;
    }
}
span{
    background-color: #4144612a;
    height: 100%;
    width: 1px;
    position: absolute;
    right: 0;
    @media (min-width: 992px) {
        display: none;
    }
}
h4{
    font-weight: 700;
    font-size: 18px;
    color: var(--colorSecondary);
    padding: 0px 5px;
}
.imgs{
    img{
        height: 32px;
    }
    .img2{
        position: absolute;
        left: 7%;
    }
    .div{
        border: 2px solid var(--bgPrincipal);
        background-color: var(--colorSecondary);
        border: 2px solid var(--bgPrincipal);
        border-radius: 50px;
        width: 32px;
        height: 32px;
        color: var(--bgPrincipal);
        font-weight: 500;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 12%;
    }
    p{
        font-size: 16px;
        font-weight: 400;
        color: #9E989A;
        padding-left: 12%;
    }
}


</style>