import { defineStore } from 'pinia'
import { onBeforeMount, ref, watch } from 'vue'


export const useCartStore = defineStore('cart', () => {
  let cart = ref([])
  let totalPrice = ref(0)
  let totalDiscount = ref(0)
  let arrayCheckout = ref({
    total: 0,
    precio: 0,
    promo: 0,
    precioTotal: 0,
    promoTotal: 0
  })
  let coinSelected = ref(JSON.parse(localStorage.getItem('coin')))
  let exchangeUpdate = ref(false)

  onBeforeMount(() => {
    if (localStorage.getItem('Cart')){
        cart.value = JSON.parse(localStorage.getItem('Cart'))
        // console.log(cart.value)
        setCheckout()
    }
  })

  //Contador de precios - OPTIMIZAR
  const setCheckout = () => {
    if(localStorage.getItem('Cart')){
      cart.value = JSON.parse(localStorage.getItem('Cart'))
    }
    // console.log(arrayCheckout.value)
    localStorage.setItem('Cart', JSON.stringify(cart.value))
    // console.log(arrayCheckout.value)
  }

  const addtoCart = (product) => {
    console.log(product)
    if(localStorage.getItem('Cart')){
        cart.value = JSON.parse(localStorage.getItem('Cart'))
    }
    let params = {
        name: product.name,
        presentation: product.presentation,
        image: product.presentation.image,
        video: product.presentation.video,
        price: product.selectedOption,
        productId: product.product_id
    }
    if(cart.value.presentation){
      params.image = cart.value.presentation.filter(item => item.image)[0].image
      params.video = cart.value.presentation.filter(item => item.video)[0].video
    }
    console.log(params)
    cart.value.push(params)
    localStorage.setItem('Cart', JSON.stringify(cart.value))
    setCheckout()
  }

  const deleteCart = (id) => {
    if (localStorage.getItem('Cart')){
        cart.value = JSON.parse(localStorage.getItem('Cart'))
        cart.value.splice(id, 1);
        localStorage.setItem('Cart', JSON.stringify(cart.value))
        setCheckout()
    }
  }

  return { cart, totalPrice, totalDiscount, arrayCheckout, setCheckout, addtoCart, deleteCart, coinSelected, exchangeUpdate }
})