<template>
    <div class="col-12 d-flex flex-column justify-content-start align-items-center px-0" v-for="(item, id) in products">
        <div class="row justify-content-between row__width ps-3 slide">
            <div class="col-3 col-lg-2 d-flex justify-content-center align-items-center px-0 caption" v-if="item.presentation">
                <img v-if="item.image" :src="item.image" alt="">
            </div>
            <div class="col-9 col-lg-8 d-flex flex-column justify-content-center align-items-start" v-if="item.translations[0]">
                <h3>{{ item.translations[0].name }}</h3>
                <h4>{{ item.translations[0].description }}</h4>
            </div>
            <div class="col-2 d-flex justify-content-center align-items-center px-0 d-none">
                <div class="row justify-content-center row__width">
                    <div class="col-12 d-flex justify-content-start align-items-center">
                        <h3>{{ $t('message.type') }}</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center pt-2">
                        <button class="acciones">
                            <img src="../../img/panel-icons/acciones.svg" alt="">
                            {{ $t('message.actions') }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-2 d-flex justify-content-center align-items-center border-left px-0 d-none d-lg-flex">
                <router-link :to="'/editar-curso/1/'+item.id" class="btn__primario mirar__ahora">    
                    Editar
                </router-link>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center px-0 d-lg-none my-3">
                <router-link :to="'/editar-curso/1/'+item.id" class="btn__primario mirar__ahora">    
                    Editar
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject} from "vue";
const id = ref(0)
let products = inject('products')
</script>

<style lang="scss" scoped>
.slide{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    min-height: auto;
    padding: 10px 0;
    margin-bottom: 20px;
    button{
        border: 0;
        background-color: transparent;
        outline: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    .see-more{
        font-size: 14px;
        color: var(--colorPrimary);
        font-weight: 500;
        text-decoration: underline;
    }
    .border-left{
        border-left: 1px solid rgba($color: #000000, $alpha: 0.2);
    }
    h3{
        color: #0E1133;
        font-weight: 700;
        font-size: 16px;
    }
    h4{
        font-weight: 400;
        font-size: 16px;
        color: #B8C1DB;
        text-align: left;
        padding-top: 3px;
        max-height: 60px;
        max-width: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .acciones{
        color: #B8C1DB;
        font-size: 14px;
        font-weight: 500;
    }
    .dropdown-toggle{
        color: var(--colorSecondary);
        font-weight: 500;
        font-size: 13px;
        text-decoration: underline;
        padding: 0;
        img{
            margin-left: 5px;
        }
        &::after{
            display: none;
        }
        @media (min-width: 425px) {
            font-size: 16px;
        }
    }
    .caption{
        position: relative;
        height:83px ;
        img{
            border-radius: 10px;
            width: 100%;
            height:83px ;
            object-fit: cover;
        }
        &::before{
            content: '';
            position: absolute;
            bottom: 0;
            width: 108%;
            max-width: 100%;
            height: 100%;
            background: transparent linear-gradient(180deg, #252E4800 0%, #081026 100%) 0% 0% no-repeat padding-box;
            filter: opacity(1);
            border-radius: 0px 0px 10px 10px;
            z-index: 1;
        }
    }
}
.mirar__ahora{
    font-size: 14px;
    width: 90%;
    height: 40px;
}
</style>