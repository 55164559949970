<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start ps-lg-0">
        <NavbarCertificados :seccion="$t('message.myReferrals')"/>
        <NavLateralPanelAdmin :referrals="true"/>
        <div class="div-grey right" id="div-grey">
            <button class="boton-toggler" id="toggleSidebar" type="button" @click="toggleSidebar">
                <img src="../../../img/home-icons/arrow-left-blanco.svg" alt="">
            </button>
        </div>
        <div class="col d-flex flex-column justify-content-center align-items-center align-items-lg-start p-lg-3 classSpecial" :style="isSidebarOpen ? 'padding-left: 300px !important;'  : 'padding-left: 50px !important;'">
            <!--- AFILIADOS --->
            <form class="row justify-content-center row__width contenedor">
                <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0 ">
                    <div class="row justify-content-center row__width padding h-100">
                        <!-- <AtrasAdmin/> -->
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center mb-4">
                            <TituloPanelAdmin :title="$t('message.myReferrals')" :subtitle="$t('message.assingAndSee')"/>
                        </div>
                    </div>
                    <div class="row justify-content-center row__width px-lg-5 border-top ">
                        <div class="col-12 d-flex justify-content-center align-items-center div px-3 pb-2">
                            <div class="row justify-content-center row__width ">
                                <div class="col-12 d-flex justify-content-center align-items-center filtrosMobile">
                                    <div class="row justify-content-center align-items-center row__width">
                                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start">
                                            <h5>{{ $t('message.referrals') }}</h5>
                                            <h6>1200 {{ $t('message.actives') }}</h6>
                                        </div>
                                        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center contenedor px-0">
                                            <input type="text" :placeholder="$t('message.placeholderSearch')" v-model.trim="search.queries[0].q">
                                            <button><img src="../../../img/home-icons/lupa.svg" alt=""></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido px-0">
                                    <div class="row justify-content-center row__width scroll my-3">
                                        <Referidos/>
                                        <Referidos/>
                                        <Referidos/>
                                        <Referidos/>
                                        <Referidos/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-11 d-flex justify-content-center align-items-center addReferrals px-0 mt-4">
                            <form class="row justify-content-center row__width">
                                <div class="col-12 d-flex flex-column justify-content-center align-items-start mb-3">
                                    <h4>{{ $t('message.addReferrals') }}</h4>
                                    <h5>{{ $t('message.addReferralsEmail') }}</h5>
                                </div>
                                <div class="col-12 d-flex justify-content-between align-items-center">
                                    <input type="text" placeholder="placeholder" id="id">
                                    <button class="button">
                                        <img src="../../../img/panel-icons/perfil-mas.svg" alt="">
                                    </button>
                                </div>
                                <div class="col-12  d-flex justify-content-center align-items-center mt-4">
                                    <div class="row justify-content-start row__width">
                                        <div class="col-3 col-lg-2 d-flex justify-content-center  align-items-center ps-0">
                                            <Cancelar @click="back"/>
                                        </div>
                                        <div class="col-4 col-lg-3 d-flex justify-content-center align-items-center pe-0">
                                            <PrimarioV2 :textoBTNPrimarioV2="$t('message.sendInvitation')"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-center align-items-center mt-4 div-gris">
                                    <div class="row justify-content-center row__width">
                                        <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                            <h5 class="pt-0 pb-3">{{ $t('message.linkSale') }}</h5>
                                        </div>
                                        <div class="col-12 d-flex justify-content-start align-items-center px-0 position-relative">
                                            <input type="text">
                                            <button class="copy">
                                                <img src="../../../img/subir-productos-icons/copiar.svg" alt="">
                                                {{ $t('message.copyReferrals') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>  
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <FooterCertificados :activeCertificados="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../components/Navs/NavbarCertificados.vue";
import FooterCertificados from "../../../components/Footer/FooterCertificados.vue";
import AtrasAdmin from "../../../components/Botones/AtrasAdmin.vue";
import Referidos from "../../../components/Panel/Admin/Referidos/Referidos.vue";
import FooterGuardarSubirCurso from "../../../components/Botones/FooterGuardarSubirCurso.vue";
import NavLateralPanelAdmin from "../../../components/Navs/NavLateralPanelAdmin.vue";
import TituloPanelAdmin from "../../../components/Titulo/TituloPanelAdmin.vue";
import Cancelar from "../../../components/Botones/Cancelar.vue";
import PrimarioV2 from "../../../components/Botones/PrimarioV2.vue";
// Importo mi método
import { storeToRefs } from "pinia"
// Llamo a mi tienda de Pinia en forma de Setup 
import { useUsersStore } from "../../../stores/user"
import { useLanguageStore } from "../../../stores/langs"
import { onBeforeMount, ref, provide, watch , computed} from "vue"
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useI18n } from "vue-i18n";


// Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
const users = useUsersStore();
// Mis estados los paso a objetos porque en la tienda están en reactivos //
const {access} = storeToRefs(users);
const {} = users;
const {validate} = users;

const algo = onBeforeMount( () => {
    access.value = [1]
    //console.log(access.value) 
    validate();
}) 

const isSidebarOpen = ref(true)
provide(isSidebarOpen, 'isSidebarOpen')

const toggleSidebar = () => {
    isSidebarOpen.value = !isSidebarOpen.value
    if(document.getElementById('sidebar').classList.contains('d-none')){
        document.getElementById('sidebar').classList.remove('d-none')
    } else{
        document.getElementById('sidebar').classList.add('d-none')
    }
    if(document.getElementById('div-grey').classList.contains('right')){
        document.getElementById('div-grey').classList.remove('right')
        document.getElementById('div-grey').classList.add('left')
    } else{
        document.getElementById('div-grey').classList.remove('left')
        document.getElementById('div-grey').classList.add('right')
    }
}

const information = ref({

})

const router = useRouter();

const {locale} = useI18n({ useScope: 'global' })
const langId = ref()
const lang = ref('es')
const X = ref('')
const searchValue = ref()
const noHits = ref()

const props = defineProps({
    center: Boolean,
})

//Setea la cantidad de resultados a mostrar en el modal
const lProfessional = ref(0)
const lBrands = ref(0)
const lPackage = ref(0)
const lProduct = ref(0)

const url = 'https://testing.masterclass-back.knowdo.studio:7722'
const urlApi = '/multi-search'

//Setea parametros de busqueda para el post
const search = ref({
    queries:[{
        indexUid: 'professionals',
        q:'',
        limit: 2
    },{
        indexUid: 'brands',
        q:'',
        limit: 2
    },{
        indexUid: 'package_products',
        q:'',
        limit: 2
    },{
        indexUid: 'product_products',
        q:'',
        limit: 2
    }]
})

//Precarga parametros de resultados de busqueda
const rProfessional = ref(0)
const rBrands = ref(0)
const rPackage = ref(0)
const rProduct = ref(0)

const mostrarDivEstado = ref(false);
//Muestra u oculta al modal de busquedas
const mostrarDiv = () => {
    mostrarDivEstado.value = true;
};
const ocultarDiv = () => {
    mostrarDivEstado.value = false;
};
//Previene que el focus del modal se pierda al hacer click en el
const mantenerVisible = (event) => {
    event.preventDefault();
};

//Setea valores segun el lenguaje -- POSIBLEMENTE NO SE USE EN EL FUTURO --
const setLang = () => {
    lang.value = locale.value
    //console.log(lang.value)
    if (locale.value == 'es'){
        langId.value = 0
    }
    else if (locale.value == 'en'){
        langId.value = 1
    }
}
setLang()

const inputValue = () => {
    if (X.value == undefined){
        sessionStorage.setItem('searchValue', "")
    }
    else {
        searchValue.value = X.value
        console.log(X.value)
        sessionStorage.setItem('searchValue', JSON.stringify(searchValue.value))
    }
    router.push('/resultados-generales')
}

//Hago un post del usuario x
const fetchSearch =  async () => {
    setLang()
    search.value.queries[0].q = X
    search.value.queries[1].q = X
    search.value.queries[2].q = X
    search.value.queries[3].q = X
    //console.log()
    try {
        await axios.post(url+urlApi, search.value).then((result) => {
            const resultData = (x) => { return result.data.results[x].hits }
            const resultLength = (x) => { return result.data.results[x].hits.length }
            rProfessional.value = resultData(0)
            rBrands.value = resultData(1)
            rPackage.value = resultData(2)
            rProduct.value = resultData(3)
            //console.log(result.data.results)
            lProfessional.value = resultLength(0)
            lBrands.value = resultLength(1)
            lPackage.value = resultLength(2)
            lProduct.value = resultLength(3)
        })
    }
    catch (error) {
    console.log(error)
    }
}
fetchSearch()
</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #fff;
    min-height: 100vh;
    @media (min-width: 992px) {
        margin-top: 77px;
    }
}
.contenedor{
    box-shadow: 0px 3px 10px #00000029;
    border: 1px solid #ECECEC;
    background-color: var(--bgPrincipal);
    border-radius: 40px;
    min-height: 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
    @media (min-width: 992px) {
        border-radius: 50px;
        padding: 22px 15px;
    }
    @media (min-width: 992px) {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding: 40px 0 30px 0;
        min-height: auto;
    } 
    .filtrosMobile{
        h5{
            color: var(--colorSecondary);
            font-weight: 700;
            font-size: 22px;
            text-align: left;
        }
        h6{
            color: #697891;
            font-weight: 500;
            font-size: 16px;
            text-align: left;
            padding-top: 10px;
        }
        .contenedor{
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #ECECEC;
            background-color: var(--bgPrincipal);
            border-radius: 40px;
            min-height: 50px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 15px;
            @media (min-width: 992px) {
                border-radius: 40px;
            }
            @media (max-width: 991px) {
                width: 100%;
            }
            .img{
                padding: 0 20px;
            }
            input{
                border: 0;
                color: #B8C1DB;
                font-weight: 400;
                font-size: 14px;
                width: 100%;
                &::placeholder{
                    color: #B8C1DB;
                }
                &:focus{
                    outline: 0;
                }
                @media (min-width: 992px) {
                    color: #697891;
                    font-size: 16px;
                    width: 93%;
                }
            }
            button{
                position: absolute;
                right: 7px;
                border: 0;
                min-height: 45px;
                width: 45px;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 50%;
                background-color: var(--colorPrimary);
                justify-content: center;
                align-items: center;
                img{
                    padding: 0;
                    max-height: 22px;
                }
                @media (min-width: 992px) {
                    right: 10px;
                }
            }
            .border-left{
                border-left: 1px solid rgba(184, 193, 219, 0.4);
            }
        }
    }
    .div{
        background-color: #fff;
        box-shadow: 0px 3px 10px #00000029;
        border-radius: 20px;
        padding: 40px 0 30px 0;
        min-height: auto;
        margin: 40px 0;
        input{
            border: 0;
            color: #B8C1DB;
            font-weight: 400;
            font-size: 14px;
            width: 100%;
            &::placeholder{
                color: #B8C1DB;
            }
            &:focus{
                outline: 0;
            }
            @media (min-width: 992px) {
                color: #697891;
                font-size: 18px;
                width: 80%;
            }
        }
        button{
            position: absolute;
            right: 7px;
            border: 0;
            min-height: 60px;
            width: 60px;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 50%;
            background-color: var(--colorPrimary);
            justify-content: center;
            align-items: center;
            img{
                padding: 0;
            }
            @media (min-width: 992px) {
                right: 20px;
            }
        }
        .scroll{
            overflow-y: scroll;
            overflow-x: hidden;
            max-width: 100%;
            max-height: 500px;
            &::-webkit-scrollbar{
                -webkit-appearance: none;
                width: 5px;
                height: 5px;
            }
            &::-webkit-scrollbar-track{
                -webkit-appearance: none;
                background-color:#dbdbdb73;
                border-radius: 5px;
                width: 5px;
                height: 5px;
            }
            &::-webkit-scrollbar-thumb{
                -webkit-appearance: none;
                background-color:#DBDBDB;
                border-radius: 5px;
                width: 5px;
                height: 5px;
            }
        }
    }
    .addReferrals{
        input{
            width: 94%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: var(--bgPrincipal);
            height: 50px;
            padding-left: 15px;
            outline: 0;
            color: #697891;
            font-size: 15px;
            font-weight: 400;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                color: #697891;
            }
            @media (max-width: 991px) {
                width: 83%;
            }
        }
        .button{
            background-color: #fff;
            border: 2px solid #E9E3E3;
            outline: 0;
            border-radius: 25px;
            min-height: 40px;
            min-width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                height: 20px;
            }
        }
        h4{
            color: var(--colorSecondary);
            font-weight: 700;
            font-size: 22px;
            text-align: left;
        }
        h5{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 16px;
            text-align: left;
            padding-top: 30px;
        }
        .div-gris{
            background-color: #F5F5F5;
            border-radius: 15px;
            padding: 30px;
            input{
                width: 100%;
            }
        }
        .copy{
            background-color: #fff;
            border: 2px solid var(--colorPrimary);
            color: var(--colorPrimary);
            font-weight: 600;
            font-size: 16px;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: 0 10px;
            width: 160px;
            position: absolute;
            right: -13px;
            img{
                margin-right: 10px;
            }
        }
    }   
}
.classSpecial{
   @media (min-width: 992px) {
        width: 70vw !important;
   }
}

</style>