<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start ps-lg-0">
        <NavbarCertificados :seccion="$t('message.myAffiliates')"/>
        <NavLateralPanel :afilities="true"/>
        <div class="div-grey right" id="div-grey">
            <button class="boton-toggler" id="toggleSidebar" type="button" @click="toggleSidebar">
                <img src="../../../img/home-icons/arrow-left-blanco.svg" alt="">
            </button>
        </div>
        <div class="col-12 col-lg d-flex flex-column justify-content-center align-items-center align-items-lg-start p-lg-3 classSpecial" :style="isSidebarOpen ? 'padding-left: 300px !important;'  : 'padding-left: 50px !important;'">
            <!--- AFILIADOS --->
            <form class="row justify-content-center row__width contenedor">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center align-items-lg-start px-0 ">
                    <div class="row justify-content-center row__width padding h-100">
                        <atrasBTNCertificados/>
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center mb-4">
                            <TituloAfiliados/>
                        </div>
                    </div>
                    <div class="row justify-content-center row__width px-lg-4 border-top">
                        <div class="col-12  d-flex flex-column justify-content-center align-items-center tablas">
                            <div class="scroll d-flex justify-content-start align-items-center">
                                <ul class="nav nav-tabs row justify-content-start" id="myTab2" role="tablist">
                                    <li class="nav-item col col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link active" id="info2-tab" data-bs-toggle="tab" data-bs-target="#info2" type="button" role="tab" aria-controls="info2" aria-selected="true">{{ $t('message.affilities') }}</button>
                                    </li>
                                    <li class="nav-item col col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link " id="question-tab" data-bs-toggle="tab" data-bs-target="#question" type="button" role="tab" aria-controls="question" aria-selected="false">Mis solicitudes</button>
                                    </li>
                                    <li class="nav-item col col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link " id="question-tab" data-bs-toggle="tab" data-bs-target="#question" type="button" role="tab" aria-controls="question" aria-selected="false">Pago y desempeño</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center d-lg-none filtrosMobile d-lg-none">
                            <div class="row justify-content-center row__width">
                                <div class="col-12 d-flex justify-content-center align-items-start">
                                    <img src="../../../img/subir-productos-icons/cursos-icons/refresh.svg" alt="">
                                    <h6>{{ $t('message.searchAffiliate') }}</h6>
                                </div>
                                <div class="col-12 d-flex justify-content-center align-items-center d-lg-none px-0">
                                    <div class="contenedor">
                                        <input type="text" :placeholder="$t('message.placeholderSearch')" v-model.trim="search.queries[0].q">
                                        <button><img src="../../../img/home-icons/lupa.svg" alt=""></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center filtros d-none d-lg-flex">
                            <div class="row justify-content-center row__width">
                                <div class="col d-flex justify-content-center align-items-center pe-0">
                                    <div class="dropdown">
                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div class="d-flex justify-content-between align-items-center w-100">
                                                <h3>{{ $t('message.byName') }}</h3>
                                                <i class="arrow"></i>
                                            </div>
                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a class="dropdown-item" href="#">
                                                    <span>
                                                        <img src="../../../img/subir-productos-icons/cursos-icons/clases.svg" alt="">
                                                    </span>
                                                    Clases
                                                </a></li>
                                                <li><a class="dropdown-item" href="#">
                                                    <span>
                                                        <img src="../../../img/subir-productos-icons/cursos-icons/proyecto-final.svg" alt="">
                                                    </span>
                                                    Proyecto final
                                                </a></li>
                                                <li><a class="dropdown-item" href="#">
                                                    <span>
                                                        <img src="../../../img/subir-productos-icons/cursos-icons/cuestionario.svg" alt="">
                                                    </span>
                                                    Cuestionario
                                                </a></li>
                                            </ul>
                                        </button>
                                    </div>
                                </div>
                                <div class="col d-flex justify-content-center align-items-center pe-0">
                                    <div class="dropdown">
                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div class="d-flex justify-content-between align-items-center w-100">
                                                <h3>{{ $t('message.byCategory') }}</h3>
                                                <i class="arrow"></i>
                                            </div>
                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a class="dropdown-item" href="#">
                                                    <span>
                                                        <img src="../../../img/subir-productos-icons/cursos-icons/clases.svg" alt="">
                                                    </span>
                                                    Clases
                                                </a></li>
                                                <li><a class="dropdown-item" href="#">
                                                    <span>
                                                        <img src="../../../img/subir-productos-icons/cursos-icons/proyecto-final.svg" alt="">
                                                    </span>
                                                    Proyecto final
                                                </a></li>
                                                <li><a class="dropdown-item" href="#">
                                                    <span>
                                                        <img src="../../../img/subir-productos-icons/cursos-icons/cuestionario.svg" alt="">
                                                    </span>
                                                    Cuestionario
                                                </a></li>
                                            </ul>
                                        </button>
                                    </div>
                                </div>
                                <div class="col d-flex justify-content-center align-items-center pe-0">
                                    <div class="dropdown">
                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div class="d-flex justify-content-between align-items-center w-100">
                                                <h3>{{ $t('message.typeOfProduct') }}</h3>
                                                <i class="arrow"></i>
                                            </div>
                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a class="dropdown-item" href="#">
                                                    <span>
                                                        <img src="../../../img/subir-productos-icons/cursos-icons/clases.svg" alt="">
                                                    </span>
                                                    Clases
                                                </a></li>
                                                <li><a class="dropdown-item" href="#">
                                                    <span>
                                                        <img src="../../../img/subir-productos-icons/cursos-icons/proyecto-final.svg" alt="">
                                                    </span>
                                                    Proyecto final
                                                </a></li>
                                                <li><a class="dropdown-item" href="#">
                                                    <span>
                                                        <img src="../../../img/subir-productos-icons/cursos-icons/cuestionario.svg" alt="">
                                                    </span>
                                                    Cuestionario
                                                </a></li>
                                            </ul>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-4 d-flex justify-content-center align-items-center">
                                    <input type="text">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido">
                            <div class="tab-content row justify-content-center row__width" id="myTabContent2">
                                <!--- INFORMACIÓN BÁSICA --->
                                <div class="col-12 tab-pane fade show active" id="info2" role="tabpanel" aria-labelledby="info2-tab">
                                    <div class="row justify-content-center">
                                        <FormAfiliadosView/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <div class="row justify-content-start row__width settings">
                                <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0 mt-4">
                                    <h2>{{ $t('message.settingCount') }}</h2>
                                </div>
                                <div class="col-12 col-lg-7 d-flex justify-content-between align-items-center mb-3 switch ps-0">
                                    <div class="d-flex justify-content-start align-items-center">
                                        <p class="sin-padding">{{ $t('message.approvedAllRequests') }}</p>
                                    </div>
                                    <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                                        <input class="form-check-input" type="checkbox" role="switch" id="mandatory" v-model="information.mandatory">
                                        <label class="form-check-label" for="mandatory">
                                            <p v-if="information.mandatory">{{ $t('message.yes') }}</p>
                                            <p v-else>{{ $t('message.no') }}</p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterGuardarSubirCurso/>
            </form>
        </div>
        <FooterCertificados :activeCertificados="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../components/Navs/NavbarCertificados.vue";
import FooterCertificados from "../../../components/Footer/FooterCertificados.vue";
import NavLateralPanel from "../../../components/Navs/NavLateralPanel.vue";
import atrasBTNCertificados from "../../../components/Botones/Atras.vue";
import TituloAfiliados from "../../../components/Titulo/TituloAfiliados.vue";
import FormAfiliadosView from "../../../components/Panel/SubirProductos/FormAfiliadosView.vue";
import FooterGuardarSubirCurso from "../../../components/Botones/FooterGuardarSubirCurso.vue";
// Importo mi método
import { storeToRefs } from "pinia"
// Llamo a mi tienda de Pinia en forma de Setup 
import { useUsersStore } from "../../../stores/user"
import { useLanguageStore } from "../../../stores/langs"
import { onBeforeMount, ref, provide, watch , computed} from "vue"
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useI18n } from "vue-i18n";

// Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
const users = useUsersStore();
// Mis estados los paso a objetos porque en la tienda están en reactivos //
const {access} = storeToRefs(users);
const {} = users;
const {validate} = users;

const algo = onBeforeMount( () => {
    access.value = [1, 3]
    //console.log(access.value) 
    validate();
}) 

const isSidebarOpen = ref(true)
provide(isSidebarOpen, 'isSidebarOpen')

const toggleSidebar = () => {
    isSidebarOpen.value = !isSidebarOpen.value
    if(document.getElementById('sidebar').classList.contains('d-none')){
        document.getElementById('sidebar').classList.remove('d-none')
    } else{
        document.getElementById('sidebar').classList.add('d-none')
    }
    if(document.getElementById('div-grey').classList.contains('right')){
        document.getElementById('div-grey').classList.remove('right')
        document.getElementById('div-grey').classList.add('left')
    } else{
        document.getElementById('div-grey').classList.remove('left')
        document.getElementById('div-grey').classList.add('right')
    }
}

const information = ref({

})

const router = useRouter();

const {locale} = useI18n({ useScope: 'global' })
const langId = ref()
const lang = ref('es')
const X = ref('')
const searchValue = ref()
const noHits = ref()

const props = defineProps({
    center: Boolean,
})

//Setea la cantidad de resultados a mostrar en el modal
const lProfessional = ref(0)
const lBrands = ref(0)
const lPackage = ref(0)
const lProduct = ref(0)

const url = 'https://testing.masterclass-back.knowdo.studio:7722'
const urlApi = '/multi-search'

//Setea parametros de busqueda para el post
const search = ref({
    queries:[{
        indexUid: 'professionals',
        q:'',
        limit: 2
    },{
        indexUid: 'brands',
        q:'',
        limit: 2
    },{
        indexUid: 'package_products',
        q:'',
        limit: 2
    },{
        indexUid: 'product_products',
        q:'',
        limit: 2
    }]
})

//Precarga parametros de resultados de busqueda
const rProfessional = ref(0)
const rBrands = ref(0)
const rPackage = ref(0)
const rProduct = ref(0)

const mostrarDivEstado = ref(false);
//Muestra u oculta al modal de busquedas
const mostrarDiv = () => {
    mostrarDivEstado.value = true;
};
const ocultarDiv = () => {
    mostrarDivEstado.value = false;
};
//Previene que el focus del modal se pierda al hacer click en el
const mantenerVisible = (event) => {
    event.preventDefault();
};

//Setea valores segun el lenguaje -- POSIBLEMENTE NO SE USE EN EL FUTURO --
const setLang = () => {
    lang.value = locale.value
    //console.log(lang.value)
    if (locale.value == 'es'){
        langId.value = 0
    }
    else if (locale.value == 'en'){
        langId.value = 1
    }
}
setLang()

const inputValue = () => {
    if (X.value == undefined){
        sessionStorage.setItem('searchValue', "")
    }
    else {
        searchValue.value = X.value
        console.log(X.value)
        sessionStorage.setItem('searchValue', JSON.stringify(searchValue.value))
    }
    router.push('/resultados-generales')
}

//Hago un post del usuario x
const fetchSearch =  async () => {
    setLang()
    search.value.queries[0].q = X
    search.value.queries[1].q = X
    search.value.queries[2].q = X
    search.value.queries[3].q = X
    //console.log()
    try {
        await axios.post(url+urlApi, search.value).then((result) => {
            const resultData = (x) => { return result.data.results[x].hits }
            const resultLength = (x) => { return result.data.results[x].hits.length }
            rProfessional.value = resultData(0)
            rBrands.value = resultData(1)
            rPackage.value = resultData(2)
            rProduct.value = resultData(3)
            //console.log(result.data.results)
            lProfessional.value = resultLength(0)
            lBrands.value = resultLength(1)
            lPackage.value = resultLength(2)
            lProduct.value = resultLength(3)
        })
    }
    catch (error) {
    console.log(error)
    }
}
fetchSearch()
</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #fff;
    min-height: 100vh;
    @media (min-width: 992px) {
        margin-top: 77px;
    }
}
.contenedor{
   @media (min-width: 992px) {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding: 40px 0 30px 0;
        min-height: auto;
   }
}
.border-bottom2{
    @media (min-width:992px) {
        border-bottom: 1px solid #E4E4E4;
        padding-bottom: 20px;
    }
}

.classSpecial{
   @media (min-width: 992px) {
        width: 70vw !important;
   }
}
.border-top{
    border-top: 2px solid #E4E4E4;
    padding-top: 20px;
}

.tablas{  
    background-color: transparent;
    min-height: 56px;
    margin-bottom: 30px;
    border-bottom: 1px solid #70707023;
    box-shadow: 0px 3px 6px #00000016;
    border-radius: 10px;
    ul{
        background-color: transparent;
        padding: 8px 0;
    }
    .nav-item{
        padding: 0;
    }
    .nav-link{
        width: 100%;
        position: relative;
        padding: 10px 0;
        border: 0;
        background-color: transparent;
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        color: #B8C1DB;
        h3{
            font-weight: 500;
            font-size: 16px;
            color: #B8C1DB;
        }
        @media (min-width: 992px) {
            color: #0E1133;
            h3{
                color: #0E1133;
            }
        }
    }
    .nav-link.active{
        font-weight: 700;
        border-color: transparent;
        color: var(--colorPrimary);
        background-color: transparent;
        border-radius: 0;
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 5px;
            bottom: -10px;
            left: 0;
            background-color: var(--colorPrimary);
            border-radius: 10px;
        }
    }
    .scroll{
        overflow-x: scroll;
        overflow-y: hidden;
        max-width: 100%;
        height: 100%;
        ul{
            min-width: 150vw;
            height: 100%;
            button{
                height: 100%;
                min-width: 150px;
            }
        }
        @media (min-width: 992px) {
            overflow: hidden;
            max-width: none;
            width: 100%;
            ul{
                max-width: 100%;
                min-width: 1vw;
                width: 100%;
            }
        }
    }
    
}
.contenido{
    .div-gris{
        background-color: #F5F5F5;
        height: auto;
        padding: 20px 20px;
        border-radius: 10px;
        box-shadow: 0px 3px 6px #0000001f;
    }
    h3{
        font-weight: 600;
        color: var(--colorSecondary);
        font-size: 18px;
        text-align: left;
        margin-bottom: 10px !important;
    }
    
    input[type=text], select{
        background-color: #FCFCFC;
        font-weight: 400;
        color: #697891;
        font-size: 16px;
        border-radius: 10px;
        border: 2px solid #E9E3E3;
        height: 50px;
        -webkit-appearance: none;
    }
    .agregar-idioma{
        .col{
            max-width: 40px;
            margin-right: 15px;
        }
        button{
            background-color: var(--bgPrincipal);
            border: 0;
            border-radius: 50%;
            height: 24px;
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: -7px;
            top: -5px;
        }
    }
    

    .collapses{
        h3{
            margin-bottom: 5px !important;
        }
        h4{
            font-weight: 400;
            font-size: 15px;
            color: #B8BCC8;
            text-align: left;
        }
        .accordion{
            width: 100%;
            .accordion-item{
                margin: 15px 0px;
                border: 0;
                border-radius: 10px;
            }
            .accordion-item:last-of-type .accordion-button.collapsed{
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            .accordion-header{
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 10px;
                background-color: #F9F9F9;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                .accordion-button{
                    border-radius: 10px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    background-color: #F9F9F9;
                    &:focus{
                        border-color: inherit;
                        box-shadow: none;
                    }
                    span{
                        border: 2px solid #E9E3E3;
                        height: 50px;
                        width: 50px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                .accordion-button:not(.collapsed){
                    background-color: inherit;
                    box-shadow: none;
                }
                .accordion-button::after{
                    position: absolute;
                    right: 5%;
                    background-image: url('../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                    background-size: 100%;
                    background-position: center;
                }
            }
        }
        @media (min-width: 992px) {
            margin: 20px 0 60px 0;
        }
    }
    .bg-gris{
        background-color: #F5F5F5;
        padding: 30px 20px 20px 20px;
        border-radius: 10px;
        h4{
            font-weight: 600;
            color: #343232;
            font-size: 20px;
            text-align: center;
            margin-bottom: 10px !important;
        }
        h5{
            font-weight: 400;
            color: #BEBDBD;
            font-size: 18px;
            text-align: center;
            margin-bottom: 10px !important;
            max-width: 54%;
            line-height: 27px;
        }
    }
}
.agregar-cursos-btn, .agregar-cursos-btn-3{
    box-shadow: 0 3px 6px #00000016;
    border-radius: 43px;
    height: 60px;
    margin: 20px 0;
    border: 0;
    background-color: var(--bgPrincipal);
    font-weight: 600;
    font-size: 16px;
    color: var(--colorSecondary);
    padding-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    max-width: 300px;
    span{
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
    }
}
.agregar-cursos-btn-3{
    display: flex;
    justify-content: space-between;
    span{
        margin: 0;
    }
}
.settings{
    h2{
        font-size: 22px;
        color: var(--colorSecondary);
        font-weight: 600;
        padding-bottom: 10px;
    }
    .switch{
        .form-switch .form-check-input{
            width: 48px;
            height: 23px;
            background-color: #E4E4E4;
            background-image: url('../../../img/panel-icons/check-icon.svg');
            background-size: 40%;
            border: 0;
            background-position-x: 2px;
        }
        .form-switch .form-check-input:focus{
            outline: 0;
            box-shadow: none;
            border: 0;
            background-image: url('../../../img/panel-icons/check-icon.svg');
            background-size: 40%;
        }
        .form-switch .form-check-input:checked{
            background-color: var(--colorPrimary);
            background-position-x: 27px;
        }
        p{
            font-weight: 500;
            padding-left: 10px;
            width: 25px;
        }
        .sin-padding{
            padding: 0;
            width: auto;
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 400;
        }
        button{
            background-color: transparent;
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            margin-left: 10px;
        }
        h5{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            padding-left: 10px;
        }
    
    }
    .col-perso{
        @media (min-width: 992px) {
            max-width: 55%;
        }
    }
    p{
        text-align: left;
    }
}
.filtros{
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    background-color: var(--bgPrincipal);
    border-radius: 33px;
    height: 66px;
    .dropdown{
        width: 100%;
        button{
            width: 100%;
            background-color: var(--bgPrincipal);
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 600;
            border: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            border-radius: 10px;
            padding: 0 20px;
            h3{
                padding: 0px 0;
                font-size: 16px;
                font-weight: 500;
            }
            .dropdown-menu{
                background-color: var(--bgPrincipal);
                width: 100%;
                border: 0;
                padding: 20px 20px 0 20px;
                height: auto;
                inset: 12px auto auto 0px !important;
                border-radius: 0 0 10px 10px ;
                a:hover, a:focus{
                    background-color: inherit;
                }
                a{
                    padding: 0 0 10px 0;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    color: #2E4765;
                    font-size: 15px;
                    font-weight: 400;
                }
                span{
                    height: 40px;
                    width: 40px;
                    border: 2px solid #E4E4E4;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 10px;
                }
                li{
                    width: 100%;
                }
            }
        }
        .dropdown-toggle::after{
            display: none;
        }
        .arrow{
            width: 25px;
            height: 15px;
            background-repeat: no-repeat;
            background-image: url('../../../img/subir-productos-icons/arrow-abajo-azul.svg');
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all ease 0.2s;
            background-size: 75%;
            background-position: center;
        }
    }
    .dropdown-toggle.show{
        .dropdown-menu{
            box-shadow: 2px 2px 10px hsla(0, 0%, 0%, 0.15);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .arrow{
            transform: rotate(180deg);
            transition: all ease 0.2s;
        }
    }
    input{
        width: 100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: #FCFCFC;
        height: 50px;
        padding-left: 15px;
        outline: 0;
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        -webkit-appearance: none;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }
}
.filtrosMobile{
    h6{
        color: #B8C1DB;
        font-weight: 400;
        font-size: 14px;
        padding-left: 15px;
        text-align: left;
    }
    .contenedor{
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #ECECEC;
        background-color: var(--bgPrincipal);
        border-radius: 40px;
        min-height: 66px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
        margin-top: 20px;
        @media (min-width: 992px) {
            border-radius: 50px;
            padding: 22px 15px;
        }
        @media (max-width: 991px) {
            width: 100%;
        }
        .img{
            padding: 0 20px;
        }
        h3{
            color: var(--colorSecondary);
            font-size: 18px;
            font-weight: 600;
            padding: 5px 0px;
            @media (min-width: 992px) {
                font-weight: 500;
                padding-top: 0;
                padding-bottom: 5px;
            }
        }
        input{
            border: 0;
            color: #B8C1DB;
            font-weight: 400;
            font-size: 14px;
            width: 100%;
            &::placeholder{
                color: #B8C1DB;
            }
            &:focus{
                outline: 0;
            }
            @media (min-width: 992px) {
                color: #697891;
                font-size: 18px;
                width: 80%;
            }
        }
        button{
            position: absolute;
            right: 7px;
            border: 0;
            min-height: 50px;
            width: 50px;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 50%;
            background-color: var(--colorPrimary);
            justify-content: center;
            align-items: center;
            img{
                padding: 0;
                max-height: 22px;
            }
            @media (min-width: 992px) {
                right: 20px;
            }
        }
        .border-left{
            border-left: 1px solid rgba(184, 193, 219, 0.4);
        }
    }
}
</style>