<template>
    <div class="row justify-content-center row__width inputs">
        <div class="col-12 d-flex flex-column justify-content-center align-items-start">
            <h3>{{$t('message.country')}}</h3>
            <MazSelect
                class="row__width"
                v-model="usuariosRegistro.country"
                :placeholder="$t('message.country')"
                color="info"
                :options="countriesList"
                :class="estados.country"
                name="country"
                @update:model-value="updateInput()"
                search
                search-placeholder="Buscar en la lista"
            />
        </div>
    </div>
</template>

<script setup>
// Importo mi método
// Llamo a mi tienda de Pinia en forma de Setup 
import { useUsersStore } from "../../stores/user";
import { useGeneralStore } from "../../stores/general"    
import { storeToRefs } from "pinia";
import { inject, onBeforeMount, ref, watch } from 'vue';
import MazSelect from 'maz-ui/components/MazSelect'
import countriesJson from '../../assets/countries.json'
import axios from "axios";

const general = useGeneralStore();
const {} = storeToRefs(general);
const {} = general;
const estados = inject('estados');
// Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
const users = useUsersStore();
// Mis estados los paso a objetos porque en la tienda están en reactivos //
const {usuariosRegistro} = storeToRefs(users);
const {} = users;

const countriesList = ref()

onBeforeMount(() => {
    countriesList.value = countriesJson.map(country => ({
        label: country.name.common,
        value: country.cca2
    }));
    console.log('Listo los paises')
})

const updateInput = () => {
    estados.value.country = ''
}

</script>

<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 7px 0px;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: transparent;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
.success-style {
	div div {
		border-width: 0 !important;
		border: 2px solid #66df4a !important;
		border-color: #66df4a !important;
	}
}
.numeros{
	input{
		display: none;
	}
}
</style>